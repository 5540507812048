import { FeaturedBlogCard } from "components";
import { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Image, Spinner, Overlay, Popover } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { Button, Card } from 'ui-neumorphism';
import * as adminActions from "actions/AdminActions";
import { MdModeEdit } from "react-icons/md";
import { avtar } from "assets";
import "core-ui/pages/Blog.scss";
import { useDispatch, useSelector } from "react-redux";

const Blog = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.user);

  const ref = useRef();

  useEffect(async () => {
    const response = await adminActions.loadBlog(id);
    const allBlogs = await adminActions.loadBlogs();
    setBlog(response);
    setBlogs(allBlogs);
  }, [])

  const deleteBlog = async (e) => {
    setIsDeleting(true)
    e.preventDefault();
    const response = await adminActions.deleteBlog(id);
    if (response) {
      navigate(`/admin/blogs`)
    }
    setIsDeleting(false)
  }

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Container className="my-5 py-5 user-select-none">
      <ul className="breadcrumb subtitle mx-1 mb-3">
        <li>
          <a href="/admin/blogs">Blogs</a>
        </li>
        <li>
          <a href="/admin/blogs">{blog?.blog_type_data?.title}</a>
        </li>
        <li>{blog?.title}</li>
      </ul>
      <Row className="my-5">
        <Col xs={12}>
          <div className="d-flex justify-content-end my-4" ref={ref}>
            <Link className="text-decoration-none" to={`/admin/blogs/${id}/edit/`}>
              <Button dark={true} className="explore-button">
                <MdModeEdit size={20} />&nbsp;Edit blog
              </Button>
            </Link>
            {user.is_superuser &&
              <Button dark={true} className="explore-button ms-4" onClick={handleClick}>
                <>
                  <AiOutlineDelete size={20} className="mx-1" /> Delete Blog
                </>
              </Button>
            }
            <Overlay
              show={show}
              target={target}
              placement="left"
              container={ref}
              containerPadding={20}
            >
              <Popover id="popover-contained"
                style={{ backgroundColor: "#555", border: "1px solid white" }}>
                <Popover.Header as="h5" className="text-white" style={{ backgroundColor: "#333" }}>Confirm Blog Delete</Popover.Header>
                <Popover.Body>
                  <div className="d-flex justify-content-between">
                    <Button
                      dark={true}
                      onClick={(e) => {
                        setShow(false);
                        // dispatch(adminActions.archiveProduct(item?.id));
                        deleteBlog(e);
                      }}
                    >
                      {isDeleting ? (
                        <span>
                          <Spinner
                            className="me-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          DELETING...
                        </span>
                      ) : (
                        <>
                          Confirm
                        </>
                      )}
                    </Button>
                    <Button dark={true} onClick={() => setShow(false)} className="mx-2">Cancel</Button>
                  </div>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>
        </Col>
        <Col lg={9} className="px-3 text-white overflow-auto" style={styles.parentContainer}>
          <h4 className="mb-4">{blog?.title}</h4>
          <div className="w-100 d-flex justify-content-center align-items-center overflow-hidden" style={{ height: 400, borderRadius: 30 }}>
            <Image loading="lazy" className="w-100" src={blog?.blog_image ? blog?.blog_image : ""} alt={blog?.title} style={{ maxWidth: "100%", height: "auto" }} />
          </div>
          <div className="my-5" dangerouslySetInnerHTML={{ __html: blog?.content }} />
        </Col>
        <Col lg={3} className="overflow-auto" style={styles.parentContainer}>
          <Card className="border-0 p-4" dark={true} outlined style={{ backgroundColor: "#27292A" }}>
            <h5>Content</h5>
            <div dangerouslySetInnerHTML={{ __html: blog?.table_content }} className="text-white">

            </div>
          </Card>
          <Card className="border-0 my-5 p-4" dark={true} outlined style={{ backgroundColor: "#27292A" }}>
            <Row>
              <Col xs={4}>
                <div className="p-0 m-0" style={{ backgroundColor: "#fff", borderRadius: 45 }}>
                  <Image loading="lazy" src={avtar} alt="Auther Picture" className="w-100" />
                </div>
              </Col>
              <Col xs={8} className="d-flex flex-column justify-content-between">
                <div>
                  <h6>{blog?.author_name}</h6>
                </div>
                <div className="d-flex justify-content-between" style={{ fontSize: 12 }}>
                  <div>
                    {blog?.published_date}
                  </div>
                  <div>
                    {blog?.read_time}
                  </div>
                </div>
              </Col>
            </Row>
          </Card>

          <h5 className="text-white">Featured Blogs</h5>
          {blogs?.map((item, index) => <FeaturedBlogCard key={index} blog={item} read_url={`/admin/blogs/${item.slug}/`} />)}

          {/* <Row>
                        <BlogCard/>
                        <BlogCard/>
                        <BlogCard/>
                        <BlogCard/>
                        <BlogCard/>
                        <BlogCard/>
                        <BlogCard/>
                    </Row> */}
        </Col>
      </Row>
    </Container>
  );
}

const styles = {
  parentContainer: {
    height: "100vh"
  }
}

export default Blog;