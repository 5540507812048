import { useRef } from "react";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { IoSearch } from "react-icons/io5";
import { AiOutlineCalendar } from "react-icons/ai"

const ModuleDatePicker = ({ startDateTime, endDateTime, setStartDateTime, setEndDateTime, fetchData }) => {
  const today = new Date();
  const startRef = useRef();
  const endRef = useRef();

  const handleStartIconClick = () => {
    if (startRef.current.state.open) {
      startRef.current.setOpen(false);
    }
    else {
      startRef.current.setOpen(true);
    }
  };

  const handleEndIconClick = () => {
    if (endRef.current.state.open) {
      endRef.current.setOpen(false);
    }
    else {
      endRef.current.setOpen(true);
    }
  };

  return (
    <div className="bg-light-grey w-100 px-0" >
      <div className="d-flex align-items-md-end align-items-center mb-3 from-group-mob">
        <div className="d-flex flex-column me-2 text-white">
          <Form.Label>Start Date</Form.Label>
          <div className="d-flex justify-content-between align-items-center p-2 bg-black border-0 rounded" >
            <DatePicker
              className="border-0 outline-none text-white bg-black"
              dateFormat="yyyy-MM-dd HH:mm"
              selected={moment(startDateTime).toDate()}
              onChange={(date) => {
                date && setStartDateTime(moment(date).format("YYYY-MM-DDTHH:mm:ss"))
              }}
              ref={startRef}
              selectsStart
              startDate={startDateTime}
              endDate={endDateTime}
              showTimeSelect
            />
            <AiOutlineCalendar
              size={22}
              className="mx-2 cursor-pointer text-white"
              onClick={handleStartIconClick}
            />
          </div>
        </div>
        <div className="d-flex flex-column me-2 text-white">
          <Form.Label>End Date</Form.Label>
          <div className="d-flex justify-content-between align-items-center p-2 bg-black border-0 rounded">
            <DatePicker
              className="border-0 outline-none text-white bg-black"
              dateFormat="yyyy-MM-dd HH:mm"
              selected={moment(endDateTime).toDate()}
              onChange={(date) => {
                date && setEndDateTime(moment(date).format("YYYY-MM-DDTHH:mm:ssZ"));
              }}
              selectsEnd
              ref={endRef}
              startDate={startDateTime}
              endDate={endDateTime}
              showTimeSelect
              style={{ width: "150px" }}
            />
            <AiOutlineCalendar
              size={22}
              className="mx-2 cursor-pointer text-white"
              onClick={handleEndIconClick}
            />
          </div>
        </div>
        <div sm={3} md={5} lg={3} className="d-flex align-items-md-end mt-md-0 mt-3">
          <Button
            className="d-flex mx-md-2 align-items-center explore-button-no-neo"
            onClick={() => fetchData()}
          >
            <IoSearch size={20} />
            <span>Search</span>
          </Button>
        </div>
      </div>
      <div className="d-flex flex-wrap gap-1 filter-btn-mob">
        <Button
          size="md"
          type="button"
          className="me-2 px-5 py-2"
          onClick={() => {
            setStartDateTime(moment(today.setHours(0, 0, 0, 0)).format("YYYY-MM-DDTHH:mm:ssZ"));
            setEndDateTime(moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"));
          }}>Today</Button>
        <Button
          size="md"
          type="button"
          className="me-2 px-5 py-2 border-radius-6"
          onClick={() => {
            setStartDateTime(moment().subtract(1, 'days').format("YYYY-MM-DDT00:00:00Z"));
            setEndDateTime(moment(today.setHours(0, 0, 0, 0)).format("YYYY-MM-DDTHH:mm:ssZ"));
          }}>
          Yesterday
        </Button>
        <Button
          size="md"
          type="button"
          className="me-2 px-5 py-2 border-radius-6"
          onClick={() => {
            setStartDateTime(moment().format("YYYY-MM-01T00:00:00Z"));
            setEndDateTime(moment().format("YYYY-MM-DDTHH:mm:ssZ"));
          }}>
          This Month
        </Button>
        <Button
          size="md"
          type="button"
          className="me-2 px-5 py-2 border-radius-6"
          onClick={() => {
            setStartDateTime(moment().subtract(30, 'days').format("YYYY-MM-DDT00:00:00Z"));
            setEndDateTime(moment().format("YYYY-MM-DDTHH:mm:ssZ"));
          }}>Last 30 days</Button>
        <Button
          size="md"
          type="button"
          className="me-2 px-5 py-2 border-radius-6"
          onClick={() => {
            setStartDateTime(moment().subtract(1, 'months').format("YYYY-MM-DDT00:00:00Z"));
            setEndDateTime(moment().format("YYYY-MM-01T00:00:00Z"));
          }}>Last Month</Button>
        <Button
          size="md"
          type="button"
          className="me-2 px-5 py-2 border-radius-6"
          onClick={() => {
            setStartDateTime(moment().format("YYYY-01-01T00:00:00Z"));
            setEndDateTime(moment().format("YYYY-MM-DDTHH:mm:ssZ"));
          }}>
          This Year
        </Button>
        <Button
          size="md"
          type="button"
          className="me-2 px-5 py-2 border-radius-6"
          onClick={() => {
            setStartDateTime(moment().subtract(365, 'days').format("YYYY-MM-DDT00:00:00Z"));
            setEndDateTime(moment().format("YYYY-MM-DDTHH:mm:ssZ"));
          }}>Last 365 days</Button>
        {/* <Button
          size="sm"
          type="button"
          variant="outline-primary"
          className="me-2 px-5 py-2"
          onClick={() => {
            setStartDateTime(moment().subtract(1, 'years').format("YYYY-01-01T00:00:00Z"));
            setEndDateTime(moment().format("YYYY-01-01T00:00:00Z"));
          }}>Last Year</Button> */}
      </div>
    </div >
  );
};

export default ModuleDatePicker;
