import React, { useState, useEffect, useRef } from "react";
import { Form, Spinner, Row, Col, Button as RButton } from "react-bootstrap";
import { Button, Card, IconButton } from "ui-neumorphism";
import { toast } from "react-toastify";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoChevronDown } from "react-icons/io5";
import * as userActions from "actions/UserActions";
import Select from "react-select";
import { AiOutlineCaretDown } from "react-icons/ai";
import Flags from "country-flag-icons/react/3x2";
import { ZDropdown } from "components";


const BusinessInformation = (props) => {
  // const { seller, form, handleBussInfoSubmit, next } = props;
  const {
    user,
    seller,
    mode,
    phone,
    email,
    form,
    asBusiness,
    setStep,
    handleBussInfoSubmit,
    handleBussInfoUpdate,
    next,
    region,
    setRegion,

  } = props;
  // console.log("asBusiness", asBusiness);

  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState(seller?.country);
  const [showPan, setShowPan] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [isSelectOpen, setIsSelectOpen] = useState([]);
  const uniqueCountries = [];
  const selectRef = useRef();

  const loadTheCountries = async () => {
    const response = await userActions.loadCountries();
    setCountries(response);
  };

  const CustomDropdownIndicator = (props) => {
    return (
      <div
        {...props.innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingRight: "8px",
        }}
      >
        <AiOutlineCaretDown />
      </div>
    );
  };
  const IndicatorSeparator = () => {
    return (
      <div
        style={{
          height: "1px",
          width: "1px",
          backgroundColor: "black",
          padding: 0,
        }}
      />
    );
  };

  const customStyles2 = {
    menu: () => ({
      marginTop: -4.5,
      padding: 0,
      position: "absolute",
      width: "100%",
      zIndex: 999,
      fontSize: "20px",
      borderRadius: "50px !important",
      overflow: "visible",
    }),
    menuList: (provided, state) => ({
      ...provided,
      borderRadius: "15px",
      textAlign: "center",
    }),

    input: (provided) => ({
      ...provided,
      color: "white",
      padding: "8px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#0D0D0D",
      color: state.isSelected ? "#777" : "white",
      marginTop: 0,
      cursor: "pointer",
      textAlign: "left",
      paddingLeft: "10px",
      fontSize: "16px",
      padding: "8px",
    }),

    control: (provided, state) => ({
      ...provided,
      color: "#fff",
      height: mode === "region" ? "60px" : "",
      backgroundColor: "#0D0D0D",
      display: "flex",
      fontSize: 16,
      fontFamily: "poppins",
      // padding: ,
      borderRadius: "10px",
      border: "1px solid #3D3D3D",
      boxShadow: "none",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "#fff";
      const transition = "opacity 300ms";
      const padding = "8px 8px 8px 0px";

      return { ...provided, opacity, transition, color, padding };
    },
  };

  useEffect(() => {
    loadTheCountries();
  }, [])

  const FlagIcon = ({ country }) => {
    const Flag = Flags[country?.code];

    return (
      <Flag
        title={country?.name}
        className="text-white"
        style={{ height: 30 }}
      />
    );
  };

  useEffect(() => {
    if (countries.length > 0) {
      let result = []
      for (let i = 0; i < countries.length; i++) {
        let data = {
          value: countries[i]?.code,
          label: (
            <div className="d-flex subtitle" key={countries[i]?.phone_code}>

              <FlagIcon country={countries[i]} />
              &nbsp;&nbsp;{countries[i]?.name}
            </div>
          )
        }
        result.push(data);
      }
      result.sort((a, b) => a.label.props.children[2].localeCompare(b.label.props.children[2]));
      setCountryList(result);
    }
  }, [countries])

  useEffect(() => {
    if (user?.region_data?.code === "IN") {
      setShowPan(true);
    } else {
      setShowPan(false);
    }
  }, [user]);



  return (
    <>
      <div
        className={`${mode === "create" ? "d-flex justify-content-center pt-5" : "d-none"
          }`}
      >
        <h3 className="text-white p-lg-2 mt-lg-0 mt-5">
          Hi, here are your personal information
        </h3>
      </div>
      {/* <div className={`${mode === "update" ? "mx-5" : "d-none"}`}>
                <h3 className="text-white p-lg-2 mt-lg-0">Personal Details</h3>
            </div> */}
      {/* <Form ref={form}
                className={`${mode === "update" ? "" : "my-5 body-text2 text-white"}`}
                onSubmit={(e) => {
                    if (seller.id) {
                        ha onChange={(e) => {
                                setUsername(e.target.value);
                                return e.target.setCustomValidity("");
                              }}ndleBussInfoUpdate(e)
                    } else {
                        handleBussInfoSubmit(e)
                    }
                }}
            > */}
      <Row className="row-cols-3" style={styles.formCenter}>
        {/* <div className="d-flex justify-content-center" style={{ paddingTop: 40 }}> */}
        <Col>
          <Form.Group className="mt-4 mx-5">
            <Form.Label className="text-white body-text2">
              {asBusiness ? "Business Name" : "Name"}
            </Form.Label>
            <Form.Control
              required
              name="business_name"
              type="text"
              className="text-white body-text1"
              placeholder={asBusiness ? "Enter Business Name" : "Enter Name"}
              defaultValue={seller.id ? seller?.business_name : ""}
              style={styles.fieldStyle}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mt-4 mx-5">
            <Form.Label className="text-white body-text2">
              Phone Number
            </Form.Label>
            <Form.Control
              required
              name="phone_number"
              type="text"
              className="text-white body-text1"
              placeholder="Enter Phone Number"
              defaultValue={seller.id ? seller?.phone_number : phone}
              style={styles.fieldStyle}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^[0-9]+$/.test(inputValue) && parseInt(inputValue, 10) !== 0) {
                  e.target.setCustomValidity("");
                } else {
                  e.target.setCustomValidity("Please enter valid phone number");
                }
              }}
              onInvalid={(e) => {
                return e.target.setCustomValidity(
                  "Please enter valid phone number"
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mt-4 mx-5">
            <Form.Label className="text-white body-text2">Email</Form.Label>
            <Form.Control
              required
              disabled
              name="email"
              type="text"
              className="text-white body-text1"
              placeholder="Enter Email"
              value={seller.id ? seller?.email : email}
              style={styles.fieldStyle}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mt-4 mx-5 ">
            <Form.Label className="text-white  body-text2">Country</Form.Label>
            <div className="position-relative ">
              <Select
                options={countryList}
                styles={customStyles2}
                value={countryList.find(
                  (country) => countryCode === country.label["key"]
                )}
                components={{
                  IndicatorSeparator,
                  DropdownIndicator: CustomDropdownIndicator,
                }}
                onChange={(e) => {
                  setCountryCode(e.value);
                  // console.log("@@@",e)
                  setRegion(e.label.key);
                  // setCountry(e.lable.key)

                  if (e.value === "IN") {
                    setShowPan(true);
                  } else {
                    setShowPan(false);
                  }
                }}
                menuShouldScrollIntoView={false}
                menuPosition="absolute"
                menuPlacement="bottom"
                filterOption={({ label }, inputValue) => {
                  // console.log(label)
                  return String(label.props.children)
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                }}

              />

            </div>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mt-4 mx-5">
            <Form.Label className="text-white body-text2">State</Form.Label>
            <Form.Control
              required
              name="state"
              type="text"
              className="text-white body-text1"
              placeholder="Enter State"
              defaultValue={seller.id ? seller?.state : ""}
              style={styles.fieldStyle}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mt-4 mx-5">
            <Form.Label className="text-white body-text2">City</Form.Label>
            <Form.Control
              required
              name="city"
              type="text"
              className="text-white body-text1"
              placeholder="Enter City"
              defaultValue={seller.id ? seller?.city : ""}
              style={styles.fieldStyle}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mt-4 mx-5">
            <Form.Label className="text-white body-text2">Address</Form.Label>
            <Form.Control
              required
              name="address"
              type="text"
              className="text-white body-text1"
              placeholder="Enter Address"
              defaultValue={seller.id ? seller?.address : ""}
              style={styles.fieldStyle}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mt-4 mx-5">
            <Form.Label className="text-white body-text2">
              Pincode / Zip Code
            </Form.Label>
            <Form.Control
              required
              name="pincode"
              type="text"
              className="text-white body-text1"
              placeholder="Enter PINCODE"
              defaultValue={seller.id ? seller?.pincode : ""}
              style={styles.fieldStyle}
            />
          </Form.Group>
        </Col>
        <Col className={`${asBusiness ? "d-block" : "d-none"}`}>
          <Form.Group className={`${asBusiness ? "mt-4 mx-5" : "d-none"}`}>
            <Form.Label className="text-white body-text2">GST/VAT</Form.Label>
            <Form.Control
              name="gst_vat"
              type="text"
              className="text-white body-text1"
              placeholder="Enter GST/VAT Number"
              defaultValue={seller.id ? seller?.gst_vat : ""}
              style={styles.fieldStyle}
            />
          </Form.Group>
        </Col>
        {showPan && <Col className={"d-block"}>
          <Form.Group className="mt-4 mx-5">
            <Form.Label className="text-white body-text2">
              PAN Card Number
            </Form.Label>
            <Form.Control
              required
              disabled={user?.region_data?.code === "IN" ? false : true}
              name="pan_card_number"
              type="text"
              className="text-white body-text1"
              placeholder="Enter PAN Card Number"
              defaultValue={seller.id ? seller?.pan_card_number : ""}
              style={styles.fieldStyle}
            // onChange={(e) => {
            //     const value = e.target.value;
            //     if (value.length !== 10) {
            //       throw new Error("PAN Card Number must be 10 characters long.");
            //     }
            //   }}
            />
          </Form.Group>
        </Col>}
      </Row>
      {/* </div> */}
      {/* <div className={`${mode === "create" ? "d-flex justify-content-end" : "d-none"}`} style={styles.nextBackButton}>
                <RButton
                    className="btn btn-link border-0 wrapped-button mx-3"
                    style={{ background: "transparent", textDecoration: "none" }}
                    onClick={(e) => {
                        e.preventDefault();
                        setStep(0);
                    }}>
                    <IconButton
                        className="d-flex justify-content-center align-items-center explore-icon-button"
                        size="large"
                        dark={true}
                        text={false}
                    >
                        <IoIosArrowBack size={20} />
                    </IconButton>
                </RButton>
                <RButton
                    className="btn btn-link border-0 wrapped-button"
                    style={{ background: "transparent", textDecoration: "none" }}
                    type="submit"
                >
                    <IconButton
                        className="d-flex justify-content-center align-items-center explore-icon-button"
                        size="large"
                        dark={true}
                        text={false}
                    >
                        <IoIosArrowForward size={20} />
                    </IconButton>
                </RButton>
            </div> */}
      {/* <div className={`${mode === "update" ? "text-center" : "d-none"}`} style={{ marginTop: 70 }}>
                <RButton
                    className="btn btn-link border-0 wrapped-button"
                    style={{ background: "transparent", textDecoration: "none" }}
                    type="submit"
                >
                    <RButton
                        className="explore-button-no-neo"
                        variant="dark"
                        size="lg"
                        type="submit"
                    >
                        {next ? (
                            <span>
                                <Spinner
                                    className="me-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                SAVING...
                            </span>
                        ) : (
                            <span className="poppins">SAVE CHANGES</span>
                        )}
                    </RButton>
                </RButton>
            </div> */}
      {/* </Form> */}
    </>
    // <>
    //     <div className="d-flex justify-content-center mt-5 text-shadow">
    //         <h3 className="text-white p-lg-2 mt-lg-0 mt-5">Business Information</h3>
    //     </div>
    //     <Col lg={4}>
    //         <Card dark={true} >
    //             <Form ref={form} className="px-5 py-4 my-5 body-text2 text-white" onSubmit={(e) => handleBussInfoSubmit(e)}>
    //                 <Form.Label className="mt-4">BUSINESS NAME</Form.Label>
    //                 <Form.Control
    //                     required
    //                     name="business_name"
    //                     type="text"
    //                     className="text-white body-text1"
    //                     placeholder="Enter Business Name"
    //                     defaultValue={seller ? seller.business_name : ""}
    //                     style={{
    //                         background: "#141414",
    //                         height: 50,
    //                     }}
    //                 />
    //                 <div className="text-center mt-4">
    //                     <RButton
    //                         className="btn btn-link my-3 p-0 border-0 wrapped-button"
    //                         style={{
    //                             background: "transparent",
    //                             textDecoration: "none",
    //                         }}
    //                         type="submit"
    //                     >
    //                         <Button
    //                             className="explore-button"
    //                             dark={true}
    //                             size="large"
    //                             type="submit"
    //                         >
    //                             {next ? (
    //                                 <span>
    //                                     <Spinner
    //                                         className="me-2"
    //                                         as="span"
    //                                         animation="border"
    //                                         size="sm"
    //                                         role="status"
    //                                         aria-hidden="true"
    //                                     />
    //                                     {seller?.id !== null ? "UPDATING..." : "PROCESSING..."}
    //                                 </span>
    //                             ) : (
    //                                 <span className="poppins">{seller?.id !== null ? "UPDATE" : "NEXT"}</span>
    //                             )}
    //                             {/* <span className="poppins">NEXT</span> */}
    //                         </Button>
    //                     </RButton>
    //                 </div>
    //             </Form>
    //         </Card>
    //     </Col>
    // </>
  );
};

const styles = {
  formCenter: {
    // paddingTop: 160,
    // paddingBottom: 53,
  },
  nextBackButton: {
    marginRight: 100,
  },
  fieldStyle: {
    background: "#0c0d0c",
    padding: 10,
    border: "1px solid #3d3d3d",
    borderRadius: "10px",
  },
};

export default BusinessInformation;
