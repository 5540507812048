import { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
// import { RoughnessMipmapper } from 'three/examples/jsm/utils/PMR';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';

import veranda_1k from "assets/viewer/veranda_1k.exr";
import venice_sunset_1k from "assets/viewer/venice_sunset_1k.exr";
import sunset_jhbcentral_1k from "assets/viewer/sunset_jhbcentral_1k.exr";
import studio_small_08_1k from "assets/viewer/studio_small_08_1k.exr";
import studio_garden_1k from "assets/viewer/studio_garden_1k.exr";
import red_hill_curve_1k from "assets/viewer/red_hill_curve_1k.exr";
import lauter_waterfall_1k from "assets/viewer/lauter_waterfall_1k.exr";
import kloppenheim_06_1k from "assets/viewer/kloppenheim_06_1k.exr";
import herkulessaulen_1k from "assets/viewer/herkulessaulen_1k.exr"
import canary_wharf_1k from "assets/viewer/canary_wharf_1k.exr";
import blue_grotto_1k from "assets/viewer/blue_grotto_1k.exr";


import blur_veranda_1k from "assets/viewer/blur_veranda_1k.exr";
import blur_venice_sunset_1k from "assets/viewer/blur_venice_sunset_1k.exr";
import blur_sunset_jhbcentral_1k from "assets/viewer/blur_sunset_jhbcentral_1k.exr";
import blur_studio_small_08_1k from "assets/viewer/blur_studio_small_08_1k.exr";
import blur_studio_garden_1k from "assets/viewer/blur_studio_garden_1k.exr";
import blur_red_hill_curve_1k from "assets/viewer/blur_red_hill_curve_1k.exr";
import blur_lauter_waterfall_1k from "assets/viewer/blur_lauter_waterfall_1k.exr";
import blur_kloppenheim_06_1k from "assets/viewer/blur_kloppenheim_06_1k.exr";
import blur_herkulessaulen_1k from "assets/viewer/blur_herkulessaulen_1k.exr"
import blur_canary_wharf_1k from "assets/viewer/blur_canary_wharf_1k.exr";
import blur_blue_grotto_1k from "assets/viewer/blur_blue_grotto_1k.exr";

import { znanyeloader } from "assets";





const Try3DURLFiles = ({ files, enableRotation, enableEnvironment, blurEnvironment, environment, intensityValue, emissionIntensityValue, backgroundColor }) => {

    //consolelog("^^^", files)

    // console.log("$$$$", emissionIntensityValue, intensityValue )
    const [loading, setLoading] = useState(true);


    const getEnvMap = (envMap, isBlur) => {
        const value = isBlur ? `blur_${envMap?.split(".")[0]}` : `${envMap?.split(".")[0]}`;
        // console.log(value);
        switch (value) {
            case "veranda_1k":
                return veranda_1k;
            case "venice_sunset_1k":
                return venice_sunset_1k;
            case "sunset_jhbcentral_1k":
                return sunset_jhbcentral_1k;
            case "studio_small_08_1k":
                return studio_small_08_1k;
            case "studio_garden_1k":
                return studio_garden_1k;
            case "red_hill_curve_1k":
                return red_hill_curve_1k;
            case "lauter_waterfall_1k":
                return lauter_waterfall_1k;
            case "kloppenheim_06_1k":
                return kloppenheim_06_1k;
            case "herkulessaulen_1k":
                return herkulessaulen_1k;
            case "canary_wharf_1k":
                return canary_wharf_1k;
            case "blue_grotto_1k":
                return blue_grotto_1k;
            case "blur_veranda_1k":
                return blur_veranda_1k;
            case "blur_venice_sunset_1k":
                return blur_venice_sunset_1k;
            case "blur_sunset_jhbcentral_1k":
                return blur_sunset_jhbcentral_1k;
            case "blur_studio_small_08_1k":
                return blur_studio_small_08_1k;
            case "blur_studio_garden_1k":
                return blur_studio_garden_1k;
            case "blur_red_hill_curve_1k":
                return blur_red_hill_curve_1k;
            case "blur_lauter_waterfall_1k":
                return blur_lauter_waterfall_1k;
            case "blur_kloppenheim_06_1k":
                return blur_kloppenheim_06_1k;
            case "blur_herkulessaulen_1k":
                return blur_herkulessaulen_1k;
            case "blur_canary_wharf_1k":
                return blur_canary_wharf_1k;
            case "blur_blue_grotto_1k":
                return blur_blue_grotto_1k;
            case "blur_":
                return blur_veranda_1k;
            default:
                return veranda_1k;
        }
    }

    let texturename = null;
    let environmentTexture = null;
    // const scene = new THREE.Scene();
    let scene = null;
    let camera = null;
    let controls = null;
    const controlRef = useRef(null);
    const sceneRef = useRef(null);
    let manager = null;
    const managerRef = useRef(null);
    let renderer = null;
    const rendererRef = useRef();
    let ambientLight = null;
    const ambientLightRef = useRef(null);
    const updateRotation = (enableRotation) => {
        controls.autoRotate = enableRotation;
        render();
    }

    const onLoadTexture = async (three_d_file, material_file, viewer_extension, texture) => {
        //consolelog(texture);
        texture.mapping = THREE.EquirectangularReflectionMapping;
        // texture.anisotropy = 1;
        // texture.needsUpdate = true;

        //consolelog(texture);

        // texture.generateMipmaps = true;
        // texture.anisotropy = 0.01;
        // texture.magFilter = THREE.NearestMipmapNearestFilter;
        const product = null;
        texture.needsUpdate = true;
        if (enableEnvironment) {
            scene.background = texture;
        } else {
            scene.background = new THREE.Color(backgroundColor);
        }
        scene.environment = texture;

        environmentTexture = texture;

        // scene.background = "#ccc";


        render();

        // model

        // use of RoughnessMipmapper is optional
        // const roughnessMipmapper = new RoughnessMipmapper(renderer);
        const pmremGenerator = new THREE.PMREMGenerator(renderer);


        // const grid = new THREE.GridHelper(2000, 20, 0x000000, 0x000000);
        // grid.material.opacity = 0.2;
        // grid.material.transparent = true;
        // scene.add(grid);
        ambientLight = new THREE.AmbientLight(0xffffff, intensityValue);
        ambientLightRef.current = ambientLight; // soft white light
        scene.add(ambientLight);

        // const loader = new GLTFLoader().setPath('{% static "viewer/models/gltf/DamagedHelmet/glTF/" %}');
        if (viewer_extension == "gltf") {
            const gltfLoader = new GLTFLoader(manager);
            gltfLoader.load(three_d_file, (gltf) => {

                gltf.scene.traverse(function (child) {

                    if (child.isMesh) {

                        // pmremGenerator.generateMipmaps(child.material);
                        // child.material.emissiveIntensity = 1000;
                        // child.material.needsUpdate = true;
                        if (emissionIntensityValue) {
                            child.material.emissive = child.material.color;
                            child.material.emissiveMap = child.material.map
                            child.material.emissiveIntensity = emissionIntensityValue;
                            child.material.needsUpdate = true;
                        }
                    }

                });

                scene.add(gltf.scene);

                let box = new THREE.Box3().setFromObject(gltf.scene);
                const size = box.getSize(new THREE.Vector3()).length();
                const scalar = 1; // Change this to set the general size for each object
                //consolelog(size); // 7085.044623234223
                const scaleSize = Math.max(scalar / size, scalar / size, scalar / size);
                scene.add(gltf.scene);
                gltf.scene.scale.set(scaleSize, scaleSize, scaleSize);

                pmremGenerator.dispose();

                render();
            });
        }

        if (viewer_extension == "fbx") {
            const fbxLoader = new FBXLoader(manager);
            fbxLoader.load(three_d_file, (fbx) => {
                // const light = new THREE.AmbientLight(0xffffff, 0.8); // soft white light
                // scene.add(light);

                fbx.traverse((child) => {
                    //consolelog(child);

                    if (child.isMesh) {

                        child.castShadow = true;
                        child.receiveShadow = true;
                        // child.material.color = new THREE.Color('#fff');
                        // child.material.side = THREE.DoubleSide;
                        // child.position.set(0, 0, 0);
                        child.scale.set(1, 1, 1);
                        child.material.needsUpdate = true;
                        child.material.side = THREE.DoubleSide;
                    }
                });

                fbx.updateMatrixWorld();
                let box = new THREE.Box3().setFromObject(fbx);
                const size = box.getSize(new THREE.Vector3()).length();
                const scalar = 1; // Change this to set the general size for each object
                //consolelog(size); // 7085.044623234223
                const scaleSize = Math.max(scalar / size, scalar / size, scalar / size);
                scene.add(fbx);
                fbx.scale.set(scaleSize, scaleSize, scaleSize);

                // 
                const temp = box.getCenter(new THREE.Vector3);
                controls.target.set(temp.x * scalar / size, temp.y * scalar / size, temp.z * scalar / size);
                controls.update();
                render();

            });
        }
        if (viewer_extension == "obj") {
            if (material_file != null) {
                const mtlLoader = new MTLLoader(manager);
                mtlLoader.load(material_file, objMaterial => {
                    const objLoader = new OBJLoader();
                    objLoader.setMaterials(objMaterial);
                    objLoader.load(three_d_file,
                        (obj) => {

                            const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
                            hemiLight.position.set(0, 200, 0);
                            scene.add(hemiLight);

                            const dirLight = new THREE.DirectionalLight(0xffffff);
                            dirLight.position.set(0, 200, 100);
                            dirLight.castShadow = true;
                            dirLight.shadow.camera.top = 180;
                            dirLight.shadow.camera.bottom = - 100;
                            dirLight.shadow.camera.left = - 120;
                            dirLight.shadow.camera.right = 120;
                            scene.add(dirLight);

                            obj.traverse((child) => {

                                if (child.isMesh) {

                                    // child.castShadow = true;
                                    // child.receiveShadow = true;
                                    // child.material.side = THREE.DoubleSide;
                                    // child.material.needsUpdate = true;
                                    child.position.set(0, 0, 0);
                                    child.scale.set(1, 1, 1);
                                }
                            });

                            obj.updateMatrixWorld();
                            let box = new THREE.Box3().setFromObject(obj);
                            const size = box.getSize(new THREE.Vector3()).length();
                            const scalar = 1; // Change this to set the general size for each object
                            //consolelog(size); // 7085.044623234223
                            const scaleSize = Math.max(scalar / size, scalar / size, scalar / size);
                            scene.add(obj);
                            obj.scale.set(scaleSize, scaleSize, scaleSize);

                            const temp = box.getCenter(new THREE.Vector3);
                            controls.target.set(temp.x * scalar / size, temp.y * scalar / size, temp.z * scalar / size);
                            controls.update();
                            controlRef.current = controls;

                            render();

                        },
                        (xhr) => {
                            //consolelog((xhr.loaded / xhr.total) * 100 + "% loaded");
                        },
                        // called when loading has errors
                        (error) => {
                            //consolelog("An error happened" + error);
                        });
                });
            } else {
                const objLoader = new OBJLoader();
                objLoader.load(three_d_file,
                    (obj) => {

                        const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
                        hemiLight.position.set(0, 200, 0);
                        scene.add(hemiLight);

                        const dirLight = new THREE.DirectionalLight(0xffffff);
                        dirLight.position.set(0, 200, 100);
                        dirLight.castShadow = true;
                        dirLight.shadow.camera.top = 180;
                        dirLight.shadow.camera.bottom = - 100;
                        dirLight.shadow.camera.left = - 120;
                        dirLight.shadow.camera.right = 120;
                        scene.add(dirLight);

                        obj.traverse((child) => {

                            if (child.isMesh) {

                                // child.castShadow = true;
                                // child.receiveShadow = true;
                                // child.material.side = THREE.DoubleSide;
                                // child.material.needsUpdate = true;
                                child.position.set(0, 0, 0);
                                child.scale.set(1, 1, 1);
                            }
                        });

                        obj.updateMatrixWorld();
                        let box = new THREE.Box3().setFromObject(obj);
                        const size = box.getSize(new THREE.Vector3()).length();
                        const scalar = 1; // Change this to set the general size for each object
                        //consolelog(size); // 7085.044623234223
                        const scaleSize = Math.max(scalar / size, scalar / size, scalar / size);
                        scene.add(obj);
                        obj.scale.set(scaleSize, scaleSize, scaleSize);

                        const temp = box.getCenter(new THREE.Vector3);
                        controls.target.set(temp.x * scalar / size, temp.y * scalar / size, temp.z * scalar / size);
                        controls.update();
                        controlRef.current = controls;

                        render();

                    },
                    (xhr) => {
                        //consolelog((xhr.loaded / xhr.total) * 100 + "% loaded");
                    },
                    // called when loading has errors
                    (error) => {
                        //consolelog("An error happened" + error);
                    });
            }
        }
    }


    const threeScene = (three_d_file, material_file, viewer_extension, enableRotation, emission_intensity_value) => {

        // scene, element, camera, renderer

        let element = document.getElementById("viewer-3d");
        let loader = document.getElementById("loading-div-3d");
        scene = new THREE.Scene();
        sceneRef.current = scene;
        const product = null;
        if (element && loader) {

            camera = new THREE.PerspectiveCamera(45, element.offsetWidth / element.offsetHeight, 0.01, 1000);
            camera.position.set(1, 0.6, 1);

            manager = new THREE.LoadingManager();
            managerRef.current = manager;

            manager.onStart = function (url, itemsLoaded, itemsTotal) {

                //consolelog('Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
            };

            manager.onLoad = function () {

                //consolelog('Loading complete!');
                // setLoading(false);
                loader.style.display = "none";
                // $("#loader-div").hide();
                // $("#loader-div").removeClass('d-flex');
                // $("#loader-div").addClass('d-none');
            };

            manager.onProgress = function (url, itemsLoaded, itemsTotal) {

                //consolelog('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');

            };

            manager.onError = function (url) {

                //consolelog('There was an error loading ' + url);

            };


            // const onLoadTexture = async (texture) => {
            //     texture.mapping = THREE.EquirectangularReflectionMapping;
            //     // texture.anisotropy = 1;
            //     // texture.needsUpdate = true;

            //     //consolelog(texture);

            //     // texture.generateMipmaps = true;
            //     // texture.anisotropy = 0.01;
            //     // texture.magFilter = THREE.NearestMipmapNearestFilter;
            //     texture.needsUpdate = true;
            //     if (product?.enable_environment_map) {
            //         scene.background = texture;
            //     } else {
            //         scene.background = new THREE.Color(product?.background_color);
            //     }
            //     scene.environment = texture;

            //     environmentTexture = texture;

            //     // scene.background = "#ccc";


            //     render();

            //     // model

            //     // use of RoughnessMipmapper is optional
            //     // const roughnessMipmapper = new RoughnessMipmapper(renderer);
            //     const pmremGenerator = new THREE.PMREMGenerator(renderer);


            //     // const grid = new THREE.GridHelper(2000, 20, 0x000000, 0x000000);
            //     // grid.material.opacity = 0.2;
            //     // grid.material.transparent = true;
            //     // scene.add(grid);

            //     // const loader = new GLTFLoader().setPath('{% static "viewer/models/gltf/DamagedHelmet/glTF/" %}');
            //     if (viewer_extension == "gltf") {
            //         const gltfLoader = new GLTFLoader(manager);
            //         gltfLoader.load(URL.createObjectURL(three_d_file), (gltf) => {
            //             ambientLight = new THREE.AmbientLight(0xffffff, 0.2); // soft white light
            //             scene.add(ambientLight);

            //             gltf.scene.traverse(function (child) {

            //                 if (child.isMesh) {

            //                     // pmremGenerator.generateMipmaps(child.material);
            //                     // child.material.emissiveIntensity = 1000;

            //                 }

            //             });

            //             scene.add(gltf.scene);

            //             let box = new THREE.Box3().setFromObject(gltf.scene);
            //             const size = box.getSize(new THREE.Vector3()).length();
            //             const scalar = 1; // Change this to set the general size for each object
            //             //consolelog(size); // 7085.044623234223
            //             const scaleSize = Math.max(scalar / size, scalar / size, scalar / size);
            //             scene.add(gltf.scene);
            //             gltf.scene.scale.set(scaleSize, scaleSize, scaleSize);

            //             pmremGenerator.dispose();

            //             render();
            //         });
            //     }

            //     if (viewer_extension == "fbx") {
            //         const fbxLoader = new FBXLoader(manager);
            //         fbxLoader.load(three_d_file, (fbx) => {
            //             const light = new THREE.AmbientLight(0xffffff, 0.8); // soft white light
            //             scene.add(light);
            //             // const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
            //             // hemiLight.position.set(0, 200, 0);
            //             // scene.add(hemiLight);

            //             // const dirLight = new THREE.DirectionalLight(0xffffff);
            //             // dirLight.position.set(0, 200, 100);
            //             // dirLight.castShadow = true;
            //             // const pointLight = new THREE.PointLight(0xffffff, 1, 10);
            //             // pointLight.position.set(0, 200, 0);
            //             // pointLight.castShadow = true;
            //             // scene.add(pointLight);
            //             //Set up shadow properties for the light
            //             // dirLight.shadow.camera.top = 180;
            //             // dirLight.shadow.camera.bottom = - 100;
            //             // dirLight.shadow.camera.left = - 120;
            //             // dirLight.shadow.camera.right = 120;
            //             // scene.add(dirLight);

            //             fbx.traverse((child) => {
            //                 //consolelog(child);

            //                 if (child.isMesh) {

            //                     child.castShadow = true;
            //                     child.receiveShadow = true;
            //                     // child.material.color = new THREE.Color('#fff');
            //                     // child.material.side = THREE.DoubleSide;
            //                     // child.position.set(0, 0, 0);
            //                     child.scale.set(1, 1, 1);
            //                     child.material.needsUpdate = true;
            //                     child.material.side = THREE.DoubleSide;
            //                 }
            //             });

            //             fbx.updateMatrixWorld();
            //             let box = new THREE.Box3().setFromObject(fbx);
            //             const size = box.getSize(new THREE.Vector3()).length();
            //             const scalar = 1; // Change this to set the general size for each object
            //             //consolelog(size); // 7085.044623234223
            //             const scaleSize = Math.max(scalar / size, scalar / size, scalar / size);
            //             scene.add(fbx);
            //             fbx.scale.set(scaleSize, scaleSize, scaleSize);

            //             // 
            //             const temp = box.getCenter(new THREE.Vector3);
            //             controls.target.set(temp.x * scalar / size, temp.y * scalar / size, temp.z * scalar / size);
            //             controls.update();

            //             render();

            //         });
            //     }
            //     // {% if extension == "obj" %}
            //     // const mtlLoader = new MTLLoader(manager);
            //     // mtlLoader.load('{{ materialFile.file.url }}', objMaterial => {

            //     //     const objLoader = new OBJLoader();
            //     //     objLoader.setMaterials(objMaterial);
            //     //     objLoader.load('{{ productFile.file.url }}',
            //     //         (obj) => {

            //     //             const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
            //     //             hemiLight.position.set(0, 200, 0);
            //     //             scene.add(hemiLight);

            //     //             const dirLight = new THREE.DirectionalLight(0xffffff);
            //     //             dirLight.position.set(0, 200, 100);
            //     //             dirLight.castShadow = true;
            //     //             dirLight.shadow.camera.top = 180;
            //     //             dirLight.shadow.camera.bottom = - 100;
            //     //             dirLight.shadow.camera.left = - 120;
            //     //             dirLight.shadow.camera.right = 120;
            //     //             scene.add(dirLight);

            //     //             obj.traverse((child) => {

            //     //                 if (child.isMesh) {

            //     //                     // child.castShadow = true;
            //     //                     // child.receiveShadow = true;
            //     //                     // child.material.side = THREE.DoubleSide;
            //     //                     // child.material.needsUpdate = true;
            //     //                     child.position.set(0, 0, 0);
            //     //                     child.scale.set(1, 1, 1);
            //     //                 }
            //     //             });

            //     //             scene.add(obj);

            //     //             render();

            //     //         },
            //     //         (xhr) => {
            //     //             //consolelog((xhr.loaded / xhr.total) * 100 + "% loaded");
            //     //         },
            //     //         // called when loading has errors
            //     //         (error) => {
            //     //             //consolelog("An error happened" + error);
            //     //         });
            //     // });
            //     // {% endif %}
            // }

            const productEnvLoader = new EXRLoader(manager);
            // alert(productEnvLoader.path)src/pages/Try3D/assets/veranda_1k.exr
            //consolelog(getEnvMap(environment, false))
            productEnvLoader.load(getEnvMap(environment, false), (texture) => {
                // productEnvLoader.load(veranda, (texture) => {
                onLoadTexture(three_d_file, material_file, viewer_extension, texture);
            });

            texturename = product?.environment;
            // if (product?.enable_environment_map) {
            //     if (product.blur_environment_map) {
            //         texturename = 'blur_' + texturename
            //     }

            //     const envLoader = new EXRLoader(manager);
            //     envLoader.load("assets/viewer/veranda_1k.exr", function (texture) {
            //         texture.mapping = THREE.EquirectangularReflectionMapping;
            //         scene.background = texture;
            //         render();
            //     });
            // }
            const geometry = new THREE.BoxGeometry(1, 1, 1);
            const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
            const cube = new THREE.Mesh(geometry, material);
            // scene.add(cube);

            // camera.position.z = 5;

            renderer = new THREE.WebGLRenderer({ antialias: true });
            rendererRef.current = renderer;
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(element.offsetWidth, element.offsetHeight);
            renderer.toneMapping = THREE.ACESFilmicToneMapping;
            renderer.toneMappingExposure = 1;
            renderer.outputEncoding = THREE.sRGBEncoding;

            controls = new OrbitControls(camera, renderer.domElement);
            // controls.addEventListener('change', render); // use if there is no animation loop
            controls.minDistance = 0.2;
            controls.maxDistance = 4;
            controls.target.set(0, 0.2, 0);

            // rotation
            // rotation
            // {% if product.enable_rotation is True %}
            // controls.autoRotate = false;
            if (enableRotation) {
                controls.autoRotate = enableRotation;
            } else {
                controls.autoRotate = enableRotation;
            }
            controls.autoRotateSpeed = 0.1;
            controls.enablePan = true;
            controls.update();
            controlRef.current = controls;

            renderer.domElement.style.position = "absolute";

            element.append(renderer.domElement);


            const onWindowResize = () => {

                camera.aspect = element.offsetWidth / element.offsetHeight;
                camera.updateProjectionMatrix();

                renderer.setSize(element.offsetWidth, element.offsetHeight);

                render();
            }


            window.addEventListener('resize', onWindowResize);
            render();
        }
    }

    const render = () => {
        requestAnimationFrame(render);

        controlRef.current.update();
        renderer.render(scene, camera);
    }

    const disposeEveryThing = () => {
        // console.log(rendererRef.current?.info);
        // console.log(sceneRef.current);
        // console.log('before', rendererRef.current?.info.programs.length);
        for (let i = sceneRef.current?.children.length - 1; i >= 0; i--) {
            let child = sceneRef.current?.children[i];
            sceneRef.current?.remove(child);
            if (child.isMesh) {
                sceneRef.current?.remove(child);
                child.geometry.dispose();
                if (child.material instanceof Array) {
                    child.material.forEach(material => material.dispose());
                } else {
                    Object.keys(child.material).forEach(prop => {
                        if (!child.material[prop])
                            return;
                        if (child.material[prop] !== null && typeof child.material[prop].dispose === 'function')
                            child.material[prop].dispose();
                    })
                    child.material.dispose();
                }
            }
        }

        if (rendererRef.current) {
            rendererRef.current.dispose()
            rendererRef.current?.renderLists.dispose()
            rendererRef.current.info.reset()
            rendererRef.current.update = true;
            rendererRef.current?.clear();
            rendererRef.current?.clearDepth();
        }
        // console.log(rendererRef.current?.info);
        // console.log('after', rendererRef.current?.info.programs.length);
    }

    useEffect(() => {
        return () => {
            disposeEveryThing();
        }
    }, [])

    useEffect(() => {
        if (files) {
            //consolelog(files)
            let three_d_file = null, viewer_extension = "gltf";
            let material_file = null;

            let expected_extensions = ["glb", "gltf", "fbx", "obj"]
            let flag = false;
            for (let i = 0; i < expected_extensions.length; i++) {
                for (let j = 0; j < files.length; j++) {
                    // alert(files[j].file)
                    if (files[j]?.file?.split(".").pop().toLowerCase() == expected_extensions[i]) {
                        three_d_file = files[j].file;
                        viewer_extension = expected_extensions[i];
                        if (expected_extensions[i] == "glb") {
                            viewer_extension = "gltf";
                        }
                        flag = true;
                        break;
                    }
                }
                if (flag) {
                    break;
                }
            }
            if (viewer_extension == "obj") {
                for (let i = 0; i < files.length; i++) {
                    let file_extension = files[i].file.split(".").pop().toLowerCase();
                    if (file_extension == "mtl") {
                        material_file = files[i].file;
                        break;
                    }
                }
            }
            //consolelog(viewer_extension);
            //consolelog(three_d_file);
            disposeEveryThing();
            if (three_d_file) {
                threeScene(three_d_file, material_file, viewer_extension, false);
            }
            if (environment) {
                new EXRLoader().load(getEnvMap(environment, false), function (texture) {
                    onLoadTexture(three_d_file, material_file, viewer_extension, texture);

                    if (enableEnvironment) {
                        sceneRef.current.background = texture;
                    }
                    render();
                });
            }
            if (blurEnvironment) {
                new EXRLoader().load(getEnvMap(environment, true), function (texture) {

                    texture.mapping = THREE.EquirectangularReflectionMapping;
                    texture.needsUpdate = true;

                    if (enableEnvironment) {
                        sceneRef.current.background = texture;
                        // sceneRef.current.environment = texture;
                    }
                    render();
                });
            }
            //consolelog(three_d_file);
            if (controlRef.current) {
                controlRef.current.autoRotate = enableRotation;
                controlRef.current.update();
            }
        }
    }, [files, environment, enableEnvironment, blurEnvironment])

    useEffect(() => {
        if (controlRef.current) {
            controlRef.current.autoRotate = enableRotation;
            controlRef.current.update();
        }
    }, [enableRotation])


    useEffect(() => {
        if (ambientLightRef.current) {
            ambientLightRef.current.intensity = intensityValue;
        }
    }, [intensityValue])

    useEffect(() => {
        if (sceneRef.current && rendererRef.current) {
            sceneRef.current.background = new THREE.Color(backgroundColor);
        }
    }, [backgroundColor])

    const applyEmissiveIntensity = (emissiveValue) => {
        sceneRef.current.traverse(function (child) {

            if (child.isMesh) {

                // pmremGenerator.generateMipmaps(child.material);

                child.material.emissive = child.material.color;
                child.material.emissiveMap = child.material.map
                child.material.emissiveIntensity = emissiveValue;
                child.material.needsUpdate = true;
                //consolelog(child.material)

            }
        });
    }

    useEffect(() => {
        // console.log(emissionIntensityValue, sceneRef.current);
        if (sceneRef.current && emissionIntensityValue) {
            applyEmissiveIntensity(emissionIntensityValue)
        }
    }, [emissionIntensityValue, sceneRef.current])


    return (
        <>
            <div className="d-flex justify-content-center align-items-center" id="viewer-3d" style={{ width: "100%", height: "100%" }}>
                <div id="loading-div-3d" className="position-absolute">
                    <div>
                        <Image src={znanyeloader} loading="lazy" style={{ width: 60, height: 60 }} />
                    </div>
                </div>
                {/* <div>tejas</div> */}
            </div>
        </>
    )
}

export default Try3DURLFiles;