import React, { useEffect, useState } from "react";
import { Button, Container, Spinner } from 'react-bootstrap'
import { IoMdArrowRoundBack } from "react-icons/io";
import * as adminActions from "actions/AdminActions";
import DataTable from "react-data-table-component";
import moment from "moment";

const ModuleTable = ({ startDateTime, endDateTime, cardTitle, setCardType, cardUrl, setCardUrl }) => {

    const [data, setData] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pending, setPending] = useState(true);
    const [columnsList, setColumnsList] = useState(null);
    const [product, setProduct] = useState(null)

    const customStyles = {
        header: {
            style: {
                color: "#FFFFFF",
                backgroundColor: "#1f1f1f"
            },
        },
        rows: {
            style: {
                minHeight: "50px",
                border: "1px solid #ffffff",
            },
        },
        headCells: {
            style: {
                padding: "15px",
                fontWeight: "bold",
                color: "#000000",
                border: "1px solid #ffffff",
                backgroundColor: "#f7941d",
            },
        },
        cells: {
            style: {
                minHeight: "50px",
                color: "#FFFFFF",
                border: "1px solid #ffffff",
                backgroundColor: "#1f1f1f",
            },
        },
        pagination: {
            style: {
                backgroundColor: "#f7941d"
            },
        },
    };

    const product_uploads = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
        },
        {
            name: 'Category',
            selector: row => row.category_data?.title,
            sortable: true,
        },
        {
            name: 'Seller Name',
            selector: row => row.created_by_name,
            sortable: true,
        },
        {
            name: 'Price',
            selector: row => row.price + ' ' + row.currency,
            sortable: true,
        },
        {
            name: 'Verified?',
            selector: row => row.is_verified ? "Yes" : "No",
            sortable: true,
        },
        {
            name: 'Archived?',
            selector: row => row.is_archived ? "Yes" : "No",
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => moment(row.created_at).format('DD-MM-YYYY'),
            sortable: true,
        },
    ];

    const product_sold = [
        {
            name: 'Product ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Product',
            selector: row =>
                <u className="text-primary cursor-pointer"
                    onClick={() => {
                        setProduct(row)
                        fetchProductData(row.id, row.product_name)
                    }}>
                    {row.product_name}
                </u>,
            sortable: true,
        },
        {
            name: 'Price',
            selector: row => row.price,
            sortable: true,
        },
        {
            name: 'Sales count',
            selector: row => row.sales_count,
            sortable: true,
        },
    ];

    const product_wise_sale = [
        {
            name: 'Sr. No.',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Order ID',
            selector: row => row.order,
            sortable: true,
        },
        {
            name: 'Price',
            selector: row => row.price,
            sortable: true,
        },
    ];

    const sellers = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Seller Name',
            selector: row => row.business_name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Phone Number',
            selector: row => row.phone_number,
            sortable: true,
        },
        {
            name: 'Individual/Company',
            selector: row => row.is_individual ? "Individual" : "Company",
            sortable: true,
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
        },
    ];

    const review_ratings = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Product Name',
            selector: row => row.product_name,
            sortable: true,
        },
        {
            name: 'Review Count',
            selector: row => row.review_count,
            sortable: true,
        },
        {
            name: 'Average Rating',
            selector: row => row.average_rating,
            sortable: true,
        }
    ];

    const unique_visitor = [
        {
            name: 'User ID',
            selector: row => row.user,
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'Average Time per Session (sec.)',
            selector: row => row.average_time_spent,
            sortable: true,
        },
        {
            name: 'Active / Total Sessions',
            selector: row => row.active_sessions + ' / ' + row.total_sessions,
            sortable: true,
        },
    ];

    const new_returning_users = [
        {
            name: 'User ID',
            selector: row => row.user,
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'User Type',
            selector: row => row.type,
            sortable: true,
        },
    ];

    const new_returning_customers = [
        {
            name: 'User ID',
            selector: row => row.user,
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'Customer Type',
            selector: row => row.type,
            sortable: true,
        },
    ];

    // const fetchData = async () => {
    //     setPending(true);
    //     const response = await adminActions.fetchTableData(cardUrl, cardTitle, { startDateTime: startDateTime, endDateTime: endDateTime })
    //     if (response) {
    //         setData(response.results)
    //         setTotalRecords(response.count)
    //     }
    //     setPending(false);
    // }

    const fetchPaginatedRecords = async (page) => {
        setPending(true);
        const url = cardUrl + `/?page=${page}&page_size=${perPage}&delay=1`
        const response = await adminActions.fetchTableData(url, cardTitle, { startDateTime: startDateTime, endDateTime: endDateTime })
        if (response) {
            setData(response.results)
            setTotalRecords(response.count)
        }
        setPending(false);
    };

    const handlePageChange = page => {
        fetchPaginatedRecords(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPending(true);
        const url = cardUrl + `/?page=${page}&page_size=${perPage}&delay=1`
        const response = await adminActions.fetchTableData(url, cardTitle, { startDateTime: startDateTime, endDateTime: endDateTime })
        if (response) {
            setData(response.results)
            setTotalRecords(response.count)
            setPerPage(newPerPage);
        }
        setPending(false);
    };

    const fetchProductData = async (product_id, title) => {
        setPending(true);
        const response = await adminActions.fetchTableData(`product_wise_sale/`, title, { product: product_id, startDateTime: startDateTime, endDateTime: endDateTime })
        if (response) {
            setData(response);
            setColumnsList(product_wise_sale);
        }
        setPending(false);
    }

    useEffect(() => {
        if (cardUrl) {
            // fetchData();
            fetchPaginatedRecords(1);
            switch (cardUrl) {
                case "product_uploads":
                    setColumnsList(product_uploads);
                    break;
                case "product_sold":
                    setColumnsList(product_sold);
                    break;
                case "sellers":
                    setColumnsList(sellers);
                    break;
                case "review_ratings":
                    setColumnsList(review_ratings);
                    break;
                case "unique_visitor":
                    setColumnsList(unique_visitor);
                    break;
                case "new_returning_users":
                    setColumnsList(new_returning_users);
                    break;
                case "new_returning_customers":
                    setColumnsList(new_returning_customers);
                    break;
                // case "product_wise_sale":
                //     setColumnsList(product_wise_sale);
                //     break;
                default:
                    setColumnsList(null);
            }
        }
    }, [startDateTime, endDateTime])


    return (
        <Container className="p-0" fluid style={{ minHeight: '50vh' }}>
            <div className="p-4 d-flex align-items-center text-white">
                <IoMdArrowRoundBack className="mx-2 cursor-pointer" size={25} color="white"
                    onClick={() => {
                        if (product) {
                            setProduct(null)
                            setColumnsList(product_sold)
                            // fetchData()
                            fetchPaginatedRecords(1)
                        } else {
                            setCardType(null)
                            setCardUrl(null)
                        }
                    }}
                />
                {product ?
                    <h4 className="inter-600 m-0">{cardTitle} - {product?.product_name}</h4>
                    :
                    <h4 className="inter-600 m-0">{cardTitle}</h4>
                }
            </div>
            {data &&
                <div className="px-5">
                    <DataTable
                        columns={columnsList}
                        data={data}
                        responsive
                        customStyles={customStyles}
                        persistTableHead
                        pagination
                        paginationServer
                        paginationTotalRows={totalRecords}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationRowsPerPageOptions={[10, 25, 50]}
                        progressPending={pending}
                        noDataComponent={
                            <div className="py-5 w-100 text-center"
                                style={{ backgroundColor: "#1f1f1f", border: "1px solid #ffffff" }}
                            >
                                <p className="my-5 text-white">No data available.</p>
                            </div>
                        }
                        progressComponent={
                            <div className="py-5 w-100 text-center"
                                style={{ backgroundColor: "#1f1f1f", border: "1px solid #ffffff" }}
                            >
                                <Spinner
                                    className="my-5"
                                    animation="border"
                                    variant="primary"
                                />
                            </div>
                        }
                    />
                </div>
            }
        </Container>
    )
}

export default ModuleTable