import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import * as actions from "actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";

import { SearchBarMyProduct } from "components";
import { CategoryForm } from "components";

const Categories = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.adminReducer.categories);
  const products = useSelector((state) => state.adminReducer.products);
  //consolelog(products);
  const coupons = useSelector((state) => state.adminReducer.coupons);
  //consolelog("categories", categories);
  //consolelog("coupons", coupons);

  const [showForm, setShowForm] = useState(null);
  const [categoryImage, setCategoryImage] = useState(null);
  const [visible, setVisible] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);

  const form = useRef(null);
  const [addCoupons, setAddCoupons] = useState([]);
  const clearCoupon = useRef(null);

  const gifRef = useRef();
  const [gif, setGif] = useState(null);

  const gifUpdateRef = useRef();
  const [gifUpdate, setGifUpdate] = useState(null);

  useEffect(() => {
    dispatch(actions.loadCategories());
    dispatch(actions.loadCoupons());
  }, []);

  useEffect(() => {
    setFilteredCategories(categories);
  }, [categories]);

  const handleCreate = (e, details) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    formData.append("category_created_by", 1);
    formData.append("category_modified_by", 1);
    formData.append("details", details);
    if (gif) {
      formData.append("gif_file", gif[0]);
    }
    if (categoryImage) {
      formData.append("image_file", categoryImage[0]);
    }
    dispatch(actions.createCategory(formData));
    form.current.reset();
  };

  const handleUpdate = (e, details) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    formData.append("id", showForm);
    formData.append("category_created_by", 1);
    formData.append("category_modified_by", 1);
    formData.append("is_visible", visible);
    formData.append("details", details);

    if (gif) {
      formData.append("gif_file", gif[0]);
    }
    if (categoryImage) {
      formData.append("image_file", categoryImage[0]);
    }
    setGif(null);
    setCategoryImage(null);
    dispatch(actions.updateCategory(formData));
    setShowForm(null);
  };

  const handleDelete = (id) => {
    dispatch(actions.deleteCategory(id));
    setShowForm(null);
  };

  const handleCouponUpdate = (categoryId, couponId, usedCoupons, mode) => {
    const formData = new FormData();
    formData.append("id", categoryId);
    if (mode == "remove") {
      const unused = usedCoupons.filter((item) => item.id !== couponId);
      unused.map((item) => formData.append("coupons", item.id));
    } else {
      addCoupons.map((item) => formData.append("coupons", item.value));
      usedCoupons.map((item) => formData.append("coupons", item.id));
      // clearCoupon.current.select.clearValue();
      // //consolelog("clearCoupon", clearCoupon);
    }
    dispatch(actions.partialUpdateCategory(formData));
  };

  return (
    <React.Fragment>
      <Container
        className="d-flex flex-column align-items-center h-100 mt-5 pt-5 home-background-categories"
        fluid
        style={styles.parentContainer}
      >
        <Container className="p-5 text-white w-95" fluid>
          {/* <Searchbar type="main" variant="admin" /> */}
          <SearchBarMyProduct placeholder="Search Categories" uploadedProducts={categories} updateProducts={(filteredProducts) => {
            setFilteredCategories(filteredProducts);
          }} />
        </Container>
        <Container className="px-5 text-white h-100 w-100" fluid>
          <CategoryForm
            form={form}
            categoryActivity="add"
            handleCreate={handleCreate}
            handleUpdate={null}
            categoryImage={categoryImage}
            setCategoryImage={setCategoryImage}
            gifRef={gifRef}
            gif={gif}
            setGif={setGif}
          />
          {filteredCategories?.map((item, index) => {
            if (showForm === item.id) {
              return (
                <CategoryForm
                  key={item.id}
                  form={form}
                  categoryActivity="update"
                  category={item}
                  coupons={coupons}
                  handleCreate={null}
                  handleUpdate={handleUpdate}
                  categoryImage={categoryImage}
                  setCategoryImage={setCategoryImage}
                  // gifUpdateRef={gifUpdateRef}
                  // gifUpdate={gifUpdate}
                  // setGifUpdate={setGifUpdate}
                  gifRef={gifRef}
                  gif={gif}
                  setGif={setGif}
                  setVisible={setVisible}
                />
              );
            } else {
              return (
                <CategoryForm
                  key={item.id}
                  categoryActivity="show"
                  category={item}
                  coupons={coupons}
                  setShowForm={setShowForm}
                  handleDelete={handleDelete}
                  handleCouponUpdate={handleCouponUpdate}
                  setAddCoupons={setAddCoupons}
                  clearCoupon={clearCoupon}
                />
              );
            }
          })}
        </Container>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  productImagesCard: {
    width: 120,
    height: 120,
    borderRadius: 15,
  },
  productImagesCardImage: {
    width: 105,
    height: 60,
  },
};

export default Categories;
