import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Button as NuButton } from "ui-neumorphism";
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Section3Gif1, Section3Gif2, Section3Gif3, SellerSection3Carousel1, SellerSection3Carousel2, SellerSection3Carousel3, ZnanyeVRVideo } from 'assets';
import { useRef } from 'react';

const SellerCarousel = () => {
    const videoRef = useRef();
    return (
        <Container className='user-select-none' fluid>
            <Carousel>
                <div>
                    <Container fluid className="w-90 p-lg-5 p-3 h-75 d-flex align-items-center" style={{ backgroundColor: "#292929", borderRadius: 40 }}>
                        <Row className="my-lg-4 mb-3">
                            <Col lg={5} className="pe-lg-5 text-start">
                                <h3 className="pb-5">Showcase Your Product In 3D Viewer</h3>
                                <p className="pb-5">Lorem ipsum dolor sit amet consectetur. Fames
                                    adipiscing interdum turpis augue gravida aenean
                                    ac senectus in. Nec sem aenean diam sed vulputate ut.
                                    Semper tincidunt quis imperdiet dolor sagittis
                                    egestas sed aliquet tristique. Gravida nibh amet
                                    fames vestibulum semper non et viverra ipsum. Feugiat
                                    venenatis erat et dignissim mattis sed risus egestas.
                                    Diam in sit feugiat porttitor nunc in neque. Nunc
                                    nisi urna lorem facilisi. Eget sit aliquet lorem ut
                                    euismod. Euismod elementum augue nunc nunc magna at
                                    facilisis massa. Odio interdum ultricies.
                                </p>
                                <NuButton
                                    dark={true}
                                    className="explore-button explore-button-large"
                                    style={{ backgroundColor: "#27292A" }}
                                >
                                    See our 3d viewer
                                </NuButton>
                            </Col>
                            <Col lg={7} className="d-flex align-items-center justify-content-center">
                                <div className="overflow-hidden mx-5 px-5" style={{ borderRadius: 24 }}>
                                    <video
                                        ref={videoRef}
                                        autoPlay="autoplay"
                                        loop="loop"
                                        playsInline
                                        muted
                                        // poster={Section1Image}
                                        className="w-100"
                                        style={{ borderRadius: 24 }}
                                    >
                                        <source src={"https://dukqh1e9lwqr5.cloudfront.net/ZnanyeVRVideo.webm"} alt="Burger Video" type="video/mp4" />
                                    </video>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div>
                    <Container fluid className="w-90 p-lg-5 h-75 d-flex align-items-center position-relative overflow-hidden" style={{ backgroundColor: "#292929", borderRadius: 40 }}>
                        <Row className="my-lg-4 mb-3">
                            <Col lg={5} className="pe-lg-5 text-start">
                                <h3 className="pb-5">Upload As many as you want ! We dont charge like other platforms</h3>
                                <p className="pb-5">
                                    Lorem ipsum dolor sit amet consectetur. Fames adipiscing interdum turpis augue gravida aenean ac senectus in. Nec sem aenean diam sed vulputate ut. Semper tincidunt quis imperdiet dolor sagittis egestas sed aliquet tristique. Gravida nibh amet fames vestibulum semper non et viverra ipsum. Feugiat venenatis erat et dignissim mattis sed risus egestas.
                                    Diam in sit feugiat porttitor nunc in neque. Nunc nisi urna lorem facilisi. Eget sit aliquet lorem ut euismod. Euismod elementum augue nunc nunc magna at facilisis massa. Odio interdum ultricies.
                                </p>
                            </Col>
                            <Col lg={7} className="d-flex align-items-center justify-content-center">
                                <Image loading="lazy" src={SellerSection3Carousel2} alt="Multiple Products" className="position-absolute w-auto" style={{ right: 0 }} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div>
                    <Container fluid className="w-90 p-lg-5 h-75 d-flex align-items-center position-relative overflow-hidden" style={{ backgroundColor: "#292929", borderRadius: 40 }}>
                        <Row className="my-lg-4 mb-3">
                            <Col lg={5} className="pe-lg-5 text-start">
                                <h3 className="pb-5">Easy Process to sell Your Models</h3>
                                <p className="pb-5">
                                    Lorem ipsum dolor sit amet consectetur. Fames adipiscing interdum turpis augue gravida aenean ac senectus in. Nec sem aenean diam sed vulputate ut. Semper tincidunt quis imperdiet dolor sagittis egestas sed aliquet tristique. Gravida nibh amet fames vestibulum semper non et viverra ipsum. Feugiat venenatis erat et dignissim mattis sed risus egestas.
                                    Diam in sit feugiat porttitor nunc in neque. Nunc nisi urna lorem facilisi. Eget sit aliquet lorem ut euismod. Euismod elementum augue nunc nunc magna at facilisis massa. Odio interdum ultricies.
                                </p>
                            </Col>
                            <Col lg={7} className="d-flex flex-column align-items-center justify-content-center px-3">
                                <div>
                                    <Row className="mb-2" style={{ backgroundColor: "#3d3D3D", borderRadius: 20 }}>
                                        <Col xs={4}>
                                            <Image loading="lazy" src={Section3Gif1} alt="Upload 3D Model" className="w-100" />
                                        </Col>
                                        <Col xs={8} className="d-flex align-items-center h4">
                                            Upload 3D Model
                                        </Col>
                                    </Row>
                                    <Row className="my-5" style={{ backgroundColor: "#3d3D3D", borderRadius: 20 }}>
                                        <Col xs={4}>
                                            <Image loading="lazy" src={Section3Gif2} alt="Sell 3D Models" className="w-100" />
                                        </Col>
                                        <Col xs={8} className="d-flex align-items-center h4">
                                            Sell 3D Models
                                        </Col>
                                    </Row>
                                    <Row className="mt-2" style={{ backgroundColor: "#3d3D3D", borderRadius: 20 }}>
                                        <Col xs={4}>
                                            <Image loading="lazy" src={Section3Gif3} alt="Get Paid" className="w-100" />
                                        </Col>
                                        <Col xs={8} className="d-flex align-items-center h4">
                                            Get Paid
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Carousel>
        </Container>
    );
}

export default SellerCarousel;