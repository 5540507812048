import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { ZCard } from "components";
// import { CategoryProducts } from "pages/categories";
import "core-ui/pages/Categories.scss";
import * as actions from "./../../actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useCurrentBreakpointName } from "react-socks";
import { Breakpoint } from "react-socks";
import {
  assured_product,
  dollar,
  models3d_custom,
  refund_policy_peace,
  viewer3d,
} from "assets";
import { Button as BootstrapButton } from "react-bootstrap";

const Categories = () => {
  let { categorySlug } = useParams();
  const breakpoint = useCurrentBreakpointName();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.loadCategories());
  }, []);
  const categories = useSelector((state) => state.userReducer.categories);
  const visible_categories = categories.filter(
    (category) => category.is_visible === true
  );
  const [changingBorder, setChangingBroder] = useState(false);

  // const handleNavigationClick = (url) => {
  //   window.location.href = url;
  // };

  // useEffect(() => {
  //   console.log("location.pathname", location.pathname);

  //   const handlePopstate = () => {
  //     const currentLocation = window.location.pathname;
  //     const pathURL = `/3dmodels/${categorySlug}`;
  //     console.log("location.pathname", currentLocation);

  //     if (currentLocation !== "/3dmodels" && location.pathname !== pathURL) {
  //       console.log("location.pathname inside", currentLocation);
  //       handleNavigationClick("/3dmodels");
  //     } else if (currentLocation === "/3dmodels") {
  //       console.log("location.pathname inside 2", currentLocation);
  //       handleNavigationClick("/");
  //     }
  //   };

  //   window.addEventListener("popstate", handlePopstate);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopstate);
  //   };
  // }, []);
  // console.log("Categories", categories);
  // console.log("Visible Categories", visible_categories);

  if (categorySlug) {
    return <Outlet />;
  }

  return (
    <React.Fragment>
      {/* <Helmet>
        <title>{`Znanye - Buy professional 3D models by the designer community`}</title>
        <meta name="description" content="Shop by category and stay organized. Save time with our clear, easy-to-use category structure and find the perfect item for you at affordable prices" />
        <link rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
      </Helmet> */}

      <Container
        className="d-flex flex-column align-items-center h-100 text-center mt-5 pt-3 home-background-categories px-0"
        fluid
        style={styles.parentContainer}
      >
        <Container className="d-flex flex-column justify-content-center align-items-center">
          {/* <Breakpoint large up className="w-100" style={{ zIndex: 1000 }}>
            <Searchbar type="main" />
          </Breakpoint> */}
          {/* <Breakpoint medium down style={{ zIndex: 1000 }}>
            <Searchbar />
          </Breakpoint> */}
        </Container>
        <Container>
          <Breakpoint large up>
            <div className="w-100 my-lg-5">
              <div className="w-100 mx-2 my-2 text-start">
                <div className=" text-start body-text3 mb-4 ">
                  <ui className="breadcrumb  mx-0 my-2 mb-2 d-flex justify content-center align-items-center">
                    <li>
                      <a
                        href="/3dmodels"
                        className="text-primary body-text3"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        3D Models{" "}
                      </a>
                    </li>
                  </ui>
                </div>
                <div className="poppins-500 text-white search-label h3 user-select-none font-size32 p-0">
                  Explore Our <span className="text-primary">3D Models</span>
                </div>
                <div className="my-4 text-white mb-5" style={{ maxWidth: 800 }}>
                  Buy Professional 3D Models. Our designer community has crafted
                  professional 3D models for you to use in your projects, games,
                  production, and others. Buy{" "}
                  <a
                    className="text-decoration-none"
                    href={`/`}
                  // onClick={() => handleNavigationClick(`/`)}
                  >
                    Professional 3D Models
                  </a>{" "}
                  for free in industry-accepted formats like OBJ, FBX, GLTF,
                  3DS, and others. There are also free downloads that are
                  available for you to use. Try out the models on our 3D viewer
                  to ensure you choose the right product. We also offer a no
                  questions asked refund.
                  {/* </a> */}
                  {/* <a href="https://www.znanye.com/contact-us/#Buy-VR-Headset"> */}
                </div>
              </div>
              <Row
                className={`${breakpoint === "xsmall" ? "row-cols-2" : "row-cols-5"
                  }`}
              >
                {visible_categories.map((item, index) => {
                  return (
                    <ZCard
                      key={item}
                      index={index}
                      item={item}
                      type="mobileExplorecategory"
                    />
                  );
                })}
              </Row>
            </div>
          </Breakpoint>
          <Breakpoint small down>
            <div className="w-100 my-lg-5">
              <div className="w-100 mx-2 my-2 text-start">
                <div className=" text-start body-text3 mb-4 ">
                  <ui className="breadcrumb  mx-0 my-2 mb-2">
                    <li>
                      <a
                        href="/3dmodels"
                        className="text-primary"
                        style={{ textDecoration: "none" }}
                      >
                        3D Models{" "}
                      </a>
                    </li>
                  </ui>
                </div>
                <div
                  className="poppins-500 text-white search-label mb-5 user-select-none font-size32 p-0"
                  style={{ maxWidth: "190px" }}
                >
                  Explore Our <span className="text-primary">3D Models</span>
                </div>
                <div className="text-white mb-5" style={{ maxWidth: 328 }}>
                  Buy Professional 3D Models. Our designer community has crafted
                  professional 3D models for you to use in your projects, games,
                  production, and others. Buy{" "}
                  <a
                    className="text-decoration-none"
                    href={`/`}
                  // onClick={() => handleNavigationClick(`/`)}
                  >
                    Professional 3D Models
                  </a>{" "}
                  for free in industry-accepted formats like OBJ, FBX, GLTF,
                  3DS, and others. There are also free downloads that are
                  available for you to use. Try out the models on our 3D viewer
                  to ensure you choose the right product. We also offer a no
                  questions asked refund.
                  {/* <a href="https://www.znanye.com/contact-us/#Buy-VR-Headset">
                  </a>{" "} */}
                </div>
              </div>
              <Row
                className={`${breakpoint === "xsmall" ? "row-cols-2" : "row-cols-5"
                  }`}
              >
                {visible_categories.map((item, index) => {
                  return (
                    <ZCard
                      key={item}
                      index={index}
                      item={item}
                      type="mobileExplorecategory"
                    />
                  );
                })}
              </Row>
            </div>
          </Breakpoint>

          <Breakpoint large up>
            <div className="w-100 my-lg-5 position-relative">
              <div
                className="w-100 my-5 text-center bg-black p-5 d-flex flex-column align-items-center position-relative custom-category-background"
                style={{ borderRadius: 40, border: "1px solid #3D3D3D" }}
              >
                {/* <Image loading="lazy" className="position-absolute w-100" src={models3d_custom} style={{ top: 0, bottom: 0, zIndex: 0 }} /> */}
                <h2 className="poppins-400 text-white search-label mb-5 user-select-none h3 p-0">
                  Couldn't Find{" "}
                  <span className="text-primary">What You Want ?</span>
                </h2>
                <div className="text-white mb-5 px-5 mx-5">
                  We believe that one size does not fit all. In this
                  fast-changing environment, what makes you stand out is your
                  key differentiator. We help you stand out with customized
                  offerings. Get in touch with us and we'll help you explore the
                  infinite possibilities out there.
                </div>
                <a
                  className="text-decoration-none"
                  href="/contact-us/#Customized-3D-model"
                // onClick={() =>
                //   handleNavigationClick("/contact-us/#Customized-3D-model")
                // }
                >
                  <BootstrapButton
                    className="mt-5 explore-button-no-neo"
                    dark={true}
                    size="lg"
                    style={{ border: changingBorder ? "0px" : "0px" }}
                    onMouseEnter={() => setChangingBroder(true)}
                    onMouseLeave={() => setChangingBroder(false)}
                  // style={{ zIndex: 99 }}
                  >
                    <span className="poppins">GET A CUSTOM 3D MODEL</span>
                  </BootstrapButton>
                </a>
              </div>
            </div>
          </Breakpoint>
          <Breakpoint medium>
            <div className="w-100 my-lg-5 position-relative">
              <div
                className="w-100 my-5 text-center bg-black p-5 d-flex flex-column align-items-center position-relative"
                style={{ borderRadius: 40, border: "1px solid #3D3D3D" }}
              >
                <Image
                  loading="lazy"
                  className="position-absolute w-100"
                  src={models3d_custom}
                  alt="Custom Model Background"
                  style={{ top: 0, bottom: 0 }}
                />
                <h2 className="poppins-400 text-white search-label mb-5 user-select-none h2 p-0">
                  Couldn't Find{" "}
                  <span className="text-primary">What You Want ?</span>
                </h2>
                <div className="text-white mb-5 px-5 mx-5">
                  We believe that one size does not fit all. In this
                  fast-changing environment, what makes you stand out is your
                  key differentiator. We help you stand out with customized
                  offerings. Get in touch with us and we'll help you explore the
                  infinite possibilities out there.
                </div>
                <a
                  className="text-decoration-none"
                  href="/contact-us/#Customized-3D-model"
                // onClick={() =>
                //   handleNavigationClick("/contact-us/#Customized-3D-model")
                // }
                >
                  <BootstrapButton
                    className="mt-5 explore-button-no-neo"
                    dark={true}
                    size="lg"
                    style={{ border: changingBorder ? "0px" : "0px" }}
                    onMouseEnter={() => setChangingBroder(true)}
                    onMouseLeave={() => setChangingBroder(false)}

                  // style={{ zIndex: 99 }}
                  >
                    <span className="poppins">GET A CUSTOM 3d MODEL</span>
                  </BootstrapButton>
                </a>
              </div>
            </div>
          </Breakpoint>
          <Breakpoint small down>
            <div className="w-100 my-5 py-5 position-relative custom-category-background">
              <div
                className="poppins-500 text-white search-label mb-5 user-select-none font-size32 p-0"
                style={{ width: "280px" }}
              >
                Couldn't.. Find{" "}
                <span className="text-primary">What You Want ?</span>
              </div>
              <div
                className="text-white mb-5"
                style={{ width: "328px", fontSize: 18 }}
              >
                We believe that one size does not fit all. In this fast-changing
                environment, what makes you stand out is your key
                differentiator. We help you stand out with customized offerings.
                Get in touch with us and we'll help you explore the infinite
                possibilities out there.
              </div>
              <a
                className="text-decoration-none"
                href="/contact-us/#Customized-3D-model"
              // onClick={() =>
              //   handleNavigationClick("/contact-us/#Customized-3D-model")
              // }
              >
                <BootstrapButton
                  className="mt-5 explore-button-no-neo"
                  dark={true}
                  size="lg"
                  style={{ border: changingBorder ? "0px" : "0px" }}
                  onMouseEnter={() => setChangingBroder(true)}
                  onMouseLeave={() => setChangingBroder(false)}
                // style={{ zIndex: 99 }}
                >
                  <span className="poppins">GET A CUSTOM 3D MODEL</span>
                </BootstrapButton>
              </a>
            </div>
          </Breakpoint>

          <Breakpoint large up>
            <div className="w-100 my-lg-5">
              <Row
                className="w-100 my-5 text-start row"
                style={{ color: "#808080", width: 220, marginLeft: -10 }}
              >
                <Col lg={3}>
                  <div className="px-5">
                    <Image loading="lazy" src={dollar} alt="Dollar" className="my-5" />
                    <p className="poppins-500 h5">
                      You save more because you pay less
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="px-5">
                    <Image
                      loading="lazy"
                      src={viewer3d}
                      alt="View in 3D Viewer"
                      className="my-5"
                    />
                    <p className="poppins-500 h5">
                      See All Products in powerful 3D Viewer
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="px-5">
                    <Image
                      loading="lazy"
                      src={assured_product}
                      alt="Assured Product"
                      className="my-5"
                    />
                    <p className="poppins-500 h5">
                      You have lower stress as we host only Znanye Assured 3D
                      models
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="px-5">
                    <Image
                      loading="lazy"
                      src={refund_policy_peace}
                      alt="Refund Policy"
                      className="my-5"
                    />
                    <p className="poppins-500 h5">
                      You have peace of mind as we have no questions asked
                      refund policy
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Breakpoint>

          <Breakpoint small down>
            <div className="w-100 my-lg-5">
              <Row
                className="my-5 text-start"
                style={{ color: "#808080", width: 220, paddingLeft: 16 }}
              >
                <Col lg={3}>
                  <div>
                    <Image loading="lazy" src={dollar} alt="Dollar" className="my-5" />
                    <p className="poppins-500">
                      You save more because you pay less
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div>
                    <Image
                      loading="lazy"
                      src={viewer3d}
                      alt="View in 3D Viewer"
                      className="my-5"
                    />
                    <p className="poppins-500">
                      See All Products in powerful 3D Viewer
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div>
                    <Image
                      loading="lazy"
                      src={assured_product}
                      alt="Assured Product"
                      className="my-5"
                    />
                    <p className="poppins-500">
                      You have lower stress as we host only Znanye Assured 3D
                      models
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div>
                    <Image
                      loading="lazy"
                      src={refund_policy_peace}
                      alt="Refund Policy"
                      className=" my-5"
                    />
                    <p className="poppins-500">
                      You have peace of mind as we have no questions asked
                      refund policy
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Breakpoint>
        </Container>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  parentContainer90: {
    minHeight: "90vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  checkedIcons: {
    width: 20,
    height: 20,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
};

export default Categories;
