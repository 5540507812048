import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const CookiesPolicy = () => {
    const location = useLocation();
    return (
        <React.Fragment>
            {/* <Helmet>
                <title>{`Znanye - We track ur usage of our website based on ur consent.`}</title>
                <meta name="description" content="Our website uses cookies to enhance your browsing experience. Learn more about the types of cookies we use and how to manage your preferences." />
                <link rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
                <link rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
            </Helmet> */}
            <Container
                className="d-flex flex-column h-100 text-left text-white px-3 px-sm-4 px-md-5 py-2 py-md-4"
                fluid
                style={styles.parentContainer}
            >
                <h4 className="mb-2">COOKIES POLICY</h4>
                <hr className="line-below-heading" />
                <Row>
                    <Col
                        className="text-white my-2"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <p className="text-justify">
                            This Cookies Policy (“<b>Policy</b>”) for all the users (“
                            <b>USer</b>” “<b>you</b>” ) Please note that Pastelcube
                            Technologies Private Limited (“<b>Znanye</b>”,"<b>us</b>“,"
                            <b>we</b>” or “<b>our</b>”) reserves the right to update this
                            Policy from time to time.
                            <br></br>
                            <br></br>
                            By using our platform or website (“<b>Platform”</b>), you agree
                            that we can place cookies on your device. It is possible that some
                            services may not function optimally if you do not accept these
                            cookies through your browser or device. Do note that in cases
                            where we have a presence on a website owned by a third party, such
                            third party’s privacy policy and terms of use, will govern your
                            usage, unless specifically stated otherwise. We use cookies that
                            are essential for our site to work.  To improve our Platform, we
                            would like to use additional cookies to help us understand how
                            visitors use the site, measure traffic to our site from social
                            media platforms and to personalise your experience.  Some of the
                            cookies that we use is provided by third parties.  Please visit
                            our Cookie Policy for more information. To accept all cookies,
                            click &#39;Accept all&#39;.  To reject all optional cookies or
                            choose which optional cookies to allow, click ‘Cookie settings’.
                            This tool uses a cookie to remember your choices.
                        </p>
                        <h5 className="mb-2">What are cookies?</h5>
                        <hr className="line-below-heading" />
                        <p className="text-justify">
                            Cookies are pieces of data/ small text files that are placed on
                            your device (such as desktop / laptop / tablet / smartphone) and
                            they can typically be read on future visits to learn more about
                            the User and to personalize an experience, including the ads that
                            are displayed to the User if such user is a returning visitor.
                        </p>
                        {/* <p className="text-justify">
                        For more information on how Znanye processes your personal information, please see our Privacy
Policy.
                        </p> */}
                    </Col>
                    <Col
                        className="text-white text-justify"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <h5 className="mb-2">What do cookies do?</h5>
                        <hr className="line-below-heading" />
                        <p className="text-justify">
                            Cookies are useful for helping us understand how our Platform is
                            being used, which pages are viewed, how often each page in our
                            viewed, remembering your preferences (if set) and enhancing your
                            overall browsing experience. Cookies also assist in better
                            marketing, so you see information that is relevant to You and to
                            improve the User experience. The Cookies we place on your computer
                            cannot be used to retrieve any other data from your hard drive, to
                            pass viruses to your computer, or capture your email address. Each
                            Cookie is unique to your web browser and device.
                        </p>

                        <h5 className="mb-2">Cookies used by us</h5>
                        <hr className="line-below-heading" />
                        {/* <ol>
                            <li>
                                Usage information and statistics about how you interact with the Platform, including the
                                application you used, saved preferences, crash reports, and navigation data (like the URLs of
                                our websites that you have visited, URLs of referring and exiting pages, page views, time spent
                                on a page;
                            </li>
                            <li>
                                Technical information about your computer, device, hardware, or software you use to access
                                our Platform, such as IP address, device identifiers, your internet service provider, plugins, or
                                other transactional or identifier information for your device (such as device make and model,
                                information about device operating systems and browsers, or other device or system-related
                                specifications); and
                            </li>
                            <li>
                                The general location of your device, which we typically derive from your device's IP address.
                            </li>
                        </ol> */}
                        <p className="text-justify">
                            <b>Essential Cookies.</b> These cookies are essential, as they
                            enable you to move around a Platform and use its features, such as
                            accessing secure areas, if applicable. Without these cookies, you
                            will be unable to avail the services requested by you. These
                            cookies don’t gather information about you that is used for
                            marketing or remembering where you’ve been on the Internet.
                        </p>
                    </Col>

                    <Col
                        className="text-white text-justify"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        {/* <h5 className="mb-2">
                            Third Party Websites
                        </h5><hr className="line-below-heading" /> */}
                        <p className="text-justify">
                            <b>Performance Cookies.</b> These cookies collect information
                            about how you use a Platform. These cookies do not gather any
                            information which can identify you. All the information collected
                            by such cookies is anonymous and only used to improve your
                            experience. These cookies are not used to target you with online
                            marketing. Without these cookies we can’t learn how our Platform
                            is performing and make relevant improvements that could better
                            your browsing experience.
                        </p>
                    </Col>

                    <Col
                        className="text-white text-justify"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        {/* <h5 className="mb-2">
                            Third Party Websites
                        </h5><hr className="line-below-heading" /> */}
                        <p className="text-justify">
                            <b>Non-essential Cookies.</b> These cookies allow the Platform to
                            remember choices you make (such as your username, language, or the
                            region you are in) to provide enhanced features and content for
                            you. These cookies are used to customise marketing to you and your
                            interests. They are also used to limit the number of times you see
                            an advertisement as well as help measure the effectiveness of the
                            advertising campaign. They remember that You have visited a
                            Platform and this information may be shared with other
                            organisations such as advertisers. Although these cookies can
                            track your visits to other websites, they don’t usually know who
                            you are. You may or may not agree to accept these cookies. Without
                            these cookies, the Platform cannot remember the choices you’ve
                            previously made or personalise your browsing experience.
                        </p>
                    </Col>

                    <Col
                        className="text-white text-justify"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <h5 className="mb-2">How can you control cookies?</h5>
                        <hr className="line-below-heading" />
                        <p className="text-justify">
                            You have the right to decide whether to accept or reject cookies.
                            However, you will not be provided with an option to reject the
                            essential cookies. You can set or amend your web browser controls
                            to accept or refuse non-essential and performance cookies. If you
                            choose to reject the non-essential and/or performance cookies, you
                            may still use our Platform though your access to some
                            functionality and areas of our Platform may be restricted. To
                            learn how to manage your cookies, please follow the instructions
                            from your specific browser.
                            <br></br>
                            <br></br>
                            If you are visiting this site using a mobile device such as a
                            smartphone or tablet, please refer to the device manufacturer’s
                            instructions on how to manage cookies.
                        </p>
                    </Col>
                    {/* <Col
                        className="text-white text-justify"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <h5 className="mb-2">
                            Use
                        </h5> <hr className="line-below-heading" />
                        <p className="text-justify">
                            We use personal information to provide the services you request. We use your personal information to
                            assist sellers and business partners in handling and fulfilling orders; enhancing customer experience; to
                            resolve disputes; troubleshoot problems; help promote a safe service; collect money; measure consumer
                            interest in our products and services, inform you about online and offline offers, products, services, and
                            updates; customize your experience; detect and protect us against error, fraud and other criminal
                            activity; enforce our terms and conditions; conduct marketing research, analysis and surveys; and as
                            otherwise described to you at the time of collection of information. We will ask for your permission to
                            allow us access to your text messages (SMS), instant messages, contacts in your directory, camera,
                            photo gallery, location and device information: (i) to send commercial communication regarding your
                            orders or other products and services (ii) enhance your experience on the platform and provide you
                            access to the products and services offered on the Platform by sellers, affiliates, partners or lending
                            partners. You understand that your access to these products/services may be affected in the event
                            permission is not provided to us. In our efforts to continually improve our product and service offerings,
                            we collect and analyse demographic and profile data about users' activity on our Platform. We identify
                            and use your IP address to help diagnose problems with our server, and to administer our Platform.
                            Your IP address is also used to help identify you and to gather broad demographic information. We will
                            occasionally ask you to complete surveys conducted either by us or through a third- party market
                            research agency.
                        </p>
                        <p className="text-justify">
                            We may also process information that does not identify you individually, including aggregate or de-
                            identified information that we create or collect from other sources. This information helps us understand

                            larger groups of users better. If we combine this information with information that identifies you, we
                            shall treat it as described in this Policy. Please note that this Policy does not restrict our ability to process

                            information that does not individually identify you, and we may use and disclose aggregated or de-
                            identified information for any reason permitted by law.
                        </p>
                    </Col>
                    <Col
                        className="text-white text-justify my-2"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <h5 className="mb-2">
                            Recipients of Your Information
                        </h5> <hr className="line-below-heading" />
                        <p className="text-justify">
                            We may share your information for the business purposes with third party entities including but not
                            limited to:
                        </p>
                        <ul>
                            <li>
                                Our affiliates, business partners, suppliers, and sub-contractors for the performance of any
                                contract we enter into with them or you, for example for hosting and operating the Platform or
                                providing support and maintenance services for the Platform;
                            </li>
                            <li>
                                analytics and search engine providers and other selected third parties that assist us in the
                                improvement and optimization of our Platform;
                            </li>
                            <li>
                                our regulators, law enforcement, or fraud prevention agencies, as well as our advisors
                                (including legal advisors and auditors), courts, and any other authorized bodies, for the purposes
                                of investigating any actual or suspected criminal activity or other regulatory or legal matters;
                            </li>
                            <li>
                                in the event of restructuring or sale of all or a significant part of our business;
                            </li>
                            <li>
                                to protect the rights, property, or safety of Znanye, our customers, or others.
                            </li>
                        </ul>
                    </Col>
                    <Col
                        className="text-white text-justify my-2"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <h5 className="mb-2">
                            Privacy Settings
                        </h5> <hr className="line-below-heading" />
                        <p className="text-justify">
                            Depending on your interaction with the Platform, We seek to provide you with meaningful choices
                            about the personal data we collect. The specific choices available to you often vary depending on how
                            you interact with the Platform:
                        </p>
                        <ul>
                            <li>
                                If we process your personal data based on your consent, you have the right to withdraw your
                                consent at any time by updating the settings in your user profile or contacting us at
                                talk2us@pastelcube.com.
                            </li>
                            <li>
                                You can also change your email marketing preferences at any time by using the opt-out
                                mechanism provided in our marketing emails.
                            </li>
                            <li>
                                If you wish to modify any of your user profile information, restrict the processing of your
                                personal data, or cancel your account, you may update the settings in your user profile or contact
                                us at <a href="mailto:talk2us@pastelcube.com">talk2us@pastelcube.com</a>.
                            </li>
                            <li>
                                You can request that we provide access to, or that we correct or delete, personal information
                                we've collected from you. Please submit requests to access, update, or delete personal
                                information associated with your Znanye account by contacting us at talk2us@pastelcube.com.
                                Note that we may ask you for additional information to help us verify who you are before
                                completing your request.
                            </li>
                            <li>
                                You can change your browser or mobile device settings to block, manage, delete, or limit
                                tracking technologies like cookies. In some cases, blocking or disabling cookies may cause the
                                Platform not to work as intended and some features may not be available.to protect the rights, property, or safety of Znanye, our customers, or others.
                            </li>
                        </ul>
                    </Col>
                    <Col
                        className="text-white text-justify my-2"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <h5 className="mb-2">
                            Security of the Personal Information
                        </h5> <hr className="line-below-heading" />
                        <p className="text-justify">
                            While we take precautions against all possible security breach in our Platform, Consequently, we cannot
                            guarantee that the Platform shall be free from unauthorized access, hacking, data loss, or other breaches.
                            We urge you to take steps to keep your personal information safe by memorizing your password or
                            storing it safely (separate from your account information), logging out of your user account, keeping
                            your OTP secret and closing your web browser whenever you finish your session or leave your
                            computer in a public area.
                            We employ a security technology known as a Secure Sockets Layer (SSL) protocol to protect the
                            transmission of critical account information.
                        </p>
                    </Col>
                    <Col
                        className="text-white text-justify my-2"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <h5 className="mb-2">
                            Disclaimer
                        </h5> <hr className="line-below-heading" />
                        <p className="text-justify">
                            YOU ARE ADVISED TO REVIEW THIS PRIVACY POLICY CAREFULLY SO THAT THE YOU UNDERSTAND OUR PRIVACY PRACTICES.
                            BY USING OUR PLATFORM, YOU WILL DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE PRACTICES AND POLICIES OUTLINED IN
                            THIS PRIVACY POLICY AND AGREE TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE, STORING AND
                            SHARING OF YOUR PERSONAL INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. IF YOU USE THE SERVICES ON BEHALF OF SOMEONE ELSE,
                            YOU REPRESENT THAT YOU ARE AUTHORISED BY SUCH INDIVIDUAL OR ARE LEGALLY AUTHORIZED TO (I) ACCEPT THIS PRIVACY POLICY ON SUCH
                            INDIVIDUAL’S BEHALF, AND (II) CONSENT ON BEHALF OF SUCH INDIVIDUAL TO OUR COLLECTION, USE AND DISCLOSURE OF SUCH INDIVIDUAL’S
                            PERSONAL INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. YOU FURTHER UNDERSTAND THAT THIS PLATFORM MAY CONTAIN LINKS TO OTHER
                            SITES THAT WE DO NOT OPERATE. IF YOU CLICK ON A THIRD-PARTY LINK, YOU WILL BE DIRECTED TO THAT THIRD PARTY'S SITE. WE ADVISE YOU
                            TO REVIEW THE PRIVACY POLICY OF EVERY SITE YOU VISIT. WE HAVE NO CONTROL OVER AND ASSUME NO RESPONSIBILITY FOR THE CONTENT,
                            PRIVACY POLICIES OR PRACTICES OF ANY THIRD-PARTY SITES OR SERVICES.
                        </p>
                    </Col>
                    <Col
                        className="text-white text-justify my-2"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <h5 className="mb-2">
                            Children's Privacy
                        </h5> <hr className="line-below-heading" />
                        <p className="text-justify">
                            Use of our Platform is available only to persons who can form a legally binding contract under the
                            applicable law. We do not knowingly solicit or collect personal information from children under the
                            age of 18 years. If you have shared any personal information of children under the age of 18 years, you
                            represent that you have the authority to do so and permit us to use the information in accordance with
                            this Privacy Policy.
                        </p>
                    </Col>
                    <Col
                        className="text-white text-justify my-2"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <h5 className="mb-2">
                            Data Retention
                        </h5> <hr className="line-below-heading" />
                        <p className="text-justify">
                            We retain your personal information for a period no longer than is required for the purpose for which it
                            was collected or as required under any applicable law. However, we may retain data related to you if
                            there is a legal obligation to retain the data; if required to comply with any applicable statutory or
                            regulatory retention requirement by law; if we believe it may be necessary to prevent fraud or future
                            abuse; to enable Znanye to exercise its legal rights and/or defend against legal claims; or for other
                            legitimate purposes. We may continue to retain your data in anonymised form for analytical and
                            research purposes.
                        </p>
                    </Col>
                    <Col
                        className="text-white text-justify my-2"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <h5 className="mb-2">
                            Consent
                        </h5> <hr className="line-below-heading" />
                        <p className="text-justify">
                            By visiting our Platform or by providing your information, you consent to the collection, use, storage,
                            transfer, disclosure and otherwise processing of your information (including sensitive personal
                            information) on the Platform in accordance with this Privacy Policy. If you disclose to us any personal
                            information relating to other people, you represent that you have the authority to do so and permit us to
                            use the information in accordance with this Privacy Policy. You, while providing your personal
                            information over the Platform or any partner platforms or establishments, consent to us (including our
                            other corporate entities, affiliates, lending partners, technology partners, marketing channels, business
                            partners and other third parties) to contact you through SMS, instant messaging apps, call and/or e-mail
                            for the purposes specified in this Privacy Policy. You have an option to withdraw your consent that you
                            have already provided by writing to the Grievance Officer at the contact information provided below.
                            Please mention “Withdrawal of consent for processing personal information” in your subject line of
                            your communication. We will verify such requests before acting on our request. Please note, however,
                            that withdrawal of consent will not be retroactive and will be in accordance with the terms of this
                            Privacy Policy, related terms of use and applicable laws. In the event you withdraw consent given to us
                            under this Privacy Policy, we reserve the right to restrict or deny the provision of our services for which
                            we consider such information to be necessary.
                        </p>
                    </Col>
                    <Col
                        className="text-white text-justify my-2"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <h5 className="mb-2">
                            Changes to this Policy
                        </h5> <hr className="line-below-heading" />
                        <p className="text-justify">
                            We may update this Policy from time to time. If the changes materially affect the way we use
                            identifiable information that we have already collected, we shall notify you on our Platform. The
                            continued usage of our Platform after such changes have been made to these terms will mean that you
                            accept these changes. Therefore, you are requested to kindly check the Policy regularly for updates.
                        </p>
                    </Col>
                    <Col
                        className="text-white text-justify my-2"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        <h5 className="mb-2">
                            GRIEVANCE OFFICER
                        </h5> <hr className="line-below-heading" />
                        <p className="text-justify">
                            In accordance with Information Technology Act 2000 and rules made there under and the Consumer
                            Protection (E-Commerce) Rules, 2020, the name and contact details of our Grievance Officer are
                            provided below:
                        </p>
                        <div>
                            <div>
                                Name:
                            </div>
                            <div>
                                Designation:
                            </div>
                            <div>
                                Address:
                            </div>
                            <div>
                                Email:
                            </div>
                            <div>
                                Phone:
                            </div>
                            <div>
                                Time:
                            </div>
                        </div>
                    </Col>
                    <Col
                        className="text-white text-center text-lg-start my-5"
                        style={styles.privacyPolicyDescription}
                        xs={12}
                    >
                        Pastelcube &copy; 2021
                    </Col> */}
                </Row>
            </Container>
        </React.Fragment>
    );
};

const styles = {
    parentContainer: {
        minHeight: "100vh",
        marginTop: "80px",
    },
};

export default CookiesPolicy;
