import { ContactForm } from "components";
import React, { useRef } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Breakpoint, useCurrentWidth } from "react-socks";
import { getintouchwithznanye } from "assets";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  const dispatch = useDispatch();
  const form = useRef(null);
  const myRefForm = useRef(null);
  const width = useCurrentWidth();
  const location = useLocation();

  return (
    <React.Fragment>
      {/* <Helmet>
                <title>{`Znanye | `}</title>
                <meta name="description" content="Contact Us" />
                <link rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
                <link rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
            </Helmet> */}
      <Container
        fluid
        className="d-flex w-100 flex-column align-items-center px-lg-5 pb-5 home-background-categories text-white my-5"
        style={styles.parentContainer85}
        ref={myRefForm}
      >
        <Container
          className="p-0 my-5"
          style={{
            width: "100%",
            backgroundColor: "#292929",
            borderRadius: width > 768 ? "40px" : "10px",
            margin: "0px 150px 0px 0px",
          }}
        >
          <Container
            className="p-0"
            style={{
              width: "100%",
              backgroundColor: "#141415",
              borderRadius: width > 768 ? "40px" : "10px",
              margin: "-70px 70px 70px 70px",
            }}
          >
            <Row className="">
              <Col
                lg={6}
                className="d-flex justify-content-center align-items-center"
              >
                <Breakpoint large up>
                  <div className="">
                    <Image
                      loading="lazy"
                      src={getintouchwithznanye}
                      alt="Get in Touch with Znanye"
                    />
                  </div>
                </Breakpoint>
                <Breakpoint medium down className="text-center">
                  <div className="mt-4 d-flex justify-content-center align-items-center">
                    <h3>
                      Get in <span className="text-primary">Touch</span>
                    </h3>
                  </div>
                </Breakpoint>
              </Col>
              <Col lg={6} className={`my-3`}>
                <Row>
                  <Col lg={10}>
                    <ContactForm form={form} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer85: {
    minHeight: "85vh",
    paddingTop: 100,
  },
};

export default ContactUs;
