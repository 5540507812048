import React, { useState, useEffect } from "react";
import { Container, Row, Image, Button as RButton } from "react-bootstrap";
import { useDispatch } from "react-redux";

import "core-ui/pages/MyAccount.scss";

import { emptycart } from "assets";
import { Link } from "react-router-dom";
import * as actions from "actions/AdminActions";
import { ProductCard } from "components";

const Approvals = () => {
  const dispatch = useDispatch();
  const axios = require("axios").default;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(actions.loadProducts());
  }, []);

  //   const products = useSelector((state) => state.adminReducer.products);
  const [products, setProducts] = useState();

  // uncomment to test the empty purchase list page
  // const uploadedProducts = [];

  const handleLoadProducts = async () => {
    setLoading(true);
    const response = await axios.get(`/product/?is_verified=False&is_in_draft=False`);
    console.log("@@", response.data);
    setProducts(response.data);
  };

  useEffect(() => {
    handleLoadProducts();
  }, []);

  if (products?.length === 0) {
    return (
      <React.Fragment>
        <Container
          className="d-flex w-100 justify-content-center align-items-center h-100 mt-5 pt-5 ps-5 home-background-categories"
          fluid
          style={styles.emptyPurchaseListContainer}
        >
          <div>
            <div className="d-flex justify-content-center">
              <Image loading="lazy" src={emptycart} alt="Empty Cart" style={{ width: 200 }} />
            </div>
            <div className="d-flex justify-content-center mt-5 mb-5">
              <h4 className="text-primary m-1">OOPS ! </h4>
              <h4 className="text-white m-1">
                There are no products for approval!
              </h4>
            </div>
            <div className="d-flex justify-content-center mt-5 mb-5">
              <Link
                to="/sell-3d/uploadproducts"
                style={{ textDecoration: "none" }}
              >
                <RButton
                  dark={true}
                  className="h-100 explore-button-no-neo btn btn-ptimary"
                  size="lg"
                >
                  UPLOAD PRODUCTS
                </RButton>
              </Link>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Container
        className="w-100 px-5 home-background-categories"
        fluid
        // style={{ paddingTop: 100 }}
        style={styles.mainContainer}
      >
        {/* <div className="d-flex justify-content-start mt-5 mx-2">
          <RButton
            className={`${
              newProducts
                ? "explore-button-no-neo btn btn-ptimary"
                : "explore-border-button explore-button-large-no-neo"
            } p-4`}
            dark={true}
            variant="dark"
            onClick={() => {
              setNewProducts(true);
              setUpdatedProducts(false);
            }}
          >
            NEW PRODUCTS
          </RButton>
          <RButton
            className={`${
              updatedProducts
                ? "explore-button-no-neo btn btn-ptimary"
                : "explore-border-button explore-button-large-no-neo"
            } p-4 mx-5`}
            dark={true}
            variant="dark"
            onClick={() => {
              setNewProducts(false);
              setUpdatedProducts(true);
            }}
          >
            UPDATED PRODUCTS
          </RButton>
        </div> */}
        {/* {loading && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "70vh", paddingTop: 50 }}
          >
            <Image
              src={znanyeloader}
              alt="Znanye Loader"
              loading="lazy"
              style={{ width: "60px", height: "60px" }}
            />
          </div>
        )} */}

        <Row className="mt-5" fluid>
          {products?.map((uploadedProduct) => (
            <ProductCard
              item={uploadedProduct}
              type="approvereject"
              newProduct={true}
            />
          ))}
        </Row>

        {/* <Row className={`${newProducts ? "mt-5" : "d-none"}`} fluid>
          {uploadedProducts?.map((uploadedProduct) => (
            <ProductCard
              item={uploadedProduct}
              type="approvereject"
              newProduct={true}
            />
          ))}
        </Row>
        <Row className={`${updatedProducts ? "mt-5" : "d-none"}`} fluid>
          {uploadedDraftProducts?.map((uploadedProduct) => (
            <ProductCard
              item={uploadedProduct}
              type="approvereject"
              newProduct={false}
            />
          ))}
        </Row> */}
      </Container>
    </React.Fragment>
  );
};

const styles = {
  mainContainer: {
    minHeight: "100vh",
    paddingTop: 100,
  },
  emptyPurchaseListContainer: {
    minHeight: "80vh",
  },
  progressBarContainer: {
    minHeight: 100,
  },
  cartCard: {
    height: 500,
  },
  cardAction: {
    backgroundColor: "#2E2E2E",
  },
  underline: {
    height: 1,
    backgroundColor: "white",
  },
  youSavedColor: {
    color: "#58D31B",
  },
};
export default Approvals;
