import React, { useState, useEffect } from "react";
import { Col, Container, Row, Image, Card, Button } from "react-bootstrap";
import * as adminActions from "actions/AdminActions";
import { BlogList } from "pages";
import { Breakpoint } from "react-socks";

const Blogs = () => {
  let params = new URLSearchParams(document.location.search);
  let typeId = params.get("blog_type");
  const [blogTypes, setBlogTypes] = useState(null);

  const findBlogTypes = async () => {
    const response = await adminActions.loadBlogTypes();
    //consolelog(response);
    if (response) {
      setBlogTypes(response);
    }
  };

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    findBlogTypes();
  }, []);

  if (typeId) {
    return <BlogList />;
  }

  return (
    <React.Fragment>
      <Breakpoint large up>
        <Container className="my-5" fluid style={{ minHeight: "100vh" }}>
          <Row>
            <Col
              sm={6}
              style={{
                borderRight: "2px solid #3D3D3D",
              }}
            >
              <ul className="breadcrumb subtitle mx-5 mt-5 px-5">
                <li>
                  <a className="poppins-400 text-white" href="/blogs">
                    Blogs
                  </a>
                </li>
                <li />
              </ul>
            </Col>
            <Col sm={6}></Col>
          </Row>
          <Row className="row-cols-2">
            {blogTypes &&
              blogTypes.map((blogType, index) => {
                return (
                  <>
                    <div
                      className={`px-5 d-flex flex-column justify-content-center ${index % 2 === 0
                        ? "align-items-end"
                        : "align-items-start"
                        }`}
                      style={{
                        borderRight: index % 2 === 0 ? "2px solid #3D3D3D" : "",
                        borderBottom: "2px solid #3D3D3D",
                      }}
                    >
                      <div className="px-5 d-flex flex-column justify-content-center align-items-start">
                        <Card
                          className="mt-5"
                          style={{
                            backgroundColor: "#1f1f1f",
                            border: "1px solid #666666",
                          }}
                        >
                          <div
                            className="mx-3"
                            style={{ fontSize: "10px", color: "#666666" }}
                          >
                            {blogType.is_story
                              ? "Story Blogs"
                              : "Technical Blogs"}
                          </div>
                        </Card>
                        <Card
                          className="border-0 blog-card position-relative overflow-hidden d-flex justify-content-center align-items-end mt-3"
                          style={{
                            backgroundColor: "#1f1f1f",
                            border: "1px solid #666666",
                          }}
                        >
                          <a
                            className="text-decoration-none"
                            onClick={() => {
                              handleNavigationClick(
                                `/blogs?blog_type=${blogType.slug}`
                              );
                            }}
                            // to={`/blogs?blog_type=${blogType.slug}`}
                            href={`/blogs?blog_type=${blogType.slug}`}
                          >
                            <Image
                              loading="lazy"
                              src={blogType?.blog_type_image}
                              alt={`${blogType?.title} - Blog Type Image`}
                              style={{ maxWidth: "480px", height: "270px" }}
                            />
                          </a>
                        </Card>
                        <h4 className="pt-4 text-white">{blogType?.title}</h4>
                        <a
                          className="text-decoration-none"
                          onClick={() => {
                            handleNavigationClick(
                              `/blogs?blog_type=${blogType.slug}`
                            );
                          }}
                          // to={`/blogs?blog_type=${blogType.slug}`}
                          href={`/blogs?blog_type=${blogType.slug}`}
                        >
                          <Button
                            className="text-white mt-3 mb-5 explore-button"
                          // onClick={() => navigate(`/blogs?blog_type=${blogType.slug}`)}
                          >
                            EXPLORE ALL
                          </Button>
                        </a>
                      </div>
                    </div>
                  </>
                );
              })}
          </Row>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container
          className="mt-5 pt-4 px-3"
          fluid
          style={{ minHeight: "100vh" }}
        >
          <ul className="breadcrumb subtitle">
            <li>
              <a className="poppins-400 text-white" href="/blogs">
                Blogs
              </a>
            </li>
            <li />
          </ul>
          <Row className="row-cols-1">
            {blogTypes &&
              blogTypes.map((blogType, index) => {
                return (
                  <div>
                    <Card
                      className="mt-3"
                      style={{
                        backgroundColor: "#1f1f1f",
                        border: "1px solid #666666",
                        maxWidth: blogType.is_story ? "100px" : "120px",
                      }}
                    >
                      <div
                        className="mx-3 d-flex justify-content-center"
                        style={{ fontSize: "10px", color: "#666666" }}
                      >
                        {blogType.is_story ? "Story Blogs" : "Technical Blogs"}
                      </div>
                    </Card>
                    <Card
                      className="overflow-hidden d-flex justify-content-center align-items-center mt-3"
                      style={{ borderRadius: "20px", background: "#3D3D3D" }}
                    >
                      <a
                        // to={`/blogs?blog_type=${blogType.slug}`}
                        href={`/blogs?blog_type=${blogType.slug}`}
                        onClick={() => {
                          handleNavigationClick(
                            `/blogs?blog_type=${blogType.slug}`
                          );
                        }}
                      >
                        <Image
                          loading="lazy"
                          className="w-100"
                          src={blogType?.blog_type_image}
                          alt={`${blogType?.title} - Blog Type Image`}
                        />
                      </a>
                    </Card>
                    <h4 className="pt-4 text-white">{blogType?.title}</h4>
                    <a
                      onClick={() => {
                        handleNavigationClick(
                          `/blogs?blog_type=${blogType.slug}`
                        );
                      }}
                      // to={`/blogs?blog_type=${blogType.slug}`}
                      href={`/blogs?blog_type=${blogType.slug}`}
                    >
                      <Button
                        className="text-white mt-3 mb-5 explore-button"
                      // onClick={() => navigate(`/blogs?blog_type=${blogType.slug}`)}
                      >
                        EXPLORE ALL
                      </Button>
                    </a>
                  </div>
                );
              })}
          </Row>
        </Container>
      </Breakpoint>
    </React.Fragment>
  );
};

export default Blogs;
