import React, { useState, useEffect } from "react";
import { Form, Button as RButton, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import "core-ui/pages/MyAccount.scss";
import { ZDropdown } from "components";
import Flags from "country-flag-icons/react/3x2";
import * as userActions from "actions/UserActions";
import { Breakpoint } from "react-socks";


const BasicInformation = (props) => {
  // const { user, form, handleProfileSubmit, next, region, setRegion } = props;
  const { form, handleProfileSubmit, next, region, setRegion } = props;

  const user = useSelector((state) => state.authReducer.user);

  const [countries, setCountries] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const uniqueCountries = [];

  const loadTheCountries = async () => {
    const response = await userActions.loadCountries();
    setCountries(response);
  };

  useEffect(() => {
    loadTheCountries();
  }, [])
  // useEffect(() => {
  //   console.log("##",region)
  // }, [region])
  // useEffect(() => {
  //   console.log("##",setRegion)
  // }, [setRegion])


  const FlagIcon = ({ country }) => {
    const Flag = Flags[country?.code];

    return (
      <Flag
        title={country?.name}
        className="text-white"
        style={{ height: 30 }}
      />
    );
  };

  useEffect(() => {
    if (countries.length > 0) {
      let result = []
      for (let i = 0; i < countries.length; i++) {
        let data = {
          value: countries[i]?.code,
          label: (
            <div className="d-flex subtitle" key={countries[i]?.id}>

              <FlagIcon country={countries[i]} />
              &nbsp;&nbsp;{countries[i]?.name}
            </div>
          )
        }
        result.push(data);
      }
      result.sort((a, b) => a.label.props.children[2].localeCompare(b.label.props.children[2]));
      setCountryList(result);
    }
  }, [countries])

  return (
    <>
      <Breakpoint large up>

        <Card style={{ borderRadius: 40, border: "1px solid #3d3d3d", maxWidth: 600, backgroundColor: "#141415" }}>
          <div className="h4 mt-4 text-center text-white">Personal Details</div>
          <hr className="text-white" />
          <Form
            ref={form}
            onSubmit={(e) => handleProfileSubmit(e)}
            className="px-5 py-4 my-3 body-text2 text-white"
          >
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                data-cy="user-name"
                className="text-white"
                placeholder="Enter your Name"
                style={styles.inputField}
                defaultValue={user?.name}
                required
              />
            </Form.Group>
            <Form.Group className="mt-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="text"
                className="text-white"
                placeholder="example@gmail.com"
                style={styles.inputField}
                defaultValue={user?.email}
                required
                disabled
              />
            </Form.Group>
            <Form.Group className="mt-4">
              <Form.Label>Phone no</Form.Label>
            </Form.Group>
            <Form.Group className="d-flex justify-content-start align-items-center mb-4">
              <RButton
                type="button"
                className="wrapped-button d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#0d0d0d",
                  color: "#888A8C",
                  border: "1px solid #3d3d3d",
                  fontSize: 16,
                  height: 50,
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderRight: "0px",
                }}
                onKeyDown={(e) => e.preventDefault()}
              >
                {/* {user.phone_code} */}
                {countries.filter((country) => country.code === region)[0]?.phone_code}
              </RButton>
              <Form.Control
                name="mobile"
                type="text"
                // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                // pattern="[0-9]{+}"
                className="text-white"
                placeholder="Enter your Mobile"
                style={{
                  background: "#0d0d0d",
                  border: "1px solid #3d3d3d",
                  height: 50,
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                }}
                defaultValue={user.mobile}
                required
              // onChange={(e) => {
              //   const inputValue = e.target.value;
              //   if (/^[0-9]+$/.test(inputValue) && parseInt(inputValue, 10) !== 0) {
              //     e.target.setCustomValidity("");
              //   } else {
              //     e.target.setCustomValidity("Please enter valid phone number");
              //   }
              // }}
              // onInvalid={(e) => {
              //   return e.target.setCustomValidity(
              //     "Please enter valid phone number."
              //   );
              // }}

              />
            </Form.Group>
            <div className="mt-4">
              <Form.Label>Region</Form.Label>
              {/* {regionOptions.map((region) => {
                if (region.value === user.region.code) {
                  return region.label;
                }
              })} */}
              <ZDropdown
                options={countryList}
                mode="region"
                region={region}
                setRegion={setRegion}
              />
              {/* {console.log(getUnicodeFlagIcon('US'))} */}
            </div>
            {/* <div className="text-center mt-4">
              <RButton
                className="btn btn-link border-0 wrapped-button"
                style={{ background: "transparent", textDecoration: "none" }}
                type="submit"
              >
                <RButton
                  className="my-3 btn btn-primary  explore-button-no-neo"
                  variant="dark"
                  disabled={next}
                  size="large"
                  type="submit"
                >
                  {next ? (
                    <span>
                      <Spinner
                        className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      UPDATING...
                    </span>
                  ) : (
                    <span className="poppins">UPDATE</span>
                  )}
                </RButton>
              </RButton>
            </div> */}
          </Form>
        </Card>
      </Breakpoint>
      <Breakpoint medium down className="w-100">
        <Card className="my-3 w-100" style={{ border: "1px solid #3d3d3d", backgroundColor: "#141415" }}>
          <div className=" h3 poppins-500 mt-4 mb-4 text-center text-white">
            Personal Details
          </div>
          <div style={styles.underline}></div>
          <div className="">
            <Form
              ref={form}
              onSubmit={(e) => handleProfileSubmit(e)}
              className="  body-text2 text-white"
            >
              <Form.Group className="mt-4 mx-3">
                <Form.Label className="font-size18">Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  className="text-white "
                  placeholder="Enter your Name"
                  style={styles.inputField}
                  defaultValue={user?.name}
                  required
                />
              </Form.Group>
              <Form.Group className="mt-4 mx-3">
                <Form.Label className="font-size18">Email</Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  className="text-white"
                  placeholder="example@gmail.com"
                  style={styles.inputField}
                  defaultValue={user?.email}
                  required
                  disabled
                />
              </Form.Group>
              <Form.Group className="mt-4 mx-3">
                <Form.Label className="font-size18">Phone no</Form.Label>
              </Form.Group>
              <Form.Group className="d-flex justify-content-start align-items-center mb-4 mx-3">
                <RButton
                  type="button"
                  className="wrapped-button d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#0d0d0d",
                    color: "#888A8C",
                    border: "1px solid #3d3d3d",
                    fontSize: 16,
                    height: 50,
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    borderRight: "0px",
                  }}
                  onKeyDown={(e) => e.preventDefault()}
                >
                  {/* {user.phone_code} */}
                  {countries.filter((country) => country.code === region)[0]?.phone_code}
                </RButton>
                <Form.Control
                  name="mobile"
                  type="text"
                  // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  pattern="[0-9]{+}"
                  className="text-white"
                  placeholder="Enter your Mobile"
                  style={{
                    background: "#0d0d0d",
                    border: "1px solid #3d3d3d",
                    height: 50,
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}
                  defaultValue={user.mobile}
                  required
                  onInvalid={(e) => {
                    return e.target.setCustomValidity(
                      "Please enter valid phone number."
                    );
                  }}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[0-9]+$/.test(inputValue) && parseInt(inputValue, 10) !== 0) {
                      e.target.setCustomValidity("");
                    } else {
                      e.target.setCustomValidity("Please enter valid phone number");
                    }
                  }}
                />
              </Form.Group>
              <div className="mt-4 mx-3 mb-4">
                <Form.Label className="font-size18">Region</Form.Label>
                {/* {regionOptions.map((region) => {
            if (region.value === user.region.code) {
              return region.label;
            }
          })} */}
                <ZDropdown
                  options={countryList}
                  mode="region"
                  region={region}
                  setRegion={setRegion}
                />
                {/* {console.log(getUnicodeFlagIcon('US'))} */}
              </div>
              {/* <div className="text-center mt-4">
          <RButton
            className="btn btn-link border-0 wrapped-button"
            style={{ background: "transparent", textDecoration: "none" }}
            type="submit"
          >
            <RButton
              className="my-3 btn btn-primary  explore-button-no-neo"
              variant="dark"
              disabled={next}
              size="large"
              type="submit"
            >
              {next ? (
                <span>
                  <Spinner
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  UPDATING...
                </span>
              ) : (
                <span className="poppins">UPDATE</span>
              )}
            </RButton>
          </RButton>
        </div> */}
            </Form>
          </div>
        </Card>
      </Breakpoint>

    </>
  );
};
const styles = {
  inputField: {
    background: "#0d0d0d",
    border: "1px solid #3d3d3d",
    height: 50,
  },
  cartCard: {
    height: 500,
  },
  underline: {
    height: 2,
    backgroundColor: "#292929",
  },
};
export default BasicInformation;
