import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button as RButton, Card } from "react-bootstrap";
import { BsDisplay } from "react-icons/bs";
import { Breakpoint } from "react-socks";
import { Slider } from "rsuite";
import Select from "react-select";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { VscRefresh } from "react-icons/vsc";
import { Try3DURLFiles } from "pages";
import { MdOutlineLightMode } from "react-icons/md";
import * as actions from "actions/AdminActions";
import { AiOutlineCaretDown } from "react-icons/ai";
const ProductEditor = ({
  productId,
  data,
  files,
  setEditorEnabled,
  setEditorValues,
  fetchProductDetails
}) => {

  const CustomDropdownIndicator = (props) => {
    return (
      <div
        {...props.innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingRight: "8px", // Adjust the spacing as needed
        }}
      >
        <AiOutlineCaretDown />
      </div>
    );
  };
  const customStyles = {
    menu: () => ({
      marginTop: -4.5,
      position: "absolute",
      width: "100%",
      zIndex: 9999,
      textAlign: "left",
      fontSize: "20px",
      borderRadius: "50px !important",
      overflow: "visible",
    }),

    menuList: (provided, state) => ({
      ...provided,
      borderRadius: "15px",
      textAlign: "center",

    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#0D0D0D",
      color: state.isSelected ? "#777" : "white",
      marginTop: 0,
      cursor: "pointer",
      textAlign: "left",
      paddingLeft: "18px",
      fontSize: "16px",
      padding: "10px",
    }),

    control: (provided, state) => ({
      ...provided,
      color: "#fff",
      backgroundColor: "#0D0D0D",
      textAlign: "left",
      fontSize: 16,
      fontFamily: "poppins",
      //   padding: 6,
      borderRadius: "10px",
      border: "1px solid #3D3D3D",
      boxShadow: "none",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "#fff";
      const transition = "opacity 300ms";
      const padding = "8px 8px 8px 6px";

      return { ...provided, opacity, transition, color, padding };
    }
  }

  const environmentOptions = [
    {
      label: "SELECT",
      value: "",
    },
    {
      label: "BlueGrottto",
      value: "blue_grotto_1k.exr",
    },
    {
      label: "Canary Wharf",
      value: "canary_wharf_1k.exr",
    },
    {
      label: "Herkulessaulen",
      value: "herkulessaulen_1k.exr",
    },
    {
      label: "Kloppenheim",
      value: "kloppenheim_06_1k.exr",
    },
    {
      label: "Lauter Waterfall",
      value: "lauter_waterfall_1k.exr",
    },
    {
      label: "Red Hill Curve",
      value: "red_hill_curve_1k.exr",
    },
    {
      label: "Studio Garden",
      value: "studio_garden_1k.exr",
    },
    {
      label: "Studio Small",
      value: "studio_small_08_1k.exr",
    },
    {
      label: "Sunset Jhbcentral",
      value: "sunset_jhbcentral_1k.exr",
    },
    {
      label: "Venice Sunset",
      value: "venice_sunset_1k.exr",
    },
    {
      label: "Veranda",
      value: "veranda_1k.exr",
    },
  ];

  const [rotationState, setrotationState] = useState(false);
  const [antiRotationState, setAntiRotationState] = useState(false);
  const [lightAnimation, setLightAnimation] = useState(true);
  const [checkDisplaySelected, setCheckDisplaySelected] = useState(true);
  const [checkLightingSelected, setCheckLightingSelected] = useState(false);
  const environmentOptionRef = useRef();
  const rangeSliderRef = useRef();
  const searchOptionRef = useRef();
  const [intensityValue, setIntensityValue] = useState(0.5);
  const [enableEnvironment, setEnableEnvironment] = useState(false);
  const [blurEnvironment, setBlurEnvironment] = useState(false);
  const [enableRotation, setEnableRotation] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: "SELECT",
    value: "",
  });
  const [emissionIntensityValue, setEmissionIntensityValue] = useState(0.0);
  const [backgroundColor, setBackgroundColor] = useState("#2E2E2E");
  const [showLighting, setshowLighting] = useState(false);
  const [range, setRange] = useState([0, 5]);

  useEffect(() => {
    if (data) {
      setEnableRotation(data?.enableRotation);
      setEnableEnvironment(data?.enableEnvironment);
      setBlurEnvironment(data?.blurEnvironment);
      setSelectedOption(
        environmentOptions.find(
          (element) => element.value == data?.selectedOption
        )
      );
      setIntensityValue(data?.intensityValue)
      setEmissionIntensityValue(data?.emissionIntensityValue)
      setBackgroundColor(data?.backgroundColor)
    }
  }, [data]);

  const IndicatorSeparator = () => {
    return (
      <div
        style={{
          height: "1px",
          width: "1px",
          backgroundColor: "black",
          padding: 0,
        }}
      />
    );
  };
  const handleEditorSavedChanges = async (
    selectedOption,
    enableEnvironment,
    blurEnvironment,
    enableRotation,
    intensityValue,
    emissionIntensityValue,
    backgroundColor
  ) => {
    // const formData = new FormData(form.current);

    // formData.append("environment", selectedOption);
    // formData.append("enable_environment_map", enableEnvironment);
    // formData.append("blur_environment_map", blurEnvironment);
    // formData.append("enable_rotation", enableRotation);
    // formData.append("intensity_value", intensityValue);
    // formData.append("emission_intensity_value", emissionIntensityValue);
    const submitData = {
      environment: selectedOption,
      enable_environment_map: enableEnvironment,
      blur_environment_map: blurEnvironment,
      enable_rotation: enableRotation,
      intensity_value: intensityValue,
      emission_intensity_value: emissionIntensityValue,
      background_color: backgroundColor
    }
    const response = await actions.update_editorValues(productId, submitData);
    fetchProductDetails();
    console.log(response);
    // console.log("Saved changes", data);
    // setEditorValues(data);
    // <ProductMain exportValues={data}/>
    // return data;
  };
  // editorvalues(data)

  console.log(enableEnvironment)
  console.log(blurEnvironment)
  console.log(selectedOption)

  const displayClassname = () => {
    if (!checkDisplaySelected && rotationState) {
      return "rotate-on-hover";
    } else if (!checkDisplaySelected && antiRotationState) {
      return "rotate-on-hover-negative";
    } else {
      return "";
    }
  };
  const disablingAntirotationState = () => {
    setAntiRotationState(false);
  };
  const disablingLightAnimation = () => {
    setLightAnimation(false);
  };
  const onChangeFilters = (value, type) => {
    const searchOption = searchOptionRef.current.state.focusedOption;
    const environmentOption = environmentOptionRef.current.state.focusedOption;

    let order = "";
  };
  const form = useRef(null);
  return (
    <React.Fragment>
      <Breakpoint large up>
        <Container
          className="d-flex flex-column h-100 mx-4 w-100 pt-5 home-background-categories"
          fluid
          style={styles.parentContainer}
        >
          <Form ref={form}>
            <Container className="p-5 mt-2 text-white" fluid>
              <RButton
                variant="dark"
                className="explore-border-button-round explore-button-large-no-neo-round position-absolute"
                style={{ backgroundColor: "#27292A" }}
                onClick={() => {
                  setEditorEnabled(false);
                }}
              >
                Back
              </RButton>
              <Row className="mt-5">
                <Col sm={3}>
                  <Card
                    className="m-2 my-4 p-0 py-4 h-100 d-flex flex-column border-R20"
                    style={{
                      backgroundColor: "#141415",
                      border: "1px solid #3D3D3D",
                    }}
                  >
                    <div
                      className="row my-4 text-white mx-2"
                      style={{ flex: 1, flexWrap: "wrap" }}
                    >
                      <div className="col-6">
                        <RButton
                          variant="light"
                          type="button"
                          className="mx-1 w-100 "
                          style={{
                            backgroundColor: !showLighting
                              ? "#ffffff"
                              : "#3d3d3d",
                            border: 0,
                            color: !showLighting ? "black" : "#ffffff",
                            height: 48,
                          }}
                          onMouseEnter={() => setrotationState(true)}
                          onMouseLeave={() => {
                            setrotationState(false);
                            setAntiRotationState(true);
                            setTimeout(disablingAntirotationState, 2000);
                          }}
                          onClick={() => {
                            setshowLighting(false);
                            setCheckDisplaySelected(true);
                            setCheckLightingSelected(false);
                          }}
                        >
                          <div className="px-2 body-text3">
                            {/* <Image src={ displayBlack} alt="Retry"></Image> */}
                            <BsDisplay
                              className={
                                !checkDisplaySelected && rotationState
                                  ? "rotate-on-hover"
                                  : !checkDisplaySelected && antiRotationState
                                    ? "rotate-on-hover-negative"
                                    : ""
                              }
                            />
                            &nbsp; DISPLAY
                          </div>
                        </RButton>
                      </div>

                      <div
                        className="col-6"
                        style={{ flex: 1, flexWrap: "wrap" }}
                      >
                        <RButton
                          variant="light"
                          type="button"
                          className="wrapped-button mx-1 w-100 "
                          style={{
                            backgroundColor: showLighting
                              ? "#ffffff"
                              : "#3d3d3d",
                            border: 0,
                            color: showLighting ? "black" : "#ffffff",
                            height: 48,
                          }}
                          // onKeyDown={(e) => e.preventDefault()}
                          onClick={() => {
                            setshowLighting(true);
                            setCheckLightingSelected(true);
                            setCheckDisplaySelected(false);
                          }}
                          onMouseEnter={() => {
                            setLightAnimation(true);
                            setTimeout(disablingLightAnimation, 2000);
                          }}
                          onMouseLeave={() => {
                            setLightAnimation(true);
                            setTimeout(disablingLightAnimation, 2000);
                          }}
                        // onFocus={()=> setLightAnimation(false)}
                        >
                          <div className="py-1 body-text3">
                            {" "}
                            <MdOutlineLightMode
                              className={
                                !checkLightingSelected &&
                                lightAnimation &&
                                "light-animation"
                              }
                            />{" "}
                            &nbsp; LIGHTING{" "}
                          </div>
                        </RButton>
                      </div>
                    </div>

                    {!showLighting && (
                      <>
                        <div
                          className="row my-4 text-white mx-2"
                          style={{ flex: 1, flexWrap: "wrap" }}
                        >
                          <div className="col-8 ">
                            <div className="body-text2 my-2 d-flex align-items-center">
                              <input type="color" name="background_color" value={backgroundColor} onChange={(e)=>{
                                setBackgroundColor((e.target.value).toUpperCase())
                              }} />
                              <div className="mx-3">
                                {backgroundColor}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row my-4 text-white mx-2"
                          style={{ flex: 1, flexWrap: "wrap" }}
                        >
                          <div className="col-8 ">
                            <div className="body-text2 my-2">
                              Select environment
                            </div>
                            <Select
                              ref={environmentOptionRef}
                              required={true}
                              isSearchable={false}
                              components={{ IndicatorSeparator, DropdownIndicator: CustomDropdownIndicator, }}
                              options={environmentOptions}
                              styles={customStyles}
                              value={selectedOption}
                              onChange={(value) => {
                                // onChangeFilters(value, "envOptions");
                                setSelectedOption(value);
                              }}
                            />
                          </div>
                        </div>

                        <div
                          className="row my-3 text-white mx-2"
                          style={{ flex: 1, flexWrap: "wrap" }}
                        >
                          <div className="col-2 d-flex justify-content-center align-items-center ">
                            <AiOutlineCheckCircle
                              size={30}
                              style={{
                                color: enableEnvironment ? "#2ED15C" : "grey",
                              }}
                              onClick={() =>
                                setEnableEnvironment(!enableEnvironment)
                              }
                            />
                          </div>
                          <div className="col-8  d-flex align-items-center ">
                            Enable Environment map as a background
                          </div>
                        </div>
                        <div
                          className="row my-2 text-white mx-2"
                          style={{ flex: 1, flexWrap: "wrap" }}
                        >
                          <div className="col-2 d-flex justify-content-center align-items-center  ">
                            <AiOutlineCheckCircle
                              size={30}
                              style={{
                                color: blurEnvironment ? "#2ED15C" : "grey",
                              }}
                              onClick={() =>
                                setBlurEnvironment(!blurEnvironment)
                              }
                            />
                          </div>
                          <div className="col-8 d-flex align-items-center ">
                            Blur Environment
                          </div>
                        </div>
                        <div
                          className="row my-2 text-white mx-2"
                          style={{ flex: 1, flexWrap: "wrap" }}
                        >
                          <div className="col-2 d-flex justify-content-center align-items-center ">
                            <AiOutlineCheckCircle
                              size={30}
                              style={{
                                color: enableRotation ? "#2ED15C" : "grey",
                              }}
                              onClick={() => setEnableRotation(!enableRotation)}
                            />
                          </div>
                          <div className="col-8 d-flex  align-items-center  ">
                            Enable Rotation
                          </div>
                        </div>
                      </>
                    )}

                    {showLighting && (
                      <>
                        <div
                          className="row my-4 text-white mx-2"
                          style={{ flex: 1, flexWrap: "wrap" }}
                        >
                          <div className="col-8">
                            <Form.Label>Change Intensity of Light</Form.Label>
                            {/* <RangeSlider
                              ref={rangeSliderRef}
                              min={0}
                              max={5}
                              defaultValue={[0]}
                              value={range}
                              onChange={(value) => setRange(value)}
                              onChangeCommitted={(value, event) => {
                                console.log(event)
                                // setRange(value);
                                onChangeFilters(value, "slider");
                              }}>
                            </RangeSlider> */}
                            <Slider
                              tooltip={false}
                              className="mt-2 custom-slider-progress-bar"
                              max={1}
                              progress
                              progressClassName="custom-slider-progress-bar"
                              handleClassName="custom-slider-progress-bar"
                              // barClassName="custom-slider-bar"
                              //  handleStyle={{ width: 20, height: 20 }}
                              //  railStyle={{ height: 5 }}
                              //  trackStyle={{ height: 5 }}
                              value={intensityValue}
                              defaultValue={0.5}
                              step={0.05}
                              onChange={(value) => {
                                setIntensityValue(value);
                              }}
                            />
                          </div>
                          <div
                            className=" col-2 mx-3 d-flex justify-content-center align-items-center "
                            style={{
                              backgroundColor: "#000",
                              border: "1px solid grey",
                              borderRadius: 5,
                            }}
                          >
                            <p className="text-white mb-0 text-center">
                              {intensityValue}
                            </p>
                          </div>
                        </div>
                        <div
                          className="row my-4 text-white mx-2"
                          style={{ flex: 1, flexWrap: "wrap" }}
                        >
                          <div className="col-8">
                            <Form.Label>Change Emission Intensity</Form.Label>
                            {/* <RangeSlider
                              ref={rangeSliderRef}
                              min={0}
                              max={5}
                              defaultValue={[0]}
                              value={range}
                              onChange={(value) => setRange(value)}
                              onChangeCommitted={(value, event) => {
                                console.log(event)
                                // setRange(value);
                                onChangeFilters(value, "slider");
                              }}>
                            </RangeSlider> */}
                            <Slider
                              tooltip={false}
                              className="mt-2 custom-slider-progress-bar"
                              max={10}
                              progress
                              progressClassName="custom-slider-progress-bar"
                              handleClassName="custom-slider-progress-bar"
                              // progressStyle={{ height: 5, width: 10 }}
                              // handleStyle={{ width: 10, height: 20 }}
                              // railStyle={{ height: 5 }}
                              // trackStyle={{ height: 5 }}
                              value={emissionIntensityValue}
                              defaultValue={0}
                              step={0.01}
                              onChange={(value) => {
                                setEmissionIntensityValue(value);
                              }}
                            />
                          </div>
                          <div
                            className="col-2 mx-3 d-flex justify-content-center align-items-center "
                            style={{
                              backgroundColor: "#000",
                              border: "1px solid grey",
                              borderRadius: 5,
                            }}
                          >
                            <p className="text-white mb-0">
                              {emissionIntensityValue}
                            </p>
                          </div>
                        </div>
                        <div
                          className="row my-4 text-white mx-2"
                          style={{ flex: 1, flexWrap: "wrap" }}
                        >
                          <span>
                            Rotate the Environment Lighting
                            <br></br>
                            ALT + LEFT CLICK
                          </span>
                        </div>
                      </>
                    )}
                    <div
                      className="row mt-5 pt-2 text-white mx-2 px-4   align-items-center "
                      style={{ flex: 1, flexWrap: "wrap" }}
                    >
                      <div className="col-3 d-flex justify-content-center align-items-center">
                        <div
                          style={{
                            backgroundColor: "#1B1C1D",
                            width: "100%",
                            height: 48,
                            borderRadius: 5,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                        >
                          <VscRefresh size={30} onClick={() => {
                            setSelectedOption({
                              label: "SELECT",
                              value: "",
                            })
                            setEnableEnvironment(false)
                            setBlurEnvironment(false)
                            setEnableRotation(false)
                            setIntensityValue(0.5)
                            setEmissionIntensityValue(0.0)
                          }} />
                        </div>
                      </div>
                      <div className=" px-2 col-9 text-end">
                        <RButton
                          className="explore-border-button w-100 d-flex justify-content-center align-items-center explore-button-large-no-neo"
                          variant="dark"
                          style={{ backgroundColor: "#27292A", height: 48 }}
                          onClick={() =>
                            handleEditorSavedChanges(
                              selectedOption?.value,
                              enableEnvironment,
                              blurEnvironment,
                              enableRotation,
                              intensityValue,
                              emissionIntensityValue,
                              backgroundColor
                            )
                          }
                        >
                          <span className="poppins-400 body-text2 ">
                            SAVE CHANGES
                          </span>
                        </RButton>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col sm={8}>
                  <Card
                    className="m-2 my-4  d-flex flex-column align-items-center h-100 border-R20 admin-filedrop-card overflow-hidden"
                    dark={true}
                    style={{
                      backgroundColor: "#141415",
                      border: "1px solid #3D3D3D",
                    }}
                  >
                    <Try3DURLFiles
                      files={files}
                      enableRotation={enableRotation}
                      enableEnvironment={enableEnvironment}
                      blurEnvironment={blurEnvironment}
                      environment={selectedOption?.value}
                      intensityValue={intensityValue}
                      emissionIntensityValue={emissionIntensityValue}
                      backgroundColor={backgroundColor}
                    />
                  </Card>
                </Col>
              </Row>
            </Container>
          </Form>
        </Container>
      </Breakpoint>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  parentContainer90: {
    minHeight: "90vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  checkedIcons: {
    width: 20,
    height: 20,
  },
  submitButton: {
    width: 250,
    height: 50,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  fileNames: {
    backgroundColor: "#141414",
  },
  priceCard: {
    borderLeft: "1px solid #4e4e4e",
    marginTop: "10px",
    marginBottom: "10px",
    color: "#555555",
  },
  selectedModeButton: {
    width: "115px",
    backgroundColor: "#fff",
    border: "none",
    color: "black",
  },
  unselectedModeButton: {
    width: "115px",
    backgroundColor: "#0D0D0D",
    border: "none",
    color: "white",
  },
};

export default ProductEditor;
