import React, { useEffect, useState } from "react";
import { useRef } from "react";
import {
  Form,
  FormControl,
  Row,
  Col,
  Image,
  Card,
  Button as RButton,
} from "react-bootstrap";
import { CardContent, Button, Subtitle2, IconButton } from "ui-neumorphism";
import { search, transparent_image } from "assets";
import { searchresult, imagenotavailable } from "assets";
import { Link, Navigate, Redirect, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "actions/UserActions";
import * as adminActions from "actions/AdminActions";
import { Products } from "pages/admin";
import axios from "axios";
import * as authactions from "actions/AuthActions";

// import { debounce } from 'lodash';

const Searchbar = ({
  setNavSearchDropdown,
  navSearchDropdown,
  variant,
  type,
  items,
  setShowSearchBar,
  setOpen,
}) => {
  // const { type, items, variant } = props;

  const [searchDropdown, setSearchDropdown] = useState(false);
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);

  const searchInputRef = useRef();
  const homeSearchRef = useRef();
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const user = useSelector((state) => state.authReducer.user);

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const handleSearchWordSave = (word) => {
    const data = { search: word, user: user.id };
    const result = authactions.searchWord(data);
  };
  const handleSearchWordNavbarSave = (word) => {
    const data = { search: word, user: user.id };
    const result = authactions.searchWord(data);
    if ((type = "mobileNavbar")) {
      setOpen(false);
      setShowSearchBar(false);
    }
  };

  useEffect(() => {
    let cancelToken;
    const search = async () => {
      try {
        const source = axios.CancelToken.source();
        setCancelTokenSource(source);

        const response = await axios.get(
          `/product/?is_verified=true&is_archived=false&search=${searchWord}`,
          {
            cancelToken: source.token,
          }
        );
        if (response) {
          // console.log("search", searchWord, response);
          setProducts(
            response.data
              .filter((item) => item.is_verified === true)
              .slice(0, 5)
          );
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error:", error);
        }
      }
    };

    const handleSearch = () => {
      if (cancelToken) {
        cancelToken.cancel("Request canceled due to new search query");
      }
      if (searchWord == "") {
        search();
      }
      // search();
      if (searchWord.trim() !== "") {
        cancelToken = cancelTokenSource;
        search();
      }
    };

    handleSearch();

    return () => {
      if (cancelToken) {
        cancelToken.cancel("Request canceled due to component unmount");
      }
    };
  }, [searchWord]);

  const navigate = useNavigate();
  // const x = async (search) => {
  //   {
  //     const response = await actions.searchProduct(search);
  //     // console.log("SEAR@", search, "@@", response);
  //     // const response = await actions.searchProduct(search);
  //     if (response) {
  //       setProducts(
  //         response.filter((item) => item.is_verified === true).slice(0, 5)
  //       );
  //     }
  //   }
  // };

  // useEffect(() => {
  //   // x(searchWord);
  //   setSearchQuery(searchWord);
  // }, [searchWord]);

  // useEffect(() => console.log("searpro",products), [products]);

  useEffect(() => {
    if (navSearchDropdown === true) {
      searchInputRef?.current?.focus();
    }
  }, [navSearchDropdown]);

  const onClick = () => alert();
  switch (type) {
    case "main":
      return (
        <div className="w-100 position-relative" style={{ zIndex: 1000 }}>
          <Form>
            <Card style={styles.searchBox}>
              <CardContent className="d-flex bg-transparent mx-3 my-2">
                <FormControl
                  onSubmit={(e) => e.preventDefault()}
                  type="search"
                  placeholder="Search 3D models"
                  className="me-2 border-0 outline-none body-text1 text-white"
                  aria-label="SEARCH"
                  // ref={homeSearchRef}
                  style={{ background: "none" }}
                  onFocus={() => {
                    setSearchDropdown(true);
                    if (products.length > 0) {
                      setSelected(0);
                    } else {
                      setSelected(null);
                    }
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setSearchDropdown(false);
                      setSelected(null);
                    }, 200);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown") {
                      setSelected((selected + 1) % products.length);
                    }
                    if (e.key === "ArrowUp") {
                      setSelected(
                        (selected - 1 + products.length) % products.length
                      );
                    }
                    if (e.key === "Enter") {
                      if (selected) {
                        // navigate(
                        //   `${
                        //     variant === "admin"
                        //       ? "/admin/product/" + products[selected].slug
                        //       : "/3dmodels/" +
                        //         products[selected]?.category_data.slug +
                        //         "/" +
                        //         products[selected].slug
                        //   }`
                        // );
                        handleNavigationClick(
                          `${variant === "admin"
                            ? "/admin/product/" + products[selected].slug
                            : "/3dmodels/" +
                            products[selected]?.category_data.slug +
                            "/" +
                            products[selected].slug
                          }`
                        );
                      }
                    }
                  }}
                  onChange={(e) => {
                    setSearchWord(e.target.value);
                  }}
                />
                {searchWord === "" || searchWord.trim() === "" ? (
                  <RButton
                    className="explore-button-no-neo btn btn-ptimary"
                    dark={true}
                    size="large"
                    onClick={(e) => {
                      e.preventDefault();
                      setSearchDropdown(true);
                      if (products.length > 0) {
                        setSelected(0);
                      } else {
                        setSelected(null);
                      }
                    }}
                  // onFocus={()=>style={{border:"none"}}}
                  >
                    <span className="poppins">SEARCH</span>
                  </RButton>
                ) : (
                  <Link
                    to={`/search/?search=` + searchWord}
                    style={{ textDecoration: "none" }}
                    state={{ searchWord: searchWord }}
                    onClick={() => {
                      handleSearchWordSave(searchWord);
                    }}
                  >
                    <RButton
                      // className="explore-button disabled"
                      className="explore-button-no-neo"
                      dark={true}
                      size="large"
                      type="submit"
                    >
                      <span className="poppins">SEARCH</span>
                    </RButton>
                  </Link>
                )}
              </CardContent>
            </Card>
            <Card
              className={`w-100 position-absolute my-1 ${searchDropdown ? "d-block" : "d-none"
                }`}
              style={{ backgroundColor: "#1b1c1d" }}
            >
              <CardContent
                className="p-0 m-0"
                style={{
                  maxHeight: 300,
                  overflowY: "auto",
                  backgroundColor: "#1b1c1d",
                }}
              >
                {products?.map((item, index) => {
                  return (
                    <Link
                      className="d-flex justify-content-start text-white text-decoration-none"
                      variant="light"
                      key={item.id}
                      to={`${variant === "admin"
                        ? "/admin/product/" + item?.slug
                        : "/3dmodels/" +
                        item?.category_data?.slug +
                        "/" +
                        item?.slug
                        }`}
                      onMouseEnter={() => {
                        setSelected(index);
                      }}
                    >
                      <div
                        key={item.id}
                        sm={12}
                        className="body-text2 d-flex flex-column w-100 justify-content-center align-items-start"
                        style={{
                          cursor: "pointer",
                          border: selected === index ? "1px solid #fff" : "",
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center m-1">
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: 50, height: 50 }}
                          >
                            <Image
                              loading="lazy"
                              className="m-2"
                              src={
                                item?.productimage_set[0]
                                  ? item?.productimage_set[0]?.image_file
                                  : imagenotavailable
                              }
                              alt={item?.title}
                              style={{ maxWidth: 40, maxHeight: 40 }}
                            />
                          </div>
                          <span className="body-text2 mx-3">{item.title}</span>
                        </div>
                        <div
                          className={`w-100 ${index === products.length - 1 ? "d-none" : "d-block"
                            }`}
                          style={{ height: 1, backgroundColor: "#292929" }}
                        ></div>
                      </div>
                    </Link>
                  );
                })}
              </CardContent>
            </Card>
          </Form>
        </div>
      );
    case "mobilehome":
      return (
        <div className="w-100 position-relative" style={{ zIndex: 1000 }}>
          <Form>
            <Card className="mt-5" style={styles.searchBox}>
              <CardContent className="d-flex bg-transparent my-2">
                <FormControl
                  type="search"
                  placeholder="Search 3D models"
                  className="border-0 outline-none body-text1 text-white"
                  aria-label="SEARCH"
                  style={{ background: "none" }}
                  onFocus={() => {
                    // console.log(products);
                    setSearchDropdown(true);
                    if (products.length > 0) {
                      setSelected(0);
                    } else {
                      setSelected(null);
                    }
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setSearchDropdown(false);
                      setSelected(null);
                    }, 200);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown") {
                      setSelected((selected + 1) % products.length);
                    }
                    if (e.key === "ArrowUp") {
                      setSelected(
                        (selected - 1 + products.length) % products.length
                      );
                    }
                    if (e.key === "Enter") {
                      if (selected) {
                        // navigate(
                        //   `/3dmodels/${products[selected]?.category_data.slug}/${products[selected].slug}}`
                        // );
                        handleNavigationClick(
                          `/3dmodels/${products[selected]?.category_data.slug}/${products[selected].slug}}`
                        );
                      }
                    }
                  }}
                  onChange={(e) => setSearchWord(e.target.value)}
                />
                {searchWord === "" || searchWord.trim() === "" ? (
                  <IconButton
                    size="large"
                    dark={true}
                    rounded
                    onClick={(e) => {
                      e.preventDefault();
                      // console.log(products);
                      setSearchDropdown(true);
                      if (products.length > 0) {
                        setSelected(0);
                      } else {
                        setSelected(null);
                      }
                    }}
                  >
                    <img src={transparent_image} alt="Search Bar" className="p-0 m-0 sprites-search" />
                  </IconButton>
                ) : (
                  <Link
                    to={`/search/?search=` + searchWord}
                    style={{ textDecoration: "none" }}
                    state={{ searchWord: searchWord }}
                    onClick={() => handleSearchWordSave(searchWord)}
                  >
                    <IconButton size="large" dark={true} rounded>
                      <img src={transparent_image} alt="Search Bar" className="p-0 m-0 sprites-search-icon" />
                    </IconButton>
                  </Link>
                )}
              </CardContent>
            </Card>
            <Card
              className={`w-100 position-absolute my-1 ${searchDropdown ? "d-block" : "d-none"
                }`}
              style={{ backgroundColor: "#1b1c1d" }}
            >
              <CardContent
                className="p-0 m-0"
                style={{
                  maxHeight: 300,
                  overflowY: "auto",
                  backgroundColor: "#1b1c1d",
                }}
              >
                {products?.map((item, index) => {
                  return (
                    <Link
                      className="d-flex justify-content-start text-white text-decoration-none"
                      variant="light"
                      to={`/3dmodels/${item?.category_data.slug}/${item.slug}`}
                      onMouseEnter={() => {
                        setSelected(index);
                      }}
                    >
                      <div
                        key={item.id}
                        sm={12}
                        className="body-text2 d-flex flex-column w-100 justify-content-center align-items-start"
                        style={{
                          cursor: "pointer",
                          border: selected === index ? "1px solid #fff" : "",
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center m-1">
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: 50, height: 50 }}
                          >
                            <Image
                              loading="lazy"
                              className="m-2"
                              src={
                                item?.productimage_set[0]
                                  ? item?.productimage_set[0]?.image_file
                                  : imagenotavailable
                              }
                              alt={item?.title}
                              style={{ maxWidth: 40, maxHeight: 40 }}
                            />
                          </div>
                          <span className="body-text2 mx-3">{item.title}</span>
                        </div>
                        <div
                          className={`w-100 ${index === products.length - 1 ? "d-none" : "d-block"
                            }`}
                          style={{ height: 1, backgroundColor: "#292929" }}
                        ></div>
                      </div>
                    </Link>
                  );
                })}
              </CardContent>
            </Card>
            <div className="d-flex justify-content-center align-items-center">
              {searchWord === "" || searchWord.trim() === "" ? (
                <RButton
                  className="mt-5 explore-button-no-neo"
                  dark={true}
                  size="large"
                  // style={{marginLeft:'25%'}}
                  onClick={(e) => {
                    e.preventDefault();
                    // console.log(products);
                    setSearchDropdown(true);
                    if (products.length > 0) {
                      setSelected(0);
                    } else {
                      setSelected(null);
                    }
                  }}
                >
                  <span className="poppins">SEARCH</span>
                </RButton>
              ) : (
                <Link
                  to={`/search/?search=` + searchWord}
                  style={{ textDecoration: "none" }}
                  state={{ searchWord: searchWord }}
                  className="mt-5"
                >
                  <RButton
                    className="explore-button-no-neo"
                    dark={true}
                    size="large"
                  >
                    <span className="poppins">Search</span>
                  </RButton>
                </Link>
              )}
            </div>
          </Form>
        </div>
      );
    case "navbar":
      return (
        <div className="position-relative" style={{ zIndex: 1000 }}>
          <Form>
            <Card className="m-0 border-0" style={styles.navSearchbar}>
              <CardContent
                className="d-flex my-2 bg-transparent mx-2 "
                style={{ maxWidth: 280, height: "30px" }}
              >
                <FormControl
                  type="search"
                  placeholder="Search 3D models"
                  className="me-2 my-1 border-0 outline-none body-text2 text-white"
                  aria-label="Search"
                  ref={searchInputRef}
                  style={{ background: "none" }}
                  onFocus={() => {
                    setNavSearchDropdown(true);
                    if (products.length > 0) {
                      setSelected(0);
                    } else {
                      setSelected(null);
                    }
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setNavSearchDropdown(false);
                      setSelected(null);
                      setShowSearchBar(false);
                    }, 200);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown") {
                      setSelected((selected + 1) % products.length);
                    }
                    if (e.key === "ArrowUp") {
                      setSelected(
                        (selected - 1 + products.length) % products.length
                      );
                    }
                    if (e.key === "Enter") {
                      setNavSearchDropdown(false);
                      if (selected) {
                        // navigate(
                        //   `/3dmodels/${products[selected]?.category_data.slug}/${products[selected].slug}`
                        // );
                        handleNavigationClick(
                          `/3dmodels/${products[selected]?.category_data.slug}/${products[selected].slug}`
                        );
                      }
                    }
                  }}
                  onChange={(e) => setSearchWord(e.target.value)}
                // onChange={(e) =>{x(e.target.value); setSearchWord(e.target.value)}}
                // onChange={(e) =>{console.log("@@",e.target.value); setSearchWord(e.target.value);}}
                />
                {searchWord === "" || searchWord.trim() === "" ? (
                  <IconButton
                    size="large"
                    className="position-relative d-flex justify-content-center align-items-center"
                    dark={true}
                    style={{ top: "-6px" }}
                    rounded
                    onClick={(e) => {
                      e.preventDefault();
                      // console.log(products);
                      setNavSearchDropdown(true);
                      if (products.length > 0) {
                        setSelected(0);
                      } else {
                        setSelected(null);
                      }
                    }}
                  >
                    <img src={transparent_image} alt="Search Bar" className="p-0 m-0 sprites-search-icon" />
                  </IconButton>
                ) : (
                  <Link
                    to={`/search/?search=` + searchWord}
                    style={{ textDecoration: "none" }}
                    state={{ searchWord: searchWord }}
                  >
                    <IconButton
                      size="large"
                      className="position-relative d-flex justify-content-center align-items-center"
                      dark={true}
                      style={{ top: "-6px" }}
                      onClick={() => handleSearchWordSave(searchWord)}
                      rounded
                    >
                      <img src={transparent_image} alt="Search Bar" className="p-0 m-0 sprites-search-icon" />
                    </IconButton>
                  </Link>
                )}
              </CardContent>
            </Card>
            <Card
              className={`w-100 custom-card-profile position-absolute my-1 ${navSearchDropdown ? "d-block" : "d-none"
                }`}
              style={{ backgroundColor: "#1b1c1d" }}
            >
              <CardContent
                className="m-0"
                style={{
                  maxHeight: 300,
                  overflowY: "auto",
                  backgroundColor: "#1b1c1d",
                }}
                onKeyPress={() => alert()}
              >
                {products?.map((item, index) => {
                  return (
                    <Link
                      className="d-flex justify-content-start text-white text-decoration-none"
                      variant="light"
                      to={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                      onMouseEnter={() => {
                        setSelected(index);
                      }}
                    >
                      <div
                        key={item.id}
                        sm={12}
                        className="body-text2 d-flex flex-column w-100 justify-content-center align-items-start"
                        style={{
                          cursor: "pointer",
                          border: selected === index ? "1px solid #fff" : "",
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center m-1">
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: 50, height: 50 }}
                          >
                            <Image
                              loading="lazy"
                              className="m-2"
                              src={
                                item?.productimage_set[0]
                                  ? item?.productimage_set[0]?.image_file
                                  : imagenotavailable
                              }
                              alt={item?.title}
                              style={{ maxWidth: 40, maxHeight: 40 }}
                            />
                          </div>
                          <span className="body-text2 mx-2">{item.title}</span>
                        </div>
                        <div
                          className={`w-100 ${index === products.length - 1 ? "d-none" : "d-block"
                            }`}
                          style={{ height: 1, backgroundColor: "#292929" }}
                        ></div>
                      </div>
                    </Link>
                  );
                })}
              </CardContent>
            </Card>
          </Form>
        </div>
      );
    case "mobileNavbar":
      return (
        <div className="position-relative" style={{ zIndex: 1000 }}>
          <Form>
            <Card className="mx-2 m-0 border-0" style={styles.navSearchbar}>
              <CardContent
                className="d-flex justify-content-center align-items-center my-2 bg-transparent mx-2"
                style={{ minWidth: 120, height: 20 }}
              >
                <FormControl
                  type="search"
                  placeholder="Search 3D models"
                  className="me-2 my-1  border-0 outline-none body-text2 text-white d-flex align-items-center justify-content-center"
                  aria-label="Search"
                  ref={searchInputRef}
                  style={{ background: "none" }}
                  onFocus={() => {
                    // console.log(products);
                    setNavSearchDropdown(true);
                    if (products.length > 0) {
                      setSelected(0);
                    } else {
                      setSelected(null);
                    }
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setNavSearchDropdown(false);
                      setSelected(null);
                      // setShowSearchBar(false);
                    }, 200);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown") {
                      setSelected((selected + 1) % products.length);
                    }
                    if (e.key === "ArrowUp") {
                      setSelected(
                        (selected - 1 + products.length) % products.length
                      );
                    }
                    if (e.key === "Enter") {
                      if (selected) {
                        // navigate(
                        //   `/3dmodels/${products[selected]?.category_data.slug}/${products[selected].slug}`
                        // );
                        handleNavigationClick(
                          `/3dmodels/${products[selected]?.category_data.slug}/${products[selected].slug}`
                        );
                      }
                    }
                  }}
                  onChange={(e) => setSearchWord(e.target.value)}
                />
                {searchWord === "" || searchWord.trim() === "" ? (
                  <IconButton
                    className=" d-flex align-items-start justify-content-center pt-2"
                    // size="md"
                    dark={true}
                    rounded
                    onClick={(e) => {
                      e.preventDefault();
                      // console.log(products);
                      setNavSearchDropdown(true);
                      if (products.length > 0) {
                        setSelected(0);
                      } else {
                        setSelected(null);
                      }
                    }}
                  >
                    <img
                      src={transparent_image}
                      alt="Search Bar"
                      className="p-0 m-0 sprites-search-icon"
                      style={{ height: 18, width: 18 }}
                    />
                  </IconButton>
                ) : (
                  <Link
                    to={`/search/?search=` + searchWord}
                    style={{ textDecoration: "none" }}
                    state={{ searchWord: searchWord }}
                  >
                    <IconButton
                      className=" d-flex align-items-center justify-content-center"
                      size="md"
                      dark={true}
                      onClick={() => handleSearchWordNavbarSave(searchWord)}
                      rounded
                    >
                      <img src={transparent_image} alt="Search Bar" className="p-0 m-0 sprites-search-icon" />
                    </IconButton>
                  </Link>
                )}
              </CardContent>
            </Card>
            <Card
              className={`w-100 custom-card-profile position-absolute my-1 ${navSearchDropdown ? "d-block" : "d-none"
                }`}
              style={{ backgroundColor: "#1b1c1d" }}
            >
              <CardContent
                className="m-0"
                style={{
                  maxHeight: 300,
                  overflowY: "auto",
                  backgroundColor: "#1b1c1d",
                }}
                onKeyPress={() => alert()}
              >
                {products?.map((item, index) => {
                  return (
                    <Link
                      className="d-flex justify-content-start text-white text-decoration-none"
                      variant="light"
                      to={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                      onMouseEnter={() => {
                        setSelected(index);
                      }}
                    >
                      <div
                        key={item.id}
                        sm={12}
                        className="body-text2 d-flex flex-column w-100 justify-content-center align-items-start"
                        style={{
                          cursor: "pointer",
                          border: selected === index ? "1px solid #fff" : "",
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center m-1">
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: 50, height: 50 }}
                          >
                            <Image
                              loading="lazy"
                              className="m-2"
                              src={
                                item?.productimage_set[0]
                                  ? item?.productimage_set[0]?.image_file
                                  : imagenotavailable
                              }
                              alt={item?.title}
                              style={{ maxWidth: 40, maxHeight: 40 }}
                            />
                          </div>
                          <span className="body-text2 mx-2">{item.title}</span>
                        </div>
                        <div
                          className={`w-100 ${index === products.length - 1 ? "d-none" : "d-block"
                            }`}
                          style={{ height: 1, backgroundColor: "#292929" }}
                        ></div>
                      </div>
                    </Link>
                  );
                })}
              </CardContent>
            </Card>
          </Form>
        </div>
      );

    default:
      return (
        <div className="position-relative" style={{ zIndex: 1000 }}>
          <Form>
            <Card
              dark={true}
              className="m-0 border-0"
              outlined
              style={styles.navSearchbar}
            >
              <CardContent
                className="d-flex my-2 bg-transparent mx-2"
                style={{ minWidth: 300, height: "28px" }}
              >
                <FormControl
                  type="search"
                  placeholder="Search 3D models"
                  className="me-2 my-1 border-0 outline-none body-text2 text-white"
                  aria-label="Search"
                  style={{ background: "none" }}
                  onFocus={() => {
                    // console.log(products);
                    setSearchDropdown(true);
                    if (products.length > 0) {
                      setSelected(0);
                    } else {
                      setSelected(null);
                    }
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setSearchDropdown(false);
                      setSelected(null);
                    }, 200);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown") {
                      setSelected((selected + 1) % products.length);
                    }
                    if (e.key === "ArrowUp") {
                      setSelected(
                        (selected - 1 + products.length) % products.length
                      );
                    }
                    if (e.key === "Enter") {
                      if (selected) {
                        // navigate(
                        //   `/3dmodels/${products[selected]?.category_data.slug}/${products[selected].slug}`
                        // );
                        handleNavigationClick(
                          `/3dmodels/${products[selected]?.category_data.slug}/${products[selected].slug}`
                        );
                      }
                    }
                  }}
                  onChange={(e) => setSearchWord(e.target.value)}
                />
                {searchWord === "" || searchWord.trim() === "" ? (
                  <IconButton
                    size="large"
                    className="my-1"
                    dark={true}
                    rounded
                    onClick={(e) => {
                      e.preventDefault();
                      // console.log(products);
                      setSearchDropdown(true);
                      if (products.length > 0) {
                        setSelected(0);
                      } else {
                        setSelected(null);
                      }
                    }}
                  >
                    <img src={transparent_image} alt="Search Bar" className="p-0 m-0 sprites-search-icon" />
                  </IconButton>
                ) : (
                  <Link
                    to={`/search/?search=` + searchWord}
                    style={{ textDecoration: "none" }}
                    state={{ searchWord: searchWord }}
                  >
                    <IconButton
                      size="large"
                      className="my-1"
                      dark={true}
                      onClick={() => handleSearchWordSave(searchWord)}
                      rounded
                    >
                      <img src={transparent_image} alt="Search Bar" className="p-0 m-0 sprites-search-icon" />
                    </IconButton>
                  </Link>
                )}
              </CardContent>
            </Card>
            <Card
              dark={true}
              className={`w-100 position-absolute my-1 ${searchDropdown ? "d-block" : "d-none"
                }`}
            >
              <CardContent
                className="m-0"
                style={{ maxHeight: 300, overflowY: "auto" }}
                onKeyPress={() => alert()}
              >
                {products?.map((item, index) => {
                  return (
                    <Link
                      className="d-flex justify-content-start text-white text-decoration-none"
                      variant="light"
                      to={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                      onMouseEnter={() => {
                        setSelected(index);
                      }}
                    >
                      <div
                        key={item.id}
                        sm={12}
                        className="body-text2 d-flex flex-column w-100 justify-content-center align-items-start"
                        style={{
                          cursor: "pointer",
                          border: selected === index ? "1px solid #fff" : "",
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center m-1">
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: 50, height: 50 }}
                          >
                            <Image
                              loading="lazy"
                              className="m-2"
                              src={
                                item?.productimage_set[0]
                                  ? item?.productimage_set[0]?.image_file
                                  : imagenotavailable
                              }
                              alt={item?.title}
                              style={{ maxWidth: 40, maxHeight: 40 }}
                            />
                          </div>
                          <span className="body-text2 mx-2">{item.title}</span>
                        </div>
                        <div
                          className={`w-100 ${index === products.length - 1 ? "d-none" : "d-block"
                            }`}
                          style={{ height: 1, backgroundColor: "#292929" }}
                        ></div>
                      </div>
                    </Link>
                  );
                })}
              </CardContent>
            </Card>
          </Form>
        </div>
      );
  }
};

const styles = {
  searchBox: {
    backgroundColor: "#1B1C1D",
    borderRadius: 12,
    border: "1px solid #3D3D3D",
  },
  navSearchbar: {
    backgroundColor: "#2E2E2E",
    borderRadius: 12,
  },
};

export default Searchbar;
