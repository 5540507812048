import "core-ui/pages/Product.scss";
import "core-ui/pages/admin/Product.scss";
import { useLocation, Outlet, useParams } from "react-router-dom";
import { ProductMain } from "..";

const Product = ({ mode }) => {
  console.log(mode);
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param2 = params.length > 0 ? params[1] : "";
  const param3 = params.length > 1 ? params[2] : "";

  // alert(param1);
  // alert(param2);

  const { productId } = useParams();
  // alert(productId);

  if (param3 === "editor" || productId !== undefined) {
    return <Outlet />;
  }

  return <ProductMain mode={mode} />;
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  parentContainer90: {
    minHeight: "90vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  checkedIcons: {
    width: 20,
    height: 20,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  fileNames: {
    backgroundColor: "#141414",
  },
};

export default Product;
