import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Spinner,
  Image,
  NavDropdown,
  Button as RButton,
} from "react-bootstrap";
import { Button, Card } from "ui-neumorphism";
import { Link, useLocation } from "react-router-dom";
import * as actions from "actions/UserActions";
import { toast } from "react-toastify";
import { ZDropdown } from "components";
import Flags from "country-flag-icons/react/3x2";

import "./ContactForm.scss";
import { IoChevronDown } from "react-icons/io5";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { AiOutlineCaretDown } from "react-icons/ai";

const ContactForm = (props) => {
  const { form } = props;

  const notify = (msg) => {
    toast.success(msg);
    // alert();
  };
  const [countryList, setCountryList] = useState([]);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [message, setMessage] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [save_in_spreadsheet, setSaveInSpreadsheet] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const selectRef = useRef(null);
  const [selectedSubject, setSelectedSubject] = useState();

  const [isSending, setIsSending] = useState(false);

  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param1 = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";

  const [countries, setCountries] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const subjects = [
    { key: "#Customized-3D-model", value: "Customized 3D model" },
    { key: "#Buy-VR-Headset", value: "Buy VR Headset" },
    { key: "#Buyer", value: "Buyer" },
    { key: "#Seller", value: "Seller" },
    { key: "#Interested-in-3D-Viewer", value: "Interested in 3D Viewer" },
    { key: "#Others", value: "Others" },
  ];

  const options = subjects.map((subject) => ({
    value: subject.key,
    label: subject.value,
  }));

  useEffect(async () => {
    const response = await actions.loadCountries();
    setCountries(response);
  }, []);

  const FlagIcon = ({ country }) => {
    const Flag = Flags[country?.code];

    return (
      <Flag
        title={country?.name}
        className="text-white"
        style={{ height: 30 }}
      />
    );
  };

  useEffect(() => {
    if (countries.length > 0) {
      let result = [];
      for (let i = 0; i < countries.length; i++) {
        let data = {
          value: countries[i]?.code,
          label: (
            <div className="d-flex subtitle" key={countries[i]?.id}>
              <FlagIcon country={countries[i]} />
              &nbsp;&nbsp;{countries[i]?.name}
            </div>
          ),
        };
        result.push(data);
      }
      result.sort((a, b) =>
        a.label.props.children[2].localeCompare(b.label.props.children[2])
      );
      setCountryList(result);
    }
  }, [countries]);

  // useEffect(() => {
  //   if (location.hash !== "") {
  //     subjects.map((subject) => {
  //       if (subject.key === location.hash) {
  //         setCompanyName(subject.value);
  //       }
  //     });
  //   }
  // }, [location]);

  useEffect(() => {
    if (location.hash !== "") {
      subjects.forEach((subject) => {
        if (subject.key === location.hash) {
          setSelectedOption({ value: subject.key, label: subject.value });
        }
      });
    } else {
      setSelectedOption(null);
    }
  }, [location]);

  useEffect(() => {
    console.log("company", selectedOption);
  }, [selectedOption]);

  const emailPattern =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
  };

  const isValidEmail = emailPattern.test(email);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setIsSending(true);
    const formData = new FormData(form.current);
    formData.append(
      "phone_code",
      countries.filter((country) => country.code === countryCode)[0]?.phone_code
    );
    formData.append("company", selectedOption?.label);

    const response = await actions.createMessage(formData);
    try {
      if (response) {
        notify("Thank you for submitting response. We'll contact you soon!");
        setName("");
        setMobile("");
        setEmail("");
        setCompanyName("");
        setSelectedOption("");
        setMessage("");
        setCountryCode("");
        setPhoneCode("");
      }
      setIsSending(false);
    } catch (error) {
      setIsSending(false);
    }
  };

  const CustomDropdownIndicator = (props) => {
    return (
      <div
        {...props.innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingRight: "8px",
        }}
      >
        <AiOutlineCaretDown />
      </div>
    );
  };
  const IndicatorSeparator = () => {
    return (
      <div
        style={{
          height: "1px",
          width: "1px",
          backgroundColor: "black",
          padding: 0,
        }}
      />
    );
  };

  const customStyles2 = {
    menu: () => ({
      marginTop: -4.5,
      padding: 0,
      position: "absolute",
      width: "100%",
      zIndex: 999,
      fontSize: "20px",
      borderRadius: "50px !important",
      overflow: "visible",
    }),
    menuList: (provided, state) => ({
      ...provided,
      borderRadius: "15px",
      textAlign: "center",
    }),

    input: (provided) => ({
      ...provided,
      color: "white",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#0d0d0d",
      color: state.isSelected ? "#777" : "white",
      marginTop: 0,
      cursor: "pointer",
      textAlign: "left",
      paddingLeft: "10px",
      fontSize: "16px",
      padding: "8px",
    }),

    control: (provided, state) => ({
      ...provided,
      color: "#fff",
      height: "60px",
      backgroundColor: "#0d0d0d",
      display: "flex",
      fontSize: 16,
      fontFamily: "poppins",
      // padding: ,
      borderRadius: "10px",
      border: "1px solid #3d3d3d",
      boxShadow: "none",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "#fff";
      const transition = "opacity 300ms";
      const padding = "8px 8px 8px 0px";

      return { ...provided, opacity, transition, color, padding };
    },
  };

  return (
    <>
      <Form
        id="contact-form"
        className={`px-lg-3 px-3 py-4`}
        ref={form}
        onSubmit={handleSendMessage}
        autoComplete="off"
      >
        <Form.Group className="mt-4 text-white">
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            type="text"
            data-cy="contact-name"
            className="text-white body-text2"
            placeholder="Enter your Name"
            style={{
              background: "#0d0d0d",
              border: "1px solid #3d3d3d",
              height: 50,
              borderRadius: "5px",
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mt-4 text-white">
          <Form.Label>Work Email</Form.Label>
          <Form.Control
            name="email"
            type="email"
            data-cy="contact-email"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
            className="text-white body-text2"
            placeholder="Your e-mail address"
            style={{
              background: "#0d0d0d",
              border: "1px solid #3d3d3d",
              height: 50,
              borderRadius: "5px",
            }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              return e.target.setCustomValidity("");
            }}
            // onChange={handleEmailChange}
            // isInvalid={!isValidEmail}
            onInvalid={(e) => {
              return e.target.setCustomValidity("Please enter valid email.");
            }}
            required
          />
        </Form.Group>
        <div className="my-4 text-white">
          <Form.Label>Region</Form.Label>
          <div data-cy="contact-region">
            <ZDropdown
              options={countryList}
              mode="region"
              region={countryCode}
              setRegion={setCountryCode}
            />
          </div>
        </div>
        <Form.Label className="text-white">Phone No</Form.Label>
        <Form.Group className="d-flex justify-content-start align-items-center mb-4">
          <RButton
            type="button"
            className="wrapped-button d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "#0d0d0d",
              color: "#888A8C",
              border: "1px solid #3d3d3d",
              fontSize: 16,
              height: 50,
              // minWidth: "30px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              borderRight: "0px",
            }}
            onKeyDown={(e) => e.preventDefault()}
          >
            {
              countries?.filter((country) => country.code === countryCode)[0]
                ?.phone_code
            }
          </RButton>
          <Form.Control
            name="mobile"
            type="text"
            data-cy="contact-mobile"
            // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            // pattern="[0-9]{10}"
            className="text-white"
            placeholder="Enter your Mobile"
            value={phoneCode}
            style={{
              background: "#0d0d0d",
              border: "1px solid #3d3d3d",
              height: 50,
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
            required
            onInvalid={(e) => {
              return e.target.setCustomValidity("Please enter valid phone number.");
            }}
            // onChange={(e) => {
            //   setPhoneCode(e.target.value);
            //   return e.target.setCustomValidity("");
            // }}
            onChange={(e) => {
              setPhoneCode(e.target.value);
              const inputValue = e.target.value;
              if (/^[0-9]+$/.test(inputValue) && parseInt(inputValue, 10) !== 0) {
                e.target.setCustomValidity("");
              } else {
                e.target.setCustomValidity("Please enter valid phone number");
              }
            }}
          />
        </Form.Group>
        {/* <Form.Control
          name="company"
          type="text" 
          className="text-white border-0 body-text2"
          placeholder="Subject"
          style={styles.inputField}
          value={company_name}
          onChange={(e) => setCompanyName(e.target.value)}
          required
        /> */}
        <Form.Group className="mt-4 text-white">
          <Form.Label>Subject</Form.Label>

          <div
            className="custom-select-container position-relative d-flex justify-content-end align-items-center mb-4"
            data-cy="contact-subject"
          >

            <Select
              className="w-100"
              options={options}
              styles={customStyles2}
              value={selectedOption}
              components={{
                IndicatorSeparator,
                DropdownIndicator: CustomDropdownIndicator,
              }}
              y
              onChange={(selected) => {
                setSelectedOption(selected);
              }}
              filterOption={({ label }, inputValue) => {
                return String(label)
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }}
            />
          </div>
        </Form.Group>

        <Form.Group className="mt-4 text-white">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows="5"
            name="message"
            data-cy="contact-message"
            className="text-white body-text2"
            placeholder="Your Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{
              background: "#0d0d0d",
              border: "1px solid #3d3d3d",
              borderRadius: "5px",
            }}
            required
          />
        </Form.Group>
        <div className="text-center mt-4">
          {/* <RButton
            className="btn btn-link border-0 wrapped-button w-100 p-0"
            style={{ background: "transparent", textDecoration: "none" }}
            type="submit"
            disabled={isSending}
          > */}
          <RButton
            data-cy="contact-submit-button"
            dark={true}
            className="d-flex justify-content-center align-items-center explore-button text-white w-100 my-2 p-0 border-0"
            style={{ height: 48, width: 328 }}
            type="submit"
            disabled={isSending}
          >
            {isSending ? (
              <span className="SEND">
                <Spinner
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                SENDING ...
              </span>
            ) : (
              <span className="poppins">SEND MESSAGE</span>
            )}
          </RButton>
          {/* </RButton> */}
        </div>
      </Form>
    </>
  );
};

const styles = {
  inputField: {
    background: "#1F1F1F",
    height: 50,
  },
};

export default ContactForm;
