import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Container, Image } from "react-bootstrap";
import { Card, IconButton } from "ui-neumorphism";
// import { edit, trash } from "assets_old/admin";
import { edit, trash } from "assets/admin";

import moment from "moment";

import { CouponForm } from "components";

import * as actions from "actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";

const Coupons = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.loadCoupons());
  }, []);

  const coupons = useSelector((state) => state.adminReducer.coupons);
  const user = useSelector((state) => state.authReducer.user);
  const [showForm, setShowForm] = useState(null);
  const form = useRef(null);
  const validTillRef = useRef(null);
  const couponCategoryRef = useRef(null);

  const clearForm = () => {
    form.current.reset();
    couponCategoryRef.current.clearValue();
  };

  const handleCreate = (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    formData.append(
      "coupon_category",
      couponCategoryRef.current.props.value.value
    );
    formData.append(
      "valid_till",
      moment(validTillRef.current.value).format("YYYY-MM-DD hh:mm:ss")
    );
    dispatch(actions.createCoupon(formData, clearForm));
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    formData.append(
      "coupon_category",
      couponCategoryRef.current.props.value.value
    );
    formData.append(
      "valid_till",
      moment(validTillRef.current.value).format("YYYY-MM-DD hh:mm:ss")
    );
    formData.append("id", showForm);
    dispatch(actions.updateCoupon(formData));
    setShowForm(null);
  };

  return (
    <React.Fragment>
      <Container
        className="d-flex flex-column align-items-center h-100 mt-5 pt-5 home-background-categories"
        fluid
        style={styles.parentContainer}
      >
        <Container className="p-5 text-white" fluid>
          <h4>Coupons</h4>
          <Row>
            <Col sm={12}>
              <Card
                className="m-2 d-flex flex-column align-items-center h-100 border-R20 admin-filedrop-card"
                dark={true}
              >
                <Container className="h-100 w-100">
                  <Row className="border-bottom py-3 mt-3">
                    <Col sm={3}>Enter Coupon Name</Col>
                    <Col>Select Coupon Category</Col>
                    <Col>Flat Off(In Rupees/Percentage)</Col>
                    <Col>Offers end in</Col>
                    <Col sm={2}></Col>
                  </Row>
                  <CouponForm
                    form={form}
                    handleCreate={handleCreate}
                    handleUpdate={null}
                    validTillRef={validTillRef}
                    couponCategoryRef={couponCategoryRef}
                    couponActivity="add"
                  />
                  {coupons.map((item, index) => {
                    if (showForm === item.id) {
                      return (
                        <CouponForm
                          key={item.id}
                          form={form}
                          handleCreate={null}
                          handleUpdate={handleUpdate}
                          validTillRef={validTillRef}
                          couponCategoryRef={couponCategoryRef}
                          couponActivity="update"
                          coupon={item}
                        />
                      );
                    } else {
                      return (
                        <Row
                          key={item.id}
                          className="py-3 subtitle d-flex align-items-center"
                          style={{ borderBottom: "1px solid #444" }}
                        >
                          <Col sm={3}>{item.code}</Col>
                          <Col className="text-center">
                            {item.coupon_category}
                          </Col>
                          <Col className="text-center">{item.flat_off}</Col>
                          <Col className="text-center">
                            {moment(item.valid_till).format("DD/MM/YYYY")}
                          </Col>
                          <Col sm={2} className="text-center">
                            <IconButton
                              className="body-text1 text-white"
                              text={false}
                              dark={true}
                              size="large"
                              onClick={() => {
                                setShowForm(item.id);
                              }}
                            >
                              <Image loading="lazy" src={edit} alt="Edit" className="m-2" />
                            </IconButton>
                            {user.is_superuser && <IconButton
                              className="body-text1 text-white ms-1"
                              text={false}
                              dark={true}
                              size="large"
                              onClick={() => {
                                dispatch(actions.deleteCoupon(item.id));
                              }}
                            >
                              <Image loading="lazy" src={trash} alt="Trash" className="m-1" />
                            </IconButton>}
                          </Col>
                        </Row>
                      );
                    }
                  })}
                </Container>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  parentContainer90: {
    minHeight: "90vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  checkedIcons: {
    width: 20,
    height: 20,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  fileNames: {
    backgroundColor: "#141414",
  },
};

export default Coupons;
