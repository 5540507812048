import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { forgot_mascot_mobile } from "assets";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Button as BootstrapButton } from "react-bootstrap";


const Error = () => {

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet>
        <title>404 Error</title>
        <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
        <link rel="canonical" href="https://www.znanye.com/" />
      </Helmet>
      <Container
        className="h-100 pt-2"
        style={styles.parentContainer}
        fluid
      >
        <Row className="mx-auto h-100">
          <Col md={6} className="mx-auto d-flex flex-column align-items-center justify-content-center error-background" style={{ height: "100vh" }}>
            <div className="d-flex flex-column justify-content-center h-100">
              <p className="text-white bold" style={{ fontSize: 96 }}>
                404
              </p>
              <h3 className="text-white">We are sorry !</h3>
              <h3 className="text-white">But the page you requested was not found</h3>
              <div>
                <BootstrapButton
                  className="mt-5 explore-button-no-neo"
                  size="lg"
                  dark={true}
                  onClick={() => navigate(-1)}
                >
                  <span className="poppins">GO BACK</span>
                </BootstrapButton>
              </div>
            </div>
          </Col>
          <Col md={6} className="mx-auto d-flex justify-content-center align-items-end h-100">
            <div className="w-100">
              <img
                src={forgot_mascot_mobile}
                alt="Forgot Mobile Mascot"
                className="w-50 position-absolute"
                style={{ bottom: 0 }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
    backgroundColor: "#161616"
  },
};

export default Error;
