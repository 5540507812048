import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Col,
  Row,
  Container,
  Overlay,
  Popover,
  Button as RButton,
  Card as BCard,
} from "react-bootstrap";
import { CardAction, Button } from "ui-neumorphism";
import { coming_soon, imagenotavailable } from "assets";
import { useDispatch, useSelector } from "react-redux";
import * as adminActions from "actions/AdminActions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useCurrentBreakpointName } from "react-socks";
import { Breakpoint } from "react-socks";
import getSymbolFromCurrency from "currency-symbol-map";

import { FiTrash } from "react-icons/fi";


const ZCard = ({
  key,
  index,
  item,
  type,
  setReloadProducts,
  reloadProducts,
}) => {
  const [hover, setHover] = useState(false);
  // const [soldCount, setSoldCount] = useState(0);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const breakpoint = useCurrentBreakpointName();
  let cardStyle = null;
  let imageStyle = null;
  let fileExtensions = null;
  const [hovered, setHovered] = useState(false);
  const [productFree, setProductFree] = useState(false);
  const currency = useSelector((state) => state.authReducer.currency);
  const user = useSelector((state) => state.authReducer.user);

  // For Archiving Product
  const [target, setTarget] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const ref = useRef(null);

  // //consolelog("product", item);
  // useEffect(async () => {
  //   const data = { "product_id": item.id };
  //   const response = await actions.soldCount(data);
  //   if (response) {
  //     setSoldCount(response["sold_count"]);
  //   }
  // }, [])

  const bestCoupon = item?.coupons_data?.find(
    (element) => element.id === item.best_coupon
  );

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    if (bestCoupon) {
      if (type === "product") {
        if (bestCoupon?.coupon_category == "ABSOLUTE") {
          if (item.price - bestCoupon?.flat_off === 0) {
            setProductFree(true);
          }
        } else {
          let x = item.price - (item.price * bestCoupon?.flat_off) / 100;
          if (x === 0) {
            setProductFree(true);
          }
        }
      }
    }
    if (type === "product") {
      //consolelog(item.price);
      if (item.price === 0) {
        setProductFree(true);
      }
      // else if (item.is_free){
      //   setProductFree(true);
      // }
    }
  }, [bestCoupon, type]);

  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param1 = params.length > 0 ? params[0] : "";
  const [changingBorder, setChangingBroder] = useState(false);
  // const [isDeletingProduct, setIsDeletingProduct] = useState(false);

  if (breakpoint === "xsmall") {
    cardStyle = styles.ZMobileCard;
    imageStyle = styles.imageMobileCard;
  } else {
    cardStyle = styles.ZCard;
    imageStyle = styles.imageCard;
  }

  switch (type) {
    case "product":
      if (item) {
        fileExtensions = Object.keys(item?.file_statistics?.extension_stats);
      }
      return (
        <>
          {breakpoint !== "xsmall" && (
            <Col
              className="py-2 d-flex justify-content-center my-2"
              key={key}
              // as={Link}
              // to={`${param1 == "admin" ? "/admin" : ""}/3dmodels/${item?.category_data?.slug
              //   }/${item.slug}`}
              style={{ textDecoration: "none" }}
            >
              <a
                className="text-decoration-none"
                data-cy={item.slug}
                href={`${param1 == "admin" ? "/admin" : ""}/3dmodels/${item?.category_data?.slug
                  }/${item.slug}`}
                onClick={() =>
                  handleNavigationClick(
                    `${param1 == "admin" ? "/admin" : ""}/3dmodels/${item?.category_data?.slug
                    }/${item.slug}`
                  )
                }
              >
                <BCard
                  className="position-relative cursor-pointer"
                  style={cardStyle}
                  // as={Link}
                  // to={`${param1 == "admin" ? "/admin" : ""}/3dmodels/${
                  //   item?.category_data?.slug
                  // }/${item.slug}`}
                  // onClick={() =>
                  //   handleNavigationClick(
                  //     `${param1 == "admin" ? "/admin" : ""}/3dmodels/${
                  //       item?.category_data?.slug
                  //     }/${item.slug}`
                  //   )
                  // }
                  dark={true}
                >
                  <div
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className="d-flex h-100 justify-content-center align-items-center"
                  >
                    <>
                      {!productFree && bestCoupon && (
                        <CardAction
                          className="m-0 p-1 text-center d-flex justify-content-center position-absolute bg-white text-black overflow-hidden w-100"
                          style={{
                            ...styles.cardAction,
                            bottom: "auto",
                            top: 0,
                            left: "auto",
                            borderRadius: 10,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        >
                          <div className="text-secondary subtitle">
                            {bestCoupon?.coupon_category === "ABSOLUTE"
                              ? getSymbolFromCurrency(currency.name) +
                              (
                                (item?.price - bestCoupon?.flat_off) *
                                currency.rate
                              ).toFixed(2)
                              : getSymbolFromCurrency(currency.name) +
                              (
                                (item?.price -
                                  (item?.price * bestCoupon?.flat_off) /
                                  100) *
                                currency.rate
                              ).toFixed(2)}
                            &nbsp;
                            {/* {bestCoupon?.coupon_category == "ABSOLUTE"
                          ? getSymbolFromCurrency(currency.name) +
                          (bestCoupon?.flat_off * currency.rate).toFixed(2) +
                          " off"
                          : bestCoupon?.flat_off + "% off"} */}
                          </div>
                          <div className="d-flex text-black">
                            {"        "}
                            <strike className="body-text3">
                              {getSymbolFromCurrency(currency.name) +
                                (item?.price * currency.rate).toFixed(2)}
                            </strike>
                          </div>
                        </CardAction>
                      )}
                      {(productFree || !bestCoupon) && (
                        <CardAction
                          className="m-0 p-1 text-center d-flex justify-content-center position-absolute bg-white text-secondary subtitle overflow-hidden w-100"
                          style={{
                            ...styles.cardAction,
                            bottom: "auto",
                            top: 0,
                            left: "auto",
                            borderRadius: 10,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        >
                          {productFree ? (
                            <div className="bg-white text-secondary px-3 d-flex flex-grow-1 justify-content-center">
                              FREE
                            </div>
                          ) : (
                            <>
                              {getSymbolFromCurrency(currency.name)}{" "}
                              {(item?.price * currency.rate).toFixed(2)}
                            </>
                          )}
                        </CardAction>
                      )}
                    </>
                    {hover && breakpoint !== "xsmall" && (
                      <div
                        className="w-50 h-100 text-white position-absolute p-2 py-4 text-start"
                        style={{
                          backgroundColor: `rgba(0,0,0,0.8)`,
                          backdropFilter: "blur(2px)",
                          zIndex: 900,
                          top: 0,
                          right: 0,
                          borderTopRightRadius: 10,
                          borderBottomRightRadius: 10,
                        }}
                      >
                        {/* <div className="small-title">Product Name</div>
                    <div className="body-text1 poppins-500">{item?.title}</div> */}
                        <div className="small-title mt-2 font-monospace">
                          File Format
                        </div>
                        <div className="body-text1 poppins-500">
                          {fileExtensions?.map((extension) => {
                            return <>{extension} </>;
                          })}
                        </div>
                        <div className="small-title mt-2 font-monospace">
                          Views
                        </div>
                        <div className="body-text1 poppins-500">
                          {item?.view_count}
                        </div>
                        {/* <div className="small-title mt-2">Sold Count</div>
                  <div className="body-text1 poppins-500">{soldCount}</div> */}
                      </div>
                    )}
                    <Image
                      loading="lazy"
                      className="mb-4"
                      src={
                        item?.productimage_set[0]
                          ? item?.productimage_set[0]?.image_file
                          : imagenotavailable
                      }
                      alt={item.title}
                      style={imageStyle}
                    />
                    <CardAction
                      className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1 text-white card-action-wrap"
                      style={styles.cardAction}
                    >
                      <span className="w-100">
                        {/* {item?.zsin ? item.title : ""} */}
                        {item?.zsin
                          ? item.title.length <= 38
                            ? item.title
                            : `${item.title.slice(0, 38)}...`
                          : ""}
                      </span>
                    </CardAction>
                  </div>
                </BCard>
              </a>
            </Col>
          )}
          {breakpoint === "xsmall" && (
            <Row
              className="py-2 my-2"
              key={key}
            // as={Link}
            // to={`${param1 == "admin" ? "/admin" : ""}/3dmodels/${
            //   item?.category_data?.slug
            // }/${item.slug}`}
            // style={{ textDecoration: "none" }}
            // onClick={() =>
            //   handleNavigationClick(
            //     `${param1 == "admin" ? "/admin" : ""}/3dmodels/${
            //       item?.category_data?.slug
            //     }/${item.slug}`
            //   )
            // }
            >
              <a
                className="text-decoration-none"
                href={`${param1 == "admin" ? "/admin" : ""}/3dmodels/${item?.category_data?.slug
                  }/${item.slug}`}
                onClick={() =>
                  handleNavigationClick(
                    `${param1 == "admin" ? "/admin" : ""}/3dmodels/${item?.category_data?.slug
                    }/${item.slug}`
                  )
                }
              >
                <BCard
                  className="mx-2 position-relative"
                  style={styles.productMobileCard}
                  dark={true}
                >
                  <div className="h-100">
                    {!productFree && bestCoupon && (
                      <CardAction
                        className="m-0 text-center position-absolute bg-primary text-black subtitle overflow-hidden"
                        style={{
                          bottom: "auto",
                          top: 0,
                          left: 0,
                          right: "auto",
                          borderRadius: 10,
                          maxHeight: "30px",
                          zIndex: 999,
                        }}
                      >
                        <div className="bg-white text-secondary p-2 px-3">
                          {bestCoupon?.coupon_category == "ABSOLUTE"
                            ? getSymbolFromCurrency(currency.name) +
                            (bestCoupon?.flat_off * currency.rate).toFixed(
                              2
                            ) +
                            " OFF"
                            : bestCoupon?.flat_off + "% OFF"}
                        </div>
                        {/* <div className="d-flex bg-primary text-black p-2 px-3">
                          {getSymbolFromCurrency(currency.name)}{" "}
                          {(item?.price * currency.rate).toFixed(2)}
                        </div> */}
                      </CardAction>
                    )}
                    {productFree && (
                      <CardAction
                        className="m-0 text-center position-absolute bg-primary text-black subtitle overflow-hidden"
                        style={{
                          bottom: "auto",
                          top: 0,
                          left: 0,
                          right: "auto",
                          borderRadius: 10,
                          maxHeight: "30px",
                          zIndex: 9999,
                        }}
                      >
                        <div className="bg-white text-secondary p-2 px-3">
                          FREE
                        </div>
                      </CardAction>
                    )}
                    <Container fluid className="p-0">
                      <Row>
                        <Col
                          xs={6}
                          className="d-flex justify-content-center align-items-center p-0"
                          style={{
                            backgroundColor: "",
                            overflow: "hidden",
                            maxWidth: 164,
                          }}
                        >
                          <div>
                            <Image
                              loading="lazy"
                              src={
                                item?.productimage_set[0]
                                  ? item?.productimage_set[0]?.image_file
                                  : imagenotavailable
                              }
                              alt={item.title}
                              style={{
                                width: "162px",
                                height: "158px",
                                objectFit: "cover",
                                borderRadius: "8px 0 0 8px",
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div
                            className="p-2 py-2 text-start text-white"
                            style={{
                              borderTopRightRadius: 10,
                              borderBottomRightRadius: 10,
                            }}
                          >
                            <div style={{ fontWeight: 400, fontSize: 10 }}>
                              Product name
                            </div>
                            <div
                              className="body-text1 poppins-400"
                              style={{
                                fontSize: 14,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.title}
                            </div>
                            <div
                              className="mt-2"
                              style={{ fontWeight: 400, fontSize: 10 }}
                            >
                              File Formats Available
                            </div>
                            <div
                              className="body-text1 poppins-400"
                              style={{
                                fontSize: 14,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {fileExtensions?.map((extension) => {
                                return <>{extension} </>;
                              })}
                            </div>
                            <div
                              className="mt-2"
                              style={{ fontWeight: 400, fontSize: 10 }}
                            >
                              Price
                            </div>
                            <div className=" d-flex body-text1 poppins-400">
                              {!bestCoupon && (
                                <div className="text-primary m-1">
                                  {(item?.price * currency.rate).toFixed(2)}
                                  {getSymbolFromCurrency(currency.name)}{" "}
                                </div>
                              )}

                              <div
                                className={`${bestCoupon ? "subtitle" : "d-none"
                                  } text-primary m-1`}
                                style={{ fontSize: 14 }}
                              >
                                {bestCoupon?.coupon_category === "ABSOLUTE"
                                  ? (
                                    (item?.price - bestCoupon?.flat_off) *
                                    currency?.rate
                                  ).toFixed(2)
                                  : (
                                    (item?.price -
                                      (item?.price * bestCoupon?.flat_off) /
                                      100) *
                                    currency?.rate
                                  ).toFixed(2)}
                              </div>
                              <div
                                className={`${bestCoupon ? "body-text1 my-1.5" : "d-none"
                                  }`}
                                style={{ fontSize: 12, margin: 4 }}
                              >
                                <strike>
                                  {" "}
                                  {(item.price * currency?.rate).toFixed(2)}
                                  {getSymbolFromCurrency(currency?.name)}
                                </strike>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </BCard>
              </a>
            </Row>
          )}
        </>
      );
    // case "myproducts":
    //   if (item) {
    //     fileExtensions = Object.keys(item?.file_statistics?.extension_stats);
    //   }
    //   return (
    //     <Col
    //       className="py-2 my-2"
    //       key={key}
    //       as={Link}
    //       to={`${param1 == "seller" ? "/seller" : ""}/product/${item.id}`}
    //       style={{ textDecoration: "none" }}
    //     >
    //       <Card className="position-relative" style={cardStyle} dark={true}>
    //         <div
    //           onMouseEnter={() => setHover(true)}
    //           onMouseLeave={() => setHover(false)}
    //           className="d-flex h-100 justify-content-center align-items-center"
    //         >
    //           {type == "product" && (
    //             <CardAction
    //               className="m-0 p-2 text-center d-flex justify-content-center position-absolute bg-primary text-black subtitle px-3"
    //               style={{
    //                 ...styles.cardAction,
    //                 top: 0,
    //                 bottom: "auto",
    //                 left: "auto",
    //                 borderBottomRightRadius: 0,
    //                 borderTopRightRadius: 10,
    //               }}
    //             >
    //               {item?.price}
    //             </CardAction>
    //           )}
    //           {hover && (
    //             <div
    //               className="w-50 h-100 position-absolute p-2 py-4 text-start"
    //               style={{
    //                 backgroundColor: `rgba(0,0,0,0.7)`,
    //                 zIndex: 3000,
    //                 top: 0,
    //                 right: 0,
    //                 borderTopRightRadius: 10,
    //                 borderBottomRightRadius: 10,
    //               }}
    //             >
    //               <div className="small-title">Product Name</div>
    //               <div className="body-text1 poppins-500">{item?.title}</div>
    //               <div className="small-title mt-2">File Formats</div>
    //               <div className="body-text1 poppins-500">
    //                 {fileExtensions?.map((extension) => {
    //                   return <>{extension} </>;
    //                 })}
    //               </div>
    //               <div className="small-title mt-2">Views</div>
    //               <div className="body-text1 poppins-500">{item?.view_count}</div>
    //               <div className="small-title mt-2">Sold Count</div>
    //               <div className="body-text1 poppins-500">{soldCount}</div>
    //             </div>
    //           )}
    //           <Image
    //             loading = "lazy"
    //             className="mb-4"
    //             src={
    //               item?.productimage_set[0]
    //                 ? item?.productimage_set[0]?.image_file
    //                 : imagenotavailable
    //             }
    //             style={imageStyle}
    //           />
    //           <CardAction
    //             className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1 text-white"
    //             style={styles.cardAction}
    //           >
    //             {item?.zsin ? item.title : "Dog"}
    //           </CardAction>
    //         </div>
    //       </Card>
    //     </Col>
    //   );
    case "category":
      return (
        <Col
          className="py-lg-3 d-flex justify-content-center my-2 user-select-none"
          key={key}
          as={Link}
          to={item.slug}
          // to={`product/?category=` + item.title}
          style={{
            textDecoration: "none",
            pointerEvents: item?.coming_soon ? "none" : "",
          }}
        >
          <div
            onMouseEnter={() => {
              setHovered(true);
            }}
            onMouseLeave={() => {
              setHovered(false);
            }}
          >

            <BCard
              className="position-relative d-flex justify-content-center align-items-center"
              style={cardStyle}
              dark={true}
            >
              {item?.coming_soon && (
                <Image
                  loading="lazy"
                  className="position-absolute"
                  src={coming_soon}
                  alt="Coming Soon"
                  style={cardStyle}
                />
              )}
              {!hovered && (
                <Image
                  loading="lazy"
                  className="mb-4 mb-lg-5"
                  src={item.image_file ? item.image_file : imagenotavailable}
                  alt={item.title}
                  style={imageStyle}
                />
              )}
              {hovered && (
                <Image
                  loading="lazy"
                  className="mb-5"
                  src={item.gif_file ? item.gif_file : item.image_file}
                  alt={item.title}
                  style={imageStyle}
                />
              )}
              <CardAction
                className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1 text-white"
                style={styles.cardAction}
              >
                {item.title}
              </CardAction>
            </BCard>
          </div>
        </Col>
      );

    case "mobileExplorecategory":
      return (
        <Col
          className="py-lg-3 d-flex justify-content-center my-2 user-select-none"
          key={key}
          // as={Link}
          // to={item.slug}
          // to={`product/?category=` + item.title}
          style={{
            textDecoration: "none",
            pointerEvents: item?.coming_soon ? "none" : "",
          }}
        >
          <div
            onMouseEnter={() => {
              setHovered(true);
            }}
            onMouseLeave={() => {
              setHovered(false);
            }}
          >
            <a
              href={`/3dmodels/${item.slug}`}
              className="text-decoration-none"
              onClick={() => handleNavigationClick(`/3dmodels/${item.slug}`)}
            >
              <BCard
                className="position-relative d-flex justify-content-center align-items-center cursor-pointer"
                style={cardStyle}
                // as={Link}
                // to={`/3dmodels/${item.slug}`}
                // onClick={() => handleNavigationClick(`/3dmodels/${item.slug}`)}
                dark={true}
              >
                {item?.coming_soon && (
                  <Image
                    loading="lazy"
                    className="position-absolute"
                    src={coming_soon}
                    alt="Coming Soon"
                    style={styles.ZExploreMobileCard}
                  />
                )}
                {!hovered && (
                  <Image
                    loading="lazy"
                    className="mb-4 mb-lg-5"
                    src={item.image_file ? item.image_file : imagenotavailable}
                    alt={item.title}
                    style={styles.imageExploreMobileCard}
                  />
                )}
                {hovered && (
                  <Image
                    loading="lazy"
                    className="mb-5"
                    src={item.gif_file ? item.gif_file : item.image_file}
                    alt={item.title}
                    style={styles.imageExploreMobileCard}
                  />
                )}
                <CardAction
                  className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1 text-white"
                  style={styles.cardAction}
                >
                  {item.title}
                </CardAction>
              </BCard>
            </a>
          </div>
        </Col>
      );

    case "homecategory":
      return (
        <Col
          className="p-3 d-flex justify-content-center user-select-none"
          key={item.id}
          // as={Link}
          // to={`3dmodels/${item.slug}`}
          // to={`/3dmodels/product/?category=` + item.title}
          style={{
            textDecoration: "none",
            pointerEvents: item?.coming_soon ? "none" : "",
          }}
        >
          <div
            onMouseEnter={() => {
              setHovered(true);
            }}
            onMouseLeave={() => {
              setHovered(false);
            }}
          >
            <Breakpoint large up>
              <a
                href={`/3dmodels/${item.slug}`}
                className="text-decoration-none"
                onClick={() => handleNavigationClick(`/3dmodels/${item.slug}`)}
              >
                <BCard
                  className="position-relative d-flex justify-content-center align-items-center cursor-pointer"
                  style={styles.categoryCard}
                // as={Link}
                // to={`/3dmodels/${item.slug}`}
                // onClick={() =>
                //   handleNavigationClick(`/3dmodels/${item.slug}`)
                // }
                >
                  {item?.coming_soon && (
                    <Image
                      loading="lazy"
                      className="position-absolute"
                      src={coming_soon}
                      alt="Coming Soon"
                    />
                  )}
                  {hovered ? (
                    <Image
                      loading="lazy"
                      className="mb-4"
                      src={item.gif_file ? item.gif_file : item.image_file}
                      alt={`${item.title} 3D Models`}
                      width="220"
                      height="auto"
                    />
                  ) : (
                    <Image
                      loading="lazy"
                      className="mb-4"
                      src={
                        item.image_file ? item.image_file : imagenotavailable
                      }
                      alt={`${item.title} 3D Models`}
                      width="220"
                      height="auto"
                    />
                  )}
                  <CardAction
                    className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1 text-white"
                    style={styles.cardAction}
                  >
                    {item.title}
                  </CardAction>
                </BCard>
              </a>
            </Breakpoint>
            <Breakpoint medium down>
              <a
                href={`/3dmodels/${item.slug}`}
                className="text-decoration-none"
                onClick={() => handleNavigationClick(`/3dmodels/${item.slug}`)}
              >
                <BCard
                  className="position-relative d-flex justify-content-center align-items-center cursor-pointer"
                  style={styles.categoryMobileCard}
                // as={Link}
                // to={`/3dmodels/${item.slug}`}
                // onClick={() =>
                //   handleNavigationClick(`/3dmodels/${item.slug}`)
                // }
                >
                  {item?.coming_soon && (
                    <Image
                      loading="lazy"
                      className="position-absolute"
                      src={coming_soon}
                      alt="Coming Soon"
                    />
                  )}
                  {hovered ? (
                    <Image
                      loading="lazy"
                      className="mb-4"
                      src={item.gif_file ? item.gif_file : item.image_file}
                      alt={`${item.title} 3D Models`}
                      style={{ width: 140.4, height: 141.7 }}
                    />
                  ) : (
                    <Image
                      loading="lazy"
                      className="mb-4"
                      src={
                        item.image_file ? item.image_file : imagenotavailable
                      }
                      alt={`${item.title} 3D Models`}
                      style={{ width: 140.4, height: 141.7 }}
                    />
                  )}
                  <CardAction
                    className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1 text-white"
                    style={styles.cardAction}
                  >
                    {item.title}
                  </CardAction>
                </BCard>
              </a>
            </Breakpoint>
          </div>
        </Col>
      );

    case "admin_product":
      if (item) {
        fileExtensions = Object.keys(item?.file_statistics?.extension_stats);
      }
      return (
        <>
          <Col
            md={3}
            lg={2}
            sm={6}
            className="py-2 d-flex justify-content-center my-2"
            key={key}
            // as={Link}
            // to={`${param1 === "admin" ? "/admin" : ""}/product/${item.slug}`}
            style={{ textDecoration: "none" }}
          >
            <BCard className="position-relative" style={cardStyle} dark={true}>
              {!item?.is_archived && user?.is_superuser &&
                <RButton
                  ref={ref}
                  size="small"
                  className="position-absolute productArchiveButton trash-icon text-white px-2"
                  style={{
                    zIndex: 999,
                    borderRadius: "20px",
                    border: changingBorder ? "0px" : "0px",
                  }}
                  onMouseEnter={() => setChangingBroder(true)}
                  onMouseLeave={() => setChangingBroder(false)}
                  onClick={(e) => {
                    setIsDeleting(!isDeleting);
                    setTarget(e.target);
                  }}
                >
                  <FiTrash size={20} />
                </RButton>
              }
              <Overlay
                show={isDeleting}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
              >
                <Popover
                  style={{ backgroundColor: "#555", border: "1px solid white" }}
                >
                  <Popover.Header
                    className="text-white body-text1"
                    style={{ backgroundColor: "#333" }}
                  >
                    Confirm Product Delete?
                  </Popover.Header>
                  <Popover.Body className="d-flex">
                    <Button
                      dark={true}
                      onClick={() => {
                        setIsDeleting(false);
                        setReloadProducts(true);
                        // setIsDeletingProduct(!isDeletingProduct);
                        // handleArchiveProduct(item?.id);
                        dispatch(adminActions.archiveProduct(item?.id));
                      }}
                    >
                      {/* {isDeletingProduct ? (
                        <span>
                          <Spinner
                            // className="me-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          DELETING
                        </span>
                      ) : ( */}
                      <span className="poppins">CONFIRM</span>
                    </Button>
                    <Button
                      dark={true}
                      onClick={() => setIsDeleting(false)}
                      className=""
                    >
                      Cancel
                    </Button>
                  </Popover.Body>
                </Popover>
              </Overlay>
              <div
                className="d-flex h-100 justify-content-center align-items-center"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => {
                  if (param1 === "admin") {
                    navigate(`/admin/product/${item.slug}`);
                  } else {
                    navigate(`/product/${item.slug}`);
                  }
                }}
              >
                {type === "admin_product" && (
                  <>
                    <CardAction
                      className="m-0 p-2 text-center d-flex justify-content-center position-absolute bg-primary text-black subtitle px-3"
                      style={{
                        ...styles.cardAction,
                        top: 0,
                        bottom: "auto",
                        left: "auto",
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 10,
                      }}
                    >
                      {getSymbolFromCurrency(currency.name)}{" "}
                      {(item?.price * currency.rate).toFixed(2)}
                    </CardAction>
                    {item?.is_in_draft && (
                      <div
                        className="position-absolute"
                        style={{
                          backgroundColor: "white",
                          top: 10,
                          left: -20,
                          transform: "rotateY(0deg) rotate(-45deg)",
                          borderRadius: 20,
                        }}
                      >
                        <div
                          className="explore-text px-3"
                          style={{ backgroundColor: "white" }}
                        >
                          <div className="caption">In Draft</div>
                        </div>
                      </div>
                    )}
                    {!item?.is_in_draft && !item?.is_verified && (
                      <div
                        className="position-absolute"
                        style={{
                          backgroundColor: "white",
                          top: 25,
                          left: -30,
                          transform: "rotateY(0deg) rotate(-45deg)",
                          borderRadius: 20,
                        }}
                      >
                        <div
                          className="explore-text px-3"
                          style={{ backgroundColor: "white" }}
                        >
                          <div className="caption">Under Approval</div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {hover && breakpoint !== "xsmall" && (
                  <div
                    className="w-50 h-100 position-absolute p-2 py-4 text-start text-white"
                    style={{
                      backgroundColor: `rgba(0,0,0,0.7)`,
                      zIndex: 900,
                      top: 0,
                      right: 0,
                      borderTopRightRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                  >
                    <div className="small-title">Product Name</div>
                    <div className="body-text1 poppins-500 ">{item?.title}</div>
                    <div className="small-title mt-2">File Formats</div>
                    <div className="body-text1 poppins-500">
                      {fileExtensions?.map((extension) => {
                        return <>{extension} </>;
                      })}
                    </div>
                    <div className="small-title mt-2">Views</div>
                    <div className="body-text1 poppins-500">
                      {item?.view_count}
                    </div>
                    {/* <div className="small-title mt-2">Sold Count</div>
                    <div className="body-text1 poppins-500">{soldCount}</div> */}
                  </div>
                )}
                <Image
                  loading="lazy"
                  className="mb-4"
                  src={
                    item?.productimage_set[0]
                      ? item?.productimage_set[0]?.image_file
                      : imagenotavailable
                  }
                  alt={item.title}
                  style={imageStyle}
                />
                <CardAction
                  className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1 text-white card-action-wrap"
                  style={styles.cardAction}
                >
                  <span className="w-100">
                    {/* {item?.zsin ? item.title : ""} */}
                    {item?.zsin
                      ? item.title.length <= 38
                        ? item.title
                        : `${item.title.slice(0, 38)}...`
                      : ""}
                  </span>
                </CardAction>
              </div>
            </BCard>
          </Col>
        </>
      );

    default:
      return <div />;
  }
};

const styles = {
  ZCard: {
    width: 210,
    height: 244,
    border: "1px solid #3D3D3D",
    backgroundColor: "#141415",
    borderRadius: "10px",
  },
  ZMobileCard: {
    width: 150,
    height: 150,
    border: "1px solid #3D3D3D",
    backgroundColor: "#141415",
    borderRadius: "10px",
  },
  ZExploreMobileCard: {
    width: 140,
    height: 160,
    border: "1px solid #3D3D3D",
    backgroundColor: "#141415",
    borderRadius: "10px",
  },
  productMobileCard: {
    width: "100%",
    height: 160,
    backgroundColor: "#141415",
    border: "1px solid #3D3D3D",
    borderRadius: "10px",
  },
  imageCard: {
    width: 210,
    maxHeight: 200,
  },
  imageMobileCard: {
    width: 148,
    height: 160,
  },
  imageExploreMobileCard: {
    width: 126,
    height: 126,
    objectFit: "contain",
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  categoryCard: {
    width: 245,
    height: 280,
    backgroundColor: "#000000",
    borderRadius: 10,
    border: "1px solid #3D3D3D",
  },
  categoryMobileCard: {
    width: 156,
    height: 180,
    backgroundColor: "#000000",
    borderRadius: 8,
    border: "1px solid #3D3D3D",
  },
};

export default ZCard;
