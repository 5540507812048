import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { ZCard, SearchBarMyProduct } from "components";

import "core-ui/pages/Home.scss";
import * as actions from "actions/AdminActions";

const Categories = () => {
  let params = new URLSearchParams(document.location.search);
  let categoryTitle = params.get("category");
  //consolelog("categoryTitle", categoryTitle);

  const [categoryProducts, setCategoryProducts] = useState([]);
  const [categoryAllProducts, setCategoryAllProducts] = useState([]);

  useEffect(async () => {
    const response = await actions.searchCategoryProduct(categoryTitle);
    setCategoryAllProducts(response);
    setCategoryProducts(response);
  }, [categoryTitle]);

  // useEffect(async () => {
  //   if(categoryTitle){
  //     const response = await actions.searchCategoryProduct(categoryTitle);
  //     setCategoryProducts(response);
  //   }
  // }, [categoryTitle]);

  //consolelog("Category Products:", categoryProducts);

  return (
    <React.Fragment>
      <Container
        className="d-flex flex-column align-items-center h-100 text-center mt-5 pt-5 home-background-categories"
        fluid
        style={styles.parentContainer}
      >
        <Container className="px-5" style={{ height: "100%" }} fluid>
          {/* <Breadcrumb>
                        <Breadcrumb.Item href="#">Category</Breadcrumb.Item>
                        <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                            Cars
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Swift Dezire</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col sm={9}>
                            <Card className="m-2 d-flex flex-column align-items-center h-100 border-R20" dark={true}>
                                <CardContent className="productImage my-5">
                                    <Image
                                        loading="lazy"
                                        src={product}
                                        className="w-100"
                                    // style={styles.productImage}
                                    />
                                </CardContent>
                                <CardAction className="w-100 d-flex justify-content-end align-items-end">
                                    <IconButton size='large' color='var(--warning)' className="mx-3">
                                        <Image loading="lazy" src={view3d} />
                                    </IconButton>
                                </CardAction>
                            </Card>
                        </Col>
                        <Col sm={3}>
                            <Card className="m-2 p-0 py-4 h-100 d-flex flex-column justify-content-between border-R20" dark={true}>
                                <div className="d-flex bg-primary m-0 border-0 text-black p-2 align-items-center">
                                    <div className="h4 poppins-500 text-center">50% off</div>
                                    <div className="p-1 text-center">Offer ends in 12:09:43 hrs</div>
                                </div>
                                <div className="h2 text-primary pt-5 text-shadow">
                                    299
                                </div>
                                <div className="h4 text-info">
                                    <strike>599</strike>
                                </div>
                                <div>
                                    <Button
                                        className="explore-button my-4"
                                        dark={true}
                                        size="large">
                                        ADD TO CART
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                    </Row> */}
        </Container>
        <Container className="d-flex flex-column justify-content-center align-items-start">
          <SearchBarMyProduct
            type="main"
            uploadedProducts={categoryAllProducts}
            updateProducts={(filteredProducts) => {
              setCategoryProducts(filteredProducts);
            }}
          />
          <ul className="breadcrumb subtitle mx-4 mb-3">
            <li>
              <a href="/admin/categories">Categories</a>
            </li>
            <li>{categoryTitle}</li>
          </ul>
        </Container>
        <Container>
          <div className="w-100">
            {categoryProducts?.length !== 0 ? (
              <Row className="row-cols-5">
                {categoryProducts?.map((item, index) => {
                  return (
                    <ZCard
                      key={item}
                      index={index}
                      item={item}
                      type="product"
                    />
                  );
                })}
              </Row>
            ) : (
              <Row className="text-white">
                <h4>No Products added yet!</h4>
              </Row>
            )}
          </div>
        </Container>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  parentContainer90: {
    minHeight: "90vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  checkedIcons: {
    width: 20,
    height: 20,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
};

export default Categories;
