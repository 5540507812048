import React, { useState, useEffect } from "react";
import { Container, Row, Image, Button as RButton } from "react-bootstrap";
import { ZCard } from "components";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import * as actions from "actions/UserActions";
import { Breakpoint, useCurrentBreakpointName } from "react-socks";
import { znanyeloader } from "assets";
import { not_found_gif } from "assets";

const SearchResults = () => {
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  let params = new URLSearchParams(document.location.search);
  let searchWord = params.get("search");
  const breakpoint = useCurrentBreakpointName();

  const dispatch = useDispatch();

  let { productId } = useParams();

  const [products, setProducts] = useState(null);
  const [searchedProucts, setSearchedProducts] = useState(null)
  const [changingBorder, setChangingBroder] = useState(false);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [productsPresent, setProductsPresent] = useState(false);

  useEffect(async () => {
    let x = []
    dispatch(actions.loadProducts());
    const response = await actions.searchProduct(searchWord);
    if (response) {
      setProducts(response);
      response.map((pro) => x.push(pro.id))
      setSearchedProducts(x)
    } else {
      setProducts([])
      setSearchedProducts([])
      setDisplayedProducts(allProducts);
    }
  }, [searchWord]);

  const allProducts = useSelector((state) => state.userReducer.products);

  useEffect(() => {
    if (products && products.length > 0) {
      const filteredProducts = allProducts?.filter(
        (product) =>
          !products.some((item) => item.id === product.id) &&
          products.some((item) => item.category === product.category)
      );
      setDisplayedProducts(filteredProducts);
    } else {
      setDisplayedProducts(allProducts);
    }
  }, [products, allProducts]);

  // useEffect(async() => {
  //   if (searchedProucts){
  //     const response = await actions.itemsYouMightLike(searchWord, searchedProucts);
  //   }
  // }, [searchWord, searchedProucts])

  if (productId) {
    return <Outlet />;
  }

  return (
    <React.Fragment>
      <Container
        className={
          breakpoint != "xxlarge"
            ? "d-flex flex-column  justify-content-between  h-100 text-center mt-5 pt-lg-5  home-background-categories"
            : "d-flex flex-column align-items-between h-100 text-center mt-5 pt-lg-5  home-background-categories"
        }
        fluid
        style={styles.parentContainer}
      >
        <Breakpoint large up>
          <Container>
            <div className="w-100 mb-5 my-5 ">
              <h3 className="poppins-400 text-white search-label mb-lg-5  text-start">
                Showing Result for
                <br></br>
                <span className="text-primary"> "{searchWord}" </span>
              </h3>
              {products && <Row className="row-cols-5">
                {products.map((item, index) => {
                  return (
                    <ZCard
                      key={item.id}
                      index={index}
                      item={item}
                      type="product"
                    />
                  );
                })}
              </Row>}
              {products && <div
                className={`${products.length > 0 ? "d-none" : "text-white"}`}
                style={{ minHeight: "50vh" }}
              >
                <Image loading="lazy" src={not_found_gif} alt="Space Ship" />
                <div className="mb-5"> No result Found !</div>
                <a
                  className="text-decoration-none"
                  // to="/contact-us/#Customized-3D-model"
                  href="/contact-us/#Customized-3D-model"
                >
                  <RButton
                    className="mt-2 p-3 explore-border-button"
                    style={{
                      zIndex: 99,
                      backgroundColor: "#27292a",
                      border: 0,
                      outline: 0,
                      border: changingBorder ? "0px" : "0px",
                    }}
                    onMouseEnter={() => setChangingBroder(true)}
                    onMouseLeave={() => setChangingBroder(false)}
                  >
                    <span className="m-4 poppins text-white">
                      GET A CUSTOM 3D MODEL
                    </span>
                  </RButton>
                </a>
              </div>}
              {!products &&
                <div className="d-flex justify-content-center align-items-center w-100 h-100" style={{ minHeight: "50vh" }} >
                  <Image loading="lazy" src={znanyeloader} style={{ width: 60, height: 60 }} />
                </div>
              }
            </div>
          </Container>

          <Container>
            <div className="w-100 my-5">
              <h3 className="poppins-400 text-white search-label mb-lg-5 h4 text-start">
                Items you might like!
              </h3>
              <Row
                className={`${breakpoint === "xsmall" ? "" : "row-cols-5"}`}
              // className="row-cols-5"
              >
                {displayedProducts?.length > 0 ? (
                  displayedProducts.map((item, index) => {
                    return (
                      <ZCard
                        key={item.id}
                        index={index}
                        item={item}
                        type="product"
                      />
                    );
                  })
                ) : (
                  <div className="w-100 h-100">
                    <Image
                      loading="lazy"
                      src={znanyeloader}
                      alt="Znanye Loader"
                      style={{ width: 60, height: 60 }}
                    />
                    {/* <video muted autoPlay loop width={60} height={60}>
                    <source src={znanyeloader} type="video/webm" />
                    Your browser does not support the video tag.
                  </video> */}
                  </div>
                )}
              </Row>
            </div>
          </Container>
        </Breakpoint>

        <Breakpoint medium down>
          <Container>
            <div className="w-100 mb-5 my-5">
              <h3 className="poppins-500 text-white search-label mb-lg-5  text-start">
                Showing Result for
                <br></br>
                <span className="text-primary"> "{searchWord}" </span>
              </h3>
              {products && <Row>
                {products.map((item, index) => {
                  return (
                    <ZCard
                      key={item.id}
                      index={index}
                      item={item}
                      type="product"
                    />
                  );
                })}
              </Row>}
              {products && <div
                className={`${products.length > 0 ? "d-none" : "text-white"}`}
                style={{ minHeight: "50vh" }}
              >
                <div className="d-flex justify-content-center overflow-hidden">
                  <Image
                    loading="lazy"
                    src={not_found_gif}
                    // style={{ height: "300px", width: "300px" }}
                    alt="Space Ship"
                  />
                </div>
                <div className="mb-5">No result Found !</div>
                <a
                  className="text-decoration-none"
                  // to="/contact-us/#Customized-3D-model"s
                  href="/contact-us/#Customized-3D-model"
                >
                  <RButton
                    className="mt-2 p-3 explore-border-button"
                    style={{
                      zIndex: 99,
                      backgroundColor: "#27292a",
                      border: 0,
                      outline: 0,
                      border: changingBorder ? "0px" : "0px",
                    }}
                    onMouseEnter={() => setChangingBroder(true)}
                    onMouseLeave={() => setChangingBroder(false)}
                  >
                    <span className="m-4 poppins text-white">
                      GET A CUSTOM 3D MODEL
                    </span>
                  </RButton>
                </a>
              </div>}
              {!products &&
                <div className="d-flex justify-content-center align-items-center w-100 h-100" style={{ minHeight: "50vh" }} >
                  <Image loading="lazy" src={znanyeloader} style={{ width: 60, height: 60 }} />
                </div>
              }
            </div>
          </Container>
          <Container>
            <div className="w-100 my-5">
              <div className="poppins-500 text-white search-label mb-5 h3 text-start">
                Items you might like!
              </div>
              <Row
                // className={`${breakpoint === "xsmall" ? "" : "row-cols-5"
                //   }`}
                className=""
              >
                {displayedProducts.length > 0 ? (
                  displayedProducts.map((item, index) => {
                    return (
                      <ZCard
                        key={item.id}
                        index={index}
                        item={item}
                        type="product"
                      />
                    );
                  })
                ) : (
                  <div className="w-100 h-100">
                    <Image
                      loading="lazy"
                      src={znanyeloader}
                      alt="Znanye Loader"
                      style={{ width: 60, height: 60 }}
                    />
                    {/* <video muted autoPlay loop width={60} height={60}>
            <source src={znanyeloader} type="video/webm" />
            Your browser does not support the video tag.
          </video> */}
                  </div>
                )}
              </Row>
            </div>
          </Container>
        </Breakpoint>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
    weight: 500,
    fontSize: 20,
  },
  parentContainer90: {
    minHeight: "90vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  checkedIcons: {
    width: 20,
    height: 20,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
};

export default SearchResults;
