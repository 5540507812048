import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";

import ModuleDatePicker from "./ModuleDatePicker";
import ModuleCard from "./ModuleCard";
import ModuleTable from "./ModuleTable";
import * as adminActions from "actions/AdminActions";

import { MdFileUpload, MdGroup, MdOutlineRateReview } from "react-icons/md";
import { MdSell } from "react-icons/md";
import { GiReturnArrow } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { RiUserReceived2Fill } from "react-icons/ri";
import { FaUserTag } from "react-icons/fa";


const Statistics = () => {

    const today = new Date();
    const [startDateTime, setStartDateTime] = useState(moment(today.setHours(0, 0, 0, 0)).format("YYYY-MM-DDTHH:mm:ssZ"));
    const [endDateTime, setEndDateTime] = useState(moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"));
    const [cardType, setCardType] = useState(null);
    const [cardUrl, setCardUrl] = useState(null);

    const [data, setData] = useState(null);

    const fetchData = async () => {
        const response = await adminActions.dashboard({ startDateTime: startDateTime, endDateTime: endDateTime, metric: "count" })
        if (response) {
            setData(response)
        }
    }

    const productCardData = [
        {
            id: 1,
            cardTitle: "Products Uploaded",
            cardContentTitle1: "Total",
            cardContent1: data?.product_upload?.total_products || 0,
            cardContentTitle2: "Verified",
            cardContent2: data?.product_upload?.verified_products || 0,
            cardContentTitle3: "Archived",
            cardContent3: data?.product_upload?.archived_products || 0,
            type: "3",
            colSize: 3,
            dataUrl: "product_uploads",
            icon: <MdFileUpload size={30} />
        },
        {
            id: 2,
            cardTitle: "Products Sold",
            cardContentTitle1: "Total",
            cardContent1: data?.product_sold?.total_products || 0,
            cardContentTitle2: "Free",
            cardContent2: data?.product_sold?.free_products || 0,
            type: "2",
            colSize: 3,
            dataUrl: "product_sold",
            icon: <MdSell size={30} />
        },
        {
            id: 3,
            cardTitle: "Total Sellers",
            cardContentTitle1: "Total",
            cardContent1: data?.seller?.total_sellers || 0,
            cardContentTitle2: "Has Products",
            cardContent2: data?.seller?.has_products || 0,
            cardContentTitle3: "No Products",
            cardContent3: data?.seller?.no_products || 0,
            type: "3",
            colSize: 3,
            dataUrl: "sellers",
            icon: <MdGroup size={30} />
        },
        {
            id: 4,
            cardTitle: "Total Returns",
            cardContentTitle1: "Returns Approved",
            cardContent1: data?.order_refund?.approved_refunds || 0,
            cardContentTitle2: "Returns Pending",
            cardContent2: data?.order_refund?.pending_refunds || 0,
            type: "2",
            colSize: 3,
            icon: <GiReturnArrow size={30} />
        },
        {
            id: 5,
            cardTitle: "Review and Ratings",
            cardContentTitle1: "Total",
            cardContent1: data?.product_review_rating?.total_reviews || 0,
            cardContentTitle2: "Products Reviewed",
            cardContent2: data?.product_review_rating?.total_products_reviewed || 0,
            type: "2",
            colSize: 3,
            dataUrl: "review_ratings",
            icon: <MdOutlineRateReview size={30} />
        },
    ]

    const sessionCardData = [
        {
            id: 1,
            cardTitle: "Unique Visitors",
            cardContentTitle1: "Total",
            cardContent1: data?.unique_visitor?.total_visitors || 0,
            type: "1",
            colSize: 3,
            dataUrl: "unique_visitor",
            icon: <HiUserGroup size={30} />
        },
        {
            id: 2,
            cardTitle: "New/Returning Users",
            cardContentTitle1: "New",
            cardContent1: data?.new_returning_users?.new_users || 0,
            cardContentTitle2: "Returning",
            cardContent2: data?.new_returning_users?.returning_users || 0,
            type: "2",
            colSize: 3,
            dataUrl: "new_returning_users",
            icon: <RiUserReceived2Fill size={30} />
        },
        {
            id: 3,
            cardTitle: "New/Returning Cust.",
            cardContentTitle1: "New",
            cardContent1: data?.new_returning_customers?.new_customers || 0,
            cardContentTitle2: "Returning",
            cardContent2: data?.new_returning_customers?.returning_customers || 0,
            type: "2",
            colSize: 3,
            dataUrl: "new_returning_customers",
            icon: <FaUserTag size={30} />
        },
    ]

    useEffect(() => {
        if (startDateTime && endDateTime) {
            fetchData();
        }
    }, [startDateTime, endDateTime])

    return (
        <Container fluid>
            <Row>
                <Col sm={12}>
                    <Col sm={12} className="px-4 mt-5 d-flex align-items-center">
                        <p className="inter-600 font-size32 mt-5 p-3 text-white">Dashboard</p>
                    </Col>
                    <Row className="px-5">
                        <ModuleDatePicker startDateTime={startDateTime} endDateTime={endDateTime} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} fetchData={fetchData} />
                    </Row>
                    <div style={{ minHeight: '50vh' }}>
                        {!cardType ?
                            <>
                                <Row className="px-5">
                                    {/* <div className="text-white mt-4 p-0">User/Seller</div> */}
                                    {productCardData.map((cardData) => {
                                        return (
                                            <ModuleCard
                                                colSize={cardData?.colSize}
                                                cardTitle={cardData?.cardTitle}
                                                cardContentTitle1={cardData?.cardContentTitle1}
                                                cardContent1={<b>{cardData?.cardContent1}</b>}
                                                cardContentTitle2={cardData?.cardContentTitle2}
                                                cardContent2={<b>{cardData?.cardContent2}</b>}
                                                cardContentTitle3={cardData?.cardContentTitle3}
                                                cardContent3={<b>{cardData?.cardContent3}</b>}
                                                type={cardData?.type}
                                                dataUrl={cardData?.dataUrl}
                                                icon={cardData?.icon}
                                                setCardType={setCardType}
                                                setCardUrl={setCardUrl}
                                            />
                                        )
                                    })}
                                </Row>
                                <Row className="px-5">
                                    {sessionCardData.map((cardData) => {
                                        return (
                                            <ModuleCard
                                                colSize={cardData?.colSize}
                                                cardTitle={cardData?.cardTitle}
                                                cardContentTitle1={cardData?.cardContentTitle1}
                                                cardContent1={<b>{cardData?.cardContent1}</b>}
                                                cardContentTitle2={cardData?.cardContentTitle2}
                                                cardContent2={<b>{cardData?.cardContent2}</b>}
                                                cardContentTitle3={cardData?.cardContentTitle3}
                                                cardContent3={<b>{cardData?.cardContent3}</b>}
                                                type={cardData?.type}
                                                dataUrl={cardData?.dataUrl}
                                                icon={cardData?.icon}
                                                setCardType={setCardType}
                                                setCardUrl={setCardUrl}
                                            />
                                        )
                                    })}
                                </Row>
                            </>
                            :
                            <ModuleTable
                                startDateTime={startDateTime}
                                endDateTime={endDateTime}
                                cardTitle={cardType}
                                setCardType={setCardType}
                                cardUrl={cardUrl}
                                setCardUrl={setCardUrl}
                            />
                        }
                    </div>
                </Col>
            </Row>
            {/* <Row>
                <Col sm={12}>
                    <Col sm={12} className="px-4 mt-5 d-flex align-items-center">
                        <p className="inter-600 font-size32 m-0 p-3 text-white">Analytics</p>
                    </Col>
                    <Row className="px-5">
                        <ModuleDatePicker startDateTime={startDateTime} endDateTime={endDateTime} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} fetchData={fetchData} />
                    </Row>
                    <Row className="px-5">
                        <ModuleCard colSize={3}
                            cardTitle="Unique Visitors"
                            cardContentTitle1={"Users"}
                            cardContent1={<b>{21}</b>}
                            type="1"
                            icon={<FaProductHunt size={30} />}
                        />
                    </Row>
                </Col>
            </Row> */}
        </Container>
    );
};

export default Statistics;
