import React from "react";
import { useParams } from "react-router-dom";
import Error from "pages/Error/Error";

const ImageViewer = () => {
    let { productId } = useParams();
    let params = new URLSearchParams(document.location.search);
    let imagesrc = params.get("imagesrc")
    return (
        <Error />
        // <Container
        //     fluid
        //     className="d-flex flex-column align-items-center h-100 home-background-categories p-0"
        //     style={styles.parentContainer}
        // >
        //     <Container fluid className="position-relative">
        //         <div className="position-absolute p-2 shadow-lg" style={{ top: 20, right: 20, backgroundColor: "#323232", borderRadius: 50 }}>
        //             {/* <MdArrowBack color="#ccc" size="30"/> */}
        //             <Link to={`/product/${productId}`}>
        //                 <MdClose color="#ccc" size="30" />
        //             </Link>
        //         </div>
        //         <div className="position-absolute">
        //             <Image loading="lazy" className="my-3" src={logo} style={{ width: 50, height: 50 }} />
        //         </div>
        //     </Container>
        //     <Image
        //         loading="lazy"
        //         className="d-flex flex-grow-1"
        //         // style={{ borderRadius: 20 }}
        //         src={imagesrc}
        //         style={{ maxWidth: "100%", maxHeight: "100vh" }}
        //     />
        // </Container>
    );
};

const styles = {
    parentContainer: {
        minHeight: "100vh",
    },
};
export default ImageViewer;
