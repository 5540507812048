import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button as RButton,
  Spinner,
} from "react-bootstrap";
import { Button as NuButton, IconButton } from "ui-neumorphism";
// import { mobileloginicon } from "assets_old/admin";
import * as actions from "actions/AuthActions";
import * as userActions from "actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { loginstep1, loginstep2 } from "assets";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useMeasure from "react-use-measure";
import { useSpring, animated } from "@react-spring/web";
import AppleGoogleFacebookLogin from "components/LoginBox/AppleGoogleFacebookLogin";

import {
  znanyelogin,
  reset_password_mascot,
  login_mascot_mobile,
  create_account_mascot_mobile,
  celebrate_mascot,
  forgot_mascot_mobile,
  glad,
  happy,
  excited,
  login_background,
  email_sent_mobile,
  eye,
  eye_off,
} from "assets";
import { IoChevronDown } from "react-icons/io5";
import { Breakpoint, useCurrentBreakpointName } from "react-socks";
import Error from "pages/Error/Error";

const MobileLogin = () => {
  const [next, setNext] = useState(false);
  const [forOtp, setForOtp] = useState(false);
  const [username, setUsername] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [timer, setTimer] = useState(0);
  const [countries, setCountries] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [login, setLogin] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [emailSentNote, setEmailSentNote] = useState(false);
  const [emailResetNote, setEmailResetNote] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [changingBorder, setChangingBroder] = useState(false);
  const [resetSending, setResetSending] = useState(false);
  const [eyeOn, setEyeOn] = useState(false);
  const [eyeOnTwo, setEyeOnTwo] = useState(false);
  const uniqueCountries = [];

  const [password, setPassword] = useState(null);

  // Login animation
  const [loginAnimationWidth, setLoginAnimationWidth] = useState(60);
  const [ref, { width }] = useMeasure();
  const animationProps = useSpring({
    width: loginAnimationWidth,
  });

  const form = useRef(null);
  const userForm = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(actions.getMyProfile());
  // }, []);

  useEffect(async () => {
    const response = await userActions.loadCountries();
    setCountries(response);
  }, []);

  const cartProducts = useSelector(
    (state) => state.authReducer.user.cartproducts
  );
  const cartSize = cartProducts ? cartProducts?.length : 0;

  // useEffect(() => {
  //   dispatch(actions.getMyProfile());
  // }, [isAuthenticated]);

  useEffect(() => {
    dispatch(actions.getMyProfile(cartProducts));

    return () => {
      setTimer(0);
    };
  }, []);

  const user = useSelector((state) => state.authReducer.user);
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  useEffect(() => {
    if (isAuthenticated) {
      if (
        user.email != null &&
        user.email != "" &&
        user.name != null &&
        user.name != ""
      ) {
        navigate(-1);
      }
    }
  }, [isAuthenticated, user]);

  const onLogin = async (e) => {
    e.preventDefault();

    setIsSending(true);
    let verify = false;
    const formData = new FormData(form.current);
    const response = await actions.loggingIn(formData);
    if (response) {
      dispatch(actions.login(response));
      dispatch(actions.loadUserFavourite(response.user.id))
      dispatch(actions.loadUserSavedForLater(response.user.id));
      setIsSending(false);
      setLoginAnimationWidth(width);
    } else {
      setNext(true);
      setIsSending(false);
    }
  };

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(userForm.current);
    // const response = await actions.updateProfile(formData, user.id);
    // if (response) {
    //   dispatch(actions.profileUpdate(response));
    //   setLoginShow(false);
    // }
    // setIsSubmitting(false);
    if (formData.get("password") === formData.get("confirm_password")) {
      const response = await actions.signUp(formData);
      if (response) {
        switch (response.status) {
          case "ALREADY_EXISTS":
          case "PROVIDE_ALL_DETAILS":
            toast.error(response.msg);
            break;
          case "UNEXPECTED":
            toast.error(response.msg);
            break;
          case "SUCCESSFUL":
            // toast.success(response.msg);
            setCreateAccount(false);
            setEmailSentNote(true);
            // setAccountCreated(true);
            // setTimeout(() => {
            //   setLogin(true);
            //   setAccountCreated(false);
            // }, 5000);
            // const interval = setInterval(async () => {
            //   if (username != "") {
            //     const response = await actions.checkAccountVerification({
            //       username: username,
            //     });
            //     if (response.status) {
            //       clearInterval(interval)
            //       setEmailSentNote(false);
            //       setAccountCreated(true)
            //   },1000}
            const interval = setInterval(async () => {
              if (username != "") {
                const response = await actions.checkAccountVerification({
                  username: username,
                });
                if (response.status) {
                  clearInterval(interval);
                  setEmailSentNote(false);
                  setAccountCreated(true);
                }
              }
            }, 10000);

            break;
          default:
            break;
        }
      }
    } else {
      toast.error("Password and Confirm Password doesn't match!");
    }
    setIsSubmitting(false);
  };

  return (
    <React.Fragment>
      <Breakpoint large up>
        <Error />
      </Breakpoint>

      <Container
        className={`h-100 ${emailSentNote || emailResetNote
          ? "mobile-email-sent-background"
          : "mobile-auth-background"
          } pt-5 d-flex flex-column ${emailSentNote || accountCreated || emailResetNote
            ? "justify-content-center"
            : "justify-content-between"
          }`}
        fluid
        style={styles.parentContainer}
      >
        <div>
          <div className="mt-5">
            {login && (
              <span className="text-white h4" style={{ fontWeight: 275 }}>
                Happy to see you again
              </span>
            )}
            {forgotPassword && (
              <span className="text-white h4" style={{ fontWeight: 275 }}>
                Don't worry! we've got your back
              </span>
            )}
            {createAccount && (
              <span
                className="text-white body-text2 "
                style={{ fontWeight: 275 }}
              >
                We are happy that you choose us!
              </span>
            )}
            {login && (
              <Image
                loading="lazy"
                className="mx-2"
                style={{ width: 22 }}
                src={excited}
                alt="Excited"
              />
            )}
            {forgotPassword && (
              <Image
                loading="lazy"
                className="mx-2"
                style={{ width: 22 }}
                src={happy}
                alt="Happy"
              />
            )}
            {createAccount && (
              <Image
                loading="lazy"
                className="mx-2"
                style={{ width: 22 }}
                src={glad}
                alt="Glad"
              />
            )}
          </div>
          <Form
            ref={form}
            className={`text-start ${login ? "d-block" : "d-none"}`}
            onSubmit={(e) => onLogin(e)}
          >
            <Form.Group className="w-100">
              <Form.Label className="text-white subtitle mt-4">
                Login To Znanye
              </Form.Label>
              <p className="text-white caption">
                Buy 3D models, Made By Awesome Artists
              </p>

              <AppleGoogleFacebookLogin
                buyer={true}
                seller={false}
                type="mobile"
              />

              <div className="d-flex flex-column pt-4">
                <Form.Control
                  name="username"
                  type="email"
                  size="lg"
                  placeholder="Enter Email"
                  className="mb-3 text-white"
                  style={{
                    background: "#141414",
                    height: 50,
                    borderColor: "#3D3D3D",
                    borderRadius: 10,
                  }}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
                <Form.Group className="d-flex justify-content-end align-items-center position-relative mb-4">
                  <Form.Control
                    name="password"
                    type={`${eyeOn ? "text" : "password"}`}
                    size="lg"
                    placeholder="Enter Password"
                    className="text-white"
                    style={{
                      background: "#141414",
                      height: 50,
                      borderColor: "#3D3D3D",
                      borderRadius: 10,
                    }}
                    // onKeyDown={handleKeyDown}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  {!eyeOn && (
                    <RButton
                      type="button"
                      className="wrapped-button position-absolute mx-2"
                      style={{ backgroundColor: "#141414", border: 0 }}
                      onKeyDown={(e) => e.preventDefault()}
                      onClick={(e) => {
                        e.preventDefault();
                        setEyeOn(true);
                      }}
                    >
                      <Image loading="lazy" src={eye} alt="Eye" />
                    </RButton>
                  )}
                  {eyeOn && (
                    <RButton
                      type="button"
                      className="wrapped-button position-absolute mx-2"
                      style={{ backgroundColor: "#141414", border: 0 }}
                      onKeyDown={(e) => e.preventDefault()}
                      onClick={(e) => {
                        e.preventDefault();
                        setEyeOn(false);
                      }}
                    >
                      <Image loading="lazy" src={eye_off} alt="Eye Off" />
                    </RButton>
                  )}
                </Form.Group>
                <div className="text-white text-end my-2">
                  <RButton
                    className="wrapped-button caption"
                    onClick={() => {
                      setLogin(false);
                      setForgotPassword(true);
                      setCreateAccount(false);
                      setEmailSentNote(false);
                      setEmailResetNote(false);
                      setAccountCreated(false);
                    }}
                    variant="link"
                  >
                    Forgot Password ?
                  </RButton>
                </div>
              </div>
            </Form.Group>
            <div className="text-center w-100">
              <RButton
                className={
                  " d-flex justify-content-center align-items-center my-4 body-text2 explore-button-no-neo btn btn-ptimary w-100 explore-button-large"
                }
                disabled={isSending}
                style={{
                  border: changingBorder ? "0px" : "0px",
                  borderRadius: "8px",
                  height: "48px",
                }}
                onMouseEnter={() => setChangingBroder(true)}
                onMouseLeave={() => setChangingBroder(false)}
                size="large"
                dark={true}
                onClick={async (e) => {
                  e.preventDefault();
                  setIsSending(true);
                  let email_regex =
                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                  if (username.match(email_regex)) {
                    const response = await actions.loggingIn({
                      email: username,
                      password: password,
                    });
                    if (response) {
                      // setIsSending(false);
                      dispatch(actions.login(response));
                      dispatch(actions.loadUserFavourite(response.user.id))
                      dispatch(actions.loadUserSavedForLater(response.user.id));
                      // setNext(false);
                      // setTimer(response.time_left);
                    }
                  } else {
                    // setIsSending(false);
                    toast.error("Please enter a valid email!");
                  }
                  setIsSending(false);
                }}
              >
                {isSending ? (
                  <span>
                    <Spinner
                      className="me-2"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    LOGGING ...
                  </span>
                ) : (
                  // <BootstrapButton
                  // className="mt-5 explore-button-no-neo"
                  // dark={true}
                  // size="lg"
                  // >
                  <span className="poppins flex">GET ME IN</span>
                )}
              </RButton>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="text-white caption">
                Don't have an account? Don't worry!{" "}
              </div>
              <RButton
                className="wrapped-button caption"
                onClick={() => {
                  setCreateAccount(true);
                  setForgotPassword(false);
                  setLogin(false);
                  setEmailSentNote(false);
                  setEmailResetNote(false);
                  setAccountCreated(false);
                }}
                variant="link"
              >
                CREATE AN ACCOUNT
              </RButton>
            </div>
          </Form>
          <Form
            className={`${forgotPassword && !isAuthenticated ? "d-block" : "d-none"
              }`}
          >
            <Form.Group className="w-100">
              <Form.Label className="text-white subtitle mt-4">
                Forgot Password
              </Form.Label>
              <p className="text-white caption">
                Enter Your Registered Email Id
              </p>
              <br />
              <div className="d-flex flex-column">
                <Form.Control
                  name="username"
                  type="email"
                  size="lg"
                  placeholder="Enter your Email"
                  className="text-white"
                  style={{
                    background: "#141414",
                    height: 50,
                    borderColor: "#3D3D3D",
                    borderRadius: 10,
                  }}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </div>
            </Form.Group>
            <div className="text-center">
              <RButton
                className="my-5 w-100 explore-button-no-neo btn btn-ptimary"
                dark={true}
                disabled={resetSending}
                size="lg"
                style={{ borderRadius: "8px" }}
                onClick={async (e) => {
                  e.preventDefault();
                  setResetSending(true);

                  let email_regex =
                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                  // alert(username)
                  if (username.match(email_regex)) {
                    const response = await actions.reset_password_request({
                      email: username,
                    });
                    if (response) {
                      // console.log(response);
                      switch (response.status) {
                        case "DOES_NOT_EXIST":
                          toast.error(response.msg);
                          break;
                        case "EMAIL_SENT":
                          setEmailResetNote(true);
                          setForgotPassword(false);
                          setEmailSentNote(false);
                          setTimeout(() => {
                            setEmailResetNote(false);
                            setLogin(true);
                          }, 8000);
                          break;
                        default:
                          alert("some error");
                      }
                    }
                  } else {
                    setResetSending(false);

                    toast.error("Please enter a valid email!");
                  }
                  setResetSending(false);
                }}
              >
                {resetSending ? (
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    SENDING...
                  </span>
                ) : (
                  <span className="poppins">SEND ME A RESET LINK</span>
                )}
              </RButton>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="text-white caption">Already have an account </div>
              <RButton
                className="wrapped-button caption"
                onClick={() => {
                  setForgotPassword(false);
                  setLogin(true);
                  setCreateAccount(false);
                  setEmailSentNote(false);
                  setEmailResetNote(false);
                  setAccountCreated(false);
                }}
                variant="link"
              >
                Log In Here
              </RButton>
              <div
                className={`${timer != 0 ? "text-white mx-2 caption" : "d-none"
                  }`}
              >
                {`after ${timer} seconds`}
              </div>
            </div>
          </Form>
          <Form
            ref={userForm}
            className={`${createAccount && !isAuthenticated ? "d-block" : "d-none"
              }`}
            onSubmit={(e) => handleProfileSubmit(e)}
          >
            <Form.Group className="w-100" controlId="formDetails">
              <Form.Label className="text-white subtitle mt-4">
                Create Account
              </Form.Label>
              <p className="text-white caption">Enter Your Details</p>
              <br />

              <AppleGoogleFacebookLogin
                buyer={true}
                seller={false}
                type="mobile"
              />

              <div className="d-flex">
                <Form.Control
                  required
                  name="name"
                  placeholder="Name"
                  type="text"
                  size="lg"
                  className="mb-4 text-white"
                  style={{
                    background: "#141414",
                    height: 50,
                    borderColor: "#3D3D3D",
                    borderRadius: 10,
                  }}
                />
              </div>
              <Form.Control
                required
                name="email"
                type="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                placeholder="Email"
                size="lg"
                className="mb-4 text-white"
                style={{
                  background: "#141414",
                  height: 50,
                  borderColor: "#3D3D3D",
                  borderRadius: 10,
                }}
                onInvalid={(e) => {
                  return e.target.setCustomValidity(
                    "Please enter valid email id."
                  );
                }}
                onChange={(e) => {
                  // console.log("##",e.target.value)
                  setUsername(e.target.value);
                  return e.target.setCustomValidity("");
                }}
              />
              <div className="position-relative d-flex justify-content-end align-items-center mb-4">
                <Form.Select
                  required
                  name="phone_code"
                  type="text"
                  className="my-select text-white subtitle pe-0 py-2"
                  style={{
                    // background: "#141414",
                    borderColor: "#3D3D3D",
                    borderRadius: 10,
                  }}
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  <option
                    key=""
                    value=""
                    selected
                  >
                    -- Select Region --
                  </option>
                  {countries
                    ?.filter((option) => {
                      if (
                        !uniqueCountries.some(
                          (country) => country.name === option.name
                        )
                      ) {
                        uniqueCountries.push(option);
                        return true;
                      }
                      return false;
                    })
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((option) => {
                      return (
                        <option
                          key={option.phone_code}
                          value={option.phone_code}
                        >
                          {option.name}
                        </option>
                      );
                      // if (option.code === "IN") {
                      //   return (
                      //     <option
                      //       key={option.phone_code}
                      //       value={option.phone_code}
                      //       selected
                      //     >
                      //       {option.name}
                      //     </option>
                      //   );
                      // } else {
                      //   return (
                      //     <option
                      //       key={option.phone_code}
                      //       value={option.phone_code}
                      //     >
                      //       {option.name}
                      //     </option>
                      //   );
                      // }
                    })}
                </Form.Select>
                {/* <IoChevronDown
                  color="#FFF"
                  size={20}
                  className="position-absolute mx-3"
                /> */}
              </div>
              <Form.Group className="d-flex justify-content-end align-items-center position-relative mb-4">
                <Form.Control
                  required
                  name="password"
                  type={`${eyeOn ? "text" : "password"}`}
                  size="lg"
                  placeholder="Password"
                  className=" text-white"
                  style={{
                    background: "#141414",
                    height: 50,
                    borderColor: "#3D3D3D",
                    borderRadius: 10,
                  }}
                />
                {!eyeOn && (
                  <RButton
                    type="button"
                    className="wrapped-button position-absolute mx-1"
                    style={{ backgroundColor: "#141414", border: 0 }}
                    onKeyDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                      setEyeOn(true);
                    }}
                  >
                    <Image loading="lazy" src={eye} alt="Eye" />
                  </RButton>
                )}
                {eyeOn && (
                  <RButton
                    type="button"
                    className="wrapped-button position-absolute mx-1"
                    style={{ backgroundColor: "#141414", border: 0 }}
                    onKeyDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                      setEyeOn(false);
                    }}
                  >
                    <Image loading="lazy" src={eye_off} alt="Eye Off" />
                  </RButton>
                )}
              </Form.Group>
              <Form.Group className="d-flex justify-content-end align-items-center position-relative mb-4">
                <Form.Control
                  required
                  name="confirm_password"
                  type={`${eyeOnTwo ? "text" : "password"}`}
                  size="lg"
                  placeholder="Confirm Password"
                  className="text-white"
                  style={{
                    background: "#141414",
                    height: 50,
                    borderColor: "#3D3D3D",
                    borderRadius: 10,
                  }}
                />
                {!eyeOnTwo && (
                  <RButton
                    type="button"
                    className="wrapped-button position-absolute mx-1"
                    style={{ backgroundColor: "#141414", border: 0 }}
                    onKeyDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                      setEyeOnTwo(true);
                    }}
                  >
                    <Image loading="lazy" src={eye} alt="Eye" />
                  </RButton>
                )}
                {eyeOnTwo && (
                  <RButton
                    type="button"
                    className="wrapped-button position-absolute mx-1"
                    style={{ backgroundColor: "#141414", border: 0 }}
                    onKeyDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                      setEyeOnTwo(false);
                    }}
                  >
                    <Image loading="lazy" src={eye_off} alt="Eye Off" />
                  </RButton>
                )}
              </Form.Group>
            </Form.Group>
            <div className="text-center">
              <RButton
                className="btn btn-link border-0 my-4 wrapped-button w-100 p-0"
                style={{
                  background: "transparent",
                  textDecoration: "none",
                }}
                type="submit"
              >
                <RButton
                  className="mt-2 explore-button-no-neo w-100 br-10 btn btn-primary d-flex justify-content-center align-items-center"
                  style={{
                    border: changingBorder ? "0px" : "0px",
                    borderRadius: "8px",
                  }}
                  onMouseEnter={() => setChangingBroder(true)}
                  onMouseLeave={() => setChangingBroder(false)}
                  size="lg"
                  dark={true}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span>
                      <Spinner
                        // className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      SUBMITTING ...
                    </span>
                  ) : (
                    <span className="poppins">CREATE MY ACCOUNT</span>
                  )}
                </RButton>
              </RButton>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="text-white mx-3 caption">
                Already have an account?{" "}
              </div>
              <RButton
                className="wrapped-button caption"
                onClick={() => {
                  setForgotPassword(false);
                  setLogin(true);
                  setCreateAccount(false);
                  setEmailSentNote(false);
                  setAccountCreated(false);
                }}
                variant="link"
              >
                Log in
              </RButton>
              <div
                className={`${timer != 0 ? "text-white mx-2 caption" : "d-none"
                  }`}
              >
                {`after ${timer} seconds`}
              </div>
            </div>
          </Form>
        </div>
        <div className="h-100 d-flex align-items-center justify-content-center">
          {login && <Image loading="lazy" src={login_mascot_mobile} alt="Login Mascot" />}
          {forgotPassword && (
            <Image loading="lazy" src={forgot_mascot_mobile} alt="Password Forgot Mascot" />
          )}
          {createAccount && (
            <Image
              loading="lazy"
              src={create_account_mascot_mobile}
              alt="Create Account Mascot"
            />
          )}
        </div>
        {/* <Image
          loading="lazy"
          className={`${emailSentNote && !isAuthenticated
            ? "d-flex flex-column justify-content-center align-items-center h-100"
            : "d-none"
            }`} style={{ width: 140 }} src={email_sent_mobile} alt="Mail"
        /> */}
        <div
          className={`${emailSentNote && !isAuthenticated
            ? "d-flex flex-column justify-content-center align-items-center h-100"
            : "d-none"
            }`}
        >
          <Image loading="lazy" style={{ width: 140 }} src={email_sent_mobile} alt="Mail" />
          <p className="text-white poppins-500 " style={{ fontSize: "18px" }}>
            We have sent you a verification link
          </p>
          <div
            className="text-white h4 m-4 d-flex flex-column justify-content-center align-items-center "
            style={{ fontWeight: 500, lineHeight: "36px" }}
          >
            <div className="text-white poppins-500 h3 ">
              Kindly Verify your email
            </div>
            <div className="text-white poppins-500 h3 ">
              <b>
                <i>{username}</i>
              </b>
            </div>
          </div>
        </div>
        <div
          className={`${emailResetNote && !isAuthenticated
            ? "d-flex flex-column justify-content-center align-items-center h-100"
            : "d-none"
            }`}
        >
          <Image loading="lazy" style={{ width: 140 }} src={email_sent_mobile} alt="Mail" />
          <div
            className="text-white text-center d-flex align-items-center justify-content-center h4 m-4 poppins-400"
            style={{ fontSize: "18px" }}
          >
            <p>
              We have shared a link for reset your password on your email Id
              &nbsp;
              <b>
                <i>{username}</i>
              </b>
              &nbsp; Kindly check your email.
            </p>
          </div>
        </div>
        <div
          className={`${accountCreated && !isAuthenticated
            ? "d-flex justify-content-center align-items-center h-100"
            : "d-none"
            }`}
        >
          <div
            className="text-white text-center d-flex flex-column justify-content-center align-items-center h4 my-4 mx-1"
            style={{ fontWeight: 400 }}
          >
            <div
              className="m-4"
              style={{
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "36px",
              }}
            >
              Yeahhh !!! Account Has Been Created
            </div>
            <div
              className="m-4"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "21px",
              }}
            >
              You Are Now The Part Of The Znanye Members
            </div>
            <Image loading="lazy" src={celebrate_mascot} alt="Celebrate Mascot" />
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
};

export default MobileLogin;
