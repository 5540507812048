import React, { useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const StatisticsMain = () => {
    return (
        <React.Fragment>
            <Breakpoint large up>
                <Container
                    className="d-flex flex-column align-items-center h-100 mt-5 pt-5 text-white"
                    // fluid
                    style={styles.parentContainer}
                >
                    <div>tejas</div>
                </Container>
            </Breakpoint>
        </React.Fragment>
    );
};


const styles = {
    parentContainer: {
        minHeight: "100vh",
    },
    parentContainer90: {
        minHeight: "90vh",
    },
    parentContainer85: {
        minHeight: "85vh",
    },
    parentContainerDark: {
        minHeight: "85vh",
        backgroundColor: "#141415",
    },
    categoryCard: {
        width: 245,
        height: 280,
    },
    checkedIcons: {
        width: 20,
        height: 20,
    },
    submitButton: {
        width: 250,
        height: 50,
    },
    cardAction: {
        backgroundColor: "#333333",
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    fileNames: {
        backgroundColor: "#141414",
    },
    priceCard: {
        borderLeft: "1px solid #4e4e4e",
        marginTop: "10px",
        marginBottom: "10px",
        color: "#555555",
    },
    selectedModeButton: {
        width: "115px",
        backgroundColor: "#fff",
        border: "none",
        color: "black",
    },
    unselectedModeButton: {
        width: "115px",
        backgroundColor: "#0D0D0D",
        border: "none",
        color: "white",
    },
};

export default StatisticsMain;
