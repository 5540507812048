import React, { useRef, useState, useEffect } from "react";

import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { view3d, view3d_znanye, yellowstar, view3dnew } from "assets";

import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const CustomSlider = ({
  items,
  setItems,
  selectCallback,
  type,
  selected,
  deleteCallback,
  setUploadedImageIndex,
  is_threed_enabled,
}) => {
  // console.log("Product Image items:", items);
  const [dragImages, updateDragImages] = useState(items);
  const scrollRef = useRef(null);
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(() => updateDragImages(items), [items]);

  // const [selected, setSelected] = useState(0);
  const [ismouseHovered, setIsmouseHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState("");
  const [clickedImageIndex, setclickedImageIndex] = useState([]);
  const [isImageClicked, setIsImageClicked] = useState(false);
  const [displayImages, setDisplayImages] = useState([]);
  // const [imagemouseHovered, setImagemouseHovered] = useState();
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
    setIsmouseHovered(true);
  };
  const handleMouseLeave = () => {
    setHoveredIndex("");
    setIsmouseHovered(false);
  };
  const handleImageindex = (index) => {
    setUploadedImageIndex(index);
    setclickedImageIndex(index);
    setIsImageClicked(true);
  };

  // useEffect(() => updateDragImages(items), [items]);

  // const [selected, setSelected] = useState(0);

  const scroll = async (scrollOffset) => {
    // console.log(scrollRef);
    let counter = 0;
    let absScrollOffset = scrollOffset < 0 ? -1 * scrollOffset : scrollOffset;
    // alert(scrollOffset);
    while (counter < absScrollOffset) {
      scrollOffset > 0
        ? (scrollRef.current.scrollLeft += 20)
        : (scrollRef.current.scrollLeft -= 20);
      counter += 20;
      await sleep(10);
    }
  };

  // useEffect(() => {
  //   if (selectCallback) {
  //     selectCallback(selected);
  //   }
  // }, [selected]);

  if (items?.length === 0 || items === null) {
    return <div />;
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const imgs = Array.from(dragImages);
    // console.log({ imgs });

    const [recordedImgs] = imgs.splice(result.source.index, 1);
    imgs.splice(result.destination.index, 0, recordedImgs);
    // console.log({ imgs });
    updateDragImages(imgs);

    setItems(imgs);
  };

  return (
    <Container className="p-0 d-flex non-selectable flex-row justify-content-start align-items-center px-3 container-fluid">
      {dragImages.length > 6 && (
        <div
          className="col-1 d-flex justify-content-center align-items-center text-white"
          style={styles.iconStyle}
          onClick={(e) => {
            e.preventDefault();
            scroll(-200);
          }}
        >
          <FaChevronLeft />
        </div>
      )}

      <div
        className="col"
        style={{ overflow: "scroll", maxWidth: 1120 + "px" }}
        ref={scrollRef}
      >
        {type === "upload_images" ? (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable
              droppableId="upload_images_droppable"
              direction="horizontal"
            >
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="d-flex my-4 mx-3 py-3 user-select-none"
                  style={{
                    overflowX: "scroll",
                    overflowY: "hidden",
                    minWidth: "fit-content",
                  }}
                >
                  {dragImages.map((item, index) => {
                    if (index === 0 && type === "image_3d_slider") {
                      return (
                        <div
                          style={
                            selected === index
                              ? styles.imageContainerSelected
                              : styles.imageContainer
                          }
                          className="me-4"
                          key={index}
                          onClick={() => {
                            selectCallback(index);
                          }}
                        >
                          <div
                            className="d-flex justify-content-center align-items-center me-0"
                            dark={true}
                            style={styles.sliderCard}
                          >
                            <Image
                              loading="lazy"
                              src={view3d_znanye}
                              alt="View 3D Znanye"
                              style={{ width: 40, height: 40 }}
                            />
                          </div>
                        </div>
                      );
                    }
                    return (
                      <Draggable
                        draggableId={
                          item?.id
                            ? item.id.toString()
                            : index.toString() + "imgs" + item?.name
                        }
                        key={
                          item?.id
                            ? item.id.toString()
                            : index.toString() + "imgs" + item?.name
                        }
                        index={index}
                      >
                        {(provided) => (
                          <Card
                            onClick={() => {
                              if (selectCallback) {
                                selectCallback(index);
                              }
                            }}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="me-4  border-R8 d-flex justify-content-center align-items-center draggable-images-background"
                          // style={
                          //   selected === index
                          //   ? styles.imageContainerSelected
                          //   : styles.imageContainer
                          // }
                          >
                            <div
                              className="d-flex justify-content-center align-items-center position-relative"
                              dark={true}
                              style={{
                                width: 100 + "%",
                                height: 100 + "%",
                                border:
                                  isImageClicked &&
                                  clickedImageIndex === index &&
                                  "2px solid white",
                                borderRadius:
                                  isImageClicked &&
                                  clickedImageIndex === index &&
                                  "8px",
                              }}
                            >
                              <Image
                                loading="lazy"
                                className="border-R8"
                                src={
                                  type === "upload_images"
                                    ? item?.id
                                      ? item?.thumbnail_file ||
                                      item?.image_file
                                      : item && URL.createObjectURL(item)
                                    : item?.thumbnail_file || item?.image_file
                                }
                                alt={item?.title}
                                style={styles.sliderCardImageUploadImages}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onClick={() => handleImageindex(index)}
                                onBlur={() => handleMouseLeave()}
                              />
                              {type === "upload_images" && (
                                <>
                                  <div
                                    className="position-absolute border-R8"
                                    style={{ right: 0, top: 0 }}
                                  >
                                    {index === 0 && (
                                      <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          borderRadius: "8px",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          deleteCallback(index);
                                        }}
                                      >
                                        <Image
                                          loading="lazy"
                                          src={yellowstar}
                                          alt="yellow-star"
                                        />
                                      </div>
                                    )}
                                  </div>

                                  <div
                                    className="position-absolute border-R8"
                                    style={{ left: 0, top: 0 }}
                                  >
                                    {ismouseHovered &&
                                      hoveredIndex === index && (
                                        <div
                                          className="d-flex justify-content-center align-items-center"
                                          style={styles.iconStyleDelete}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            deleteCallback(index);
                                          }}
                                        >
                                          <AiOutlineDelete
                                            style={{
                                              backgroundColor: "#1f1f1f",
                                              fontSize: 20,
                                              color: "white",
                                            }}
                                          />
                                        </div>
                                      )}
                                  </div>
                                </>
                              )}
                            </div>
                            {provided.placeholder}
                          </Card>
                        )}
                      </Draggable>
                      // </div>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div
            className="d-flex my-4 mx-3 py-3 user-select-none"
            style={{
              overflowX: "scroll",
              overflowY: "hidden",
              minWidth: "fit-content",
            }}
          >
            {items?.map(
              (item, index) => {
                // console.log({ item });
                if (index === 0 && type === "image_3d_slider") {
                  if (!is_threed_enabled) {
                    return null;
                  }
                  return (
                    <div
                      style={
                        selected === index
                          ? styles.imageContainerSelected
                          : styles.imageContainer
                      }
                      className="me-4 border-R20"
                      key={index}
                      onClick={() => {
                        selectCallback(index);
                      }}
                    >
                      <div
                        className="d-flex justify-content-center align-items-center me-0"
                        // dark={true}
                        style={styles.sliderCard}
                      >
                        <Image
                          loading="lazy"
                          src={view3dnew}
                          alt="View 3D Znanye"
                          style={{ width: 70, height: 70 }}
                        />
                      </div>
                    </div>
                  );
                }
                return (
                  <Card
                    onClick={() => {
                      if (selectCallback) {
                        selectCallback(index);
                      }
                    }}
                    className="me-4 h-100 border-R20 d-flex justify-content-center align-items-center"
                    style={
                      selected === index
                        ? styles.imageContainerSelected
                        : styles.imageContainer
                    }
                  >
                    <div
                      className="d-flex justify-content-center align-items-center position-relative"
                      dark={true}
                      style={styles.sliderCard}
                    >
                      <Image
                        loading="lazy"
                        className="border-R20"
                        src={
                          type === "upload_images"
                            ? item?.id
                              ? item?.thumbnail_file || item?.image_file
                              : item && URL.createObjectURL(item)
                            : item?.thumbnail_file || item?.image_file
                        }
                        alt={item?.title}
                        style={styles.sliderCardImage}
                      />
                      {type === "upload_images" && (
                        <div
                          className="position-absolute border-R20"
                          style={{
                            backgroundColor: "#2E2E2E",
                            right: 0,
                            top: -20,
                          }}
                        >
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={styles.iconStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              deleteCallback(index);
                            }}
                          >
                            <AiOutlineClose style={{ color: "white" }} />
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>
                );
              }
              // </div>
            )}
          </div>
        )}
      </div>

      {items.length > 6 && (
        <div
          className="col-1 d-flex justify-content-center align-items-center text-white"
          style={styles.iconStyle}
          onClick={(e) => {
            e.preventDefault();
            scroll(200);
          }}
        >
          <FaChevronRight />
        </div>
      )}
    </Container>
  );
};

const styles = {
  sliderCard: {
    width: 100 + "%",
    height: 100 + "%",
  },
  sliderCardImage: {
    maxWidth: 118,
    maxHeight: 118,
  },
  sliderCardImageUploadImages: {
    maxHeight: 118,
    width: 118,
  },
  imageContainer: {
    backgroundColor: "#141415",
    border: "1px solid #3D3D3D",
    width: 120,
    minHeight: 120,
    cursor: "pointer",
  },
  imageContainerSelected: {
    backgroundColor: "#141415",
    border: "1px solid #fff",
    width: 120,
    overflowX: "scroll",
    overflowY: "hidden",
    minHeight: 120,
    cursor: "pointer",
  },
  iconStyle: {
    width: "40px",
    height: "40px",
    border: "1px solid #3D3D3D",
    borderRadius: "20px",
    backgroundColor: "#292929",
    cursor: "pointer",
  },
  iconStyleDelete: {
    width: "40px",
    height: "40px",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "#292929",
  },
};

export default CustomSlider;
