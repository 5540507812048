// import axios from "axios";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";

const axios = require("axios").default;

const notify = (msg, type) => {
  switch (type) {
    case "success":
      toast.success(msg);
      break;
    case "error":
      toast.error(msg);
      break;
    case "warning":
      toast.warning(msg);
      break;
    default:
      toast.error(msg);
  }
  // alert();
};

////////////////////////// COUPON - Action Creator //////////////////////////

export const couponLoad = (data) => {
  return {
    type: "LOAD_ALL_COUPON",
    payload: data,
  };
};

export const couponAdd = (data) => {
  return {
    type: "ADD_COUPON",
    payload: data,
  };
};

export const couponUpdate = (data) => {
  return {
    type: "UPDATE_COUPON",
    payload: data,
  };
};

export const couponDelete = (id) => {
  return {
    type: "DELETE_COUPON",
    payload: id,
  };
};

////////////////////////// CATEGORY - Action Creator //////////////////////////

export const categoryLoad = (data) => {
  return {
    type: "LOAD_ALL_CATEGORY",
    payload: data,
  };
};

export const categoryAdd = (data) => {
  return {
    type: "ADD_CATEGORY",
    payload: data,
  };
};

export const categoryUpdate = (data) => {
  return {
    type: "UPDATE_CATEGORY",
    payload: data,
  };
};

export const categoryPartialUpdate = (data) => {
  return {
    type: "PARTIAL_UPDATE_CATEGORY",
    payload: data,
  };
};

export const categoryDelete = (id) => {
  return {
    type: "DELETE_CATEGORY",
    payload: id,
  };
};

////////////////////////// PRODUCT - Action Creator //////////////////////////

export const productLoad = (data) => {
  return {
    type: "LOAD_ALL_PRODUCT",
    payload: data,
  };
};

export const productAdd = (data) => {
  return {
    type: "ADD_PRODUCT",
    payload: data,
  };
};

export const productUpdate = (data) => {
  return {
    type: "UPDATE_PRODUCT",
    payload: data,
  };
};

export const productPartialUpdate = (data) => {
  return {
    type: "PARTIAL_UPDATE_PRODUCT",
    payload: data,
  };
};

export const archive_product = (data) => {
  return {
    type: "ARCHIVE_PRODUCT",
    payload: data.id,
  };
};

////////////////////////// DRAFT PRODUCT - Action Creator //////////////////////////

export const draftProductLoad = (data) => {
  return {
    type: "LOAD_ALL_DRAFT_PRODUCT",
    payload: data,
  };
};

export const draftProductUpdate = (data) => {
  return {
    type: "UPDATE_DRAFT_PRODUCT",
    payload: data,
  };
};

////////////////////////// COUPON - Actions //////////////////////////

export const loadCoupons = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/coupon/`);
      // console.log("Response", response);
      dispatch(couponLoad(response.data));
    } catch (err) {
      // console.log("Error", err);
      notify("Coupons could not be loaded!!", "error");
    }
  };
};

export const createCoupon = (data, clearForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/coupon/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("Response", response);
      if (response.status === 201) {
        clearForm();
        dispatch(couponAdd(response.data));
        notify("Coupon created!", "success");
      }
    } catch (err) {
      // console.log("Error", err);
      notify("Coupon could not be created!", "error");
    }
  };
};

export const updateCoupon = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `/coupon/` + data.get("id") + `/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Response", response);
      if (response.status === 200) {
        dispatch(couponUpdate(response.data));
        notify("Coupon updated!", "success");
      }
    } catch (err) {
      // console.log("Error", err);
      notify("Coupon could not be updated!", "error");
    }
  };
};

export const deleteCoupon = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/coupon/` + id + `/`);
      dispatch(couponDelete(id));
      notify("Coupon deleted!", "success");
    } catch (err) {
      // console.log("Error", err);
      notify("Coupon could not be deleted!", "error");
    }
  };
};

////////////////////////// CATEGORY - Actions //////////////////////////

export const loadCategories = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/category/`);
      // console.log("Response", response);
      dispatch(categoryLoad(response.data));
    } catch (err) {
      // console.log("Error", err);
      notify("Categories could not be loaded!!", "error");
    }
  };
};

export const createCategory = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/category/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log("Response", response);
      dispatch(categoryAdd(response.data));
      notify("Category created!", "success");
    } catch (err) {
      // console.log("Error", err);
      notify("Category could not be created!", "error");
    }
  };
};

export const updateCategory = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `/category/` + data.get("id") + `/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("Response", response);
      dispatch(categoryUpdate(response.data));
      notify("Category updated!", "success");
    } catch (err) {
      // console.log("Error", err);
      notify("Category could not be updated!", "error");
    }
  };
};

export const deleteCategory = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/category/` + id + `/`);
      dispatch(categoryDelete(id));
      notify("Category deleted!", "success");
    } catch (err) {
      // console.log("Error", err);
      notify("Category could not be deleted!", "error");
    }
  };
};

export const partialUpdateCategory = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `/category/` + data.get("id") + `/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Response", response);
      dispatch(categoryPartialUpdate(response.data));
      notify("Category updated!", "success");
    } catch (err) {
      // console.log("Error", err);
      notify("Category could not be updated!", "error");
    }
  };
};

////////////////////////// PRODUCT - Actions //////////////////////////

export const loadProducts = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/product/?is_archived=false`);
      // console.log("Response", response);
      dispatch(productLoad(response.data));
    } catch (err) {
      // console.log("Error", err);
      notify("Products could not be loaded!", "error");
    }
  };
};

export const loadAdminProducts = async (url) => {
  try {
    const response = await axios.get(`/product/?is_archived=false`);
    // console.log("Response", response);
    if (response.status === 200) {
      return response.data
    }
    // dispatch(productLoad(response.data));
  } catch (err) {
    // console.log("Error", err);
    notify("Products could not be loaded!", "error");
  }
};

export const loadPaginatedAdminProducts = async (url) => {
  try {
    const response = await axios.post(url);
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    notify("Products could not be loaded!", "error");
  }
};

const validateFormData = (formData) => {
  // const keys = formData.keys();
  const check_keys = [
    "geometry",
    "software_used",
    // "currency",
    "unwrapped_uvs",
    "category",
    "viewer_extension",
  ];
  for (let i in check_keys) {
    if (
      formData.get(check_keys[i]) == "undefined" ||
      formData.get(check_keys[i]) == undefined ||
      formData.get(check_keys[i]) == null
    ) {
      notify("Field '" + check_keys[i] + "' should not be empty", "error");
      return false;
    }
  }
  return true;
};

export const createProduct = async (data) => {
  // console.log("formData", data)
  // return async (dispatch) => {
  // console.log(data.get("geometry"));
  try {
    if (validateFormData(data)) {
      const response = await axios.post(`/product/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("Response", response);
      if (response.status === 200) {
        // notify("Product created successfully!", "success");
        return response.data;
      }
      // dispatch(productAdd(response.data));
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Product could not be created!", "error");
  }
  // };
};

export const updateProduct = async (productId, data) => {
  // console.log("formData", data)
  // return async (dispatch) => {
  // console.log(data.get("tags"));
  try {
    const response = await axios.put(`/product/${productId}/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    if (response.status === 200) {
      // notify("Product updated successfully!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    // notify("Product could not be updated!", "error");
    notify("Fill All Required Details!", "error");

  }
  // };
};

export const update_editorValues = async (productId, data) => {
  // console.log("formData", data)
  // return async (dispatch) => {
  // console.log(data.get("tags"));
  try {
    const response = await axios.post(`/product/${productId}/update_editorValues/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    if (response.status === 200) {
      notify("Product updated successfully!", "success");
      // return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Product could not be updated!", "error");
  }
  // };
};

export const approveProduct = async (productId, data) => {
  // return async (dispatch) => {
  // console.log(data.get("tags"));
  try {
    const response = await axios.put(`/product/${productId}/approve/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    if (response.status === 200) {
      // notify("Product updated successfully!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Product could not be updated!", "error");
  }
  // };
};


export const archiveProduct = (productId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/product/archive_product/`,
        { product_id: productId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Response", response);
      if (response.status === 200) {
        notify("Product removed successfully!", "success");
        dispatch(archive_product(response.data));
      }
    } catch (err) {
      // console.log("Error", err);
      notify("Product could not be removed!", "error");
    }
  };
};

export const cancelVerification = (productId) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `/product/cancel_verification/`,
        { product_id: productId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Response", response);
      if (response.status === 200) {
        notify("Verification cancelled successfully!", "success");
        dispatch(productUpdate(response.data));
      }
    } catch (err) {
      // console.log("Error", err);
      notify("Product could not cancelled!", "error");
    }
  };
};

export const partialUpdateProduct = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `/product/` + data.get("id") + `/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Response", response);
      dispatch(productPartialUpdate(response.data));
      notify("Coupons updated successfully!", "success");
    } catch (err) {
      // console.log("Error", err);
      notify("Coupons could not be updated!", "error");
    }
  };
};

export const searchCategoryProduct = async (data) => {
  // return async () => {
  try {
    const response = await axios.get(`/product/?category__title=` + data);
    // console.log("Response", response);
    // dispatch(categoryProductSearch(response.data));
    return response.data;
  } catch (err) {
    // console.log("Error", err);
  }
  // };
};

export const getProduct = async (productId) => {
  try {
    const response = await axios.get("/product/" + productId + "/");
    // console.log("Response", response);
    return response.data;
  } catch (err) {
    // console.log("Error", err);
  }
};

export const createTag = async (data) => {
  try {
    const response = await axios.post(`/tag/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    notify("Tag created successfully!", "success");
    return response.data;
  } catch (err) {
    // console.log("Error", err);
    notify("Tag could not be created!", "error");
  }
};

export const getTags = async (data) => {
  try {
    const response = await axios.get(`/tag/`);
    return response.data;
  } catch (err) {
    // console.log("Error", err);
    notify("Tags could not be fetched!", "error");
  }
};

////////////////////////// DRAFT PRODUCT - Actions //////////////////////////

export const loadDraftProducts = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/product/productdraft/`);
      // console.log("Response", response);
      dispatch(draftProductLoad(response.data));
    } catch (err) {
      // console.log("Error", err);
      notify("Draft Products could not be loaded!", "error");
    }
  };
};

export const updateDraftProduct = async (productId, data) => {
  // return async (dispatch) => {
  // console.log(data.get("tags"));
  try {
    const response = await axios.put(`/product/productdraft/${productId}/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    if (response.status === 200) {
      // notify("Product updated successfully!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Product could not be updated!", "error");
  }
  // };
};

////////////////////// PAYOUTS ////////////////////////

export const payouts = async (data) => {
  try {
    const response = await axios.post(`/order/payouts/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    // console.log("Error", err.response);
    return 0;
  }
};

export const export_payouts = async (data) => {
  try {
    const response = await axios.post(`/user/payouts/export_csv/`, data, {
      responseType: "blob",
    });
    fileDownload(response.data, "export.csv");
  } catch (err) {
    // console.log("Error", err.response);
  }
};


////////////////////// SELLER PRODUCTS ////////////////////////

export const approved_products = async () => {
  try {
    const response = await axios.post(`/product/approved_products/`, {}, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    if (response.status === 200) {
      // notify("Product updated successfully!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Product could not be loaded!", "error");
  }
}

export const pending_approvals = async () => {
  try {
    const response = await axios.post(`/product/pending_approvals/`, {}, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    if (response.status === 200) {
      // notify("Product updated successfully!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Product could not be loaded!", "error");
  }
}

export const drafts = async () => {
  try {
    const response = await axios.post(`/product/drafts/`, {}, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    if (response.status === 200) {
      // notify("Product loaded successfully!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Product could not be loaded!", "error");
  }
}

export const sellerProducts = async () => {
  try {
    const response = await axios.post(`/product/seller_products/`);
    // console.log("Response", response);
    if (response.status === 200) {
      // notify("Product loaded successfully!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Products could not be loaded!", "error");
  }
}

////////////////////// BLOGS - Actions ////////////////////////

export const loadBlogs = async () => {
  try {
    const response = await axios.get(`/blog/`);
    // console.log("Response", response);
    if (response.status === 200) {
      // notify("Product loaded successfully!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Blogs could not be loaded!", "error");
  }
}

export const loadBlog = async (id) => {
  try {
    const response = await axios.get(`/blog/${id}/`);
    // console.log("Response", response);
    if (response.status === 200) {
      // notify("Product loaded successfully!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Blogs could not be loaded!", "error");
  }
}


export const createBlog = async (data) => {
  try {
    const response = await axios.post(`/blog/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    if (response.status === 201) {
      notify("Blog created successfully!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Blog could not be created!", "error");
  }
}

export const updateBlog = async (slug, data) => {
  try {
    const response = await axios.put(`/blog/${slug}/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    if (response.status === 200) {
      notify("Blog updated successfully!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Blog could not be created!", "error");
  }
}

export const deleteBlog = async (id) => {
  try {
    const response = await axios.delete(`/blog/${id}/`);
    // console.log("Response", response);
    if (response.status === 204) {
      notify("Blog deleted successfully!", "success");
      return true;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Blog could not be created!", "error");
    return false;
  }
}

////////////////////// BLOG TYPES - Actions ////////////////////////

export const loadBlogTypes = async () => {
  try {
    const response = await axios.get(`/blog/blogtype/`);
    // console.log("Response", response);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Blog Types could not be loaded!", "error");
  }
}

export const loadBlogType = async (id) => {
  try {
    const response = await axios.get(`/blog/blogtype/${id}/`);
    // console.log("Response", response);
    if (response.status === 200) {
      // notify("Product loaded successfully!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Blogs could not be loaded!", "error");
  }
}

export const loadBlogTypeBlogs = async (id, order) => {
  try {
    const response = await axios.get(`/blog/blogtype/${id}/blog_type_blogs/?ordering=${order}`);
    // console.log("Response", response);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (err) {
    // console.log("Error", err);
  }
}

////////////////////// STATISTICS ////////////////////////

export const dashboard = async (data) => {
  try {
    const response = await axios.post(`/dashboard/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify("Could not fetch dashboard data!", "error");
  }
}

export const fetchTableData = async (url, title, data) => {
  try {
    const response = await axios.post(`/dashboard/${url}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(`Could not fetch ${title} data!`, "error");
  }
}