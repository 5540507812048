import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Card, CardAction, IconButton, Button } from "ui-neumorphism";

import {
  Image,
  Badge,
  Col,
  Card as BCard,
  Button as RButton,
} from "react-bootstrap";

import {
  category,
  product,
  grabup,
  imagenotavailable,
  external_link,
} from "assets";
// import { entrygate } from "assets_old/business";
import { entrygate } from "assets/business";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentBreakpointName } from "react-socks";
import { Breakpoint } from "react-socks";
import getSymbolFromCurrency from "currency-symbol-map";
import { useDispatch, useSelector } from "react-redux";
import { ZCard } from "components";
import axios from "axios";
import { Button as BootstrapButton } from "react-bootstrap";

import "./ZSlider.scss";

const NextArrow = (props) => {
  const {
    className,
    style,
    currentSlide,
    slideCount,
    onClick,
    type,
    breakpoint,
    slidesToShow,
  } = props;
  // console.log("##", props)
  let isDisabled = false;
  // const [slideToScroll, setIsSlidetoScroll] = useState(0);
  // if (type === "category") {
  //   setIsSlidetoScroll(4);
  // }
  if (slidesToShow == 1) {
    if (currentSlide === slideCount - 1) {
      isDisabled = true;
    }
  } else {
    if (currentSlide === slideCount - slidesToShow) {
      isDisabled = true;
    }
  }

  return (
    <BootstrapButton
      variant="primary"
      disabled={isDisabled}
      style={{
        backgroundColor: "#1b1c1d",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        border: "1px solid #3D3D3D",
        marginLeft: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: isDisabled ? "0%" : "100%",
        color: "#fff",
      }}
      onClick={onClick}
    >
      <FaChevronRight />
    </BootstrapButton>
  );
};

const PrevArrow = (props) => {
  // console.log(props)
  const {
    className,
    style,
    currentSlide,
    slideCount,
    onClick,
    type,
    breakpoint,
  } = props;

  const isDisabled = currentSlide === 0;
  return (
    <BootstrapButton
      variant="primary"
      disabled={isDisabled}
      style={{
        backgroundColor: "#1b1c1d",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        border: "1px solid #3D3D3D",
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: isDisabled ? "0%" : "100%",
        color: "#fff",
      }}
      onClick={onClick}
    >
      {<FaChevronLeft />}
    </BootstrapButton>
  );
};

const ZSlider = ({ type, items = [1, 2, 3, 4, 5, 6, 7, 8] }) => {
  const breakpoint = useCurrentBreakpointName();
  const navigate = useNavigate();

  let slidesToShow = 4;
  let slidesToScroll = 1;
  let infinite = false;
  let centerMode = false;
  let className = "";

  const [currentSlidesToShow, setCurrentSlidesToShow] = useState(slidesToShow);

  let nextArrow = <NextArrow slidesToShow={currentSlidesToShow} />;
  let prevArrow = <PrevArrow slidesToShow={currentSlidesToShow} />;
  let arrows = true;
  let dots = false;

  // console.log(items);

  const [oldSlide, setOldSlide] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlide1, setActiveSlide1] = useState(0);
  const currency = useSelector((state) => state.authReducer.currency);
  const [hovered, setHovered] = useState(false);
  const [changingBorder, setChangingBroder] = useState(false);

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  switch (type) {
    case "carousel":
      slidesToShow = 1;
      slidesToScroll = 1;
      if (breakpoint === "xsmall") {
        arrows = false;
      }
      break;
    case "advertise":
      slidesToShow = 3;
      slidesToScroll = 1;
      if (breakpoint === "xsmall") {
        arrows = false;
      }
      break;
    case "grabup":
      if (breakpoint === "xsmall") {
        arrows = false;
        slidesToShow = 1;
        slidesToScroll = 1;
      } else {
        slidesToShow = 2;
        slidesToScroll = 1;
      }
      break;
    case "product-images":
      slidesToShow = 6;
      slidesToScroll = 1;
      break;
    case "admin-uploads":
      slidesToShow = 1;
      slidesToScroll = 1;
      break;
    case "business":
      slidesToShow = 1;
      slidesToScroll = 1;
      // alert(breakpoint);
      if (breakpoint === "xsmall") {
        arrows = false;
      } else {
        arrows = true;
      }
      break;
    case "casestudy":
      if (breakpoint === "xsmall") {
        arrows = false;
        slidesToShow = 1;
        slidesToScroll = 1;
      } else {
        arrows = true;
        slidesToShow = 3;
        slidesToScroll = 1;
      }
      break;
    case "review":
      if (breakpoint === "xsmall") {
        arrows = false;
        slidesToShow = 1;
        slidesToScroll = 1;
      } else {
        arrows = true;
        slidesToShow = 3;
        slidesToScroll = 1;
      }
      break;
      // infinite = true;
      // centerMode = true;
      // className = "centerModeReviewCard";
      // className = "center";
      // centerMode = true;
      break;
    case "category":
      if (breakpoint === "xsmall") {
        arrows = false;
        slidesToShow = 1;
        slidesToScroll = 1;
      } else {
        slidesToShow = 4;
        slidesToScroll = 1;
      }
      break;
    case "mobile-product":
      arrows = false;
      slidesToShow = 1;
      slidesToScroll = 1;
      dots = true;
    default:
      if (breakpoint === "xsmall") {
        arrows = false;
        slidesToShow = 1;
        slidesToScroll = 1;
      } else {
        arrows = true;
        slidesToShow = 4;
        slidesToScroll = 1;
      }
      break;
  }

  let settingsMobile = {
    className: className,
    centerMode: centerMode,
    dots: dots,
    infinite: infinite,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    nextArrow: nextArrow,
    prevArrow: prevArrow,
    variableWidth: true,
    arrows: arrows,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (current, next) => {
      setOldSlide(current);
      setActiveSlide(next);
    },
    afterChange: (current) => {
      setActiveSlide1(current);
    },
    customPaging: (i) => {
      return (
        <div
          className={`custom-dot ${i === activeSlide ? "active" : ""}`}
          style={{
            backgroundColor: "white",
            width: i === activeSlide ? "8px" : "4px",
            height: i === activeSlide ? "8px" : "4px",
            borderRadius: "50%",
          }}
        ></div>
      );
    },
    appendDots: (dots) => (
      <div
        className="custom-dots-list"
        style={{
          background: "black",
          width: "100px",
          height: "14px",
          overflow: "scroll",
          top: "200px",
          display: "flex",
          alignItems: "center",
          borderRadius: "7px",
          border: "1px solid #3D3D3D",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ul
          style={{
            listStyle: "none",
            height: "0px !important",
            margin: 0,
            padding: "0 2px",
            display: "flex",
            overflowX: "auto",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
  };

  let settings = {
    className: className,
    centerMode: centerMode,
    dots: dots,
    infinite: infinite,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    // centerPadding: "10px",
    nextArrow: nextArrow,
    prevArrow: prevArrow,
    variableWidth: true,
    arrows: arrows,

    // variableHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (current, next) => {
      setOldSlide(current);
      setActiveSlide(next);
    },
    afterChange: (current) => {
      setActiveSlide1(current);
    },
  };

  useEffect(() => {
    setCurrentSlidesToShow(slidesToShow);
  }, [slidesToShow]);
  // console.log(slidesToShow);
  // console.log(slidesToScroll);

  if (type === "business") {
    // alert(items.length);
    return (
      <Slider
        {...settings}
        className="text-white d-flex justify-content-start align-items-center w-95 mx-xl-5"
      >
        {items.map((item, index) => {
          return (
            <>
              <Breakpoint large up>
                <div
                  className="p-lg-3 d-flex flex-column justify-content-center  align-items-center px-xl-5"
                  key={index}
                >
                  <BCard
                    className="position-relative mx-lg-5 mx-3 my-4 p-0 overflow-hidden cursor-pointer"
                    dark={true}
                    flat
                    style={{ borderRadius: 25, backgroundColor: "#1f1f1f", }}
                    onClick={() => {
                      window.location.href = item.link;
                    }}
                  >
                    <a href={item.link} style={{ textDecoration: "none" }}>
                      <Image
                        style={{ width: 880, }}
                        loading="lazy"
                        src={item.image}
                        alt={item.title}
                        className="businessSliderImage p-0"
                      />
                      <CardAction
                        className="m-2 p-2 text-center d-flex justify-content-center position-absolute body-text1 text-white"
                        style={styles.cardActionOpacity}
                      >
                        {item.title}
                      </CardAction>
                    </a>
                  </BCard>
                  <a href={item.link} style={{ textDecoration: "none" }}>
                    <BootstrapButton
                      className="mt-5 explore-button-no-neo"
                      dark={true}
                      size="lg"
                    >
                      <span className="poppins">VIEW CASE STUDY</span>
                    </BootstrapButton>
                  </a>
                </div>
              </Breakpoint>
              <Breakpoint medium down>
                <BCard className="position-relative mx-2 my-4" dark={true}>
                  <div className="d-flex justify-content-center align-items-center">
                    <Image
                      src={item.image}
                      alt={item.title}
                      style={{ width: 300 }}
                      loading="lazy"
                      className="cursor-pointer p-0 m-0"
                      onClick={() => {
                        window.location.href = item.link;
                      }}
                    />
                  </div>
                  <CardAction
                    className="py-1 text-center d-flex justify-content-center position-absolute body-text1 text-white"
                    style={styles.cardActionOpacity}
                  >
                    {item.title}
                  </CardAction>
                </BCard>
              </Breakpoint>
            </>
          );
        })}
        {/* <Breakpoint medium down>
          {items.map((item, index) => {
            return (
              <div
                className="p-lg-3 d-flex flex-column justify-content-center px-xl-5"
                key={index}
              >
                <Card className="position-relative mx-lg-5 mx-1 my-4 p-0 overflow-hidden" dark={true} flat style={{ borderRadius: 25 }}>
                  <Image
                    style={{ width: 900 }}
                    src={item.image}
                    loading="lazy"
                    className="businessSliderImage cursor-pointer p-0"
                    onClick={() => {
                      window.location.href = item.link;
                    }}
                  />
                  <CardAction
                    className="m-2 p-2 text-center d-flex justify-content-center position-absolute body-text1"
                    style={styles.cardActionOpacity}
                  >
                    {item.title}
                  </CardAction>
                </Card>
                <a href={item.link} style={{ textDecoration: "none" }}>
                  <Button
                    className="explore-button my-2 user-select-none"
                    dark={true}
                    size="large"
                  >
                    <span className="poppins">VIEW CASE STUDY</span>
                  </Button>
                </a>
              </div>
            );
          })}
        </Breakpoint> */}
      </Slider>
    );
  }

  return (
    <Slider
      {...(type === "mobile-product" ? settingsMobile : settings)}
      className="text-white w-100 d-flex justify-content-center align-items-center"
    >
      {items.map((item, index) => {
        switch (type) {
          case "carousel":
            const bestCoupon = item?.coupons_data.find(
              (coupon) => coupon.id === item.best_coupon
            );
            return (
              <>
                <Breakpoint xlarge up>
                  <div
                    className="py-5 d-flex justify-content-center"
                    key={item.id}
                  >
                    <BCard
                      className="position-relative mx-5 d-flex flex-column justify-content-center align-items-center"
                      dark={true}
                      style={styles.carouselCardLargeFix}
                      flat
                    // filter={[{ property: 'display', value: 'flex' }, { property: 'justify-content', value: 'center' }, { property: 'align-items', value: 'center' }]}
                    >
                      <div className="flex-grow-1 iframe-preview">
                        <div className="iframe-hover">
                          {/* <iframe
                            src={`${axios.defaults.baseURL}viewer/trending/${item.id}`}
                            width="750"
                            height="500"
                          /> */}
                          <Image
                            src={
                              item?.productimage_set?.length > 0
                                ? item?.productimage_set[0].image_file
                                : imagenotavailable
                            }
                            alt={item.title}
                            style={{
                              maxWidth: 400,
                              maxHeight: 400,
                              borderRadius: 20,
                            }}
                            loading="lazy"
                            onClick={
                              () =>
                                handleNavigationClick(
                                  `/3dmodels/${item?.category_data?.slug}/${item.slug}`
                                )
                            }
                          />
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="d-flex justify-content-center">
                          <BCard
                            className="position-relative d-flex flex-row p-2 my-4"
                            style={{
                              backgroundColor: "#292929",
                              borderRadius: "10px",
                              border: "1px solid #3D3D3D",
                            }}
                          >
                            <div className="d-flex flex-column align-items-start mx-3 text-start">
                              <div className="subtitle">{item.title}</div>
                              <div className="caption">
                                {item.category_data.title}
                              </div>
                            </div>
                            <div className="d-flex align-items-center mx-3">
                              <p
                                className={`${bestCoupon ? "d-block h3" : "d-none"
                                  } text-primary m-1 poppins-400`}
                              >
                                {getSymbolFromCurrency(currency?.name)}
                                {bestCoupon?.coupon_category === "ABSOLUTE"
                                  ? (
                                    (item?.price - bestCoupon?.flat_off) *
                                    currency?.rate
                                  ).toFixed(2)
                                  : (
                                    (item?.price -
                                      (item?.price * bestCoupon?.flat_off) /
                                      100) *
                                    currency?.rate
                                  ).toFixed(2)}
                              </p>
                              <p
                                className={`${bestCoupon ? "d-none" : "d-block h3"
                                  } text-primary m-1 poppins-400`}
                              >
                                {getSymbolFromCurrency(currency?.name)}{" "}
                                {(item.price * currency?.rate).toFixed(2)}
                              </p>
                              <div className="m-1">
                                <div>
                                  <Badge
                                    bg="primary"
                                    className={`${bestCoupon ? "d-block" : "d-none"
                                      } text-black body-text2`}
                                  >
                                    {bestCoupon?.coupon_category === "ABSOLUTE"
                                      ? "Flat " +
                                      getSymbolFromCurrency(currency?.name) +
                                      (
                                        bestCoupon?.flat_off * currency?.rate
                                      ).toFixed(2) +
                                      " OFF"
                                      : bestCoupon?.flat_off + "% OFF"}
                                  </Badge>
                                </div>
                                <div
                                  className={`${bestCoupon ? "d-block" : "d-none"
                                    } body-text2`}
                                >
                                  <strike>
                                    {getSymbolFromCurrency(currency?.name)}{" "}
                                    {(item.price * currency?.rate).toFixed(2)}
                                  </strike>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center mx-3">
                              <a
                                href={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                                style={{ textDecoration: "none" }}
                                onClick={() =>
                                  handleNavigationClick(
                                    `/3dmodels/${item?.category_data?.slug}/${item.slug}`
                                  )
                                }
                              >
                                <RButton
                                  className="my-1 explore-button-no-neo"
                                  variant="dark"
                                  size="lg"
                                  style={{
                                    border: changingBorder ? "0px" : "0px",
                                  }}
                                  onMouseEnter={() => setChangingBroder(true)}
                                  onMouseLeave={() => setChangingBroder(false)}
                                >
                                  <span className="poppins">SEE PRODUCT</span>
                                </RButton>
                              </a>
                            </div>
                          </BCard>
                        </div>
                      </div>
                    </BCard>
                  </div>
                </Breakpoint>
                <Breakpoint large>
                  <div
                    className="py-5 d-flex justify-content-center"
                    key={item.id}
                  >
                    <Card
                      className="position-relative mx-5 mx-lg-0 px-lg-0 d-flex flex-column justify-content-center align-items-center"
                      dark={true}
                      style={
                        breakpoint === "large"
                          ? styles.carouselCardLarge
                          : styles.carouselCard
                      }
                      // style={styles.carouselCardLarge}
                      flat
                    >
                      <div className="flex-grow-1 iframe-preview">
                        <div className="iframe-hover">
                          <iframe
                            src={`${axios.defaults.baseURL}viewer/trending/${item.id}`}
                            width="500"
                            height="350"
                            loading="lazy"
                          />
                        </div>
                        {/* <Image
                          loading="lazy"
                          src={
                            item.productimage_set.length != 0
                              ? item.productimage_set[0]?.image_file
                              : imagenotavailable
                          }
                          className="mb-4"
                          style={styles.carouselCardImage}
                        /> */}
                      </div>
                      <div>
                        <p className="caption mb-2">
                          Click & Drag
                          <br />
                          to rotate
                        </p>
                        <div className="d-flex justify-content-center">
                          <Card
                            className="position-relative d-flex justify-content-between p-2 my-4"
                            dark={true}
                          >
                            <div className="d-flex flex-column align-items-start mx-3 text-start">
                              <div className="subtitle">{item.title}</div>
                              <div className="caption">
                                {item.category_data.title}
                              </div>
                            </div>
                            <div className="d-flex align-items-center mx-3">
                              <h3
                                className={`${bestCoupon ? "d-block" : "d-none"
                                  } text-primary m-1`}
                              >
                                {getSymbolFromCurrency(currency?.name)}
                                {bestCoupon?.coupon_category === "ABSOLUTE"
                                  ? (
                                    (item?.price - bestCoupon?.flat_off) *
                                    currency?.rate
                                  ).toFixed(2)
                                  : (
                                    (item?.price -
                                      (item?.price * bestCoupon?.flat_off) /
                                      100) *
                                    currency?.rate
                                  ).toFixed(2)}
                              </h3>
                              <h3
                                className={`${bestCoupon ? "d-none" : "d-block"
                                  } text-primary m-1`}
                              >
                                {getSymbolFromCurrency(currency?.name)}{" "}
                                {(item.price * currency?.rate).toFixed(2)}
                              </h3>
                              <div className="m-1">
                                <div>
                                  <Badge
                                    bg="primary"
                                    className={`${bestCoupon ? "d-block" : "d-none"
                                      } text-black body-text2`}
                                  >
                                    {bestCoupon?.coupon_category === "ABSOLUTE"
                                      ? "Flat " +
                                      getSymbolFromCurrency(currency?.name) +
                                      (
                                        bestCoupon?.flat_off * currency?.rate
                                      ).toFixed(2) +
                                      " OFF"
                                      : bestCoupon?.flat_off + "% OFF"}
                                  </Badge>
                                </div>
                                <div
                                  className={`${bestCoupon ? "d-block" : "d-none"
                                    } body-text2`}
                                >
                                  <strike>
                                    {getSymbolFromCurrency(currency?.name)}{" "}
                                    {(item.price * currency?.rate).toFixed(2)}
                                  </strike>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center mx-3">
                              <a
                                href={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                                style={{ textDecoration: "none" }}
                                onClick={() =>
                                  handleNavigationClick(
                                    `/3dmodels/${item?.category_data?.slug}/${item.slug}`
                                  )
                                }
                              >
                                <RButton
                                  className="my-1 explore-button-no-neo"
                                  variant="dark"
                                  size="lg"
                                  style={{
                                    border: changingBorder ? "0px" : "0px",
                                  }}
                                  onMouseEnter={() => setChangingBroder(true)}
                                  onMouseLeave={() => setChangingBroder(false)}
                                >
                                  <span className="poppins">SEE PRODUCT</span>
                                </RButton>
                              </a>
                            </div>
                          </Card>
                        </div>
                      </div>
                    </Card>
                  </div>
                </Breakpoint>
                <Breakpoint medium down>
                  <BCard
                    className="mx-2 mb-3 h-100"
                    dark={true}
                    style={{
                      width: 300,
                      backgroundColor: "#1b1c1d",
                      borderRadius: 8,
                      border: "1px solid #3D3D3D",
                    }}
                  >
                    <div className="my-2 pt-3 pb-2 text-primary d-flex justify-content-center align-items-center">
                      Trending
                    </div>
                    <div
                      className="m-0 border-0 text-white p-2 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#333333",
                        width: 300,
                        height: 250,
                      }}
                    >
                      <Image
                        src={
                          item.productimage_set.length != 0
                            ? item.productimage_set[0]?.image_file
                            : imagenotavailable
                        }
                        alt={item.title}
                        className="mb-0"
                        loading="lazy"
                        style={{ maxWidth: 300, maxHeight: 250 }}
                        onClick={
                          () =>
                            handleNavigationClick(
                              `/3dmodels/${item?.category_data?.slug}/${item.slug}`
                            )
                        }
                      />
                    </div>
                    <div className="my-4 ms-3 d-flex flex-column justify-content-center align-items-start">
                      <div className="text-shadow body-text2">
                        {item.category_data.title}
                      </div>
                      <div className="text-white subtitle">{item.title}</div>
                    </div>
                    <div className="my-4 ms-3 d-flex flex-column justify-content-center align-items-start">
                      <div className="text-shadow body-text2">Price</div>
                      <div className="text-white d-flex justify-content-center align-items-center">
                        <div
                          className={`${bestCoupon ? "subtitle" : "d-none"
                            } text-primary m-1`}
                        >
                          {getSymbolFromCurrency(currency?.name)}
                          {bestCoupon?.coupon_category === "ABSOLUTE"
                            ? (
                              (item?.price - bestCoupon?.flat_off) *
                              currency?.rate
                            ).toFixed(2)
                            : (
                              (item?.price -
                                (item?.price * bestCoupon?.flat_off) / 100) *
                              currency?.rate
                            ).toFixed(2)}
                        </div>
                        <div
                          className={`${bestCoupon ? "d-none" : "subtitle text-primary m-1"
                            }`}
                        >
                          {getSymbolFromCurrency(currency?.name)}{" "}
                          {(item.price * currency?.rate).toFixed(2)}
                        </div>
                        <Badge
                          bg="primary"
                          className={`${bestCoupon ? "text-black body-text2" : "d-none"
                            }`}
                          style={{ borderRadius: 0 }}
                        >
                          {bestCoupon?.coupon_category === "ABSOLUTE"
                            ? "Flat " + bestCoupon?.flat_off + " OFF"
                            : bestCoupon?.flat_off + "% OFF"}
                        </Badge>
                        <div
                          className={`${bestCoupon ? "body-text2 mx-1" : "d-none"
                            }`}
                        >
                          <strike>
                            {getSymbolFromCurrency(currency?.name)}{" "}
                            {(item.price * currency?.rate).toFixed(2)}
                          </strike>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center mx-3">
                      <a
                        href={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                        style={{ textDecoration: "none" }}
                        onClick={() =>
                          handleNavigationClick(
                            `/3dmodels/${item?.category_data?.slug}/${item.slug}`
                          )
                        }
                      >
                        <RButton
                          className="mb-4 explore-button-no-neo"
                          variant="dark"
                          size="lg"
                          style={{ border: changingBorder ? "0px" : "0px" }}
                          onMouseEnter={() => setChangingBroder(true)}
                          onMouseLeave={() => setChangingBroder(false)}
                        >
                          <span className="poppins">SEE PRODUCT</span>
                        </RButton>
                      </a>
                    </div>
                  </BCard>
                </Breakpoint>
              </>
            );
          case "advertise":
            return (
              <>
                <Breakpoint large up>
                  <div
                    className="pb-5 d-flex justify-content-center align-items-center py-2"
                    key={item.id}
                  >
                    <Card
                      className="position-relative mx-4 d-flex flex-column justify-content-center align-items-center py-4"
                      dark={true}
                      style={styles.advertiseCard}
                    // flat
                    >
                      {/* <div className="d-flex flex-column justify-content-center align-items-center"> */}
                      <div
                        style={styles.advertiseCardImageBox}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Image
                          src={item.image}
                          alt={item.title}
                          className="mb-4"
                          loading="lazy"
                          style={{
                            ...styles.advertiseCardImage,
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                          onClick={() => {
                            if (item.link) {
                              window.open(item.link, "_blank");
                            }
                          }}
                        />
                      </div>
                      <div>
                        <h5 className="py-2">{item.title}</h5>
                        {/* <p><span className="poppins-700 text-primary">$ {item.price} </span><strike><small>${item.mrp}</small></strike></p> */}
                        <div
                          className="d-flex justify-content-center align-items-center cursor-pointer"
                          onClick={() => {
                            window.location.href = item.link;
                          }}
                        >
                          <div className="explore-text">BUY NOW </div>{" "}
                          <Image
                            className="mx-2"
                            src={external_link}
                            alt="External Link"
                            loading="lazy"
                            style={{ width: 16, height: 16 }}
                          />
                        </div>
                      </div>
                      {/* </div> */}
                    </Card>
                  </div>
                </Breakpoint>
                <Breakpoint medium down>
                  <Card
                    className="mx-2 mt-2 mb-3 h-100"
                    dark={true}
                    style={{ width: 300, borderRadius: "20px" }}
                  >
                    <div
                      className="m-0 border-0 text-black p-2 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#333333",
                        width: 300,
                        height: 250,
                        borderRadius: "20px",
                      }}
                    >
                      <Image
                        src={item.image}
                        alt={item.title}
                        className="mb-2"
                        loading="lazy"
                        style={{
                          maxWidth: 300,
                          maxHeight: 250,
                        }}
                      />
                    </div>
                  </Card>
                  <div>
                    <h5 className="py-2">{item.title}</h5>
                    {/* <p><span className="poppins-700 text-primary">$ {item.price} </span><strike><small>${item.mrp}</small></strike></p> */}
                    <div
                      className="d-flex justify-content-center align-items-center cursor-pointer"
                      onClick={() => {
                        window.location.href = item.link;
                      }}
                    >
                      <div className="explore-text">BUY NOW </div>{" "}
                      <Image
                        className="mx-2"
                        src={external_link}
                        alt="External Link"
                        loading="lazy"
                        style={{ width: 16, height: 16 }}
                      />
                    </div>
                  </div>
                </Breakpoint>
              </>
            );
          case "grabup":
            return (
              <>
                <Breakpoint large up>
                  <Col
                    className="p-3 d-flex justify-content-center"
                    key={item?.id}
                    as={Link}
                    to={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card
                      className="position-relative d-flex justify-content-center align-items-center"
                      style={styles.grabupCard}
                      dark={true}
                    >
                      <Image
                        className="mb-4"
                        src={
                          item.productimage_set.length != 0
                            ? item.productimage_set[0]?.image_file
                            : imagenotavailable
                        }
                        alt={item.title}
                        loading="lazy"
                        style={styles.grabupCardImage}
                      />
                      <CardAction
                        className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1"
                        style={styles.cardActionOpacity}
                      >
                        {item.title}
                      </CardAction>
                    </Card>
                  </Col>
                </Breakpoint>
                <Breakpoint medium down>
                  <Col
                    className="p-2 d-flex justify-content-center"
                    key={item?.id}
                    as={Link}
                    to={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card
                      className="position-relative"
                      style={styles.grabupMobileCard}
                      dark={true}
                    >
                      <Image
                        src={
                          item.productimage_set.length != 0
                            ? item.productimage_set[0]?.image_file
                            : imagenotavailable
                        }
                        alt={item.title}
                        style={styles.grabupCardImage}
                        className="w-100"
                        loading="lazy"
                      />
                      <CardAction
                        className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1"
                        style={styles.cardActionOpacity}
                      >
                        {item.title}
                      </CardAction>
                    </Card>
                  </Col>
                </Breakpoint>
              </>
            );
          case "product-images":
            return (
              <div
                className="py-1 d-flex justify-content-center cursor-pointer "
                key={item}
              >
                <Card
                  className="py-5 position-relative d-flex justify-content-center align-items-center w-100 cursor-pointer"
                  style={styles.productImagesCard}
                  dark={true}
                  onMouseEnter={() => { }}
                >
                  <Image
                    src={product}
                    style={styles.productImagesCardImage}
                    loading="lazy"
                  />
                </Card>
              </div>
            );
          case "admin-uploads":
            if (!item.id) {
              return (
                <div
                  className="d-flex justify-content-center p-2"
                  style={{ width: 100 }}
                  key={index + 1}
                >
                  <Card
                    className="position-relative d-flex justify-content-center align-items-center cursor-pointer"
                    style={styles.adminUploadsCard}
                    dark={true}
                    onMouseEnter={() => { }}
                  >
                    <Image
                      src={URL.createObjectURL(item)}
                      loading="lazy"
                      style={styles.adminUploadsCardImage}
                    />
                  </Card>
                </div>
              );
            }
            return (
              <div
                className="d-flex justify-content-center p-2"
                style={{ width: 100 }}
                key={index + 1}
              >
                <Card
                  className="position-relative d-flex justify-content-center align-items-center cursor-pointer"
                  style={styles.adminUploadsCard}
                  dark={true}
                  onMouseEnter={() => { }}
                >
                  <Image
                    src={item.image_file}
                    alt={item?.title}
                    style={styles.adminUploadsCardImage}
                    loading="lazy"
                  />
                </Card>
              </div>
            );
          case "business":
            return (
              <div
                className="p-lg-3 d-flex justify-content-center px-lg-5"
                key={item}
              >
                <Card
                  className="position-relative mx-lg-5 mx-1 my-4"
                  dark={true}
                >
                  <Image
                    src={entrygate}
                    alt="Znanyeland Entry Gate"
                    className="businessSliderImage"
                    loading="lazy"
                  />
                  <CardAction
                    className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1"
                    style={styles.cardActionOpacity}
                  >
                    Znanyeland
                  </CardAction>
                </Card>
              </div>
            );
          case "casestudy":
            return (
              <div
                className="px-2 px-lg-3 d-flex justify-content-center"
                key={item}
              >
                <div className="d-flex flex-column justify-content-between">
                  <BCard className="position-relative" dark={true}>
                    <Image
                      src={item.image}
                      className="caseStudyCard"
                      loading="lazy"
                    />
                  </BCard>
                  <div className="mt-3 text-center">
                    <p>{item.name}</p>
                  </div>
                </div>
              </div>
            );
          case "review":
            return (
              <div
                className="px-2 p-lg-3 d-flex justify-content-center"
                key={item}
              >
                <div className="d-flex flex-column justify-content-center py-4">
                  <BCard
                    className="position-relative my-5 d-flex flex-column align-items-center"
                    dark={true}
                    style={{
                      backgroundColor: "#000000",
                      borderRadius: "8px",
                      border: "1px solid #3d3d3d",
                    }}
                  >
                    <Image
                      src={item.image ? item.image : imagenotavailable}
                      alt={item.review}
                      className="cardImgTop"
                      loading="lazy"
                    />
                    <div className="d-flex flex-column align-items-center py-3">
                      <div className="mt-5 mb-0 text-center">
                        <p>{item.name}</p>
                      </div>
                      <div style={styles.underline}></div>
                      <div
                        className="mt-4 text-center p-2 p-lg-3 reviewCard"
                        style={{ overflowX: "hidden" }}
                      >
                        <p>{item.review}</p>
                      </div>
                    </div>
                  </BCard>
                </div>
              </div>
              // <div className="p-3 d-flex justify-content-center" key={item}>
              //   <div className="d-flex justify-content-between py-3">
              //     <Card className="position-relative d-flex flex-column align-items-center p-3 my-5" dark={true}>
              //       <Image src={item.image} className="cardImgTop" />
              //       <div className="d-flex flex-column align-items-center">
              //         <div className="mt-5 mb-0 text-center">
              //           <p>{item.name}</p>
              //         </div>
              //         <div style={styles.underline}></div>
              //         <div className="mt-4 text-center" style={{width: 350, height: 300}}>
              //           <p>{item.review}</p>
              //         </div>
              //       </div>
              //     </Card>
              //   </div>
              // </div>
            );
          case "category":
            return (
              <ZCard
                key={item.id}
                index={index}
                item={item}
                type="homecategory"
              />
            );
          case "product":
            return (
              <Col
                className="p-3 d-flex justify-content-start cursor-pointer"
                key={item.id}
                // as={Link}
                // to={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                style={{ textDecoration: "none" }}
              >
                <Breakpoint large up>
                  <a
                    href={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                    onClick={() =>
                      handleNavigationClick(
                        `/3dmodels/${item?.category_data?.slug}/${item.slug}`
                      )
                    }
                  >
                    <BCard
                      className="position-relative d-flex justify-content-center align-items-start overflow-hidden"
                      style={styles.categoryCard}
                    // as={Link}
                    // to={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                    // dark={true}
                    // onClick={() =>
                    //   handleNavigationClick(
                    //     `/3dmodels/${item?.category_data?.slug}/${item.slug}`
                    //   )
                    // }
                    >
                      <Image
                        className="myimage"
                        style={styles.myimage}
                        loading="lazy"
                        src={
                          item.productimage_set[0]
                            ? item.productimage_set[0].image_file
                            : imagenotavailable
                        }
                        alt={item.title}
                      />
                      <CardAction
                        className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1 text-white"
                        style={styles.cardAction}
                      >
                        {item.title}
                      </CardAction>
                    </BCard>
                  </a>
                </Breakpoint>

                <Breakpoint medium down>
                  <a
                    href={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                    onClick={() =>
                      handleNavigationClick(
                        `/3dmodels/${item?.category_data?.slug}/${item.slug}`
                      )
                    }
                  >
                    <BCard
                      className="position-relative d-flex justify-content-center align-items-start overflow-hidden"
                      style={styles.categoryFastmovingcard}
                      // as={Link}
                      // to={`/3dmodels/${item?.category_data?.slug}/${item.slug}`}
                      dark={true}
                    // onClick={() =>
                    //   handleNavigationClick(
                    //     `/3dmodels/${item?.category_data?.slug}/${item.slug}`
                    //   )
                    // }
                    >
                      <Image
                        className="myimage"
                        style={styles.myimagemobileFastmoving}
                        loading="lazy"
                        src={
                          item.productimage_set[0]
                            ? item.productimage_set[0].image_file
                            : imagenotavailable
                        }
                        alt={item.title}
                      />
                      <CardAction
                        className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1 text-white"
                        style={styles.cardAction}
                      >
                        {item.title}
                      </CardAction>
                    </BCard>
                  </a>
                </Breakpoint>
              </Col>
            );
          case "mobile-product":
            return (
              <div className="px-5" key={item?.id}>
                <Image
                  src={item ? item?.image_file : imagenotavailable}
                  alt={item.title}
                  style={{ maxHeight: 3000, maxWidth: 200 }}
                  loading="lazy"
                />
              </div>
            );
            break;
          default:
            return (
              <div className="p-3 d-flex justify-content-center" key={item?.id}>
                <BCard
                  className="position-relative"
                  style={styles.categoryCard}
                  dark={true}
                >
                  <Image
                    src={category}
                    alt="Category"
                    className="w-100"
                    loading="lazy"
                  />
                  <CardAction
                    className="m-0 p-2 text-center d-flex justify-content-center position-absolute body-text1"
                    style={styles.cardAction}
                  >
                    Animal
                  </CardAction>
                </BCard>
              </div>
            );
        }
      })}
    </Slider>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  myimage: {
    width: "280px",
    height: "280px",
    objectFit: "contain",
  },
  myimagemobileFastmoving: {
    width: 160,
    height: 180,
    objectFit: "cover",
  },
  categoryCard: {
    width: 256,
    height: 280,
    backgroundColor: "#141415",
    borderRadius: 10,
    border: "1px solid #3D3D3D",
  },
  categoryFastmovingcard: {
    width: 160,
    height: 180,
    backgroundColor: "#333333",
    borderRadius: 8,
    border: "1px solid #3D3D3D",
  },

  adminUploadsCard: {
    width: 100,
    height: 100,
  },
  grabupCard: {
    width: 534,
    height: 300,
  },
  grabupMobileCard: {
    width: 312,
    height: 176,
  },
  grabupCardImage: {
    borderRadius: 10,
    maxWidth: 500,
    maxHeight: 200,
  },
  carouselCard: {
    background: "none",
    width: 880,
    height: 600,
  },
  carouselCardLarge: {
    background: "none",
    width: 800,
    height: 600,
  },
  carouselCardLargeFix: {
    background: "none",
    width: 880,
    height: 600,
  },

  carouselCardImage: {
    maxWidth: 600,
    maxHeight: 400,
  },
  advertiseCard: {
    background: "none",
    width: 300,
    height: 300,
    borderRadius: "20px",
  },
  advertiseCardImageBox: {
    width: 200,
    height: 200,
  },
  advertiseCardImage: {
    maxWidth: 200,
    maxHeight: 200,
  },
  productImagesCard: {
    width: 138,
    height: 88,
    borderRadius: 0,
  },
  adminUploadsCardImage: {
    width: 100,
    borderRadius: 0,
  },
  productImagesCardImage: {
    width: 105,
    height: 60,
  },
  cardAction: {
    fontSize: "12px",
    backgroundColor: "#333333",
    bottom: 0,
    right: 0,
    left: 0,
    height: 40,
    borderRadius: 0,
  },
  cardActionOpacity: {
    backgroundColor: "rgba(0,0,0,0.6)",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  entryGateImage: {
    minHeight: "90vh",
  },
  underline: {
    height: 1,
    width: 120,
    backgroundColor: "white",
  },
};

export default ZSlider;
