import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Card,
  Badge,
  OverlayTrigger,
  Button as BootstrapButton,
  Tooltip,
  Overlay,
  Popover,
  Spinner,
} from "react-bootstrap";
import { Button, IconButton } from "ui-neumorphism";
import { AiOutlineClose, AiOutlineDownload } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { download, imagenotavailable } from "assets";

import "./ProductCard.scss";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "actions/AuthActions";
import * as adminActions from "actions/AdminActions";
import { Breakpoint } from "react-socks";
import { AiOutlineEye, AiOutlineHeart, AiOutlineDelete } from "react-icons/ai";
import { BsCalendar4 } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { BsCheck2 } from "react-icons/bs";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
import { toast } from "react-toastify";
import { BsInfo } from "react-icons/bs";

const ProductCard = (props) => {
  const { item, type } = props;
  const location = useLocation();
  const [soldCount, setSoldCount] = useState(0);
  const [latestSell, setLatestSell] = useState(0);
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";
  const currency = useSelector((state) => state.authReducer.currency);
  //consolelog("currency", currency);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const ref = useRef(null);

  // useEffect(() => {
  //     dispatch(adminActions.loadProducts());
  //   }, [item]);

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  let fileExtensions = [];

  if (item?.file_statistics?.extension_stats) {
    fileExtensions = Object.keys(item?.file_statistics?.extension_stats);
  }

  const handleProductVerify = async (e, isNewproduct) => {
    e.preventDefault();
    const formData = new FormData();
    console.log(item);
    formData.append("is_verified", false);
    formData.append("is_in_draft", false);
    formData.append("title", item.title);
    formData.append("price", item.price);
    // if (isNewproduct) {
    formData.append("created_by", item.created_by);
    formData.append("modified_by", item.modified_by);
    const response = await adminActions.updateProduct(item.slug, formData);
    if (response) {
      //consolelog(response);
      toast.success(
        "We are delighted that you decided to partner with us in selling your VR Asset. Your asset is under review. If we need any clarification, we will come back; else, your asset will automatically Go Live on our store."
      );
      dispatch(adminActions.productUpdate(response));
    }
  };

  const handleProductApproval = async (e, flag) => {
    e.preventDefault();
    const formData = new FormData();
    // console.log("@@",item)
    formData.append("title", item.title);
    formData.append("price", item.price);
    formData.append("created_by", item.created_by);
    formData.append("modified_by", item.modified_by);
    if (flag === true) {
      formData.append("is_verified", true);
      formData.append("is_in_draft", false);
    } else {
      formData.append("is_in_draft", true);
      formData.append("is_verified", false);
    }
    // if (newProduct) {
    const response = await adminActions.approveProduct(item.slug, formData);
    if (response) {
      if (flag === true) {
        toast.success("Product Verified!");
      } else {
        toast.warning("Product updates have been Refused!");
      }
      dispatch(adminActions.productUpdate(response));
    }
  };

  const handleProductDownload = async (e) => {
    setDownloading(true);
    const response = await actions.downloadProductFile(
      item.current_version,
      item.id,
      item.slug
    );
    if (response) {
      setDownloading(false);
    }
  };

  useEffect(async () => {
    const data = { product_id: item.id };
    // console.log(newProduct, item);

    const response = await actions.soldCount(data);
    if (response) {
      setSoldCount(response["sold_count"]);
    }

    const response1 = await actions.latestSell(data);
    if (response1) {
      if (response1["last_bought"] !== "00/00/0000") {
        setLatestSell(moment(response1["last_bought"]).format("DD/MM/YY"));
      }
    }
  }, []);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  switch (type) {
    case "verifiedProduct":
      return (
        <>
          <Breakpoint large up>
            <Card
              className="position-relative bg-dark mx-4 mb-4 mt-0 d-flex flex-column item-details-card"
              dark={true}
              key={item?.id}
              style={{
                backgroundColor: "#141415",
                border: "1px solid #3d3d3d",
                userSelect: "none",
              }}
            >
              <Row>
                <Col
                  sm="auto"
                  // as={Link}
                  // to={`/3dmodels/${item?.category_data?.slug}/${item?.slug}`}
                  onClick={() =>
                    handleNavigationClick(
                      `/3dmodels/${item?.category_data?.slug}/${item?.slug}`
                    )
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    className="mt-4 ms-4 d-flex justify-content-center align-items-center"
                    style={styles.itemImageCard}
                  >
                    <Image
                      loading="lazy"
                      src={
                        item?.productimage_set.length !== 0
                          ? item?.productimage_set[0]?.thumbnail_file ||
                          item?.productimage_set[0]?.image_file
                          : imagenotavailable
                      }
                      alt={item?.title}
                      style={{ maxWidth: 120, maxHeight: 120 }}
                    />
                  </Card>
                </Col>
                <Col className="mt-4 text-white">
                  <p>
                    Product Name - <b>{item?.title}</b>({item.current_version})
                  </p>
                  <p>
                    File Formats -{" "}
                    {fileExtensions.map((extension) => {
                      return <b>{extension} </b>;
                    })}
                  </p>
                  <p className="text-primary" style={{ fontSize: 20 }}>
                    Price -{" "}
                    {getSymbolFromCurrency(currency.name) +
                      " " +
                      (item?.price * currency.rate).toFixed(2)}
                  </p>
                </Col>
                <Col className="mt-4 me-4">
                  <div className="d-flex justify-content-end" ref={ref}>
                    <Link
                      // to={`${param1 == "sell-3d" ? "/sell-3d" : ""}/product/${
                      //   item.slug
                      // }`}
                      onClick={() =>
                        handleNavigationClick(
                          `${param1 == "sell-3d" ? "/sell-3d" : ""}/product/${item.slug
                          }`
                        )
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <BootstrapButton
                        className="p-4 explore-border-button explore-button-large-no-neo"
                        variant="dark"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FiEdit3 size={24} style={{ color: "white" }} />
                        </div>
                      </BootstrapButton>
                    </Link>
                    <BootstrapButton
                      // onClick={() => {
                      //     dispatch(adminActions.archiveProduct(item?.id));
                      // }}
                      onClick={handleClick}
                      className="p-4  mx-3 explore-border-button explore-button-large-no-neo"
                      variant="dark"
                      dark={true}
                    >
                      <AiOutlineDelete size={20} />
                    </BootstrapButton>
                    <Overlay
                      show={show}
                      target={target}
                      placement="left"
                      container={ref}
                      containerPadding={20}
                    >
                      <Popover
                        id="popover-contained"
                        style={{
                          backgroundColor: "#555",
                          border: "1px solid white",
                        }}
                      >
                        <Popover.Header
                          className="text-white body-text1"
                          style={{ backgroundColor: "#333" }}
                        >
                          Confirm Product Delete
                        </Popover.Header>
                        <Popover.Body>
                          <Button
                            dark={true}
                            onClick={() => {
                              setShow(false);
                              dispatch(adminActions.archiveProduct(item?.id));
                            }}
                          >
                            Confirm
                          </Button>
                          <Button
                            dark={true}
                            onClick={() => setShow(false)}
                            className=""
                          >
                            Cancel
                          </Button>
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </div>
                  <div className="mt-4 d-flex justify-content-end align-items-center h-50">
                    <div style={styles.statisticDiv} className="px-5">
                      <div className="d-flex">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">Product Views</Tooltip>
                          }
                        >
                          <IconButton size="large" dark={true}>
                            <AiOutlineEye size={30} />
                          </IconButton>
                        </OverlayTrigger>
                        <span className="d-flex flex-column justify-content-center text-white">
                          &nbsp;&nbsp;{item?.view_count}
                        </span>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">Product Likes</Tooltip>
                          }
                        >
                          <IconButton className="ms-4" size="large" dark={true}>
                            <AiOutlineHeart size={30} />
                          </IconButton>
                        </OverlayTrigger>
                        <span className="d-flex flex-column justify-content-center text-white">
                          &nbsp;&nbsp;{item?.favourite_count}
                        </span>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              Product Downloads
                            </Tooltip>
                          }
                        >
                          <IconButton className="ms-4" size="large" dark={true}>
                            <MdOutlineDownloadForOffline size={30} />
                          </IconButton>
                        </OverlayTrigger>
                        <span className="d-flex flex-column justify-content-center text-white">
                          &nbsp;&nbsp;{soldCount}
                        </span>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              Last Downloaded On
                            </Tooltip>
                          }
                        >
                          <IconButton
                            className={`${latestSell !== 0 ? "ms-4" : "d-none"
                              }`}
                            size="large"
                            dark={true}
                          >
                            <BsCalendar4 size={25} />
                          </IconButton>
                        </OverlayTrigger>
                        <span className="d-flex flex-column justify-content-center text-white">
                          &nbsp;&nbsp;{latestSell !== 0 ? latestSell : ""}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Breakpoint>
        </>
      );
    case "underApprovalProduct":
      return (
        <>
          <Breakpoint large up>
            <Card
              className="position-relative bg-dark mx-4 mb-4 mt-0 d-flex flex-column item-details-card"
              dark={true}
              key={item?.id}
              style={{
                backgroundColor: "#141415",
                border: "1px solid #3d3d3d",
                userSelect: "none",
              }}
            >
              <Row>
                <Col
                  sm="auto"
                  // as={Link}
                  // to={`/3dmodels/${item?.category_data?.slug}/${item?.slug}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    className="mt-4 ms-4 d-flex justify-content-center align-items-center"
                    style={styles.itemImageCard}
                  >
                    <Image
                      loading="lazy"
                      src={
                        item?.productimage_set.length !== 0
                          ? item?.productimage_set[0]?.thumbnail_file ||
                          item?.productimage_set[0]?.image_file
                          : imagenotavailable
                      }
                      alt={item?.title}
                      style={{ maxWidth: 120, maxHeight: 120 }}
                    />
                  </Card>
                </Col>
                <Col className="mt-4 text-white">
                  <p>
                    Product Name - <b>{item?.title}</b>(
                    {item.current_version})
                  </p>
                  <p>
                    File Formats -{" "}
                    {fileExtensions.map((extension) => {
                      return <b>{extension} </b>;
                    })}
                  </p>
                  <p className="text-primary" style={{ fontSize: 20 }}>
                    Price -{" "}
                    {getSymbolFromCurrency(currency.name) +
                      " " +
                      (item?.price * currency.rate).toFixed(2)}
                  </p>
                </Col>
                <Col className="mt-4 me-4">
                  <div className="d-flex justify-content-end" ref={ref}>
                    {/* <BootstrapButton
                      className="p-4  mx-3 explore-border-button explore-button-large-no-neo"
                      variant="dark"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FiEdit3 size={24} style={{ color: "white" }} />
                      </div>
                    </BootstrapButton> */}
                    <BootstrapButton
                      onClick={handleClick}
                      className="p-4  mx-3 explore-border-button explore-button-large-no-neo"
                      variant="dark"
                      dark={true}
                    >
                      CANCEL
                    </BootstrapButton>
                    <Overlay
                      show={show}
                      target={target}
                      placement="left"
                      container={ref}
                      containerPadding={20}
                    >
                      <Popover
                        id="popover-contained"
                        style={{
                          backgroundColor: "#555",
                          border: "1px solid white",
                        }}
                      >
                        <Popover.Header
                          className="text-white body-text1"
                          style={{ backgroundColor: "#333" }}
                        >
                          Confirm Product Delete
                        </Popover.Header>
                        <Popover.Body>
                          <p className="text-white">
                            Verification of your product will be cancelled, do
                            you confirm this?
                          </p>
                          <div className="d-flex justify-content-center">
                            <Button
                              dark={true}
                              onClick={() => {
                                setShow(false);
                                dispatch(
                                  adminActions.cancelVerification(item?.id)
                                );
                              }}
                              className="me-3"
                            >
                              YES
                            </Button>
                            <Button
                              dark={true}
                              onClick={() => setShow(false)}
                              className=""
                            >
                              NO
                            </Button>
                          </div>
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </div>
                  <div className="mt-4 d-flex justify-content-end align-items-center h-50">
                    <div style={styles.statisticDiv} className="px-5">
                      <div className="d-flex">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">Product Views</Tooltip>
                          }
                        >
                          <IconButton size="large" dark={true}>
                            <AiOutlineEye size={30} />
                          </IconButton>
                        </OverlayTrigger>
                        <span className="d-flex flex-column justify-content-center text-white">
                          &nbsp;&nbsp;{item?.view_count}
                        </span>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">Product Likes</Tooltip>
                          }
                        >
                          <IconButton className="ms-4" size="large" dark={true}>
                            <AiOutlineHeart size={30} />
                          </IconButton>
                        </OverlayTrigger>
                        <span className="d-flex flex-column justify-content-center text-white">
                          &nbsp;&nbsp;{item?.favourite_count}
                        </span>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              Product Downloads
                            </Tooltip>
                          }
                        >
                          <IconButton className="ms-4" size="large" dark={true}>
                            <MdOutlineDownloadForOffline size={30} />
                          </IconButton>
                        </OverlayTrigger>
                        <span className="d-flex flex-column justify-content-center text-white">
                          &nbsp;&nbsp;{soldCount}
                        </span>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              Last Downloaded On
                            </Tooltip>
                          }
                        >
                          <IconButton
                            className={`${latestSell !== 0 ? "ms-4" : "d-none"
                              }`}
                            size="large"
                            dark={true}
                          >
                            <BsCalendar4 size={25} />
                          </IconButton>
                        </OverlayTrigger>
                        <span className="d-flex flex-column justify-content-center text-white">
                          &nbsp;&nbsp;{latestSell !== 0 ? latestSell : ""}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Breakpoint>
        </>
      );
    case "approvereject":
      return (
        <>
          <Breakpoint large up>
            <Card
              className="position-relative bg-dark mx-4 mb-4 mt-0 d-flex flex-column item-details-card"
              dark={true}
              key={item?.id}
              style={{
                backgroundColor: "#141415",
                border: "1px solid #3d3d3d",
                userSelect: "none",
              }}
            >
              <Row>
                <Col
                  sm="auto"
                  // as={Link}
                  // to={`/3dmodels/${item?.category_data?.slug}/${item?.slug}`}
                  onClick={() =>
                    handleNavigationClick(
                      `/3dmodels/${item?.category_data?.slug}/${item?.slug}`
                    )
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    className="mt-4 ms-4 d-flex justify-content-center align-items-center"
                    style={styles.itemImageCard}
                  >
                    <Image
                      loading="lazy"
                      src={
                        item?.productimage_set.length !== 0
                          ? item?.productimage_set[0]?.thumbnail_file ||
                          item?.productimage_set[0]?.image_file
                          : imagenotavailable
                      }
                      alt={item?.title}
                      style={{ maxWidth: 120, maxHeight: 120 }}
                    />
                  </Card>
                </Col>
                <Col className="mt-4 text-white">
                  <p>
                    Product Name - <b>{item?.title}</b>(
                    {item.current_version})
                  </p>
                  <p>
                    File Formats -{" "}
                    {fileExtensions.map((extension) => {
                      return <b>{extension} </b>;
                    })}
                  </p>
                  <h4 className="text-primary">
                    Price -{" "}
                    {getSymbolFromCurrency(currency.name) +
                      " " +
                      (item?.price * currency.rate).toFixed(2)}
                  </h4>
                </Col>
                <Col className="mt-4 me-4 text-white">
                  <div className="d-flex justify-content-end">
                    <BootstrapButton
                      className="w-25 p-0 mx-4 text-white"
                      disabled={downloading}
                      onClick={(e) => handleProductDownload(e)}
                    >
                      DOWNLOAD&nbsp;&nbsp;
                      {downloading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <AiOutlineDownload size={20} />
                      )}
                    </BootstrapButton>
                    <BootstrapButton
                      className="w-25 p-0 explore-button-no-neo"
                      size="lg"
                      // style={{height:40}}
                      dark={true}
                      onClick={(e) => handleProductApproval(e, true)}
                    >
                      APPROVE&nbsp;&nbsp;
                      <BsCheck2 size={20} />
                    </BootstrapButton>
                    <BootstrapButton
                      variant="dark"
                      className="w-25 p-0 mx-4 explore-border-button text-white"
                      dBootstrapButtonark={true}
                      onClick={(e) => handleProductApproval(e, false)}
                    >
                      REJECT&nbsp;&nbsp;X
                      {/* <AiOutlineDelete size={20} /> */}
                    </BootstrapButton>
                  </div>
                </Col>
              </Row>
            </Card>
          </Breakpoint>
        </>
      );
    default:
      return (
        <>
          <Breakpoint large up>
            <Card
              className="position-relative bg-dark mx-4 mb-4 mt-0 d-flex flex-column item-details-card"
              dark={true}
              key={item?.id}
              style={{
                backgroundColor: "#141415",
                border: "1px solid #3d3d3d",
                userSelect: "none",
              }}
            >
              <Row>
                <Col
                  sm="auto"
                  // as={Link}
                  // to={`/3dmodels/${item?.category_data?.slug}/${item?.slug}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    className="mt-4 ms-4 d-flex justify-content-center align-items-center"
                    style={styles.itemImageCard}
                  >
                    <Image
                      loading="lazy"
                      src={
                        item?.productimage_set.length !== 0
                          ? item?.productimage_set[0]?.thumbnail_file ||
                          item?.productimage_set[0]?.image_file
                          : imagenotavailable
                      }
                      alt={item?.title}
                      style={{ maxWidth: 120, maxHeight: 120 }}
                    />
                  </Card>
                </Col>
                <Col className="mt-4 text-white">
                  <p>
                    Product Name - <b>{item?.title}</b>(
                    {item.current_version})
                  </p>
                  <p>
                    File Formats -{" "}
                    {fileExtensions.map((extension) => {
                      return <b>{extension} </b>;
                    })}
                  </p>
                  <p className="text-primary" style={{ fontSize: 20 }}>
                    Price -{" "}
                    {getSymbolFromCurrency(currency.name) +
                      " " +
                      (item?.price * currency.rate).toFixed(2)}
                  </p>
                </Col>
                <Col className="mt-4 me-4">
                  <div className="d-flex justify-content-end" ref={ref}>
                    <Link
                      // to={`${param1 == "sell-3d" ? "/sell-3d" : ""}/product/${
                      //   item.slug
                      // }`}
                      onClick={() =>
                        handleNavigationClick(
                          `${param1 == "sell-3d" ? "/sell-3d" : ""}/product/${item.slug
                          }`
                        )
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <BootstrapButton
                        className="p-4  mx-3 explore-border-button explore-button-large-no-neo"
                        variant="dark"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FiEdit3 size={24} style={{ color: "white" }} />
                        </div>
                      </BootstrapButton>
                    </Link>
                    <BootstrapButton
                      // onClick={() => {
                      //     dispatch(adminActions.archiveProduct(item?.id));
                      // }}
                      onClick={handleClick}
                      className="p-4  mx-3 explore-border-button explore-button-large-no-neo"
                      variant="dark"
                      dark={true}
                    >
                      <AiOutlineDelete size={20} />
                    </BootstrapButton>
                    <Overlay
                      show={show}
                      target={target}
                      placement="left"
                      container={ref}
                      containerPadding={20}
                    >
                      <Popover
                        id="popover-contained"
                        style={{
                          backgroundColor: "#555",
                          border: "1px solid white",
                        }}
                      >
                        <Popover.Header
                          className="text-white body-text1"
                          style={{ backgroundColor: "#333" }}
                        >
                          Confirm Product Delete
                        </Popover.Header>
                        <Popover.Body>
                          <p className="text-white">
                            Your product will be deleted forever, do you confirm
                            this?
                          </p>
                          <Button
                            dark={true}
                            onClick={() => {
                              setShow(false);
                              dispatch(adminActions.archiveProduct(item?.id));
                            }}
                            className="me-3"
                          >
                            REMOVE&nbsp;&nbsp;
                            <AiOutlineDelete size={20} />
                          </Button>
                          <Button
                            dark={true}
                            onClick={() => setShow(false)}
                            className=""
                          >
                            Cancel
                          </Button>
                        </Popover.Body>
                      </Popover>
                    </Overlay>

                    {/* <Button
                      className="p-4 explore-button"
                      dark={true}
                      onClick={(e) => {
                        handleProductVerify(e, true);
                        // if (newProduct) {
                        //     handleProductVerify(e, true)
                        // } else {
                        //     handleProductVerify(e, false)
                        // }
                      }}
                    >
                      SEND TO VERIFY&nbsp;&nbsp;
                      <BsCheck2 size={20} />
                    </Button> */}
                  </div>
                  <div className="mt-4 d-flex justify-content-end align-items-center h-50">
                    <div style={styles.statisticDiv} className="px-5">
                      <div className="d-flex ">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">Product Views</Tooltip>
                          }
                        >
                          <IconButton size="large" dark={true}>
                            <AiOutlineEye size={30} />
                          </IconButton>
                        </OverlayTrigger>
                        <span className="d-flex flex-column justify-content-center text-white">
                          &nbsp;&nbsp;{item?.view_count}
                        </span>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">Product Likes</Tooltip>
                          }
                        >
                          <IconButton className="ms-4" size="large" dark={true}>
                            <AiOutlineHeart size={30} />
                          </IconButton>
                        </OverlayTrigger>
                        <span className="d-flex flex-column justify-content-center text-white">
                          &nbsp;&nbsp;{item?.favourite_count}
                        </span>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              Product Downloads
                            </Tooltip>
                          }
                        >
                          <IconButton className="ms-4" size="large" dark={true}>
                            <MdOutlineDownloadForOffline size={30} />
                          </IconButton>
                        </OverlayTrigger>
                        <span className="d-flex flex-column justify-content-center text-white">
                          &nbsp;&nbsp;{soldCount}
                        </span>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              Last Downloaded On
                            </Tooltip>
                          }
                        >
                          <IconButton
                            className={`${latestSell !== 0 ? "ms-4" : "d-none"
                              }`}
                            size="large"
                            dark={true}
                          >
                            <BsCalendar4 size={30} />
                          </IconButton>
                        </OverlayTrigger>
                        <span className="d-flex flex-column justify-content-center text-white">
                          &nbsp;&nbsp;{latestSell !== 0 ? latestSell : ""}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Breakpoint>
        </>
      );
  }
};

const styles = {
  itemImageCard: {
    width: 125,
    height: 125,
    backgroundColor: "#1e1f1e",
    border: "1px solid #3d3d3d",
    borderRadius: "4px",
  },
  underline: {
    height: 2,
    backgroundColor: "#292929",
  },
  statisticDiv: {
    borderRadius: "6px",
    backgroundColor: "#1F1F1F",
    padding: 2,
    border: "1px solid #3d3d3d",
  },
};

export default ProductCard;
