import React, { useState, useEffect, useRef } from "react";
import {
    Container,
    Row,
    Col,
    Image,
    Form,
    Button as RButton,
} from "react-bootstrap";
import { sellercreated, sellerbackground } from "assets";
import { useSpring, animated, useSpringRef } from "react-spring";
import * as easings from "d3-ease";

const SellerCreated = () => {

    const [imageVisible, setImageVisible] = useState(true);

    const springProps = useSpring({
        from: { opacity: 0, height: "0%" },
        // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
        to: { opacity: 1, height: "100%" },
        config: { duration: 1500, tension: 100 },
        onRest: () => {
            setImageVisible(false);
        },
        delay: 1000,
    });

    const springProps1 = useSpring({
        from: { leftDiv: 0, rightDiv: 0, opacity: 0, scale: 0, scaleX: 0.1, scaleY: 0.2, borderRadius: 200, width: 500, height: 500 },
        // to: { leftDiv: 0, candidateX: 0 },
        to: { leftDiv: -300, rightDiv: 350, opacity: 1, scale: 1, scaleX: 1.25, scaleY: 1.75, borderRadius: 2000 },
        config: { duration: 1000, easing: easings.easeCubicOut },
        delay: 2500,
        // onRest: () => setIsAnimationOver(true),
    });


    return (
        <React.Fragment>
            <Container className="position-relative p-0 d-flex justify-content-center align-items-center" fluid style={{ backgroundColor: '#1F1F1F', zIndex: 1, overflow: 'hidden' }}>
                <animated.div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 2, backgroundImage: imageVisible ? 'none' : 'linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%)', scaleX: springProps1.scaleX, scaleY: springProps1.scaleY, maxHeight: "100vw", borderRadius: springProps1.borderRadius }}>

                </animated.div>
                {imageVisible && <Row style={{ zIndex: 3 }}>
                    <Col sm={12} className="d-flex justify-content-center align-items-center p-0">
                        <Container
                            className="d-flex flex-column justify-content-center align-items-center home-background-categories h-100"
                            style={{
                                opacity: springProps.opacity,
                                height: springProps.height, ...styles.firstHalfContainer
                            }}
                            fluid
                            as={animated.div}
                        >
                            <Image loading="lazy" className="position-absolute" src={sellerbackground} alt="Seller Background" />
                            <Image loading="lazy" className="position-absolute" src={sellercreated} alt="Seller Created" style={{ maxWidth: 500, maxHeight: 500 }} />
                        </Container>
                    </Col>
                </Row>}
                {!imageVisible && <Row>
                    <Col className="p-0" style={{ position: 'relative', zIndex: 3 }}>
                        <Container
                            className="d-flex flex-column justify-content-center align-items-center h-100 position-absolute"
                            style={{ translateX: springProps1.leftDiv, ...styles.firstHalfContainer }}
                            fluid
                            as={animated.div}
                        >
                            <Image loading="lazy" src={sellercreated} alt="Seller Created" style={{ maxWidth: 500, maxHeight: 500 }} />
                        </Container>
                        <Container
                            className="d-flex flex-column justify-content-center align-items-center h-100"
                            style={{ translateX: springProps1.rightDiv, opacity: springProps1.opacity, scale: springProps1.scale, ...styles.secondHalfContainer }}
                            fluid
                            as={animated.div}
                        >
                            <h2 className="text-white">Congratulations!<br /> You are a seller now!</h2>
                        </Container>
                    </Col>
                </Row>}
            </Container>
        </React.Fragment >
    );
};

const styles = {
    firstHalfContainer: {
        minHeight: "100vh",
        // backgroundColor: "#292929",
        padding: 100,
    },
    secondHalfContainer: {
        minHeight: "100vh",
        // backgroundColor: "#292929",
    },
};

export default SellerCreated;
