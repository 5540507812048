import { useEffect, useState } from "react";
import {
  dummy_user_notification,
  notification_icons,
  styles,
} from "./notification_commons";
import NoNotifications from "./NoNotifications";
import { Breakpoint, useCurrentWidth } from "react-socks";
import "./notifications.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Error from "pages/Error/Error";
import * as authActions from "actions/AuthActions";

const MobileNotification = () => {
  const navigate = useNavigate();

  const x = useCurrentWidth();
  const user = useSelector((state) => state.authReducer.user);
  const [notificationsForUser, setNotificationsForUser] = useState([]);

  // const fetchUserNotifications = async () => {
  //     setNotificationsForUser(dummy_user_notification);
  // }
  const fetchUserNotifications = async () => {
    // setNotificationsForUser(dummy_user_notification);
    const response = await authActions.fetchNotifications(user.email);
    if (response) {
      setNotificationsForUser(response);
    }
  };

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const clearNotifications = async () => {
    const response = await authActions.archiveNotifications(user.email);
    if (response) {
      setNotificationsForUser([]);
    }
  };

  useEffect(() => {
    if (x > 992) {
      // navigate("/");
      handleNavigationClick("/");
    }
    fetchUserNotifications();
  }, []);

  // useEffect(()=>{
  //    console.log("hih", notificationsForUser)
  // },[notificationsForUser])

  return (
    <>
      <>
        <Breakpoint medium down>
          <div id="notifications-desktop" style={styles.mobileContainer}>
            <div
              id="notification_header"
              className="d-flex flex-row justify-content-between"
              style={styles.header}
            >
              <h6 className="py-2 text-white m-0">Notification</h6>
              <p
                className="py-2 m-0"
                style={
                  notificationsForUser.length > 0
                    ? styles.clear
                    : styles.clearDisabled
                }
                onClick={clearNotifications}
              >
                clear all
              </p>
            </div>
            <div style={styles.underline}></div>
            {/* <hr className="mt-0" style={styles.hr} /> */}
            <div>
              {notificationsForUser.length > 0 ? (
                notificationsForUser.map((notification, index) => (
                  <>
                    <div
                      className=" my-2 d-flex flex-row justify-content-start align-items-center"
                      style={styles.notification_container}
                    >
                      {/* <img src={notification_icons[notification.type]} alt={notification.type} style={styles.icon} /> */}
                      <img
                        src={`${process.env.REACT_APP_CLOUDFRONT_URL}${notification.image_link}`}
                        alt={notification.type}
                        style={styles.icon}
                      />

                      <p
                        style={styles.notification_title}
                        dangerouslySetInnerHTML={{
                          __html: notification.description,
                        }}
                      ></p>
                    </div>
                    <>
                      <div style={styles.underline}></div>
                      {/* {index < (notificationsForUser.length - 1) && <hr className="mt-0" style={styles.hr} />} */}
                    </>
                  </>
                ))
              ) : (
                <NoNotifications />
              )}
            </div>
          </div>
        </Breakpoint>

        <Breakpoint large up>
          <Error />
        </Breakpoint>
      </>
    </>
  );
};

export default MobileNotification;
