import React from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Card,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { MdCopyright } from "react-icons/md";
import { useSelector } from "react-redux";
import { Breakpoint } from "react-socks";
import "core-ui/components/Footer.scss";
import {
  facebook_new,
  instagram_new,
  linkedin_new,
  pinterest_new,
  discord_new,
  transparent_image,
} from "assets";
import { useCurrentWidth } from "react-socks";

const Footer = () => {
  const width = useCurrentWidth();
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const page = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";
  const param3 = params.length > 1 ? params[2] : "";

  if (
    page === "admin" ||
    page === "reset_password" ||
    param3 === "viewer" ||
    page === "login" ||
    param3 === "imageviewer" ||
    (page === "cart" && width < 992 && param2 !== "thankyou") ||
    (page === "favourites" && width < 992)
  ) {
    return <div />;
  }

  return (
    <Card
      as={Row}
      className="pt-5 pb-0 m-0 text-white user-select-none"
      style={styles.parentContainer}
    >
      <Breakpoint large up>
        <Container fluid>
          <Row>
            <Col lg={{ span: 2, offset: 1 }}>
              <h5 className="subtitle">Contact Details </h5>
              <div className="d-flex align-items-center footer-info footer-link my-4">
                <Image loading="lazy" src={transparent_image} alt="Map Pin" className="me-2 sprites-map-pin" />
                AWFIS, GK Mall, 3rd Floor, Pimple Saudagar, Pune-411027
              </div>

              <div className="d-flex align-items-center footer-info footer-link my-4">
                <a
                  href="tel:1800 266 4020"
                  style={{ textDecoration: 0, width: 216 }}
                  className="text-white poppins"
                >
                  <Image loading="lazy" src={transparent_image} alt="Phone" className="me-2 sprites-phone" />
                  1800 266 4020
                </a>
              </div>
              <div className="d-flex align-items-center footer-info footer-link my-4">
                <a
                  href="mailto:talk2us@znanye.com?subject=Contact%20us"
                  style={{ textDecoration: 0 }}
                  className="text-white poppins"
                >
                  <Image loading="lazy" src={transparent_image} alt="Mail" className="me-2 sprites-mail" />
                  talk2us@znanye.com
                </a>
              </div>
            </Col>
            <Col lg={3} className="d-flex justify-content-lg-center ">
              <div>
                <h5 className="subtitle">Resources</h5>
                <div className="my-4">
                  <a
                    href="/business"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Business</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Shop</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href={
                      !isAuthenticated ? "/sell-3d" : "/sell-3d/uploadproducts"
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Sell 3D</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/3dmodels"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">3d Models</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/blogs"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Blogs</p>
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={2} className="d-flex justify-lg-content-center">
              <div>
                <h5 className="subtitle">Company Info</h5>
                <div className="my-4">
                  <Link
                    to="/we-are-in-news"
                    // onClick={() => handleNavigationClick("/we-are-in-news")}
                    // // target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">We are in news!</p>
                  </Link>
                </div>
                <div className="my-4">
                  <a
                    href="/about-us-policy"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">About Us</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/contact-us"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Contact Us</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/refund_policy"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p
                      className="footer-info footer-link"
                    // onClick={() => {
                    //   window.open("/refund_policy", "_blank");
                    // }}
                    >
                      Refund Policy
                    </p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/sitemap"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Sitemap</p>
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={2}>
              <div className="">
                <h5 className="subtitle font-size18 p-0">Policies</h5>
                <div className="my-4">
                  <a
                    href="/privacy_policy"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">
                      User’s Privcay Policy
                    </p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/terms_of_use_user"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">
                      User's Terms of Use
                    </p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/terms_of_use_seller"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">
                      Seller's Terms of Use
                    </p>
                  </a>
                </div>

                <div className="my-4">
                  <a
                    href="/cookies_policy"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Cookies Policy</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/license"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">License</p>
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={2} className="d-flex ">
              <div>
                <h5 className="subtitle font-size18 p-0">Stay Connected</h5>
                <div className="d-flex">
                  <a
                    href="https://www.facebook.com/znanye/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <Image
                      loading="lazy"
                      className="sprites-facebook"
                      src={transparent_image}
                      alt="Facebook"
                      style={styles.socialImage}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/znanye/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <Image
                      loading="lazy"
                      className="sprites-linkedin"
                      src={transparent_image}
                      alt="Linkedin"
                      style={styles.socialImage}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/znanye.vr/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <Image
                      loading="lazy"
                      className="sprites-instagram"
                      src={transparent_image}
                      alt="Instagram"
                      style={styles.socialImage}
                    />
                  </a>
                </div>
                <div className="d-flex">
                  <a
                    href="https://in.pinterest.com/znanye/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <Image
                      loading="lazy"
                      className="sprites-pinterest"
                      src={transparent_image}
                      alt="Pinterest"
                      style={styles.socialImage}
                    />
                  </a>
                  <a
                    href="https://discord.gg/ZfzRt98CPe"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <Image
                      loading="lazy"
                      className="sprites-discord"
                      src={transparent_image}
                      alt="Discord"
                      style={styles.socialImage}
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="footer-strip py-3 text-white">
            <Col
              lg={4}
              className="text-center caption d-flex justify-content-center align-items-center"
            >
              CIN : U72900PN2020PTC193934
            </Col>
            <Col
              as={Button}
              variant="link"
              href="https://www.pastelcube.com/"
              sm={4}
              className="  text-center caption d-flex justify-content-center align-items-center text-white text-decoration-none wrapped-button"
            >
              <MdCopyright size="16" /> Pastelcube Technologies Private Limited
            </Col>
            <Col sm={4} className="text-center caption">
              Made in India with
              <Image loading="lazy" className="sprites-white-heart" src={transparent_image} alt="heart" />
            </Col>
          </Row>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container fluid>
          <Row>
            <Col lg={{ span: 2, offset: 1 }}>
              <div className="poppins-500 font-size18 ">Contact Details</div>
              <div
                className="d-flex align-items-center footer-info footer-link my-4"
                style={{ width: 216 }}
              >
                <Image loading="lazy" src={transparent_image} alt="Map Pin" className="me-2 sprites-map-pin" />
                AWFIS, GK Mall, 3rd Floor, Pimple Saudagar, Pune-411027
              </div>

              <div className="d-flex align-items-center footer-info footer-link my-4">
                <a
                  href="tel:1800 266 4020"
                  style={{ textDecoration: 0, width: 216 }}
                  className="text-white poppins"
                >
                  <Image loading="lazy" src={transparent_image} alt="Phone" className="me-2 sprites-phone" />
                  1800 266 4020
                </a>
              </div>
              <div className="d-flex align-items-center footer-info footer-link my-4">
                <a
                  href="mailto:talk2us@znanye.com?subject=Contact%20us"
                  style={{ textDecoration: 0, width: 216 }}
                  className="text-white poppins"
                >
                  <Image loading="lazy" src={transparent_image} alt="Mail" className="me-2 sprites-mail" />
                  talk2us@znanye.com
                </a>
              </div>
            </Col>
            <Col lg={3} className="d-flex justify-content-lg-center my-2">
              <div>
                <div className="poppins-500 font-size18 ">Resources</div>
                <div className="my-4">
                  <a
                    href="/business"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Business</p>
                  </a>
                </div>
                <div className="my-4">
                  <a href="/" target="_blank" className="text-decoration-none">
                    <p className="footer-info footer-link">Shop</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href={
                      !isAuthenticated ? "/sell-3d" : "/sell-3d/uploadproducts"
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Sell 3D</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/3dmodels"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">3d Models</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/blogs"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Blogs</p>
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={2} className="d-flex justify-lg-content-center my-2">
              <div>
                <div className="poppins-500 font-size18 ">Company Info</div>
                <div className="my-4">
                  <Link
                    to="/we-are-in-news"
                    // onClick={() => handleNavigationClick("/we-are-in-news")}
                    // target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">We are in news!</p>
                  </Link>
                </div>
                <div className="my-4">
                  <a
                    href="/about-us-policy"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">About Us</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/contact-us"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Contact Us</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/refund_policy"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Refund Policy</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/sitemap"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Sitemap</p>
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={2}>
              <div className="my-2">
                <div className="poppins-500 font-size18 ">Policies</div>
                <div className="my-4">
                  <a
                    href="/privacy_policy"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">
                      User’s Privcay Policy
                    </p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/terms_of_use_user"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">
                      User's Terms of Use
                    </p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/terms_of_use_seller"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">
                      Seller's Terms of Use
                    </p>
                  </a>
                </div>

                <div className="my-4">
                  <a
                    href="/cookies_policy"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">Cookies Policy</p>
                  </a>
                </div>
                <div className="my-4">
                  <a
                    href="/license"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <p className="footer-info footer-link">License</p>
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={2} className="d-flex my-2">
              <div>
                <div className="poppins-500 font-size18 "> Stay Connected</div>
                <div className="d-flex">
                  <a
                    href="https://www.facebook.com/znanye/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <Image
                      loading="lazy"
                      className="me-1 my-2"
                      src={facebook_new}
                      alt="Facebook"
                      style={styles.socialImageMobile}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/znanye/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <Image
                      loading="lazy"
                      className="mx-3 my-2"
                      src={linkedin_new}
                      alt="Linkedin"
                      style={styles.socialImageMobile}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/znanye.vr/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <Image
                      loading="lazy"
                      className=" my-2"
                      src={instagram_new}
                      alt="Instagram"
                      style={styles.socialImageMobile}
                    />
                  </a>
                </div>
                <div className="d-flex">
                  <a
                    href="https://in.pinterest.com/znanye/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <Image
                      loading="lazy"
                      className="me-1 my-2"
                      src={pinterest_new}
                      alt="Pinterest"
                      style={styles.socialImageMobile}
                    />
                  </a>
                  <a
                    href="https://discord.gg/ZfzRt98CPe"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <Image
                      loading="lazy"
                      className="mx-3 my-2"
                      src={discord_new}
                      alt="Discord"
                      style={styles.socialImageMobile}
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="footer-strip py-3 text-white">
            <Col
              lg={4}
              className="text-center caption d-flex justify-content-center align-items-center"
            >
              CIN : U72900PN2020PTC193934
            </Col>
            <Col
              as={Button}
              variant="link"
              href="https://www.pastelcube.com/"
              sm={4}
              className="  text-center caption d-flex justify-content-center align-items-center text-white text-decoration-none wrapped-button"
            >
              <MdCopyright size="16" /> Pastelcube Technologies Private Limited
            </Col>
            <Col sm={4} className="text-center caption">
              Made in India with
              <Image loading="lazy" className="sprites-white-heart" src={transparent_image} alt="heart" />
            </Col>
          </Row>
        </Container>
      </Breakpoint>
    </Card>
  );
};
const styles = {
  parentContainer: {
    // backgroundColor: "#1A1A1A",
    backgroundColor: "#141415",

    borderRadius: 0,
  },
  socialImage: {
    cursor: "pointer",
    width: "57px",
    height: "57px",
  },
  socialImageMobile: {
    cursor: "pointer",
    height: "24px",
    width: "24px",
  },
};

export default Footer;
