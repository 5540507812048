import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Navbar,
  Nav,
  Row,
  Col,
  Modal,
  Image,
  Form,
  Button,
  NavDropdown,
  Spinner,
  Card,
} from "react-bootstrap";
import {
  CardContent,
  IconButton,
  Badge,
} from "ui-neumorphism";
import {
  navigation_favorite,
  navigation_logout,
  navigation_orders,
  seller_signup_back,
  profileicon,
  transparent_image,
} from "assets";
import mobilemenu from "assets/mobile-menu.webp";
import search from "assets/search.webp";
import shopping_cart from "assets/shopping-cart.webp";
import bell from "assets/bell.webp";
import cross from "assets/cross.webp";
import { upload_icon } from "assets/admin";
import "core-ui/components/NavBar.scss";
import { FiChevronDown } from "react-icons/fi";
import { AiOutlineClose, AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import * as actions from "actions/AuthActions";
import * as userActions from "actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { ZDropdown, ProfileDropdown } from "components";
import {
  login_mascot,
  create_account_mascot,
  celebrate_mascot,
  forgot_mascot,
  glad,
  happy,
  excited,
  email_sent,
  eye,
  eye_off,
} from "assets";
import { Breakpoint } from "react-socks";
import { useTransition, animated } from "react-spring";
import { toast } from "react-toastify";
import moment from "moment";

import { Searchbar } from "..";
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Notifications from "components/Notifications/DesktopNotification";

import AppleGoogleFacebookLogin from "components/LoginBox/AppleGoogleFacebookLogin";
import * as authActions from "actions/AuthActions";
import Flags from "country-flag-icons/react/3x2";

import { GoGraph } from "react-icons/go";

// const hasAccess = hasGrantedAnyScopeGoogle(
//   tokenResponse,
//   'google-scope-1',
//   'google-scope-2',
// );

// const Searchbar = React.lazy(()=>import("../Searchbar/Searchbar"))

const NavBar = (props) => {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const activeBar = {
    borderLeft: "4px solid red",
    weight: "500",
    lineHeight: "24px",
    size: "20px",
    fontFamily: "Poppins",
    // Add red border to active component
  };
  const notifybuttonRef = useRef(null);
  const notify = (msg) => {
    toast.warning(msg);
    // alert();
  };

  const nonactiveBar = {
    weight: "500",
    lineHeight: "24px",
    size: "20px",
    fontFamily: "Poppins",
  };

  const { loginShow, setLoginShow } = props;
  const [categoryDropdown, setCategoryDropdown] = useState(false);
  const [moreDropdown, setMoreDropdown] = useState(false);
  const [profileDropdown, setProfileDropDown] = useState(false);
  const user = useSelector((state) => state.authReducer.user);
  const session = useSelector((state) => state.authReducer.session);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [next, setNext] = useState(false);
  const [forOtp, setForOtp] = useState(false);
  const [authCheck, setAuthCheck] = useState(true);
  const [timer, setTimer] = useState(0);
  const [currency, setCurrency] = useState(
    useSelector((state) => state.authReducer.currency?.name?.toLowerCase())
  );
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState();
  const uniqueCountries = [];
  const [isSending, setIsSending] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [login, setLogin] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [emailSentNote, setEmailSentNote] = useState(false);
  const [emailResetNote, setEmailResetNote] = useState(false);
  const [myProductsDropdown, setMyProductsDropdown] = useState(false);
  const [executed, setExecuted] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const [showMore, setshowMore] = useState(false);
  const [changingBorder, setChangingBroder] = useState(false);
  const [eyeOn, setEyeOn] = useState(false);
  const [eyeOnTwo, setEyeOnTwo] = useState(false);
  const [categoryHover, setCategoryHover] = useState(false);
  const [dropdownCategory, setDropdownCategory] = useState(null);
  const [notificationsForUser, setNotificationsForUser] = useState([]);
  const notificationRef = useRef(null);
  const [notificationModelClicked, setNotificationModalClicked] = useState(true);
  const [resetSending, setResetSending] = useState(false);
  const [navSearchDropdown, setNavSearchDropdown] = useState(false);
  const [showMobileSearchBar, setShowMobileSearchBar] = useState(false);

  const handleNavigationClick = (url) => {
    // console.log("window", window.location);
    window.location.href = url;
  };

  const handleNotificationModalClick = (event) => {
    if (notificationRef.current.contains(event.target)) {
      setNotificationModalClicked(true);
    } else {
      setNotificationModalClicked(false);
    }
  };

  const loginForm = useRef(null);
  const userForm = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  const isAdmin = useSelector((state) => state?.authReducer?.user?.is_admin);
  const banner_offer_status_popup_expiry = useSelector(
    (state) => state.authReducer.banner_offer_status_popup_expiry
  );
  const [countryList, setCountryList] = useState([]);
  const [isUpdatingRegion, setisUpdatingRegion] = useState(false);
  const [region, setRegion] = useState(null);
  const [showRegionModal, setShowRegionModal] = useState(false);
  // const [sortedCountriesList,setsortedCountriesList] =useState([]);
  const signUptype = useSelector(
    (state) => state.authReducer.user.sign_up_type
  );

  const moreItems = [
    { title: "Business", navigateTo: "business" },
    { title: "Blogs", navigateTo: "blogs" },
    { title: "Contact Us", navigateTo: "contact-us" },
  ];

  useEffect(() => {
    if (showNotifications) {
      // //console.log("-------consino");
      notificationRef.current.focus();
    }
  }, [showNotifications]);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        (signUptype === "GOOGLE" || signUptype === "FACEBOOK") &&
        user.region === null
      ) {
        // //console.log("--------------------", user);
        // //console.log(signUptype);
        setShowRegionModal(true);
      }
      setisUpdatingRegion(false);
    }
  }, [isAuthenticated]);

  const handleSetRegion = async () => {
    if (region != null) {
      const response = await actions.updateProfile({ region: region }, user.id);
      dispatch(actions.getMyProfile());
      setShowRegionModal(false);
    } else {
      notify("Please Select A Value!!!");
    }
    setisUpdatingRegion(false);
  };

  useEffect(() => {
    dispatch(userActions.loadCategories());
    return () => {
      setTimer(0);
    };
  }, []);

  const loadTheCountries = async () => {
    const response = await userActions.loadCountries();
    // //console.log(countries);
    setCountries(response);
    // console.log(countries);
  };

  useEffect(() => {
    loadTheCountries();
    fetchUserNotifications();
  }, []);

  const FlagIcon = ({ country }) => {
    const Flag = Flags[country?.code];
    return (
      <Flag
        title={country?.name}
        className="text-white"
        style={{ height: 30 }}
      />
    );
  };

  useEffect(() => {
    if (countries?.length > 0) {
      let result = [];
      for (let i = 0; i < countries.length; i++) {
        let data = {
          value: countries[i]?.code,
          label: (
            <div className="d-flex subtitle" key={countries[i]?.id}>
              {/* <Flags.IN
                title={countries[i]?.name}
                className="text-white"
                style={{ height: 30 }}
              /> */}
              <FlagIcon country={countries[i]} />
              &nbsp;&nbsp;{countries[i]?.name}
            </div>
          ),
        };
        result.push(data);
      }
      result.sort((a, b) =>
        a.label.props.children[2].localeCompare(b.label.props.children[2])
      );
      setCountryList(result);
    }
  }, [isAuthenticated, countries]);

  const closeOffer = (e) => {
    e.preventDefault();
    setShowOffer(false);
    dispatch(
      actions.updateBannerOfferStatus(moment(new Date()).add(10, "m").toDate())
    );
  };

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  // useEffect(() => {
  //   console.log("##", countryList)
  // }, [countryList]);

  useEffect(() => {
    // console.log("forntend url ", frontendURL);

    let now = moment(new Date());
    // //console.log(moment(banner_offer_status_popup_expiry).diff(now, 'seconds'));
    if (
      !banner_offer_status_popup_expiry ||
      moment(banner_offer_status_popup_expiry).diff(now, "seconds") < 0
    ) {
      setShowOffer(true);
    }
  }, []);

  const categories = useSelector((state) => state.userReducer.categories);

  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";
  const param3 = params.length > 1 ? params[2] : "";
  const param4 = params.length > 1 ? params[3] : "";

  const cartProducts = useSelector(
    (state) => state?.authReducer?.user?.cartproducts
  );
  const cartSize = cartProducts ? cartProducts?.length : 0;

  // const notificationSize =

  // useEffect(() => {
  //   dispatch(actions.getMyProfile());
  // }, [isAuthenticated]);

  useEffect(() => {
    if (user) {
      if (!executed) {
        dispatch(actions.getMyProfile(cartProducts));
        dispatch(authActions.loadUserFavourite(user.id));
        dispatch(authActions.loadUserSavedForLater(user.id));
        setExecuted(true);
      }
    }
  }, [user]);

  const onLogin = async (e) => {
    console.log("inside");
    e.preventDefault();
    setIsSending(true);
    const formData = new FormData(loginForm.current);
    const response = await actions.loggingIn(formData);
    if (response) {
      dispatch(actions.login(response));
      dispatch(actions.loadUserFavourite(response.user.id))
      dispatch(authActions.loadUserSavedForLater(user.id));
      setIsSending(false);
    } else {
      setNext(true);
      setIsSending(false);
    }
  };

  const checkAccountVerification = async (e) => {
    const response = await actions.checkAccountVerification({
      username: username,
    });
    if (response) {
      ////console.log(response.status);
      return response.status;
    }
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let verify = false;
    const formData = new FormData(userForm.current);
    // const response = await actions.updateProfile(formData, user.id);
    // if (response) {
    //   dispatch(actions.profileUpdate(response));
    //   setLoginShow(false);handleProfileSubmit
    // }
    // setIsSubmitting(false);
    if (formData.get("password") === formData.get("confirm_password")) {
      formData.set("phone_code", formData.get("phone_code").split("__")[0]);
      const response = await actions.signUp(formData);
      if (response) {
        switch (response.status) {
          case "ALREADY_EXISTS":
          case "PROVIDE_ALL_DETAILS":
            toast.error(response.msg);
            break;
          case "UNEXPECTED":
            toast.error(response.msg);
            break;
          case "SUCCESSFUL":
            setCreateAccount(false);
            setEmailSentNote(true);

            const interval = setInterval(async () => {
              const response = await actions.checkAccountVerification({
                username: username,
              });
              if (response.status) {
                clearInterval(interval);
                setEmailSentNote(false);
                setAccountCreated(true);
              }
            }, 10000);

            break;
          default:
            break;
        }
      }
    } else {
      toast.error("Password and Confirm Password doesn't match!");
    }
    setIsSubmitting(false);
  };

  const handleLoginKey = async (e) => {
    e.preventDefault();
    setIsSending(true);
    let email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (username.match(email_regex)) {
      const response = await actions.loggingIn({
        email: username,
        password: password,
      });
      if (response) {
        dispatch(actions.login(response));
        dispatch(actions.loadUserFavourite(response.user.id))
        dispatch(actions.loadUserSavedForLater(response.user.id));
      }
      setIsSending(false);
    } else {
      toast.error("Please enter a valid email!");
    }
    setIsSending(false);
  };
  // const handleCreateAccountKey = (e) => {
  //   console.log("inside login");
  //   if (e.key === "Enter") {
  //     handleProfileSubmit(e);
  //   }
  // };
  // setsortedCountriesList(result)
  const handleReset = async (e) => {
    e.preventDefault();
    setResetSending(true);
    let email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (username.match(email_regex)) {
      const response = await actions.reset_password_request({
        email: username,
      });
      if (response) {
        switch (response.status) {
          case "DOES_NOT_EXIST":
            // toast.error(response.msg);
            break;
          case "EMAIL_SENT":
            setForgotPassword(false);
            setEmailResetNote(true);
            break;
          default:
            alert("some error");
        }
      }
    } else {
      setResetSending(false);

      toast.error("Please enter a valid email!");
    }
    setResetSending(false);
  };

  useEffect(() => {
    if (user.id && user.is_seller) {
      if (user) {
        dispatch(actions.getMySellerProfile(user?.id));
      }
    }
  }, [user.id]);

  const seller = useSelector((state) => state.authReducer.seller);

  // //console.log("Current User Information:", user);

  const [open, setOpen] = useState(false);
  // document.addEventListener('click', (event) => {
  //   if (open){
  //     //console.log("**********************8",open)
  //     // setOpen(false);
  //     setShowNotifications(false);
  //     // dropdownOpen = false;
  //     // dropdown.classList.remove('open');
  //   }
  // });
  const transition = useTransition(open, {
    from: { y: "-100%" },
    enter: {
      y: "0%",
    },
    leave: {
      y: "-100%",
    },
    config: { duration: 300 },
  });

  useEffect(() => {
    if (
      user?.name !== "" &&
      user?.name !== null &&
      user?.email !== "" &&
      user?.email !== null
    ) {
      setLoginShow(false);
    }
  }, [user]);

  const currencies = [
    { key: "usd", value: "USD" },
    { key: "inr", value: "INR" },
    { key: "gbp", value: "GBP" },
    { key: "eur", value: "EUR" },
    { key: "jpy", value: "JPY" },
    { key: "aud", value: "AUD" },
    { key: "brl", value: "BRL" },
    { key: "cad", value: "CAD" },
    { key: "cny", value: "CNY" },
    { key: "egp", value: "EGP" },
    { key: "mxn", value: "MXN" },
    { key: "sar", value: "SAR" },
    { key: "sgd", value: "SGD" },
    { key: "sek", value: "SEK" },
    { key: "try", value: "TRY" },
    { key: "aed", value: "AED" },
    { key: "rub", value: "RUB" },
  ];

  const fetchUserNotifications = async () => {
    // setNotificationsForUser(dummy_user_notification);
    const response = await authActions.fetchNotifications(user.email);
    if (response) {
      setNotificationsForUser(response);
    }
  };

  const clearNotifications = async () => {
    const response = await authActions.archiveNotifications(user.email);
    if (response) {
      setNotificationsForUser([]);
    }
  };

  if (param2 === "login") {
    return (
      <Navbar className="navbar-background" fixed="top">
        <Container fluid className="px-3 px-lg-5">
          <Navbar.Brand href="#home" className="px-3">
            <img
              alt="Znanye Logo"
              src={transparent_image}
              loading="lazy"
              className="d-inline-block align-top sprites-logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
    );
  }

  if (param1 === "admin") {
    return (
      <Navbar className="navbar-background" fixed="top">
        <Container fluid className="px-5">
          <Navbar.Brand href="/" className="px-3">
            <img
              alt="Znanye Logo"
              src={transparent_image}
              loading="lazy"
              className="d-inline-block align-top sprites-logo"
            />
          </Navbar.Brand>
          <Nav
            className={`ms-auto ${isAuthenticated && isAdmin ? "" : "d-none"}`}
          >
            <Nav.Link
              className="text-white px-3 body-text2 main-nav-links"
              as={Link}
              to="/admin"
              style={{ textDecoration: "none" }}
            >
              <div className="d-flex align-items-center">
                <span>Upload 3D Model</span>
                <Image
                  className="ms-2"
                  src={upload_icon}
                  alt="Upload Icon"
                  loading="lazy"
                  style={{ width: 16, height: 16 }}
                />
              </div>
              {param2 === "" && (
                <div className="bg-secondary border-underline mt-1" />
              )}
            </Nav.Link>
            <Nav.Link
              className="text-white px-3 body-text2 main-nav-links"
              as={Link}
              to="/admin/statistics"
              style={{ textDecoration: "none" }}
            >
              <div className="d-flex align-items-center">
                <span className="me-2">Statistics</span>
                <GoGraph />
              </div>
              {param2 === "statistics" && (
                <div className="bg-secondary border-underline mt-1" />
              )}
            </Nav.Link>
            <Nav.Link
              className="text-white px-4 body-text2 main-nav-links"
              as={Link}
              to="/admin/categories"
              style={{ textDecoration: "none" }}
            >
              <div>Categories</div>
              {param2 === "categories" && (
                <div className="bg-secondary border-underline mt-1" />
              )}
            </Nav.Link>
            <Nav.Link
              className="text-white px-3 body-text2 main-nav-links"
              as={Link}
              to="/admin/products"
              style={{ textDecoration: "none" }}
            >
              <div>Products</div>
              {param2 === "products" && (
                <div className="bg-secondary border-underline mt-1" />
              )}
            </Nav.Link>
            <Nav.Link
              className="text-white px-4 body-text2 main-nav-links"
              as={Link}
              to="/admin/approvals"
              style={{ textDecoration: "none" }}
            >
              <div>Product Approvals</div>
              {param2 === "approvals" && (
                <div className="bg-secondary border-underline mt-1" />
              )}
            </Nav.Link>
            <Nav.Link
              className="text-white px-3 body-text2 main-nav-links"
              as={Link}
              to="/admin/coupons"
              style={{ textDecoration: "none" }}
            >
              <div>Coupons</div>
              {param2 === "coupons" && (
                <div className="bg-secondary border-underline mt-1" />
              )}
            </Nav.Link>
            {user.is_superuser && <Nav.Link
              className="text-white px-4 body-text2 main-nav-links"
              as={Link}
              to="/admin/payouts"
              style={{ textDecoration: "none" }}
            >
              <div>Payouts</div>
              {param2 === "payouts" && (
                <div className="bg-secondary border-underline mt-1" />
              )}
            </Nav.Link>}
            <Nav.Link
              className="text-white px-4 body-text2 main-nav-links"
              as={Link}
              to="/admin/blogs"
              style={{ textDecoration: "none" }}
            >
              <div>Blogs</div>
              {param2 === "blogs" && (
                <div className="bg-secondary border-underline mt-1" />
              )}
            </Nav.Link>
            <NavDropdown
              className="ms-2"
              title={
                <div
                  className={`text-white currency-flag currency-flag-${currency}`}
                ></div>
              }
              menuVariant="dark"
              // style={{width: 50}}
              align="end"
              onSelect={(e) => {
                // //console.log(e);
                setCurrency(e);
                dispatch(actions.loadCurrencyRate(e.toUpperCase()));
              }}
              style={{ textDecoration: "none" }}
            >
              {currencies.map((currency) => {
                return (
                  <NavDropdown.Item eventKey={currency.key}>
                    <div
                      className={`text-white currency-flag currency-flag-${currency.key}`}
                    />
                    &nbsp;&nbsp;{currency.value}
                  </NavDropdown.Item>
                );
              })}
              {/* <NavDropdown.Divider /> */}
            </NavDropdown>
            <Nav.Link
              className="text-white mx-4 body-text2 main-nav-links"
              onMouseEnter={() => {
                setProfileDropDown(true);
              }}
              onMouseLeave={() => {
                setProfileDropDown(false);
              }}
              style={{ textDecoration: "none" }}
            >
              <div className="position-relative">
                <div className="body-text2">My Account</div>
                <ProfileDropdown
                  profileDropdown={profileDropdown}
                  in_admin_panel={true}
                />
              </div>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    );
  }

  if (param4 === "viewer" || param4 === "imageviewer") {
    return <div />;
  }

  return (
    <>
      <Breakpoint large up>
        <Navbar
          className="navbar-background d-flex flex-column pb-0"
          fixed="top"
          style={{ borderBottom: "2px solid #292929" }}
        >
          <Container fluid className="pb-1 px-5 w-100">
            <Navbar.Brand href="/">
              <img
                alt="Znanye Logo"
                src={transparent_image}
                loading="lazy"
                className="d-inline-block align-top mx-2 sprites-logo"
              />
            </Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link
                className="text-white px-3 body-text2 main-nav-links"
                // as={Link}
                // eventKey=""
                href={`/`}
                // to="/"
                style={{ textDecoration: "none" }}
              >
                <div className="my-2">Home</div>
                {param1 === "" && (
                  <div
                    className="bg-secondary border-underline mt-1"
                    style={{ width: 20 }}
                  />
                )}
              </Nav.Link>
              {/* <Nav.Link
                className="text-white px-3 body-text2 main-nav-links"
                as={Link}
                to="/sell-3d"
                style={{ textDecoration: "none" }}
              >
                <div className="my-2">Sell 3D</div>
                {param1 === "sell-3d" && (
                  <div
                    className="bg-secondary border-underline mt-1"
                    style={{ width: 20 }}
                  />
                )}
              </Nav.Link> */}
              <Nav.Item
                className="cursor-pointer text-white px-4 body-text2 main-nav-links"
                // eventKey=""
                // href="/3dmodels"
                // onClick={() => {
                //   handleNavigationClick("/3dmodels");
                // }}
                // onClick={() => {
                //   window.location.href =
                //   // categoriesRef.current.scrollIntoView();
                // }}
                onMouseLeave={() => {
                  setCategoryDropdown(false);
                }}
                onMouseEnter={() => {
                  setCategoryDropdown(true);
                }}
              >
                {/* <div
                  className="mt-3"
                  onClick={() => {
                    handleNavigationClick("/3dmodels");
                  }}
                >
                  3D Models <FiChevronDown size="22" />
                  {param1 === "3dmodels" && (
                    <div
                      className="bg-secondary border-underline mt-1"
                      style={{ width: 20 }}
                    />
                  )}
                </div> */}
                <div
                  className="mt-3"
                  data-cy="navbar-3dmodels"
                  onClick={() => {
                    handleNavigationClick("/3dmodels");
                  }}
                >
                  <Link
                    className="text-white text-decoration-none"
                    to={"/3dmodels"}
                    state={{ previousPage: location.pathname }}
                  >
                    3D Models <FiChevronDown size="22" />
                    {param1 === "3dmodels" && (
                      <div
                        className="bg-secondary border-underline mt-1"
                        style={{ width: 20 }}
                      />
                    )}
                  </Link>
                </div>
                <Card
                  dark={true}
                  className={`position-absolute my-2 d-flex categories-container ${categoryDropdown ? "d-block" : "d-none"
                    }`}
                  style={{
                    zIndex: 2,
                    backgroundColor: "#141414",
                    border: "2px solid #3d3d3d",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    className="p-2"
                    style={{ backgroundColor: "#141414" }}
                  >
                    <Row>
                      {categories
                        ?.filter((item) => item.is_visible)
                        ?.map((item, index) => {
                          return (
                            <div className="col-sm-4 " key={item.title} data-cy={`navbar-3dmodels-${item?.title}`}>
                              <a
                                href={`/3dmodels/${item?.slug}`}
                                className="text-decoration-none w-100"
                              >
                                <Col
                                  sm={4}
                                  // as={Link}
                                  className={`${categoryHover && dropdownCategory === index
                                    ? "text-primary fw-bold"
                                    : "text-white"
                                    } text-decoration-none body-text2 my-1 p-1 px-2 w-100`}
                                  onMouseEnter={() => {
                                    setCategoryHover(true);
                                    setDropdownCategory(index);
                                  }}
                                  onMouseLeave={() => {
                                    setCategoryHover(false);
                                    setDropdownCategory(null);
                                  }}
                                  style={{
                                    backgroundColor: `${categoryHover &&
                                      dropdownCategory === index
                                      ? "#2E2E2E"
                                      : ""
                                      }`,
                                  }}
                                  state={{ previousPage: location.pathname }}
                                >
                                  {item?.title}
                                </Col>
                              </a>
                            </div>
                          );
                        })}
                    </Row>
                  </CardContent>
                </Card>
              </Nav.Item>

              <Nav.Link
                className="text-white px-3 body-text2 main-nav-links"
                // as={Link}
                // to="vrsolutions"
                href="/vrsolutions"
                style={{ textDecoration: "none" }}
              >
                <div className="my-2">VR Solutions</div>
                {param1 === "vrsolutions" && (
                  <div
                    className="bg-secondary border-underline mt-1"
                    style={{ width: 20 }}
                  />
                )}
              </Nav.Link>

              <Nav.Link
                className="text-white px-3 body-text2 main-nav-links"
                // as={Link}
                // to="/"
                style={{ textDecoration: "none" }}
              >
                <div
                  // className="text-decoration-none text-white"
                  onClick={() =>
                    window.open("https://www.darkdengame.com/", "_blank")
                  }
                >
                  <div className="my-2">
                    <a
                      className="text-white poppins-400"
                      href="https://www.darkdengame.com/"
                      style={{ textDecoration: "none" }}
                    >
                      VR Game
                    </a>
                  </div>
                </div>
              </Nav.Link>

              <Nav.Link
                style={{ textDecoration: "none" }}
                className="text-white px-4 body-text2 main-nav-links"
                // href="/categories"
                onClick={() => {
                  // categoriesRef.current.scrollIntoView();
                }}
                onMouseEnter={() => {
                  setMoreDropdown(true);
                }}
                onMouseLeave={() => {
                  setMoreDropdown(false);
                }}
              >
                <div className="my-2">
                  More <FiChevronDown size="22" color="white" />
                </div>
                <Card
                  dark={true}
                  className={`position-absolute my-2 d-flex more-container ${moreDropdown ? "d-block" : "d-none"
                    }`}
                  style={{
                    zIndex: 2,
                    backgroundColor: "#141414",
                    border: "2px solid #3d3d3d",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    className="p-2"
                    style={{ backgroundColor: "#141414" }}
                  >
                    <Row>
                      {moreItems.map((item, index) => {
                        return (
                          <a
                            className="text-decoration-none"
                            href={`/${item.navigateTo}`}
                          >
                            <Col
                              sm={12}
                              // as={Link}
                              className={`${categoryHover && dropdownCategory === index
                                ? "text-primary fw-bold "
                                : "text-white"
                                } text-decoration-none body-text2 my-1 p-1 px-2`}
                              onMouseEnter={() => {
                                setCategoryHover(true);
                                setDropdownCategory(index);
                                // //console.log(index);
                              }}
                              onMouseLeave={() => {
                                setCategoryHover(false);
                                setDropdownCategory(null);
                              }}
                              onClick={() =>
                                handleNavigationClick("/" + item.navigateTo)
                              }
                              // to={item.navigateTo}
                              // eventKey=""
                              // href={item.navigateTo}
                              style={{
                                backgroundColor: `${categoryHover && dropdownCategory === index
                                  ? "#2E2E2E"
                                  : ""
                                  }`,
                              }}
                            >
                              {item.title}
                            </Col>
                          </a>
                        );
                      })}
                    </Row>
                  </CardContent>
                </Card>
                {(param1 === "business" ||
                  param1 === "blogs" ||
                  param1 === "contact-us") && (
                    <div
                      className="bg-secondary border-underline mt-1"
                      style={{ width: 20 }}
                    />
                  )}
              </Nav.Link>
              {/* <Nav.Link
                className="text-white px-3 body-text2 main-nav-links"
                as={Link}
                to="/"
                style={{ textDecoration: "none" }}
              >
                <div
                  // className="text-decoration-none text-white"
                  onClick={() =>
                    window.open("https://www.darkdengame.com/", "_blank")
                  }
                >
                  <div className="my-2">
                    <a
                      className="text-white"
                      href="https://www.darkdengame.com/"
                      style={{ textDecoration: "none" }}
                    >
                      Play VR Game
                    </a>
                  </div>
                </div>
              </Nav.Link> */}
              {/* <Nav.Link
                className="text-white px-4 body-text2 main-nav-links"
                as={Link}
                to="/categories"
                onMouseEnter={() => {
                  setCategoryDropdown(true);
                }}
                onMouseLeave={() => {
                  setCategoryDropdown(false);
                }}
              >
                <div className="my-2">
                  3D Models <FiChevronDown size="22" />
                </div>
                {param1 === "categories" && (
                  <div
                    className="bg-secondary border-underline mt-1"
                    style={{ width: 20 }}
                  />
                )}
                <CategoryDropdown
                  categoryDropdown={categoryDropdown}
                  categories={categories}
                />
              </Nav.Link> */}
            </Nav>

            {showSearchBar ? (
              <div className="m-2 pb-1">
                <Searchbar
                  // className="mx-2"
                  type="navbar"
                  navSearchDropdown={navSearchDropdown}
                  setNavSearchDropdown={setNavSearchDropdown}
                  setShowSearchBar={setShowSearchBar}
                />
              </div>
            ) : (
              <>
                <Button
                  size="lg"
                  dark={true}
                  variant="dark"
                  className="btn-znanye-search-lg d-flex justify-content-center align-items-center mx-2 my-2"
                  style={styles.znanyeMenuButton}
                  onClick={() => {
                    setOpen(!open);
                    setNavSearchDropdown(true);
                    setShowSearchBar(true);
                  }}
                  onBlur={() => {
                    setOpen(!open);
                    setShowSearchBar(false);
                  }}
                >
                  <Image
                    className="sprites-search"
                    src={transparent_image}
                    alt="Search"
                    loading="lazy"
                    style={styles.shoppingcart_large}
                  />
                </Button>
              </>
            )}

            {isAuthenticated && (
              <div className="ms-2 me-2">
                <Badge
                  overlap
                  bgColor="var(--error)"
                  content={notificationsForUser?.length}
                  style={styles.znanyeCartBadge}
                >
                  {/* <IconButton
                  size="large"
                  dark={true}
                  active={param1 === "cart" ? true : false}
                  style={styles.znanyeCartButton}
                  text={false}
                >
                  <img src={shoppingcart} alt="Shopping Cart" style={styles.shoppingcart} loading="lazy" />
                </IconButton> */}
                  <Button
                    ref={notifybuttonRef}
                    size="lg"
                    dark={true}
                    variant="dark"
                    className="btn-znanye-search-lg"
                    style={styles.znanyeMenuButton}
                    onClick={() => {
                      setShowNotifications(true);
                      notifybuttonRef.current.focus();
                    }}
                    onBlur={(event) =>
                      notificationModelClicked && setShowNotifications(true)
                    }
                  >
                    <Image
                      className="sprites-bell"
                      src={transparent_image}
                      alt="Notifications"
                      loading="lazy"
                      style={styles.shoppingcart_large}
                    />
                  </Button>
                </Badge>
              </div>
            )}
            {showNotifications && isAuthenticated && (
              <Notifications
                ref={notificationRef}
                onClick={handleNotificationModalClick}
                onBlur={() => setShowNotifications(false)}
                notificationsForUser={notificationsForUser}
                fetchUserNotifications={fetchUserNotifications}
                clearNotifications={clearNotifications}
              />
            )}
            <a
              // to="/cart"
              href="/cart"
              // onClick={() => {
              //   handleNavigationClick("/cart");
              // }}
              // href="/cart"
              style={{ textDecoration: "none" }}
              className="ms-2 me-3"
            >
              <Badge
                overlap
                bgColor="var(--error)"
                content={cartSize}
                style={styles.znanyeCartBadge}
              >
                {/* <IconButton
                  size="large"
                  dark={true}
                  active={param1 === "cart" ? true : false}
                  style={styles.znanyeCartButton}
                  text={false}
                >
                  <img src={shoppingcart} alt="Shopping Cart" style={styles.shoppingcart} loading="lazy" />
                </IconButton> */}
                <Button
                  size="lg"
                  dark={true}
                  variant="dark"
                  className="btn-znanye-search-lg "
                  style={styles.znanyeMenuButton}
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <Image
                    className="sprites-shopping-cart"
                    src={transparent_image}
                    alt="Shopping Cart"
                    loading="lazy"
                    style={styles.shoppingcart_no_neo}
                  />
                </Button>
              </Badge>
            </a>
            {(param1 === "sell-3d" || param1 === "sell-3d-login") &&
              (param2 === "myproducts" ||
                param2 === "drafts" ||
                param2 === "pendingapprovals" ||
                param2 === "uploadproducts" ||
                param2 === "" ||
                param2 === "product") &&
              !isAuthenticated ? (
              <Link
                to="/"
                onClick={() => {
                  handleNavigationClick("/");
                }}
                style={{ textDecoration: "none" }}
                className="me-2"
              >
                {/* <NuButton
                    dark={true}
                    className="explore-border-button explore-button-large"
                    style={{ backgroundColor: "#27292A" }}
                  >
                    <span className="poppins">Buy 3D Models</span>
                  </NuButton> */}
                <Button
                  variant="dark"
                  className="explore-border-button explore-button-large-no-neo"
                // style={{ backgroundColor: "#27292A" }}
                >
                  <span className="poppins">BUY 3D MODELS</span>
                </Button>
              </Link>
            ) : isAuthenticated ? (
              <a
                onClick={() => {
                  handleNavigationClick("/sell-3d/uploadproducts");
                }}
                href="/sell-3d/uploadproducts"
                style={{ textDecoration: "none" }}
                className="me-2"
              >
                {/* <NuButton
                      dark={true}
                      className="explore-border-button explore-button-large"
                      style={{ backgroundColor: "#27292A" }}
                    >
                      <span className="poppins">Upload Model</span>
                      <Image
                        className="ms-2"
                        src={upload_icon}
                        alt="Upload Icon"
                        loading="lazy"
                        style={{ width: 16, height: 16 }}
                      />
                    </NuButton> */}
                <Button
                  variant="dark"
                  className="explore-border-button explore-button-large-no-neo"
                  style={{ backgroundColor: "#27292A" }}
                >
                  <span className="poppins">UPLOAD MODEL</span>
                  <Image
                    className="ms-2"
                    src={upload_icon}
                    alt="Upload Icon"
                    loading="lazy"
                    style={{ width: 16, height: 16 }}
                  />
                </Button>
              </a>
            ) : (
              <a
                onClick={() => {
                  handleNavigationClick("/sell-3d");
                }}
                href="/sell-3d"
                style={{ textDecoration: "none" }}
                className="me-2"
              >
                <Button
                  className="explore-border-button explore-button-large-no-neo"
                  variant="dark"
                  style={{ backgroundColor: "#27292A" }}
                >
                  <span className="poppins">BECOME A SELLER</span>
                </Button>
              </a>
            )}
            <NavDropdown
              className="me-2"
              title={
                <div
                  className={`text-white currency-flag currency-flag-${currency}`}
                ></div>
              }
              menuVariant="dark"
              align="end"
              onSelect={(e) => {
                setCurrency(e);
                toast.success(
                  "This is an indicative price in your local currency, Final billing will be only in USD"
                );
                dispatch(actions.loadCurrencyRate(e.toUpperCase()));
              }}
            >
              {[...currencies]
                .filter((currency) => currency.key === "usd")
                .concat(
                  [...currencies]
                    .filter((currency) => currency.key !== "usd")
                    .sort((a, b) => a.value.localeCompare(b.value))
                )

                .map((currency) => {
                  return (
                    <NavDropdown.Item eventKey={currency.key}>
                      <div
                        className={`text-white currency-flag currency-flag-${currency.key}`}
                      />
                      &nbsp;&nbsp;{currency.value}
                    </NavDropdown.Item>
                  );
                })}
              {/* <NavDropdown.Divider /> */}
            </NavDropdown>
            <Nav.Link
              className={`text-white body-text2 main-nav-links ${isAuthenticated ? "" : "d-none"
                }`}
              data-cy="navbar-my-account"
              onMouseEnter={() => {
                setProfileDropDown(true);
              }}
              onMouseLeave={() => {
                setProfileDropDown(false);
              }}
              style={{ textDecoration: "none" }}
            >
              <div className="position-relative" style={{ zIndex: 2 }}>
                <div className="body-text2 poppins">My Account</div>
                {(param1 === "profile" ||
                  param1 === "orders" ||
                  param1 === "favourites" ||
                  (param1 === "sell-3d" &&
                    (param2 === "uploadproducts" ||
                      param2 === "myproducts"))) && (
                    <div
                      NuButtonBoot
                      className="bg-secondary border-underline mt-1"
                      style={{ width: 20 }}
                    />
                  )}
                <ProfileDropdown profileDropdown={profileDropdown} />
              </div>
            </Nav.Link>

            {/* Login Button on Navbar */}

            {!isAuthenticated &&
              !(param1 === "sell-3d" || param1 === "sell-3d-login") && (
                <Button
                  text
                  className="explore-button-no-neo btn btn-primary"
                  data-cy="navbar-desktop-login-button"
                  dark={true}
                  size="lg"
                  onClick={() => setLoginShow(true)}
                >
                  LOGIN
                </Button>
              )}
            {!isAuthenticated &&
              (param1 === "sell-3d" || param1 === "sell-3d-login") && (
                <Link to="/sell-3d-login" style={{ textDecoration: "none" }}>
                  <Button
                    text
                    dark={true}
                    className="explore-button-no-neo btn btn-primary"
                  >
                    LOGIN
                  </Button>
                </Link>
              )}

            {/* Modal for Login */}

            <Modal
              show={loginShow}
              onHide={() => setLoginShow(false)}
              size="lg"
              // className=""
              data-cy="signin-modal"
              dialogClassName="d-flex justify-content-center"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              contentClassName="w-dialog d-flex justify-content-center align-items-center w-contentBox user-select-none"
              backdrop={isAuthenticated ? "static" : true}
              backdropClassName="modal-backdrop login-modal-backdrop"
              onExited={() => {
                setForOtp(false);
              }}
            >
              <Modal.Body
                className="w-dialog w-contentBox overflow-hidden p-0"
                style={{
                  backgroundColor: "#2E2E2E",
                }}
              >
                <IconButton
                  size="large"
                  className="d-flex justify-content-center removeButtonRight position-absolute text-white mt-4 me-4"
                  // className={`${!isAuthenticated && (user.name === null || user.email === "")
                  //   ? "d-flex justify-content-center removeButtonRight position-absolute text-white"
                  //   : "d-none"
                  //   }`}
                  dark={true}
                  outlined
                  rounded
                  style={{ borderColor: "#3D3D3D", backgroundColor: "#292929" }}
                  onClick={() => {
                    setLoginShow(false);
                    setLogin(true);
                    setForgotPassword(false);
                    setCreateAccount(false);
                    setEmailSentNote(false);
                    setEmailResetNote(false);
                    setAccountCreated(false);
                  }}
                >
                  <AiOutlineClose size={20} />
                </IconButton>
                <Container>
                  <Row>
                    <Col
                      sm={6}
                      className="p-5 login_background overflow-auto"
                      style={{ height: 600 }}
                    >
                      <div className="d-flex align-items-center">
                        {!login && (
                          // <Button
                          //   rounded
                          //   size="large"
                          //   className="d-flex justify-content-center text-white"
                          //   // className={`${!isAuthenticated && (user.name === null || user.email === "")
                          //   //   ? "d-flex justify-content-center removeButtonRight position-absolute text-white"
                          //   //   : "d-none"
                          //   //   }`}
                          //   text={false}
                          //   dark={true}
                          //   outlined
                          //   style={{
                          //     borderColor: "#3D3D3D",
                          //     backgroundColor: "#292929",
                          //     borderRadius:"50%"
                          //   }}
                          //   onClick={() => {
                          //     setLogin(true);
                          //     setForgotPassword(false);
                          //     setCreateAccount(false);
                          //     setEmailSentNote(false);
                          //     setAccountCreated(false);
                          //   }}
                          // >
                          //   <MdArrowBackIosNew size={20} />
                          // </Button>
                          <img
                            src={seller_signup_back}
                            alt="Seller Signup Back"
                            onClick={() => {
                              setLogin(true);
                              setForgotPassword(false);
                              setCreateAccount(false);
                              setEmailSentNote(false);
                              setAccountCreated(false);
                              setEmailResetNote(false);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        {login && (
                          <span
                            className="text-white subtitle"
                            style={{ fontWeight: 275 }}
                          >
                            {/* <img
                              src={seller_signup_back}
                              onClick={() => setLoginShow(false)}
                            /> */}
                            &nbsp; Happy to see you again
                          </span>
                        )}
                        {forgotPassword && (
                          <span
                            className="text-white mx-3  subtitle"
                            style={{ fontWeight: 275 }}
                          >
                            Don't worry! we've got your back
                          </span>
                        )}
                        {createAccount && (
                          <span
                            className="text-white mx-3 subtitle"
                            style={{ fontWeight: 275 }}
                          >
                            We are happy that you chose us!
                          </span>
                        )}
                        {login && (
                          <Image src={excited} alt="Excited" loading="lazy" />
                        )}
                        {forgotPassword && (
                          <Image src={happy} alt="Happy" loading="lazy" />
                        )}
                        {createAccount && (
                          <Image src={glad} alt="Glad" loading="lazy" />
                        )}
                      </div>
                      <Form
                        ref={loginForm}
                        className={`text-start ${login ? "d-block" : "d-none"}`}
                        onSubmit={(e) => handleLoginKey(e)}
                      >
                        <div
                          className={`${forOtp || isAuthenticated ? "d-none" : "d-block"
                            }`}
                        >
                          <Form.Group className="w-100">
                            <Form.Label data-cy="login-to-znanye-text" className="text-white subtitle mt-4">
                              Login To Znanye
                            </Form.Label>
                            <p className="text-white caption">
                              Buy 3D models, Made By Awesome Artists
                            </p>
                            {/* <GoogleLogin
                              onSuccess={credentialResponse => {
                                //console.log(credentialResponse);
                              }}
                              onError={() => {
                                //console.log('Login Failed');
                              }}
                            />; */}
                            <AppleGoogleFacebookLogin
                              buyer={true}
                              seller={false}
                            />
                            <div className="d-flex flex-column pt-2">
                              <Form.Control
                                required
                                data-cy="login-username"
                                name="username"
                                type="email"
                                size="lg"
                                placeholder="Enter Email"
                                className="mb-3 text-white"
                                style={{
                                  background: "#141414",
                                  height: 50,
                                  borderColor: "#3D3D3D",
                                  borderRadius: 10,
                                }}
                                onChange={(e) => {
                                  setUsername(e.target.value);
                                }}
                              />
                              <Form.Group className="d-flex justify-content-end align-items-center position-relative mb-3">
                                <Form.Control
                                  required
                                  data-cy="login-password"
                                  name="password"
                                  type={`${eyeOn ? "text" : "password"}`}
                                  size="lg"
                                  placeholder="Enter Password"
                                  className="text-white"
                                  style={{
                                    background: "#141414",
                                    height: 50,
                                    borderColor: "#3D3D3D",
                                    borderRadius: 10,
                                  }}
                                  onChange={(e) => {
                                    setPassword(e.target.value);
                                  }}
                                />
                                {!eyeOn && (
                                  <Button
                                    type="button"
                                    className="wrapped-button position-absolute mx-2"
                                    style={{
                                      backgroundColor: "#141414",
                                      border: 0,
                                    }}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEyeOn(true);
                                    }}
                                  >
                                    <Image src={eye} alt="Eye" loading="lazy" />
                                  </Button>
                                )}
                                {eyeOn && (
                                  <Button
                                    type="button"
                                    className="wrapped-button position-absolute mx-2"
                                    style={{
                                      backgroundColor: "#141414",
                                      border: 0,
                                    }}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEyeOn(false);
                                    }}
                                  >
                                    <Image
                                      src={eye_off}
                                      alt="Eye Off"
                                      loading="lazy"
                                    />
                                  </Button>
                                )}
                              </Form.Group>
                              <div className="text-white text-end">
                                <Button
                                  className="wrapped-button btn btn-ptimary  caption"
                                  onClick={() => {
                                    setLogin(false);
                                    setForgotPassword(true);
                                    setCreateAccount(false);
                                    setEmailSentNote(false);
                                    setEmailResetNote(false);
                                    setAccountCreated(false);
                                  }}
                                  variant="link"
                                >
                                  Forgot Password
                                </Button>
                              </div>
                            </div>
                          </Form.Group>
                          <div className="text-center">
                            <Button
                              className="btn btn-link border-0 mb-2 wrapped-button w-100 p-0"
                              data-cy="get-me-in-button"
                              style={{
                                background: "transparent",
                                textDecoration: "none",
                              }}
                              type="submit"
                            >
                              <Button
                                className="mt-2 explore-button-no-neo w-100 br-10 btn btn-primary d-flex justify-content-center align-items-center"
                                style={{
                                  border: changingBorder ? "0px" : "0px",
                                }}
                                onMouseEnter={() => setChangingBroder(true)}
                                onMouseLeave={() => setChangingBroder(false)}
                                disabled={isSending}
                                size="lg"
                                dark={true}
                                onClick={async (e) => {
                                  e.preventDefault();
                                  setIsSending(true);
                                  let email_regex =
                                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                                  if (username.match(email_regex)) {
                                    const response = await actions.loggingIn({
                                      email: username,
                                      password: password,
                                    });
                                    if (response) {
                                      dispatch(actions.login(response));
                                      dispatch(actions.loadUserFavourite(response.user.id))
                                      dispatch(actions.loadUserSavedForLater(response.user.id));
                                      // setForOtp(true);
                                      // setNext(false);
                                      // setTimer(response.time_left);
                                    }
                                    setIsSending(false);
                                  } else {
                                    toast.error("Please enter a valid email!");
                                  }
                                  setIsSending(false);
                                }}
                              >
                                {isSending ? (
                                  <span>
                                    <Spinner
                                      className="me-2"
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    LOGGING ...
                                  </span>
                                ) : (
                                  <span className="poppins">GET ME IN</span>
                                )}
                              </Button>
                            </Button>
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="text-white caption">
                              Don't have an account? Don't worry!{" "}
                            </div>
                            <Button
                              className="wrapped-button btn btn-ptimary caption"
                              onClick={() => {
                                setCreateAccount(true);
                                setForgotPassword(false);
                                setLogin(false);
                                setEmailSentNote(false);
                                setEmailResetNote(false);
                                setAccountCreated(false);
                              }}
                              variant="link"
                            >
                              Create an Account
                            </Button>
                            <div
                              className={`${timer !== 0
                                ? "text-white mx-2 caption"
                                : "d-none"
                                }`}
                            >
                              {`after ${timer} seconds`}
                            </div>
                          </div>
                        </div>
                      </Form>
                      <Form
                        ref={loginForm}
                        className="text-start"
                        onSubmit={(e) => handleReset(e)}
                      >
                        <div
                          className={`${forgotPassword && !isAuthenticated
                            ? "d-block"
                            : "d-none"
                            }`}
                        >
                          <Form.Group className="w-100">
                            <Form.Label className="text-white subtitle mt-4">
                              Forgot Password
                            </Form.Label>
                            <p className="text-white caption">
                              Enter Your Registered Email Id
                            </p>
                            <br />
                            <div className="d-flex flex-column">
                              <Form.Control
                                required
                                name="username"
                                type="email"
                                size="lg"
                                placeholder="Enter your Email"
                                className="text-white"
                                style={{
                                  background: "#141414",
                                  height: 50,
                                  borderColor: "#3D3D3D",
                                  borderRadius: 10,
                                }}
                                onChange={(e) => {
                                  setUsername(e.target.value);
                                }}
                              />
                            </div>
                          </Form.Group>
                          <div className="text-center">
                            <Button
                              className="btn btn-link border-0  wrapped-button w-100 p-0"
                              style={{
                                background: "transparent",
                                textDecoration: "none",
                              }}
                              type="submit"
                            >
                              <Button
                                className="mt-5 mb-2 explore-button-no-neo btn btn-ptimary"
                                dark={true}
                                size="lg"
                                disabled={resetSending}
                                style={{
                                  border: changingBorder ? "0px" : "0px",
                                }}
                                onMouseEnter={() => setChangingBroder(true)}
                                onMouseLeave={() => setChangingBroder(false)}
                                onClick={async (e) => {
                                  e.preventDefault();
                                  setResetSending(true);
                                  let email_regex =
                                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                                  // alert(username)
                                  if (username.match(email_regex)) {
                                    const response =
                                      await actions.reset_password_request({
                                        email: username,
                                      });
                                    if (response) {
                                      switch (response.status) {
                                        case "DOES_NOT_EXIST":
                                          // toast.error(response.msg);
                                          break;
                                        case "EMAIL_SENT":
                                          setForgotPassword(false);
                                          setEmailResetNote(true);
                                          // toast.success(response.msg);
                                          break;
                                        default:
                                          alert("some error");
                                      }
                                    }
                                  } else {
                                    setResetSending(false);
                                    toast.error("Please enter a valid email!");
                                  }
                                  setResetSending(false);
                                }}
                              >
                                {resetSending ? (
                                  <span>
                                    <Spinner
                                      className="me-2"
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    SENDING...
                                  </span>
                                ) : (
                                  <span className="poppins">
                                    SEND ME A RESET LINK
                                  </span>
                                )}
                              </Button>
                            </Button>
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="text-white caption">
                              Already have an account{" "}
                            </div>
                            <Button
                              className="wrapped-button  caption"
                              onClick={() => {
                                setForgotPassword(false);
                                setLogin(true);
                                setCreateAccount(false);
                                setEmailSentNote(false);
                                setEmailResetNote(false);
                                setAccountCreated(false);
                              }}
                              variant="link"
                            >
                              Log In Here
                            </Button>
                            <div
                              className={`${timer !== 0
                                ? "text-white mx-2 caption"
                                : "d-none"
                                }`}
                            >
                              {`after ${timer} seconds`}
                            </div>
                          </div>
                        </div>
                      </Form>
                      <div
                        className={`${emailSentNote && !isAuthenticated
                          ? "d-flex flex-column justify-content-center align-items-start h-100"
                          : "d-none"
                          }`}
                      >
                        <p className="text-white subtitle mx-4">
                          We have sent you a verification link
                        </p>
                        <div
                          className="text-white d-flex align-items-center h4 m-4"
                          style={{ fontWeight: 500, lineHeight: "36px" }}
                        >
                          <p>
                            Kindly Verify your email{" "}
                            <b>
                              <i>{username}</i>
                            </b>
                          </p>
                        </div>
                      </div>
                      <div
                        className={`${emailResetNote && !isAuthenticated
                          ? "d-flex justify-content-center align-items-center h-100"
                          : "d-none"
                          }`}
                      >
                        <div
                          className="text-white d-flex align-items-center h4 m-4"
                          style={{ fontWeight: 400 }}
                        >
                          <p>
                            We have shared a link for reset your password on
                            your email Id <i>{username}</i> Kindly check your
                            email.
                          </p>
                        </div>
                      </div>
                      <div
                        className={`${accountCreated && !isAuthenticated
                          ? "d-flex justify-content-center align-items-center"
                          : "d-none"
                          }`}
                      >
                        <div
                          className="text-white d-flex flex-column justify-content-center h4 mt-5 pt-5"
                          style={{ fontWeight: 400 }}
                        >
                          <p className="h3 mb-5">
                            Yeahhh !!! Account Has Been Created.
                          </p>
                          <p className="h4">
                            You Are Now The Part Of The Znanye Members
                          </p>
                        </div>
                      </div>
                      <Form
                        ref={userForm}
                        className="text-start"
                        onSubmit={(e) => handleProfileSubmit(e)}
                      >
                        <div
                          className={`${!isAuthenticated && createAccount
                            ? "d-block"
                            : "d-none"
                            }`}
                        >
                          <Form.Group className="w-100" controlId="formDetails">
                            <Form.Label className="text-white subtitle mt-4">
                              Create Account
                            </Form.Label>
                            <p className="text-white caption">
                              Enter Your Details
                            </p>
                            <AppleGoogleFacebookLogin
                              buyer={true}
                              seller={false}
                            />
                            <br />
                            {/* <Form.Label className="text-white">Select Country</Form.Label> */}
                            <div className="d-flex">
                              <Form.Control
                                required
                                name="name"
                                placeholder="Name"
                                type="text"
                                size="lg"
                                className="mb-4 text-white"
                                style={{
                                  background: "#141414",
                                  height: 50,
                                  borderColor: "#3D3D3D",
                                  borderRadius: 10,
                                }}
                              />
                            </div>

                            <Form.Control
                              required
                              name="email"
                              type="email"
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                              placeholder="Email"
                              size="lg"
                              className="mb-4 text-white"
                              style={{
                                background: "#141414",
                                height: 50,
                                borderColor: "#3D3D3D",
                                borderRadius: 10,
                              }}
                              onInvalid={(e) => {
                                return e.target.setCustomValidity(
                                  "Please enter valid email id."
                                );
                              }}
                              onChange={(e) => {
                                setUsername(e.target.value);
                                return e.target.setCustomValidity("");
                              }}
                            />
                            <div className="position-relative d-flex justify-content-end align-items-center mb-4">
                              <Form.Select
                                required
                                name="phone_code"
                                type="text"
                                className="my-select text-white subtitle pe-0 py-2"
                                style={{
                                  borderRadius: 10,
                                }}
                                value={countryCode}
                                onChange={(e) => setCountryCode(e.target.value)}
                              >
                                <option
                                  key=""
                                  value=""
                                  selected
                                >
                                  -- Select Region --
                                </option>
                                {countries
                                  ?.filter((option) => {
                                    if (
                                      !uniqueCountries.some(
                                        (country) =>
                                          country.name === option.name
                                      )
                                    ) {
                                      uniqueCountries.push(option);
                                      return true;
                                    }
                                    return false;
                                  })
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map((option) => {
                                    return (
                                      <option
                                        key={option.phone_code}
                                        value={option.phone_code}
                                      >
                                        {option.name}
                                      </option>
                                    );
                                    // if (option.code === "IN") {
                                    //   return (
                                    //     <option
                                    //       key={option.phone_code}
                                    //       value={option.phone_code}
                                    //       selected
                                    //     >
                                    //       {option.name}
                                    //     </option>
                                    //   );
                                    // } else {
                                    //   return (
                                    //     <option
                                    //       key={option.phone_code}
                                    //       value={option.phone_code}
                                    //     >
                                    //       {option.name}
                                    //     </option>
                                    //   );
                                    // }
                                  })}
                              </Form.Select>
                              {/* <IoChevronDown
                                color="#FFF"
                                size={20}
                                className="position-absolute mx-3"
                              /> */}
                            </div>
                            <Form.Group className="d-flex justify-content-end align-items-start position-relative">
                              <Form.Control
                                required
                                name="password"
                                type={`${eyeOn ? "text" : "password"}`}
                                size="lg"
                                placeholder="Password"
                                className="mb-4 text-white"
                                style={{
                                  background: "#141414",
                                  height: 50,
                                  borderColor: "#3D3D3D",
                                  borderRadius: 10,
                                }}
                              />
                              {!eyeOn && (
                                <Button
                                  type="button"
                                  className="wrapped-button position-absolute m-1"
                                  style={{
                                    backgroundColor: "#141414",
                                    border: 0,
                                  }}
                                  onKeyDown={(e) => e.preventDefault()}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEyeOn(true);
                                  }}
                                >
                                  <Image src={eye} alt="Eye" loading="lazy" />
                                </Button>
                              )}
                              {eyeOn && (
                                <Button
                                  type="button"
                                  className="wrapped-button position-absolute m-1"
                                  style={{
                                    backgroundColor: "#141414",
                                    border: 0,
                                  }}
                                  onKeyDown={(e) => e.preventDefault()}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEyeOn(false);
                                  }}
                                >
                                  <Image
                                    src={eye_off}
                                    alt="Eye Off"
                                    loading="lazy"
                                  />
                                </Button>
                              )}
                            </Form.Group>
                            <Form.Group className="d-flex justify-content-end align-items-start position-relative">
                              <Form.Control
                                required
                                name="confirm_password"
                                type={`${eyeOnTwo ? "text" : "password"}`}
                                size="lg"
                                placeholder="Confirm Password"
                                className="mb-4 text-white"
                                style={{
                                  background: "#141414",
                                  height: 50,
                                  borderColor: "#3D3D3D",
                                  borderRadius: 10,
                                }}
                              />
                              {!eyeOnTwo && (
                                <Button
                                  type="button"
                                  className="wrapped-button position-absolute m-1"
                                  style={{
                                    backgroundColor: "#141414",
                                    border: 0,
                                  }}
                                  onKeyDown={(e) => e.preventDefault()}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEyeOnTwo(true);
                                  }}
                                >
                                  <Image src={eye} alt="Eye" loading="lazy" />
                                </Button>
                              )}
                              {eyeOnTwo && (
                                <Button
                                  type="button"
                                  className="wrapped-button position-absolute m-1"
                                  style={{
                                    backgroundColor: "#141414",
                                    border: 0,
                                  }}
                                  onKeyDown={(e) => e.preventDefault()}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEyeOnTwo(false);
                                  }}
                                >
                                  <Image
                                    src={eye_off}
                                    alt="Eye Off"
                                    loading="lazy"
                                  />
                                </Button>
                              )}
                            </Form.Group>
                          </Form.Group>
                          <div className="text-center">
                            <Button
                              className="btn btn-link border-0 my-4 wrapped-button w-100 p-0"
                              style={{
                                background: "transparent",
                                textDecoration: "none",
                              }}
                              type="submit"
                            >
                              <Button
                                className="mt-2 explore-button-no-neo w-100 br-10 btn btn-primary d-flex justify-content-center align-items-center"
                                style={{
                                  border: changingBorder ? "0px" : "0px",
                                }}
                                // onKeyDown={handleCreateAccountKey}
                                onMouseEnter={() => setChangingBroder(true)}
                                onMouseLeave={() => setChangingBroder(false)}
                                size="lg"
                                dark={true}
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? (
                                  <span>
                                    <Spinner
                                      className="me-2"
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    SUBMITTING ...
                                  </span>
                                ) : (
                                  <span className="poppins">
                                    CREATE MY ACCOUNT
                                  </span>
                                )}
                              </Button>
                            </Button>
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="text-white mx-3 caption">
                              Already have an account?{" "}
                            </div>
                            <Button
                              className="wrapped-button caption"
                              onClick={() => {
                                setForgotPassword(false);
                                setLogin(true);
                                setCreateAccount(false);
                                setEmailSentNote(false);
                                setEmailResetNote(false);
                                setAccountCreated(false);
                              }}
                              variant="link"
                            >
                              Login here
                            </Button>
                            <div
                              className={`${timer !== 0
                                ? "text-white mx-2 caption"
                                : "d-none"
                                }`}
                            >
                              {`after ${timer} seconds`}
                            </div>
                          </div>
                        </div>
                      </Form>
                    </Col>
                    <Col
                      sm={6}
                      className="d-flex flex-column justify-content-between align-items-center"
                    >
                      <div
                        className={`${emailResetNote || emailSentNote || accountCreated
                          ? "d-none"
                          : "d-flex"
                          } justify-content-center align-items-end h-100`}
                      >
                        <div className="p-4 text-white notch-div text-center">
                          {login && (
                            <span>
                              Hope you find <br /> what you want !
                            </span>
                          )}
                          {forgotPassword && <span>Oops !</span>}
                          {createAccount && <span>Cheers !</span>}
                        </div>
                        {<div className="notch"></div>}
                      </div>
                      <div className="h-100 d-flex align-items-center mt-4">
                        {login && (
                          <Image
                            src={login_mascot}
                            alt="Login Mascot"
                            loading="lazy"
                          />
                        )}
                        {forgotPassword && (
                          <Image
                            src={forgot_mascot}
                            alt="Password Mascot"
                            loading="lazy"
                          />
                        )}
                        {createAccount && (
                          <Image
                            src={create_account_mascot}
                            alt="Create Account Mascot"
                            loading="lazy"
                          />
                        )}
                        {(emailSentNote || emailResetNote) && (
                          <Image
                            src={email_sent}
                            alt="Email Sent"
                            loading="lazy"
                          />
                        )}
                        {accountCreated && (
                          <Image
                            src={celebrate_mascot}
                            alt="Celebrate Mascot"
                            loading="lazy"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
                {/* <Modal.Header
                className="d-flex flex-row justify-content-center"
                style={{
                  backgroundColor: "#1F1F1F",
                  border: "2.5px solid #BEBEBE",
                }}
              >
                <Modal.Title id="contained-modal-title-vcenter">
                  <Image loading="lazy" src={znanyelogin} alt="Znanye Login" className="my-4" />
                </Modal.Title>
              </Modal.Header> */}
              </Modal.Body>
            </Modal>
          </Container>
          {/* {showOffer &&
            <div className="w-100">
              <div className="position-relative">
                <Image loading="lazy" className="w-100" src={newoffer40bg} style={{ top: 0, left: 0, zIndex: 1 }} />
                <Image
                  loading="lazy"
                  src={xcircle}
                  className="position-absolute cursor-pointer"
                  style={{ minWidth: 30, minHeight: 30, zIndex: 999, right: 10, top: 10 }}
                  onClick={(e) => closeOffer(e)}
                />
              </div>
            </div>
          } */}
        </Navbar>
        {user.region === null && isAuthenticated && (
          // <div
          //   className="modal show d-flex justify-content-center align-items-center"
          //   style={{ position: "absolute" }}
          // >
          <div id="modalTest">
            <Modal
              show={showRegionModal}
              style={{ zIndex: 3000 }}
              size="md"
              dialogClassName="d-flex justify-content-center"
              centered
              contentClassName="d-flex justify-content-center align-items-center user-select-none w-contentBox"
              backdrop={isAuthenticated ? "static" : true}
              backdropClassName="modal-backdrop login-modal-backdrop"
              onExited={() => {
                setForOtp(false);
              }}
            >
              <Modal.Body
                className="px-5 py-4 w-100"
                style={{
                  backgroundColor: "#141415",
                }}
              >
                <Modal.Title className="my-4 d-flex justify-content-center">
                  <div
                    className="text-white poppins-500"
                    style={{ fontSize: 20 }}
                  >
                    Select Your Region
                  </div>
                </Modal.Title>
                <div className="mb-5">
                  <ZDropdown
                    options={countryList}
                    mode="region"
                    region={region}
                    setRegion={setRegion}
                  />
                </div>
                <Button
                  className="mt-4 mb-2 explore-button-no-neo w-100 br-10 btn btn-primary d-flex justify-content-center align-items-center"
                  style={{ border: changingBorder ? "0px" : "0px" }}
                  onMouseEnter={() => setChangingBroder(true)}
                  onMouseLeave={() => setChangingBroder(false)}
                  onClick={() => {
                    setisUpdatingRegion(true);
                    handleSetRegion();
                  }}
                  size="lg"
                  dark={true}
                >
                  {isUpdatingRegion ? (
                    <span className="poppins-500" style={{ fontSize: 14 }}>
                      <Spinner
                        // className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      UPDATING ...
                    </span>
                  ) : (
                    <span className="poppins-500" style={{ fontSize: 14 }}>
                      {" "}
                      UPDATE MY REGION
                    </span>
                  )}
                </Button>
              </Modal.Body>
            </Modal>
          </div>
          // </div>
        )}
      </Breakpoint>
      <Breakpoint medium down>
        <Navbar
          className="navbar-background d-flex flex-column w-100"
          fixed="top"
          style={{
            borderBottom: "1px solid #3D3D3D",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Container
            fluid
            className={`${showMobileSearchBar ? "pb-2 px-0 mx-0 " : "pb-2 px-0 mx-0 "
              }`}
            style={{ width: 95 + "%" }}
          >
            <>
              {!showMobileSearchBar && (
                <Navbar.Brand href="/">
                  <Image
                    alt="Znanye Logo"
                    src={transparent_image}
                    loading="lazy"
                    className="d-inline-block align-center sprites-logo"
                  />
                </Navbar.Brand>
              )}
              {/* <Nav className="ms-auto">
              <NavDropdown
                className="d-inline-block align-end"
                title={
                  <div
                    className={`text-white currency-flag currency-flag-${currency}`}
                  ></div>
                }
                menuVariant="dark"
                align="end"
                onSelect={(e) => {
                  //console.log(e);
                  setCurrency(e);
                  dispatch(actions.loadCurrencyRate(e.toUpperCase()));
                }}
              >
                {currencies.map((currency) => {
                  return (
                    <NavDropdown.Item eventKey={currency.key}>
                      <div
                        className={`text-white currency-flag currency-flag-${currency.key}`}
                      />
                      &nbsp;&nbsp;{currency.value}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            </Nav> */}

              {open ? (
                <div>
                  {showMobileSearchBar ? (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <Searchbar
                            className="mx-2"
                            type="mobileNavbar"
                            navSearchDropdown={navSearchDropdown}
                            setNavSearchDropdown={setNavSearchDropdown}
                            setShowSearchBar={setShowMobileSearchBar}
                            setOpen={setOpen}
                          />
                        </div>
                        <div className="mx-2">
                          <Button
                            size="lg"
                            dark={true}
                            variant="dark"
                            className="btn-znanye-search w-100 d-flex align-items-center justify-content-center"
                            // style={styles.znanyeMenuButton}
                            onClick={() => {
                              setShowMobileSearchBar(false);
                            }}
                          >
                            <span
                              className=" mx-2 text-white"
                              style={{ fontSize: 14 }}
                            >
                              CANCEL
                            </span>
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Button
                      size="lg"
                      dark={true}
                      variant="dark"
                      className="btn-znanye-search mx-2"
                      style={styles.znanyeMenuButton}
                      onClick={() => {
                        setNavSearchDropdown(true);
                        setShowMobileSearchBar(true);
                      }}
                    >
                      <Image
                        clsssName="sprites-search-icon"
                        src={transparent_image}
                        alt="SEARCH"
                        loading="lazy"
                        style={styles.shoppingcart}
                      />
                    </Button>
                  )}
                  {!showMobileSearchBar && (
                    <>
                      {isAuthenticated && (
                        <Button
                          size="lg"
                          dark={true}
                          variant="dark"
                          className="btn-znanye-cart mx-2"
                          style={styles.znanyeMenuButton}
                          onClick={() => {
                            setOpen(!open);
                            handleNavigationClick("/notifications");
                            // navigate("notifications");
                          }}
                        >
                          <Badge
                            bgColor="var(--error)"
                            inline
                            content={notificationsForUser?.length}
                            className={`align-self-center`}
                            style={{
                              paddingLeft: "10px",
                              position: "absolute",
                              top: "2px",
                            }}
                          ></Badge>
                          <Image
                            className="sprites-bell-icon"
                            src={transparent_image}
                            alt="Notifications"
                            loading="lazy"
                            style={styles.shoppingcart}
                          />
                        </Button>
                      )}
                      <Button
                        size="lg"
                        dark={true}
                        variant="dark"
                        className="btn-znanye-cart mx-2"
                        style={styles.znanyeMenuButton}
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        <Badge
                          bgColor="var(--error)"
                          inline
                          content={cartSize}
                          className={`align-self-center`}
                          style={{
                            paddingLeft: "10px",
                            position: "absolute",
                            top: "2px",
                          }}
                        ></Badge>
                        <Image
                          className="sprites-shopping-cart"
                          src={transparent_image}
                          alt="Cart"
                          loading="lazy"
                          style={styles.shoppingcart}
                        />
                      </Button>

                      <Button
                        size="lg"
                        dark={true}
                        variant="dark"
                        className="btn-znanye-menu mx-2"
                        style={styles.znanyeMenuButton}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <Image
                          src={cross}
                          alt="Mobile Menu"
                          loading="lazy"
                          style={styles.shoppingcart}
                        />
                      </Button>
                    </>
                  )}
                </div>
              ) : (
                <div className="d-flex">
                  {showMobileSearchBar ? (
                    <>
                      <div className=" mt-2 d-flex align-items-center justify-content-between">
                        <div>
                          <Searchbar
                            className="mx-2"
                            type="mobileNavbar"
                            navSearchDropdown={navSearchDropdown}
                            setNavSearchDropdown={setNavSearchDropdown}
                            setShowSearchBar={setShowMobileSearchBar}
                            setOpen={setOpen}
                          />
                        </div>
                        <div className="mx-2">
                          <Button
                            size="lg"
                            dark={true}
                            variant="dark"
                            className="btn-znanye-search w-100 d-flex align-items-center justify-content-center"
                            // style={styles.znanyeMenuButton}
                            onClick={() => {
                              setShowMobileSearchBar(false);
                            }}
                          >
                            <span
                              className=" mx-2 text-white"
                              style={{ fontSize: 14 }}
                            >
                              CANCEL
                            </span>
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Button
                      size="lg"
                      dark={true}
                      variant="dark"
                      className="btn-znanye-search mx-2"
                      style={styles.znanyeMenuButton}
                      onClick={() => {
                        setNavSearchDropdown(true);
                        setShowMobileSearchBar(true);
                      }}
                    >
                      <Image
                        className="sprites-search-icon"
                        src={transparent_image}
                        alt="SEARCH"
                        loading="lazy"
                        style={styles.shoppingcart}
                      />
                    </Button>
                  )}
                  {isAuthenticated && !showMobileSearchBar && (
                    <div>
                      <Button
                        size="lg"
                        dark={true}
                        variant="dark"
                        className="btn-znanye-search mx-2 d-flex justify-content-center align-items-center"
                        style={styles.znanyeMenuButton}
                        onClick={() => {
                          handleNavigationClick("/notifications");
                          // navigate("notifications");
                        }}
                      >
                        <Badge
                          overlap
                          bgColor="var(--error)"
                          content={notificationsForUser?.length}
                          style={{
                            paddingLeft: "30px",
                            position: "absolute",
                            top: "10px",
                          }}
                        ></Badge>
                        <Image
                          className="sprites-bell-icon"
                          src={transparent_image}
                          alt="Notifications"
                          loading="lazy"
                          style={styles.shoppingcart}
                        />
                      </Button>
                    </div>
                  )}
                  {!showMobileSearchBar && (
                    <>
                      <Button
                        size="lg"
                        // as={NavLink}
                        to="cart"
                        dark={true}
                        variant="dark"
                        className="btn-znanye-cart mx-2"
                        style={styles.znanyeMenuButton}
                        onClick={() => {
                          // setOpen(!open);
                          handleNavigationClick("/cart");

                          // navigate("/cart");
                        }}
                      >
                        <Badge
                          bgColor="var(--error)"
                          inline
                          content={cartSize}
                          className={`align-self-center`}
                          style={{
                            paddingLeft: "10px",
                            position: "absolute",
                            top: "2px",
                          }}
                        ></Badge>
                        <Image
                          className="sprites-shopping-cart-icon"
                          src={transparent_image}
                          alt="Cart"
                          loading="lazy"
                          style={styles.shoppingcart}
                        />
                      </Button>

                      <Button
                        size="lg"
                        dark={true}
                        variant="dark"
                        className="btn-znanye-menu mx-2 d-flex justify-content-center align-items-center"
                        style={styles.znanyeMenuButton}
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        <Image
                          src={mobilemenu}
                          alt="Mobile Menu"
                          loading="lazy"
                          style={styles.shoppingcart}
                        />
                      </Button>
                    </>
                  )}
                </div>
              )}
            </>
          </Container>
          {/* {showOffer &&
            <div className="w-100">
              <div className="position-relative">
                <Image
                  loading="lazy"
                  className="w-100"
                  src={breakpoint === "xsmall" ? newoffer40bgxsmall : newoffer40bgmobile}
                  style={{ top: 0, left: 0, zIndex: 1 }}
                />
                <Image
                  loading="lazy"
                  src={xcircle}
                  className="position-absolute cursor-pointer"
                  style={breakpoint === "xsmall" ? styles.bannerCrossButtonMobile : styles.bannerCrossButton}
                  onClick={(e) => closeOffer(e)}
                />
              </div>
            </div>
          } */}
        </Navbar>

        {transition(
          (props, item) =>
            item && (
              <Modal
                className="custom-modal"
                // as={animated.div}
                contentClassName="custom-modal-dialog"
                dialogClassName="custom-modal-dialog"
                show={open}
                style={{
                  // transition:s
                  // " transform 1s ease-in-out",
                  overflow: "hidden",
                  backgroundColor: "#1B1C1D",
                  // opacity:  "1" ,
                  // transform:  "scale(1)" ,
                }}
              >
                <Modal.Header
                  className="p-0 custom-modal "
                  style={{
                    border: "none !important",
                    borderBottom: "none !important",
                    backgroundColor: "#1B1C1D",
                  }}
                >
                  <Container
                    fluid
                    as={animated.div}
                    className={`${" d-flex  custom-modal mb-2  p-2 px-0 mx-0 "}`}
                    style={{
                      width: 100 + "%",
                      backgroundColor: "#1B1C1D ",
                      border: "0px !important",
                      justifyContent: "space-between",
                    }}
                  >
                    <>
                      {!showMobileSearchBar && (
                        <Navbar.Brand href="/" className="px-2 ">
                          <Image
                            alt="Znanye Logo"
                            src={transparent_image}
                            loading="lazy"
                            className="sprites-logo"
                          />
                        </Navbar.Brand>
                      )}
                      {open ? (
                        <div className="pt-1 ">
                          {showMobileSearchBar ? (
                            <>
                              <div className="d-flex">
                                <div>
                                  <Searchbar
                                    className="mx-2"
                                    type="mobileNavbar"
                                    navSearchDropdown={navSearchDropdown}
                                    setNavSearchDropdown={setNavSearchDropdown}
                                    setShowSearchBar={setShowMobileSearchBar}
                                    setOpen={setOpen}
                                  />
                                </div>
                                <div className="mx-2 mt-1">
                                  <Button
                                    size="lg"
                                    dark={true}
                                    variant="dark"
                                    className="btn-znanye-search w-100 d-flex align-items-center justify-content-center"
                                    // style={styles.znanyeMenuButton}
                                    onClick={() => {
                                      setShowMobileSearchBar(false);
                                    }}
                                  >
                                    <span
                                      className=" mx-2 text-white"
                                      style={{ fontSize: 14 }}
                                    >
                                      CANCEL
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <Button
                              size="lg"
                              dark={true}
                              variant="dark"
                              className="btn-znanye-search mx-2"
                              style={styles.znanyeMenuButton}
                              onClick={() => {
                                setNavSearchDropdown(true);
                                setShowMobileSearchBar(true);
                                // setOpen(!open);
                              }}
                            >
                              <Image
                                className="sprites-search-icon"
                                src={transparent_image}
                                alt="SEARCH"
                                loading="lazy"
                                style={styles.shoppingcart}
                              />
                            </Button>
                          )}
                          {!showMobileSearchBar && (
                            <>
                              {isAuthenticated && (
                                <Button
                                  size="lg"
                                  dark={true}
                                  variant="dark"
                                  className="btn-znanye-cart mx-2"
                                  style={styles.znanyeMenuButton}
                                  onClick={() => {
                                    setOpen(!open);
                                    handleNavigationClick("/notifications");
                                    // navigate("notifications");
                                  }}
                                >
                                  <Badge
                                    bgColor="var(--error)"
                                    inline
                                    content={notificationsForUser?.length}
                                    className={`align-self-center`}
                                    style={{
                                      paddingLeft: "10px",
                                      position: "absolute",
                                      top: "2px",
                                    }}
                                  ></Badge>
                                  <Image
                                    className="sprites-bell-icon"
                                    src={transparent_image}
                                    alt="Notifications"
                                    loading="lazy"
                                    style={styles.shoppingcart}
                                  />
                                </Button>
                              )}
                              <Button
                                size="lg"
                                dark={true}
                                variant="dark"
                                className="btn-znanye-cart mx-2"
                                style={styles.znanyeMenuButton}
                                onClick={() => {
                                  handleNavigationClick("/cart");

                                  // navigate("/cart");
                                  setOpen(!open);
                                }}
                              >
                                <Badge
                                  bgColor="var(--error)"
                                  inline
                                  content={cartSize}
                                  className={`align-self-center`}
                                  style={{
                                    paddingLeft: "10px",
                                    position: "absolute",
                                    top: "2px",
                                  }}
                                ></Badge>
                                <Image
                                  className="sprites-shopping-cart-icon"
                                  src={transparent_image}
                                  alt="Cart"
                                  loading="lazy"
                                  style={styles.shoppingcart}
                                />
                              </Button>

                              <Button
                                size="lg"
                                dark={true}
                                variant="dark"
                                className="btn-znanye-menu mx-2"
                                style={styles.znanyeMenuButton}
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                <Image
                                  src={cross}
                                  alt="Mobile Menu"
                                  loading="lazy"
                                  style={styles.shoppingcart}
                                />
                              </Button>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="">
                          {showMobileSearchBar ? (
                            <>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>
                                  <Searchbar
                                    className="mx-2"
                                    type="mobileNavbar"
                                    navSearchDropdown={navSearchDropdown}
                                    setNavSearchDropdown={setNavSearchDropdown}
                                    setShowSearchBar={setShowMobileSearchBar}
                                    setOpen={setOpen}
                                  />
                                </div>
                                <div className="mx-2">
                                  <Button
                                    size="lg"
                                    dark={true}
                                    variant="dark"
                                    className="btn-znanye-search w-100 d-flex align-items-center justify-content-center"
                                    // style={styles.znanyeMenuButton}
                                    onClick={() => {
                                      setShowMobileSearchBar(false);
                                    }}
                                  >
                                    <span
                                      className=" mx-2 text-white"
                                      style={{ fontSize: 14 }}
                                    >
                                      CANCEL
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <Button
                              size="lg"
                              dark={true}
                              variant="dark"
                              className="btn-znanye-search mx-2"
                              style={styles.znanyeMenuButton}
                              onClick={() => {
                                setNavSearchDropdown(true);
                                setShowMobileSearchBar(true);
                                // setOpen(!open);
                              }}
                            >
                              <Image
                                className="sprites-search-icon"
                                src={transparent_image}
                                alt="SEARCH"
                                loading="lazy"
                                style={styles.shoppingcart}
                              />
                            </Button>
                          )}
                          {isAuthenticated && !showMobileSearchBar && (
                            <div>
                              <Button
                                size="lg"
                                dark={true}
                                variant="dark"
                                className="btn-znanye-search mx-2 d-flex justify-content-center align-items-center"
                                style={styles.znanyeMenuButton}
                                onClick={() => {
                                  handleNavigationClick("/notifications");

                                  // navigate("notifications");
                                }}
                              >
                                <Badge
                                  overlap
                                  bgColor="var(--error)"
                                  content={notificationsForUser?.length}
                                  style={{
                                    paddingLeft: "30px",
                                    position: "absolute",
                                    top: "10px",
                                  }}
                                ></Badge>
                                <Image
                                  className="sprites-bell-icon"
                                  src={transparent_image}
                                  alt="Notifications"
                                  loading="lazy"
                                  style={styles.shoppingcart}
                                />
                              </Button>
                            </div>
                          )}

                          {!showMobileSearchBar && (
                            <>
                              <Button
                                size="lg"
                                as={NavLink}
                                to="cart"
                                dark={true}
                                variant="dark"
                                className="btn-znanye-cart mx-2"
                                style={styles.znanyeMenuButton}
                                onClick={() => {
                                  handleNavigationClick("/cart");

                                  // setOpen(!open);
                                }}
                              >
                                <Badge
                                  bgColor="var(--error)"
                                  inline
                                  content={cartSize}
                                  className={`align-self-center`}
                                  style={{
                                    paddingLeft: "10px",
                                    position: "absolute",
                                    top: "2px",
                                  }}
                                ></Badge>
                                <Image
                                  className="sprites-shopping-cart-icon"
                                  src={transparent_image}
                                  alt="Cart"
                                  loading="lazy"
                                  style={styles.shoppingcart}
                                />
                              </Button>

                              <Button
                                size="lg"
                                dark={true}
                                variant="dark"
                                className="btn-znanye-menu mx-2 d-flex justify-content-center align-items-center"
                                style={styles.znanyeMenuButton}
                                onClick={() => {
                                  setOpen(!open);
                                }}
                              >
                                <Image
                                  src={mobilemenu}
                                  alt="Mobile Menu"
                                  loading="lazy"
                                  style={styles.shoppingcart}
                                />
                              </Button>
                            </>
                          )}
                        </div>
                      )}
                    </>
                  </Container>
                </Modal.Header>
                <Modal.Body className="overflow-scroll">
                  <Container
                    as={animated.div}
                    className=" position-fixed overflow-scroll pb-5 w-100"
                    fluid
                    style={{
                      width: "100%",
                      height: "100%",
                      // height:"60vh",
                      y: props.y,
                      top: 56,
                      left: 0,
                      // transition:"smooth",
                      transition: "height 0.5s ease-in-out",
                      backgroundColor: "#1f1f1f",
                      zIndex: 999,
                      borderTop: "1px solid rgb(61, 61, 61)",
                    }}
                  >
                    <Nav
                      className={
                        !showMobileSearchBar
                          ? "  ms-auto main-nav-menu mt-1 d-flex align-items-start justify-content-center flex-column d-lg-none"
                          : "  ms-auto main-nav-menu d-flex align-items-start justify-content-center flex-column d-lg-none"
                      }
                    >
                      <Nav.Link
                        as={Link}
                        className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                        to="/"
                        onClick={() => {
                          setOpen(false);
                          handleNavigationClick("/");
                        }}
                        style={param1 === "" ? activeBar : nonactiveBar}
                      >
                        <div className="text-white poppins-500 h3 p-0 m-0">
                          HOME
                        </div>
                      </Nav.Link>

                      <Nav.Link
                        // as={Link}
                        className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                        // to="/3dmodels"
                        href="/3dmodels"
                        onClick={() => {
                          setOpen(false);
                        }}
                        style={param1 === "3dmodels" ? activeBar : nonactiveBar}
                      >
                        <div className="text-white h3 poppins-500 p-0 m-0">
                          3D MODELS
                        </div>
                      </Nav.Link>

                      <Nav.Link
                        className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          // className="text-decoration-none text-white"
                          onClick={() =>
                            window.open(
                              "https://www.darkdengame.com/",
                              "_blank"
                            )
                          }
                        >
                          <div className="my-2 h3 p-0 m-0">
                            <a
                              className="text-white poppins-500"
                              href="https://www.darkdengame.com/"
                              style={{ textDecoration: "none" }}
                            >
                              VR Game
                            </a>
                          </div>
                        </div>
                      </Nav.Link>

                      <Nav.Link
                        // as={NavLink}
                        className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                        // to="/vrsolutions"
                        href="/vrsolutions"
                        onClick={() => {
                          setOpen(false);
                        }}
                        style={
                          param1 === "vrsolutions" ? activeBar : nonactiveBar
                        }
                      >
                        <div className="text-white h3 poppins-500 p-0 m-0">
                          VR Solutions
                        </div>
                      </Nav.Link>

                      <Nav.Link
                        // as={NavLink}
                        className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                        // to="/sell-3d"
                        onClick={() => {
                          setOpen(false);
                          handleNavigationClick("/sell-3d");
                        }}
                      >
                        <div className="text-white poppins-500 h3 p-0 m-0">
                          Become A Seller
                        </div>
                      </Nav.Link>

                      <Nav.Link
                        className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                        onClick={() => {
                          // setOpen(false);
                          setshowMore(!showMore);
                        }}
                        style={
                          param1 === "business" ||
                            param1 === "blogs" ||
                            param1 === "contact-us"
                            ? activeBar
                            : nonactiveBar
                        }
                      >
                        {!showMore && (
                          <div className="text-white poppins-500 h3 p-0 m-0">
                            More
                            <AiFillCaretDown
                              width={"12px"}
                              height={"6px"}
                              top={"14.25px"}
                              left={"18px"}
                            ></AiFillCaretDown>
                          </div>
                        )}
                        {showMore && (
                          <div className="text-white h3 p-0 m-0">
                            More
                            <AiFillCaretUp
                              width={"12px"}
                              height={"6px"}
                              top={"14.25px"}
                              left={"18px"}
                            ></AiFillCaretUp>
                          </div>
                        )}
                      </Nav.Link>

                      {showMore && (
                        <Nav.Link
                          // as={NavLink}
                          className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                          // to="/business"
                          href="/business"
                          onClick={() => {
                            setOpen(false);
                            // handleNavigationClick("/business");
                          }}
                          style={{
                            weight: "400",
                            lineHeight: "24px",
                            size: "16px",
                            fontFamily: "Poppins",
                          }}
                        >
                          <div className="text-white h3 p-0 m-0">Business</div>
                        </Nav.Link>
                      )}

                      {showMore && (
                        <Nav.Link
                          // as={NavLink}
                          className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                          // to="/blogs"
                          href="/blogs"
                          onClick={() => {
                            setOpen(false);
                            // handleNavigationClick("/blogs");
                          }}
                          style={{
                            weight: "400",
                            lineHeight: "24px",
                            size: "16px",
                            fontFamily: "Poppins",
                          }}
                        >
                          <div className="text-white h3 p-0 m-0">Blogs</div>
                        </Nav.Link>
                      )}

                      {showMore && (
                        <Nav.Link
                          // as={NavLink}
                          className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                          // to="/contact-us"
                          href="/contact-us"
                          onClick={() => {
                            setOpen(false);
                            // handleNavigationClick("/contact-us");
                          }}
                          style={{
                            weight: "400",
                            lineHeight: "24px",
                            size: "16px",
                            fontFamily: "Poppins",
                          }}
                        >
                          <div className="text-white h3 p-0 m-0">
                            Contact US
                          </div>
                        </Nav.Link>
                      )}

                      <hr className="text-white w-100" />

                      <Nav.Link
                        // as={Link}
                        className={`${isAuthenticated
                          ? "main-nav-menu-link m-2 d-flex flex-row justify-content-center align-items-center"
                          : "d-none"
                          }`}
                        // className="main-nav-menu-link m-2 d-flex flex-row justify-content-center align-items-center"
                        // to="/profile"

                        onClick={() => {
                          setOpen(false);
                          handleNavigationClick("/profile");
                        }}
                        style={param1 === "profile" ? activeBar : nonactiveBar}
                      >
                        <Image
                          loading="lazy"
                          src={profileicon}
                          alt="Profile Icon"
                          className="me-2"
                          style={{ width: 25, height: 25 }}
                        />
                        <div className="text-white poppins-500 h3 p-0 m-0">
                          {" "}
                          Profile
                        </div>
                      </Nav.Link>

                      <Nav.Link
                        // as={Link}
                        className={`${isAuthenticated
                          ? "main-nav-menu-link m-2 d-flex flex-row justify-content-center align-items-center"
                          : "d-none"
                          }`}
                        // className="main-nav-menu-link m-2 d-flex flex-row justify-content-center align-items-center"
                        // to="/notifications"

                        onClick={() => {
                          setOpen(false);
                          handleNavigationClick("/notifications");
                        }}
                        style={
                          param1 === "notifications" ? activeBar : nonactiveBar
                        }
                      >
                        <Image
                          loading="lazy"
                          src={transparent_image}
                          alt="Notification Icon"
                          className="me-2 sprites-bell"
                        />
                        <div className="text-white poppins-500 h3 p-0 m-0">
                          {" "}
                          Notification{" "}
                        </div>{" "}
                        &nbsp;
                        {/* {newNotification > 0 && <Badge pill variant="danger" className="ml-2">{newMessages}</Badge>} */}
                        <Badge
                          bgColor="var(--error)"
                          inline
                          content={notificationsForUser?.length}
                          className={`align-self-center`}
                        ></Badge>
                      </Nav.Link>
                      <Nav.Link
                        // as={Link}
                        className="main-nav-menu-link m-2 d-flex flex-row justify-content-center align-items-center"
                        // to="/cart"
                        onClick={() => {
                          setOpen(false);
                          handleNavigationClick("/cart");
                        }}
                        style={param1 === "cart" ? activeBar : nonactiveBar}
                      >
                        <Image loading="lazy" src={transparent_image} alt="Cart Icon" className="me-2 sprites-shopping-cart" />
                        <div className="text-white h3  poppins-500 p-0 m-0">
                          Cart
                        </div>
                        &nbsp;
                        <Badge
                          bgColor="var(--error)"
                          inline
                          content={cartSize}
                          className={`align-self-center`}
                        ></Badge>
                      </Nav.Link>
                      <Nav.Link
                        // as={Link}
                        className={`${isAuthenticated
                          ? "main-nav-menu-link m-2 d-flex flex-row justify-content-center align-items-center"
                          : "d-none"
                          }`}
                        // className="main-nav-menu-link m-2 d-flex flex-row justify-content-center align-items-center"
                        // to="/orders"

                        onClick={() => {
                          setOpen(false);
                          handleNavigationClick("/orders");
                        }}
                        style={param1 === "orders" ? activeBar : nonactiveBar}
                      >
                        <Image loading="lazy" src={navigation_orders} alt="Order Icon" className="me-2" />
                        <div className="text-white h3 poppins-500 p-0 m-0">
                          My Orders
                        </div>
                      </Nav.Link>
                      <Nav.Link
                        // as={Link}
                        className={`${isAuthenticated
                          ? "main-nav-menu-link m-2 d-flex flex-row justify-content-center align-items-center"
                          : "d-none"
                          }`}
                        // className="main-nav-menu-link m-2 d-flex flex-row justify-content-center align-items-center"
                        // to="/favourites"

                        onClick={() => {
                          setOpen(false);
                          handleNavigationClick("/favourites");
                        }}
                        style={
                          param1 === "favourites" ? activeBar : nonactiveBar
                        }
                      >
                        <Image loading="lazy" src={navigation_favorite} alt="Favourite Icon" className="me-2" />
                        <div className="text-white poppins-500 h3 p-0 m-0">
                          Favorite
                        </div>
                      </Nav.Link>

                      <hr className="text-white w-100" />

                      <div className={`${isAuthenticated ? "mb-4" : "d-none"}`}>
                        <Nav.Link
                          as={Link}
                          to={`${param1 === "admin" ? "/admin" : "/"}`}
                          style={{
                            weight: "500",
                            lineHeight: "30px",
                            size: "20px",
                            fontFamily: "Poppins",
                          }}
                          className="main-nav-menu-link m-2 mb-3 d-flex flex-row justify-content-center align-items-center"
                          onClick={() => {
                            navigate("/");
                            dispatch(actions.loggingOut({ session_id: session }));
                            setOpen(false);
                            // handleNavigationClick(
                            //   `${param1 === "admin" ? "/admin" : "/"}`
                            // );
                          }}
                        >
                          <Image loading="lazy" src={navigation_logout} alt="Logout Icon" className="me-2" />
                          <div className="text-white h3 p-0 m-0">Logout</div>
                          {/* <span className="poppins">Logout</span> */}
                        </Nav.Link>
                      </div>
                      <div
                        className={`${isAuthenticated ? "d-none" : ""
                          } d-flex flex-column justify-content-center align-items-center mb-4`}
                      >
                        <Nav.Link
                          // as={Link}
                          // to="/login"
                          className="main-nav-menu-link d-flex flex-column justify-content-center align-items-center"
                          style={{ textDecoration: 0 }}
                        // onClick={handleNavigationClick("/login")}
                        >
                          <Button
                            // text
                            dark={true}
                            onClick={() => {
                              setOpen(false);
                              handleNavigationClick("/login");
                            }}
                            className="mt-2 poppins-500 explore-button-no-neo"
                          >
                            LOGIN
                          </Button>
                        </Nav.Link>
                      </div>
                      {/* <div
                          className={`${isAuthenticated
                            ? "my-5 d-flex flex-column justify-content-center align-items-center text-white"
                            : "d-none"
                            }`}
                        >
                          <div>Hi,</div>
                          <div>
                            <b>{user?.name}</b>
                          </div>
                          <div>Happy to see you here.</div>
                        </div> */}
                    </Nav>
                  </Container>
                </Modal.Body>
              </Modal>

              // <Modal show = {open} style={{backgroundColor:"#ffffff", paddingTop:"20px"}}>
            )
        )}
      </Breakpoint>
    </>
  );
};

const styles = {
  znanyeCartButton: {
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: "1.25px",
    padding: 24,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: "12px",
  },
  znanyeMenuButton: {
    // padding: 24,
    // paddingLeft: 35,
    // paddingRight: 35,
    borderRadius: "4px",
    border: "1px solid #3D3D3D",
  },
  znanyeCartBadge: {
    paddingTop: 15,
  },
  znanyeNotifiyBadgeMobile: {
    // paddingTop: 15,
    left: "40px",
  },

  shoppingcart: {
    width: "16px",
    height: "16px",
  },
  shoppingcart_no_neo: {
    width: "24px",
    height: "24px",
  },
  shoppingcart_large: {
    width: "24px",
    height: "24px",
  },
  loginModalContaienr: {
    border: "2.5px solid #BEBEBE",
    borderRadius: "10px 10px 0px 0px",
  },
  // dropdown-toggle::after {
  // color: #fff;
  // }
  bannerCrossButton: {
    minWidth: 30,
    minHeight: 30,
    zIndex: 99,
    right: 10,
    top: 10,
  },
  bannerCrossButtonMobile: {
    minWidth: 30,
    minHeight: 30,
    zIndex: 99,
    right: 5,
    top: 5,
  },
};

export default NavBar;
