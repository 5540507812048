import React, { useEffect, useState } from "react";
import {
  Form,
  FormControl,
  Card,
} from "react-bootstrap";
import { CardContent, IconButton } from "ui-neumorphism";
import { transparent_image } from "assets";
import { useDispatch } from "react-redux";

const SearchBarMyProduct = (props) => {
  const {
    type,
    items,
    uploadedProducts,
    updateProducts,
    placeholder = "Search Your Products",
  } = props;

  const [searchDropdown, setSearchDropdown] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [selected, setSelected] = useState(null);

  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    //consolelog(uploadedProducts);
    if (uploadedProducts) {
      setProducts(uploadedProducts);
    }
  }, [uploadedProducts]);

  const getResults = () => {
    const tempProducts = products.filter((item) =>
      item.title.toLowerCase().includes(searchWord.toLowerCase())
    );
    //consolelog(tempProducts);
    if (tempProducts?.length == 0 && searchWord != "") {
      updateProducts(products);
    } else {
      updateProducts(tempProducts);
    }
  };

  return (
    <div className="w-100 position-relative" style={{ zIndex: 1000 }}>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Card className="p-0 m-0" style={styles.searchBox}>
          <CardContent className="m-0 d-flex justify-content-center align-items-center bg-transparent">
            <FormControl
              type="search"
              placeholder={placeholder}
              className="border-0 outline-none body-text1 text-white"
              style={{ background: "#0D0D0D" }}
              onChange={(e) => setSearchWord(e.target.value)}
              onFocus={() => {
                //consolelog(products);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setSelected(null);
                }, 200);
              }}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  getResults();
                }
              }}
            />
            <IconButton
              size="large"
              className="my-1"
              dark={true}
              onClick={(e) => getResults()}
            >
              <img src={transparent_image} alt="Search Bar" className="p-0 m-0 sprites-search-icon" />
            </IconButton>
            {/* {searchWord === "" ? (
              <Button
                className="my-1 explore-button disabled"
                dark={true}
                size="large"
                onClick={(e) => {
                  getResults();
                }}
              >
                <span className="poppins">Search</span>
              </Button>
            ) : (
              <Button
                className="my-1 explore-button disabled"
                dark={true}
                size="large"
                onClick={(e) => {
                  getResults();
                }}
              >
                <span className="poppins">Search</span>
              </Button>
            )} */}
          </CardContent>
        </Card>
      </Form>
    </div>
  );
};

const styles = {
  searchBox: {
    backgroundColor: "#0D0D0D",
    borderRadius: 5,
    border: "1px solid #3d3d3d",
  },
};

export default SearchBarMyProduct;
