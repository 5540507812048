import { BlogCard, SearchBarBlogs } from "components";
import { useState, useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Button, Card } from 'ui-neumorphism';
import * as adminActions from "actions/AdminActions";
import { IoMdAdd } from "react-icons/io";
import { Link } from "react-router-dom";



const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [blogTypes, setBlogTypes] = useState([]);
    const [allBlogs, setAllBlogs] = useState([]);
    const [searchWord, setSearchWord] = useState("");
    const [searchedBlogs, setSearchedBlogs] = useState([]);
    // const typeArray = ["All Blogs", "Featured", "Knowledge based", "VR/AR/MR", "3D Modelling", "News", "Company News"];
    const [blogType, setBlogType] = useState(null);

    useEffect(() => {
        setBlogs(allBlogs);
    }, [allBlogs]);

    const findBlogTypes = async () => {
        const response = await adminActions.loadBlogTypes();
        setBlogTypes(response);
        setBlogType(response[0])
    }

    const findBlogs = async () => {
        const response = await adminActions.loadBlogs();
        setAllBlogs(response);
    }

    useEffect(() => {
        findBlogTypes();
        findBlogs();
    }, []);

    useEffect(() => {
        if (searchWord != null && searchWord != "") {
            setSearchedBlogs(blogs.filter((item) => item.title.toLowerCase().includes(searchWord.toLowerCase())))
        }
    }, [searchWord]);

    useEffect(() => {
        if (blogType) {
            if (blogType == "All Blogs") {
                setBlogs(allBlogs);
            } else {
                setBlogs(allBlogs.filter(item => item.blog_type == blogType.id))
            }
        }
    }, [blogType])

    return (
        <Container className="my-5 py-5">
            <ul className="breadcrumb subtitle mx-1 mb-3">
                <li>Blogs</li>
            </ul>
            <Row>
                <Col lg={3}>
                    <Card className="border-0 blog-top-section p-4" dark={true} outlined>
                        <Row>
                            {blogTypes.map((item, index) => {
                                return (
                                    <Col xs={12} className="text-start d-flex justify-content-start align-items-start">
                                        <div className="text-white w-100">
                                            <div
                                                className={`w-100 border-0 py-2 cursor-pointer ${blogType?.id == item?.id ? "text-primary" : ""}`}
                                                dark={true}
                                                text
                                                onClick={() => {
                                                    setBlogType(item)
                                                }}
                                            >
                                                {item.title}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Card>
                </Col>
                <Col lg={9}>
                    <SearchBarBlogs searchWord={searchWord} setSearchWord={(x) => setSearchWord(x)} />
                    <div className="d-flex justify-content-end mt-5">
                        <Link className="text-decoration-none" to={`/admin/blogs/new/`}>
                            <Button dark={true} className="explore-button">
                                <IoMdAdd size={20} />&nbsp;Add a blog
                            </Button>
                        </Link>
                    </div>
                    {/* <div className={`${(searchWord != null && searchWord != "") ? "d-none" : "d-block"}`}>
                        <Row>
                            {blogs?.map((item, index) => <BlogTopSection key={index} blog={item} read_url={`/blogs/${item?.slug}`} />)}
                        </Row>
                    </div> */}
                    {blogType && <div className="mt-5">
                        <p className="h4 text-white">{blogType?.title}</p>
                        <Image
                            loading="lazy"
                            className="w-100"
                            src={blogType?.blog_type_image}
                            alt={`${blogType?.title} - Blog Type Image`}
                            style={{ borderRadius: "30px" }}
                        />
                    </div>}
                    <div className={`${(searchWord != null && searchWord != "") ? "d-none" : "d-block"}`}>
                        {blogs?.length > 0 && <Row>
                            {blogs?.map((item, index) => <BlogCard key={index} blog={item} read_url={`/admin/blogs/${item?.slug}/`} />)}
                        </Row>}
                        {blogs?.length == 0 && <Row>
                            <Col xs={12} className="d-flex justify-content-center align-items-center text-white my-5 py-5">
                                No blogs available in this category!
                            </Col>
                        </Row>}
                    </div>
                    <div className={`${(searchWord != null && searchWord != "") ? "d-block" : "d-none"}`}>
                        {searchedBlogs?.length > 0 && <Row>
                            {searchedBlogs?.map((item, index) => <BlogCard key={index} blog={item} read_url={`/admin/blogs/${item?.slug}/`} />)}
                        </Row>}
                        {searchedBlogs?.length == 0 && <Row>
                            <Col xs={12} className="d-flex justify-content-center align-items-center text-white my-5 py-5">
                                No blogs available with this word!
                            </Col>
                        </Row>}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Blogs;