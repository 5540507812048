import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button as BootstrapButton,
  Card,
  Badge,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { Button, IconButton, CardAction } from "ui-neumorphism";
import { Link } from "react-router-dom";
import { download, imagenotavailable, raiserefund, znanyelogin } from "assets";
import * as actions from "actions/AuthActions";
import { useSelector } from "react-redux";
import moment from "moment";
import { BsInfo } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { Breakpoint } from "react-socks";
import getSymbolFromCurrency from "currency-symbol-map";
import "./OrderProductCard.scss";
import DownloadProduct from "components/DownloadProduct/DownloadProduct";

const OrderProductCard = ({ item, retryPayment }) => {
  const [downloading, setDownloading] = useState(false);

  const productFileDownload = async (version, productId, productName) => {
    setDownloading(true);
    const response = await actions.downloadProductFile(
      version,
      productId,
      productName
    );
    if (response) {
      setDownloading(false);
    }
  };

  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundProducts, setRefundProducts] = useState([]);
  const currency = useSelector((state) => state.authReducer.currency);
  const today = new Date();
  const [timeLeft, setTimeLeft] = useState(null);
  const [changingBorder, setChangingBroder] = useState(false);
  const [invoiceDownloading, setInvoiceDownloading] = useState(false);

  const invoiceDownload = async (id) => {
    setInvoiceDownloading(true);
    const response = await actions.downloadInvoice(id);
    if (response) {
      setInvoiceDownloading(false);
    }
    setInvoiceDownloading(false);
  };
  useEffect(() => {
    if (item?.payment_paid_timestamp) {
      const paymentTime = moment(item?.payment_paid_timestamp).add(7, "days");
      setTimeLeft(moment(paymentTime).diff(moment(today), "seconds") * 1000);
    }
  }, [item]);

  let message =
    "Hello Team I would like to discuss a refund on the following products: \n\n";
  for (let i = 0; i < refundProducts.length; i++) {
    message += refundProducts[i] + "        ";
  }
  // console.log(message);

  const getOrderStatus = (order_status) => {
    switch (order_status) {
      case "PL":
        return "Successful";
      case "CL":
        return "Cancelled";
      default:
        return "Pending";
    }
  };

  return (
    <>
      <Breakpoint large up>
        <Card
          className="mb-5 w-75 text-white order-card overflow-hidden"
          key={item?.id}
        >
          <Row className="mt-4" style={{ backgroundColor: "#2E2E2E" }}>
            <Col
              sm={4}
              className="d-flex flex-column justify-content-center align-items-center my-4"
            >
              <div className="poppins-300 body-text2 mb-2">Order ID</div>
              <div className="poppins-500 body-text2">{item?.order_id}</div>
            </Col>
            <Col
              sm={4}
              className="d-flex flex-column justify-content-center align-items-center my-4"
            >
              <div className="poppins-300 body-text2 mb-2">
                Order Status and Date
              </div>
              <div className="poppins-500 body-text2">
                <div className="d-flex align-items-center">
                  <div className="poppins-500">
                    {getOrderStatus(item.order_status)} (
                    {moment(item.timestamp).format("DD.MM.YYYY")})
                  </div>
                  <div className="poppins-500">
                    {item.order_status == "CL" && (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            {item.cancellation_reason}
                          </Tooltip>
                        }
                      >
                        <IconButton dark={true}>
                          <BsInfo className="text-white" size={25} />
                        </IconButton>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col
              sm={4}
              className="d-flex flex-column justify-content-center align-items-center my-4"
            >
              <div className="poppins-300 body-text2 mb-2">Invoice</div>
              <div className="poppins-500 body-text2">
                {item.order_status == "PL" ? (
                  <div className="d-flex justify-content-between align-items-center">
                    <b>
                      <a
                        className="text-primary"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          invoiceDownload(item.id);
                          // setInvoiceDownloading(true);
                          // actions.downloadInvoice(item.id);
                        }}
                        style={{ textDecoration: 0 }}
                      >
                        Download &nbsp;
                        {invoiceDownloading && (
                          <Spinner
                            className=" me-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </a>
                    </b>
                  </div>
                ) : (
                  <b style={{ color: "#3D3D3D" }}>Download</b>
                )}
              </div>
            </Col>
          </Row>
          <div>
            {item.orderproducts.map((orderproduct, index) => {
              const obj =
                orderproduct?.product?.file_statistics?.extension_stats;

              const fileExtensions = Object.keys(obj ? obj : {});

              const refundableItems = item.orderproducts.filter(
                (prod) => {
                  if (prod.price !== 0) {
                    if (prod?.coupon_code) {
                      if (prod?.coupon_category === "ABSOLUTE") {
                        console.log((prod?.product?.price - prod?.coupon_flat_off).toFixed(2))
                        if (((prod?.product?.price - prod?.coupon_flat_off).toFixed(2)) <= 0) {
                          return false
                        } else {
                          return true
                        }
                      } else {
                        console.log((prod?.product?.price - ((prod?.product?.price * prod?.coupon_flat_off) / 100)))
                        if (((prod?.product?.price - ((prod?.product?.price * prod?.coupon_flat_off) / 100)).toFixed(2)) <= 0) {
                          return false
                        } else {
                          return true
                        }
                      }
                    } else {
                      return true
                    }
                  } else {
                    return false
                  }
                }
              );
              return (
                <>
                  <DownloadProduct
                    orderproduct={orderproduct}
                    fileExtensions={fileExtensions}
                    refundableItems={refundableItems}
                    index={index}
                    item={item}
                    timeLeft={timeLeft}
                  />
                </>
              );
            })}
            {item?.order_status == "PE" && (
              <div
                // className={`${item.orderproducts.length === 1 ? "mx-5" : ""} d-flex flex-column justify-content-between my-2`}
                className="d-flex flex-column justify-content-between my-2"
                style={{ borderTop: "1px solid #666666" }}
              >
                <Row className="mx-5 py-4">
                  <Col sm={4} className="mt-3"></Col>
                  <Col
                    sm={8}
                    className="d-flex justify-content-end align-items-center mt-2"
                  >
                    <div
                      className={`d-flex justify-content-center align-items-center p-0 text-primary`}
                    >
                      <p className="h4 d-flex justify-content-center align-items-center p-0 my-0 me-3">
                        {getSymbolFromCurrency(currency.name) +
                          (item?.total * currency.rate).toFixed(2)}
                      </p>
                      {item?.total != item.subtotal && (
                        <strike className="h5 text-white poppins-400 d-flex justify-content-center align-items-center p-0 my-0 me-3">
                          {getSymbolFromCurrency(currency.name) +
                            (item?.subtotal * currency.rate).toFixed(2)}
                        </strike>
                      )}
                    </div>
                    {/* </Col>
                  <Col sm={4} className="mt-1 p-0 d-flex justify-content-end"> */}
                    {item.order_status != "PL" && (
                      <div
                      // className={`${index === 0 ? "d-block" : "d-none"}`}
                      >
                        <BootstrapButton
                          className="explore-button-no-neo d-flex justify-content-center align-items-center position-relative py-4"
                          dark={true}
                          style={{
                            width: "100%",
                            border: changingBorder ? "0px" : "0px",
                          }}
                          onMouseEnter={() => setChangingBroder(true)}
                          onMouseLeave={() => setChangingBroder(false)}
                          size="large"
                          onClick={(e) => {
                            e.preventDefault();
                            retryPayment(item);
                          }}
                        >
                          <span className="text-white body-text2">
                            RETRY PAYMENT
                          </span>
                        </BootstrapButton>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Card>
      </Breakpoint>
      <Breakpoint medium down>
        <Card
          className="mb-5 w-100 order-card"
          dark={true}
          key={item?.id}
          style={{ overflowWrap: "anywhere" }}
        >
          <div
            className="m-0 p-3 text-white w-100 body-text2"
            style={styles.cardAction}
          >
            <div className="d-flex justify-content-between">
              <div>
                ORDER #<span className="fw-bold">{item.order_id}</span>
              </div>
              <div>
                {item.order_status == "PL" ? (
                  <a
                    className="explore-text body-text2"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      invoiceDownload(item.id);
                      // actions.downloadInvoice(item.id);
                    }}
                    style={{ textDecoration: 0 }}
                  >
                    INVOICE &nbsp;{" "}
                    {invoiceDownloading && (
                      <Spinner
                        className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                {getOrderStatus(item.order_status)}
                <span className="fw-bold">
                  ({moment(item.timestamp).format("DD MMMM YYYY")})
                </span>
              </div>
              <div className="subtitle">
                {/* <p className={`${timeLeft > 0 ? "d-block" : "d-none"}`}>
                  <a
                    className="explore-text"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowRefundModal(true);
                    }}
                    style={{ textDecoration: 0 }}
                  >
                    <Image loading="lazy" src={raiserefund} alt="Raise Fund" width="25" />
                    &nbsp;&nbsp;
                  </a>
                </p> */}
                {item.order_status == "CL" && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        {item.cancellation_reason}
                      </Tooltip>
                    }
                  >
                    <IconButton dark={true}>
                      <BsInfo className="text-white" size={25} />
                    </IconButton>
                  </OverlayTrigger>
                )}
              </div>
            </div>
            <div>
              {item.order_status != "CL" && (
                <div>
                  {item.order_status == "PL" ? (
                    <></>
                  ) : (
                    <div className="mt-3 mx-0">
                      <BootstrapButton
                        className="explore-button-no-neo btn btn-ptimary position-relative"
                        dark={true}
                        style={{
                          width: "100%",
                          border: changingBorder ? "0px" : "0px",
                        }}
                        onMouseEnter={() => setChangingBroder(true)}
                        onMouseLeave={() => setChangingBroder(false)}
                        size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          retryPayment(item);
                        }}
                      >
                        RETRY PAYMENT
                      </BootstrapButton>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {item.orderproducts.map((orderproduct, index) => {
            const obj = orderproduct?.product?.file_statistics?.extension_stats;
            const fileExtensions = Object.keys(obj ? obj : {});
            return (
              <Container className="h-100 p-0" fluid>
                <DownloadProduct
                  orderproduct={orderproduct}
                  fileExtensions={fileExtensions}
                  index={index}
                  item={item}
                  timeLeft={timeLeft}
                  type="mobile"
                />
              </Container>
            );
          })}
        </Card>
      </Breakpoint>
    </>
  );
};

const styles = {
  itemImageCard: {
    width: 95,
    height: 95,
    backgroundColor: "#2E2E2E",
    borderRadius: 10,
  },
  itemImageMobileCard: {
    width: 100,
    height: 100,
    backgroundColor: "#2E2E2E",
  },
  itemPriceCard: {
    width: 130,
    height: 55,
    backgroundColor: "#2E2E2E",
  },
  underline: {
    height: 2,
    backgroundColor: "#292929",
  },
  orderDivision: {
    height: 1,
    width: "100%",
    backgroundColor: "#666666",
  },
  successfulImageCard: {
    width: 80,
    height: 80,
    backgroundColor: "#1f1f1f",
  },
  successfulImage: {
    width: 70,
    height: 70,
  },
  znanyeDownloadButton: {
    width: 10,
    height: 20,
    borderRadius: "4px",
  },
  downloadItem: {
    width: "24px",
    height: "24px",
  },
  cardAction: {
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "#111",
  },
};

export default OrderProductCard;
