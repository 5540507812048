import React, { useState } from "react";
import { Button } from "ui-neumorphism";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import * as authActions from "actions/AuthActions";
import Heart from "react-animated-heart";
import { info, biggreentick, biggreytick } from "assets";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";

const ToggleButton = ({ type, item, array, setArray, handleFavorite }) => {
  // console.log("item:", item);
  // console.log("array:", array);
  // console.log("setArray:", setArray);
  const [isVisible, setIsVisible] = useState(false);

  const renderTooltip = (props, item) => (
    <Tooltip id="button-tooltip" {...props}>
      {`${item.flat_off} ${item.coupon_category == "RELATIVE" ? "% OFF" : "FLAT OFF"
        } ON YOUR TOTOAL AMOUNT`}
    </Tooltip>
  );

  switch (type) {
    case "productDetails":
      return (
        <>
          {item.value ? (
            <div className="d-flex align-items-center mb-3">
              <Image
                loading="lazy"
                src={biggreentick}
                alt="Selected"
                onClick={(e) => {
                  e.preventDefault();
                  setArray(
                    array.map((item1, index) => {
                      if (item1.id == item.id) {
                        return {
                          id: item1.id,
                          name: item1.name,
                          set: item1.set,
                          value: !item1.value,
                        };
                      }
                      return item1;
                    })
                  );
                }}
              />
              <span className="ms-3">{item.name}</span>
            </div>
          ) : (
            <div className="d-flex align-items-center mb-3">
              <Image
                loading="lazy"
                src={biggreytick}
                alt="Not Selected"
                onClick={(e) => {
                  e.preventDefault();
                  setArray(
                    array.map((item1, index) => {
                      if (item1.id == item.id) {
                        return {
                          id: item1.id,
                          name: item1.name,
                          set: item1.set,
                          value: !item1.value,
                        };
                      }
                      return item1;
                    })
                  );
                }}
              />
              <span className="ms-3">{item.name}</span>
            </div>
          )}
        </>
        // <Button
        //   dark={true}
        //   className={`m-4 body-text2 py-4 ${item.value ? "explore-button" : "znanye-button"
        //     }`}
        //   onClick={(e) => {
        //     e.preventDefault();
        //     setArray(
        //       array.map((item1, index) => {
        //         if (item1.id == item.id) {
        //           return {
        //             id: item1.id,
        //             name: item1.name,
        //             set: item1.set,
        //             value: !item1.value,
        //           };
        //         }
        //         return item1;
        //       })
        //     );
        //   }}
        // >
        //   {item.name}
        // </Button>
      );
    case "coupons":
      return (
        // <OverlayTrigger placement="top"
        //   delay={{ show: 250, hide: 400 }}
        //   overlay={(props) => renderTooltip(props, item)} className="d-flex justify-content-between align-items-center">
        //   <Button
        //     dark={true}
        //     className={`m-4 body-text2 py-4 ${array.find(elem => elem.id == item.id) ? "explore-button" : "znanye-button"
        //       }`}
        //     onClick={(e) => {
        //       e.preventDefault();
        //       if (array.find(elem => elem.id == item.id)) {
        //         const index = array.findIndex(elem => elem.id == item.id);
        //         const x = [...array];
        //         x.splice(index, 1);
        //         setArray(x);
        //       } else {
        //         setArray([...array, item]);
        //       }
        //     }}
        //   >
        //     <div>{item.label} <Image loading="lazy" className="ms-3" src={info} alt="Info" /></div>
        //   </Button>
        // </OverlayTrigger>
        <>
          {array?.find((elem) => elem.id === item.id) ? (
            <div className="d-flex align-items-center mb-3">
              <Image
                loading="lazy"
                src={biggreentick}
                alt="Selected"
                onClick={(e) => {
                  e.preventDefault();
                  if (array.find((elem) => elem.id == item.id)) {
                    const index = array.findIndex((elem) => elem.id == item.id);
                    const x = [...array];
                    x.splice(index, 1);
                    setArray(x);
                  } else {
                    setArray([...array, item]);
                  }
                }}
              />
              <span className="ms-3" style={{ color: "#5baf6e" }}>
                {item.label}
              </span>
            </div>
          ) : (
            <div className="d-flex align-items-center mb-3">
              <Image
                loading="lazy"
                src={biggreytick}
                alt="Not Selected"
                onClick={(e) => {
                  e.preventDefault();
                  if (array.find((elem) => elem.id == item.id)) {
                    const index = array.findIndex((elem) => elem.id == item.id);
                    const x = [...array];
                    x.splice(index, 1);
                    setArray(x);
                  } else {
                    setArray([...array, item]);
                  }
                }}
              />
              <span className="ms-3">{item.label}</span>
            </div>
          )}
        </>
      );
    case "productUsage":
      return (
        <>
          {item.value ? (
            <div className="d-flex align-items-center mb-3">
              <Image
                loading="lazy"
                src={biggreentick}
                alt="Selected"
                onClick={(e) => {
                  e.preventDefault();
                  setArray(
                    array.map((item1, index) => {
                      if (item1.id == item.id) {
                        return {
                          id: item1.id,
                          name: item1.name,
                          set: item1.set,
                          value: !item1.value,
                        };
                      }
                      return item1;
                    })
                  );
                }}
              />
              <span className="ms-3">{item.name}</span>
            </div>
          ) : (
            <div className="d-flex align-items-center mb-3">
              <Image
                loading="lazy"
                src={biggreytick}
                alt="Not Selected"
                style={{ width: "32px", height: "32px" }}
                onClick={(e) => {
                  e.preventDefault();
                  setArray(
                    array.map((item1, index) => {
                      if (item1.id == item.id) {
                        return {
                          id: item1.id,
                          name: item1.name,
                          set: item1.set,
                          value: !item1.value,
                        };
                      }
                      return item1;
                    })
                  );
                }}
              />
              <span className="ms-3">{item.name}</span>
            </div>
          )}
        </>
        // <Button
        //   dark={true}
        //   className={`m-4 body-text2 py-4 ${item.value ? "explore-button" : "znanye-button"
        //     }`}
        //   onClick={(e) => {
        //     e.preventDefault();
        //     setArray(
        //       array.map((item1, index) => {
        //         if (item1.id == item.id) {
        //           return {
        //             id: item1.id,
        //             name: item1.name,
        //             set: item1.set,
        //             value: !item1.value,
        //           };
        //         }
        //         return item1;
        //       })
        //     );
        //   }}
        // >
        //   {item.name}
        // </Button>
      );
    case "favorite":
      if (array) {
        return <Heart isClick={true} onClick={() => handleFavorite(false)} />;
      } else {
        return <Heart isClick={false} onClick={() => handleFavorite(true)} />;
      }
  }
};

export default ToggleButton;
