import { BlogCard, BlogTopSection, SearchBarBlogs, Loader } from "components";
import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Image, Card } from "react-bootstrap";
import * as adminActions from "actions/AdminActions";
import { Helmet } from "react-helmet";
import { useLocation, useParams, Outlet, useNavigate } from "react-router-dom";
import Select from "react-select";
import { AiOutlineCaretDown } from "react-icons/ai";
import { Breakpoint } from "react-socks";
import { browserHistory } from "react-router";

const CustomDropdownIndicator = (props) => {
  return (
    <div
      {...props.innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: "8px", // Adjust the spacing as needed
      }}
    >
      <AiOutlineCaretDown />
    </div>
  );
};
const customStyles = {
  menu: () => ({
    marginTop: -4.5,
    position: "absolute",
    width: "100%",
    zIndex: 999,
    textAlign: "left",
    fontSize: "20px",
    borderRadius: "50px !important",
    overflow: "visible",
  }),

  menuList: (provided, state) => ({
    ...provided,
    borderRadius: "15px",
    textAlign: "center",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#0D0D0D",
    color: state.isSelected ? "#777" : "white",
    marginTop: 0,
    cursor: "pointer",
    textAlign: "left",
    paddingLeft: "18px",
    fontSize: "16px",
    padding: "10px",
  }),

  control: (provided, state) => ({
    ...provided,
    color: "#fff",
    backgroundColor: "#0D0D0D",
    textAlign: "left",
    fontSize: 16,
    fontFamily: "poppins",
    height: "50px",
    borderRadius: "8px",
    border: "1px solid #3D3D3D",
    boxShadow: "none",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const color = "#fff";
    const transition = "opacity 300ms";
    const padding = "8px 8px 8px 4px";

    return { ...provided, opacity, transition, color, padding };
  },
};

// singleValue: (provided, state) => ({
//     ...provided,
//     color: "#fff",
//     textAlign:state.isSelected? "left":"",
//   }),
// singleValue: (provided, state) => {
//     ...provided,
//     color: "#fff",
//     textAlign: "left",
//   const opacity = state.isDisabled ? 0.5 : 1;
//   const color = "#fff";
//   const transition = "opacity 300ms";
//   const textAlign = "left";

//   return { ...provided, opacity, transition, color, textAlign };
// },

const Blogs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // const { typeId, id } = useParams();
  const { id } = useParams();
  let params = new URLSearchParams(document.location.search);
  let ordering = params.get("ordering");
  let typeId = params.get("blog_type");
  const [blogs, setBlogs] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [searchedBlogs, setSearchedBlogs] = useState([]);
  const [blogType, setBlogType] = useState(null);
  const searchOptionRef = useRef();

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const searchOptions = [
    { label: "Latest", value: 1 },
    { label: "Oldest", value: 2 },
  ];

  const IndicatorSeparator = () => {
    return (
      <div
        style={{
          height: "1px",
          width: "1pidx",
          backgroundColor: "black",
          padding: 0,
        }}
      />
    );
  };

  const onChangeFilters = (value, type) => {
    const searchOption = searchOptionRef.current.state.focusedOption;
    //consolelog(searchOptionRef.current.state);
    let order = "";

    if (searchOption?.value == 2) {
      order += "created_at";
    } else if (searchOption?.value == 1) {
      order += "-created_at";
    }

    // navigate(`/blogs?blog_type=${typeId}&ordering=${order}`);
    handleNavigationClick(`/blogs?blog_type=${typeId}&ordering=${order}`);
  };

  const findBlogType = async () => {
    const response = await adminActions.loadBlogType(typeId);
    setBlogType(response);
  };

  const findBlogs = async () => {
    const response = await adminActions.loadBlogTypeBlogs(typeId, ordering);
    setBlogs(response);
  };

  useEffect(() => {
    findBlogs();
  }, [ordering]);

  useEffect(() => {
    findBlogType();
    findBlogs();
    console.log("typeid", typeId, id);
  }, []);

  useEffect(() => {
    if (searchWord != null && searchWord != "") {
      setSearchedBlogs(
        blogs.filter((item) =>
          item.title.toLowerCase().includes(searchWord.toLowerCase())
        )
      );
    }
  }, [searchWord]);

  if (id) {
    return <Outlet />;
  }

  if (!blogs) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Breakpoint large up>
        <Container style={{ minHeight: "90vh" }} fluid>
          <Row className="d-flex my-5 justify-content-center align-items-center">
            <Col>
              <ul className="breadcrumb subtitle mx-5 mt-5 px-5">
                <li>
                  <a href="/blogs">Blogs</a>
                </li>
                <li>{blogType?.title}</li>
              </ul>
            </Col>
            <Col className="mx-5 mt-5 px-5 d-flex">
              <SearchBarBlogs
                searchWord={searchWord}
                setSearchWord={(x) => {
                  setSearchWord(x);
                }}
              />
              <div className="d-flex align-items-center">
                <div className="mx-3" style={{ minWidth: 200, maxWidth: 400 }}>
                  <Select
                    ref={searchOptionRef}
                    required={true}
                    options={searchOptions}
                    isSearchable={false}
                    components={{
                      IndicatorSeparator,
                      DropdownIndicator: CustomDropdownIndicator,
                    }}
                    styles={customStyles}
                    defaultValue={
                      searchOptions.length > 0 ? searchOptions[0] : ""
                    }
                    onChange={(value) => onChangeFilters(value, "searchOption")}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Container className="my-5">
            <div
              className={`${
                searchWord != null && searchWord != "" ? "d-none" : "d-block"
              }`}
            >
              {blogs?.length > 0 && (
                <Row>
                  {blogs?.map((item, index) => (
                    <BlogCard
                      key={index}
                      blog={item}
                      read_url={`/blogs/${item?.slug}/`}
                      typeID={typeId}
                    />
                  ))}
                </Row>
              )}
              {blogs?.length == 0 && (
                <Row>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center align-items-center text-white my-5 py-5"
                  >
                    No blogs available in this category!
                  </Col>
                </Row>
              )}
            </div>
            <div
              className={`${
                searchWord != null && searchWord != "" ? "d-block" : "d-none"
              }`}
            >
              {searchedBlogs?.length > 0 && (
                <Row>
                  {searchedBlogs?.map((item, index) => (
                    <BlogCard
                      key={index}
                      blog={item}
                      read_url={`/blogs/${item?.slug}/`}
                    />
                  ))}
                </Row>
              )}
              {searchedBlogs?.length == 0 && (
                <Row>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center align-items-center text-white my-5 py-5"
                  >
                    No blogs available with this word!
                  </Col>
                </Row>
              )}
            </div>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container
          className="mt-5 pt-4 px-0"
          fluid
          style={{ minHeight: "100vh" }}
        >
          <ul className="breadcrumb subtitle px-3">
            <li>
              <a href="/blogs">Blogs</a>
            </li>
            <li>{blogType?.title}</li>
          </ul>
          <Row className="ps-3 me-0">
            <Col xs={10} className="pe-0">
              <SearchBarBlogs
                searchWord={searchWord}
                setSearchWord={(x) => {
                  setSearchWord(x);
                }}
              />
            </Col>
            <Col xs={2}>
              <Card
                style={{
                  borderRadius: "8px",
                  background: "#292828",
                  height: 50,
                }}
              >
                <div className="fa fa-filter text-white">
                  <Select
                    ref={searchOptionRef}
                    required={true}
                    options={searchOptions}
                    isSearchable={false}
                    components={{
                      IndicatorSeparator,
                      DropdownIndicator: CustomDropdownIndicator,
                    }}
                    styles={customStyles}
                    defaultValue={
                      searchOptions.length > 0 ? searchOptions[0] : ""
                    }
                    onChange={(value) => onChangeFilters(value, "searchOption")}
                  />
                </div>
              </Card>
            </Col>
          </Row>
          <Container className="my-5 px-3">
            <div
              className={`${
                searchWord != null && searchWord != "" ? "d-none" : "d-block"
              }`}
            >
              {blogs?.length > 0 && (
                <Row>
                  {blogs?.map((item, index) => (
                    <BlogCard
                      key={index}
                      blog={item}
                      read_url={`/blogs/${item?.slug}/`}
                    />
                  ))}
                </Row>
              )}
              {blogs?.length == 0 && (
                <Row>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center align-items-center text-white my-5 py-5"
                  >
                    No blogs available in this category!
                  </Col>
                </Row>
              )}
            </div>
            <div
              className={`${
                searchWord != null && searchWord != "" ? "d-block" : "d-none"
              }`}
            >
              {searchedBlogs?.length > 0 && (
                <Row>
                  {searchedBlogs?.map((item, index) => (
                    <BlogCard
                      key={index}
                      blog={item}
                      read_url={`/blogs/${item?.slug}/`}
                    />
                  ))}
                </Row>
              )}
              {searchedBlogs?.length == 0 && (
                <Row>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center align-items-center text-white my-5 py-5"
                  >
                    No blogs available with this word!
                  </Col>
                </Row>
              )}
            </div>
          </Container>
        </Container>
      </Breakpoint>
    </React.Fragment>
  );
};

export default Blogs;
