import React, { useState } from "react";
import { Container, Image, Card as BCard } from "react-bootstrap";
import { CardAction } from "ui-neumorphism";
import { useLocation } from "react-router-dom";
import { BsChevronDoubleDown, BsChevronDoubleUp } from "react-icons/bs";
import { useCurrentBreakpointName } from "react-socks";

// import {
//   bigentrygate,
//   smallentrygate,
//   girlvr,
//   boyvr,
//   ferriswheel,
//   merrygoround,
//   archery,
//   rushikeshveer,
//   tyre_company,
//   bridgestone,
// } from "assets_old/business";
import { tyre_company } from "assets/business";
import { Breakpoint } from "react-socks";
import ReactPlayer from "react-player";

const CaseStudy2 = () => {
  const location = useLocation();

  const breakpoint = useCurrentBreakpointName();
  const [isReadMore, setIsReadMore] = useState(
    breakpoint == "xsmall" ? false : true
  );

  return (
    <React.Fragment>
      {/* <Helmet>
        <link rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
      </Helmet> */}
      <Container
        className="h-100 mt-5 pt-5 casestudy-background-case px-lg-5"
        fluid
      >
        <Breakpoint large up>
          <ul className="breadcrumb subtitle mx-4 mb-3">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/business">CaseStudy</a>
            </li>
            <li>AR Solution for Tyre Company</li>
          </ul>
          <BCard
            className="position-relative mx-4 overflow-hidden custom-card-profile"
            dark={true}
            style={styles.cardImage}
            flat
          >
            <Image
              loading="lazy"
              className="m- "
              src={tyre_company}
              alt="Tyre Company"
              style={{ width: "100%" }}
            />
            <CardAction
              className="m-0 p-3 m-2 m-3 position-absolute d-flex justify-content-between"
              style={styles.cardActionOpacity}
            >
              <h4 className="m-2 text-white">AR Solution for Tyre Company</h4>
              {/* <a
                href="https://znanye.com/znanye_games/ZnanyeLand/"
                className="text-decoration-none"
              >
                <Button
                  className="me-2 text-white explore-button h-50"
                  dark={true}
                >
                  <span className="poppins">WEB VR</span>
                </Button>
              </a> */}
            </CardAction>
          </BCard>
        </Breakpoint>
        <Breakpoint medium down>
          <BCard
            className="position-relative"
            dark={true}
            style={styles.cardImage}
          >
            <div className="d-flex justify-content-center">
              <Image
                loading="lazy"
                className="m-0"
                src={tyre_company}
                alt="Tyre Company"
                style={{ width: 335 }}
              />
            </div>
          </BCard>
          <h2 className="mt-4 text-white d-flex justify-content-center">
            AR Solution for Tyre Company
          </h2>
          {/* <div className="d-flex flex-column justify-content-center align-items-center">
            <a
              href="https://znanye.com/znanye_games/ZnanyeLand/"
              className="text-decoration-none"
            >
              <Button
                className="mt-5 text-white explore-button h-50 w-100"
                dark={true}
              >
                <span className="poppins">WEB VR</span>
              </Button>
            </a>
          </div> */}
        </Breakpoint>
      </Container>
      <Container
        className="d-flex w-100 h-100 pt-5 ps-lg-5 casestudy-background-case text-white text-center"
        fluid
        style={styles.projectContainer}
      >
        <div className="px-lg-4 text-lg-start d-flex flex-column align-items-center align-items-lg-start">
          <div>
            <h4>Why We started this project</h4>
          </div>
          <div style={styles.underline}></div>
          <div className="pe-lg-4">
            <div className="mt-4">
              You can buy clothes without visiting a store. You can buy
              groceries without visiting a store. You can buy your meal without
              visiting a store. Then why not your car tyres? It is (mostly) once
              every 3- or 4-year exercise that you buy tyres for your car. What
              if you could choose between various tyres from the comfort of your
              home or office… see every detailing of the tyre; and even see how
              the tyre looks on your car?
            </div>
          </div>
        </div>
      </Container>
      <Container
        className="d-flex w-100 h-100 pt-5 p-lg-5 casestudy-background-case text-white text-center"
        fluid
        style={styles.solutionContainer}
      >
        <div className="px-lg-4 text-lg-start d-flex flex-column align-items-center align-items-lg-start">
          <div>
            <h4>How we come up with this solution</h4>
          </div>
          <div style={styles.underline}></div>
          <div className="pe-lg-4">
            <div className="mt-4">
              We can buy furniture using AR/VR. Then why not tyres. We designed
              the tyres and the cars, with all available details. We then added
              physics to them. And behold, you would experience the tyres on
              your car from the confirm of your home or office.
            </div>

            {/* <div>
              Auctor sollicitudin at libero in. Nec quisque nisl faucibus
              fermentum. Magna ac feugiat adipiscing massa eu amet elit
              dignissim augue. Ultrices malesuada enim varius quam pretium
              purus. Cras adipiscing curabitur nulla vulputate porttitor
              volutpat felis. Amet lectus eu elementum velit. Arcu vitae nunc
              ullamcorper faucibus at dignissim felis, scelerisque congue.
              Pellentesque sagittis aenean adipiscing tempor imperdiet felis
              nibh mattis. Dictum nec dui et quisque tempus, vehicula.
            </div>
            <div>
              Quisque urna est, purus amet sed sem ornare eu. Sed porta nunc
              nunc donec nibh eget eget ridiculus. Pharetra dictum ultrices
              velit a adipiscing sit ullamcorper faucibus ut. Non lacus lectus
              tristique feugiat vel. Blandit in enim orci maecenas pellentesque
              eget laoreet mus massa. Tristique feugiat velit nam sagittis,
              ultricies convallis arcu scelerisque. A, molestie mattis auctor
              posuere volutpat, nibh diam odio et. Gravida nisi, aliquet
              volutpat sapien purus at nec. Dictum blandit posuere risus eu.
              Quis viverra pulvinar dui et in quis accumsan id consectetur.
              Facilisis faucibus penatibus vitae arcu vulputate in turpis eu
              tellus. Et scelerisque mauris a vulputate etiam nec venenatis.
              Aenean nulla vitae, euismod gravida enim, nibh tempus suspendisse
              tempor.
            </div>
            <span className={`${isReadMore ? "d-block" : "d-none"}`}>
              <div>
                Aliquet pellentesque congue sed tristique. Risus sapien
                imperdiet odio ullamcorper. Lectus sit id nisl diam odio orci
                tempus non vitae. Erat mauris turpis curabitur ornare. Mi ut sed
                eu facilisi pellentesque ultrices fames. Nibh ut sit egestas
                auctor netus quis. Sed eget praesent dictum varius arcu.
              </div>
              <div>
                Adipiscing a, at aliquam pellentesque diam. Ridiculus nunc
                mauris consectetur porta enim enim consectetur. Quam in cum
                purus, in lectus. Senectus diam vulputate donec.
              </div>
            </span> */}
            {/* <div className="d-flex flex-column flex-md-row justify-content-center my-5 py-3 px-2">
              <Card
                className="position-relative my-3 mx-lg-3"
                dark={true}
                style={styles.cardImage}
              >
                <Image loading="lazy" className="m-0" src={girlvr} style={{ width: "100%" }} />
              </Card>
              <Card
                className="position-relative my-3 mx-lg-3"
                dark={true}
                style={styles.cardImage}
              >
                <Image loading="lazy" className="m-0" src={boyvr} style={{ width: "100%" }} />
              </Card>
            </div> */}
            {/* <span className={`${isReadMore ? "d-block" : "d-none"}`}>
              <div className="mt-3">
                Velit enim risus aliquet volutpat bibendum pellentesque in. Eu
                nisl volutpat fermentum, leo eget velit. Volutpat arcu amet
                nulla tincidunt nisi integer odio eu morbi. Platea netus nec
                imperdiet vitae. Eget turpis arcu amet faucibus pretium ornare
                imperdiet. Vulputate id tristique pretium proin. Ante tempor, id
                hendrerit quis consectetur dui risus, volutpat, magna. Sed
                habitant interdum eu, amet fermentum sem libero, sed adipiscing.
                Commodo dis dolor, felis augue felis. Urna, etiam velit nec in
                phasellus vel in. Duis urna, tincidunt semper lectus pulvinar
                lorem quisque. Purus fermentum, diam et pellentesque ac semper
                ac at ultricies. Convallis pellentesque adipiscing volutpat hac
                enim. Feugiat eget tellus, sagittis, aliquet sed massa a pretium
                elementum.
              </div>
              <div>
                Leo convallis viverra magna cursus diam. Bibendum pellentesque
                viverra morbi tellus, et et. Porttitor morbi turpis nunc, nam
                vivamus eget vestibulum. Adipiscing ultricies hendrerit viverra
                posuere non. Ultricies ac eros sed mattis tristique quis congue
                nisl nisl. Fermentum adipiscing blandit feugiat scelerisque.
              </div>
              <div>
                Auctor non gravida aliquam, dictumst. Molestie dignissim vivamus
                turpis augue sed morbi. Sed vitae ac purus in quam orci mauris
                vestibulum. Convallis nisl imperdiet blandit porttitor felis
                quis vestibulum. Ultrices senectus in vel pharetra ultricies
                aenean. Luctus faucibus congue mauris, nunc pellentesque eget
                consequat auctor. Sit pharetra enim felis enim in. Id eget
                scelerisque aliquam risus pellentesque. Diam vel, metus
                tincidunt nullam lorem fames.
              </div>
              <div>
                Laoreet in eget luctus id nulla sagittis et. Nunc ut diam
                elementum mauris pulvinar tellus. Neque risus at ac suspendisse
                augue convallis. Egestas neque, varius lorem viverra cursus.
                Enim, venenatis, vel posuere lectus lacus scelerisque at.
                Interdum arcu quam at fames. Tristique felis, volutpat, mattis
                velit vestibulum, lectus mollis ut ultricies. Mi massa sagittis
                pulvinar vitae ultricies natoque at arcu. Risus pretium pretium,
                tincidunt nibh rhoncus rhoncus dolor. Feugiat ac mauris erat
                faucibus diam viverra. Quam rhoncus cursus imperdiet ultrices
                diam. Nec laoreet sapien viverra nibh diam. Adipiscing tristique
                pharetra varius nulla. Quis consectetur blandit viverra tortor
                quis at sit.
              </div>
            </span> */}
          </div>
          <div className="d-flex justify-content-center w-100 my-5 py-5">
            <ReactPlayer
              className="react-player"
              url={
                "https://dukqh1e9lwqr5.cloudfront.net/bridgestone.86890718.mp4"
              }
              width="100%"
              height="500px"
              controls={true}
            />
          </div>
          <div
            className={`${isReadMore ? "d-block" : "d-none"
              } d-flex flex-column align-items-center align-items-lg-start mb-4`}
          >
            <div>
              <h4>How does this satisfy our users</h4>
            </div>
            <div style={styles.underline}></div>
            <div className="pe-lg-4">
              <div className="mt-4">
                Most people buying tyres for their car either buy the same tyre
                they already have or go by how a new tyre looks on their car.
                Our solution offered these buyers to decide the same – after
                looking at all details of the tyres they chose on the car they
                have. Except that, they could do so without stepping out of
                their comfort- be at home or at office. They could choose their
                car tyres during their work breaks or on a lazy Sunday
                afternoon. The new world of Virtual Reality holds unprecedented
                possibilities.
              </div>
              {/* <div>
                Dolor, tristique in sit parturient tristique bibendum in.
                Montes, eleifend vitae massa sit sapien nibh consequat neque.
                Commodo, commodo eleifend felis tempus risus pulvinar adipiscing
                ac. Elit purus eget elementum id auctor. Aliquet erat proin
                magna lacus nisl, gravida id. Faucibus posuere felis in congue.
                Vulputate volutpat convallis nunc convallis senectus interdum.
                Vulputate nunc nisl adipiscing molestie porttitor.
              </div> */}
            </div>
          </div>
        </div>
      </Container>
      <Breakpoint medium down>
        <Container
          className="d-flex w-100 flex-column justify-content-center align-items-center h-100 mb-5"
          fluid
        >
          <div
            className="text-secondary poppins-500"
            style={styles.readMoreButton}
            onClick={() => {
              setIsReadMore(!isReadMore);
            }}
          >
            {isReadMore ? (
              <>
                READ LESS <BsChevronDoubleUp size="18" />
              </>
            ) : (
              <>
                READ MORE <BsChevronDoubleDown size="18" />
              </>
            )}
          </div>
        </Container>
      </Breakpoint>
      {/* <Breakpoint medium down>
        <Container
          className="d-flex w-100 flex-column justify-content-center align-items-center h-100 pt-5"
          fluid
          style={styles.userReviewContainer}
        >
          <Container style={{ height: "100%", margin: -40 }}>
            <div className="my-5">
              <ZSlider type="review" items={reviews} />
            </div>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint large up>
        <Container
          className="d-flex w-100 flex-column justify-content-center align-items-center h-100 pt-5 casestudy-background-case"
          fluid
          style={styles.userReviewContainer}
        >
          <Container style={{ height: "100%" }}>
            <div className="my-5">
              <AnimatedSlider items={reviews} />
            </div>
          </Container>
        </Container>
      </Breakpoint>
      <Container
        className="d-flex flex-column w-100 h-100 pt-5 px-1 px-lg-5 casestudy-background-case text-white align-items-center align-items-lg-start"
        fluid
        style={styles.ridesContainer}
      >
        <div className="px-5 d-flex flex-column align-items-center align-items-lg-start">
          <h4>Rides you can enjoy</h4>
          <div style={styles.underline}></div>
        </div>
        <Container style={{ height: "100%" }}>
          <div className="my-5">
            <ZSlider type="casestudy" items={rides} />
          </div>
        </Container>
      </Container> */}
    </React.Fragment>
  );
};

const styles = {
  gateContainer: {
    minHeight: "90vh",
  },
  projectContainer: {
    minHeight: "30vh",
  },
  solutionContainer: {
    minHeight: "90vh",
  },
  userReviewContainer: {
    minHeight: "50vh",
  },
  ridesContainer: {
    minHeight: "50vh",
  },
  cardActionOpacity: {
    backgroundColor: "rgba(0,0,0,0.8)",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    bottom: 0,
    right: 0,
    left: 0,
  },
  cardImage: {
    borderRadius: 20,
    backgroundColor: "#1f1f1f",
  },
  underline: {
    height: 1,
    width: 120,
    backgroundColor: "white",
  },
  readMoreButton: {
    color: "red",
  },
};

export default CaseStudy2;
