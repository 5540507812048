import React from "react";
import { Container, Image, Modal } from "react-bootstrap";
import { Card } from "ui-neumorphism";

import { MdArrowBack, MdClose } from "react-icons/md";
import { useParams, Link, useSearchParams } from "react-router-dom";
import axios from "axios";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { imagenotavailable, transparent_image } from "assets";

const ImageModal = ({ visible, setVisible, productimage_set, setCurrentImage, currentImage }) => {
    // let { productId } = useParams();
    // let params = new URLSearchParams(document.location.search);
    // let imagesrc = params.get("imagesrc")
    return (
        <Modal
            show={visible}
            fullscreen={true}
            onHide={() => setVisible(false)}
            size="lg"
            centered
        // className="d-flex flex-column align-items-center h-100 home-background-categories p-0"
        // style={styles.parentContainer}
        >
            <Container fluid className="position-relative">
                <div className="position-absolute p-2 shadow-lg cursor-pointer" style={{ top: 20, right: 20, backgroundColor: "#323232", borderRadius: 50, zIndex: 2000 }} onClick={() => {
                    setVisible(false);
                }}>
                    {/* <MdArrowBack color="#ccc" size="30"/> */}
                    {/* <Link to={`/product/${productId}`}> */}
                    <MdClose color="#ccc" size="30" />
                    {/* </Link> */}
                </div>
                <div className="position-absolute user-select-none">
                    <Image loading="lazy" className="my-3 sprites-logo" src={transparent_image} alt="Znanye Logo" />
                </div>
            </Container>
            <div className="d-flex justify-content-center align-items-center">
                <Image
                    loading="lazy"
                    className="d-flex user-select-none"
                    // style={{ borderRadius: 20 }}
                    src={productimage_set?.length > 0 ? productimage_set[currentImage]?.image_file : imagenotavailable}
                    alt={productimage_set?.length > 0 ? productimage_set[currentImage]?.image_file : "Image Not Available"}
                    style={{ maxWidth: "100%", maxHeight: "100vh" }}
                />
            </div>
            <div className="d-flex flex-column justify-content-center h-100 position-absolute p-2">
                <div className="cursor-pointer" onClick={() => {
                    if (currentImage > 0) {
                        setCurrentImage(currentImage - 1);
                    }
                }}>
                    <div className="py-5" style={{ backgroundColor: "#555", borderRadius: 5 }}>
                        <BsChevronLeft size={100} color="#fff" />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column justify-content-center h-100 position-absolute p-2" style={{ right: 0 }}>
                <div className="cursor-pointer" onClick={() => {
                    if (currentImage < productimage_set.length - 1) {
                        setCurrentImage(currentImage + 1)
                    }
                }}>
                    <div className="py-5" style={{ backgroundColor: "#555", borderRadius: 5 }}>
                        <BsChevronRight size={100} color="#fff" />
                    </div>
                </div>
            </div>
        </Modal >
    );
};

const styles = {
    parentContainer: {
        minHeight: "100vh",
    },
};
export default ImageModal;
