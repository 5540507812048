import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate, Link } from "react-router-dom";
import { Container, Button as RButton, Image } from "react-bootstrap";
import "core-ui/pages/Seller.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "actions/AuthActions";
import { SellerCreated } from "components";
import { Breakpoint } from "react-socks";
import { LoginPose } from "assets";

const Seller = ({ type }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";
  const param3 = params.length > 1 ? params[2] : "";
  const param4 = params.length > 1 ? params[3] : "";

  const [sellerCreated, setSellerCreated] = useState(false);
  const user = useSelector((state) => state.authReducer.user);
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  useEffect(() => {
    dispatch(actions.getMySellerProfile(user?.id));
  }, []);

  const seller = useSelector((state) => state.authReducer.seller);

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const sellerCreate = async () => {
    const formData = new FormData();
    console.log("Copying Seller Data from user to seller");
    formData.append("user", user.id);
    formData.append("business_name", user.name);
    formData.append("email", user.email);
    formData.append("phone_number", user.mobile);
    formData.append("country", user.phone_code);
    formData.append("gst_vat", user.gst_number);
    const response = await actions.createSeller(formData);
    if (response) {
      console.log("Creation in progress!")
      // bankAccountCreate(response.id);
      // dispatch(actions.getMySellerProfile(user.id));
      setSellerCreated(true);
      setTimeout(() => {
        setSellerCreated(false);
        handleNavigationClick("/sell-3d/uploadproducts");
        // navigate("/sell-3d/uploadproducts");
      }, 5000);
    }
  };

  // const bankAccountCreate = async (sellerId) => {
  //     console.log("Create Bank Account")
  //     const formData = new FormData();
  //     formData.append("seller", sellerId);
  //     formData.append("account_created_by", user.id);
  //     formData.append("account_modified_by", user.id);
  //     const response = await actions.createBankAccount(formData);
  //     if (response) {
  //         setSellerCreated(true);
  //         setTimeout(() => {
  //             setSellerCreated(false);
  //             dispatch(actions.getMySellerProfile(user.id));
  //             navigate('/sell-3d/uploadproducts')
  //         }, 5000);
  //     }
  // }

  useEffect(() => {
    if (!seller.id) {
      sellerCreate();
    }
    // else if (seller.id){
    //   navigate("uploadproducts")
    // }
  }, [seller, isAuthenticated]);

  if (sellerCreated) {
    return (
      <div className={`${sellerCreated ? "d-block" : "d-none"}`}>
        <SellerCreated />
      </div>
    );
  }

  return (
    <React.Fragment>
      {/* <Helmet>
                <title>{`Znanye | Sell 3D assets and VR models to earn money`}</title>
                <meta name="description" content="Now you can earn one of the highest commissions of up to 91% on our 3D marketplace. Sell your 3D assets on our 3D marketplace, Znanye." />
                <link rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
                <link rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
            </Helmet> */}
      <Breakpoint large up>
        {params.length < 2 && param1 === "sell-3d" && (
          <div style={{ minHeight: "100vh" }}></div>
        )}
      </Breakpoint>
      <Breakpoint medium down>
        <Container
          fluid
          className="pt-5 d-flex flex-column justify-content-between"
          style={styles.parentContainer}
        >
          <div>
            <div className="mt-5 text-white text-center h3 poppins-500 pt-4">
              <p className="pt-4">
                It Seems that you are logged in register through mobile !
              </p>
              <p className="pt-4">
                You Need To Login Through the Desktop To sell Your Products
              </p>
            </div>
            <div className="d-flex justify-content-center py-4">
              <Link className="text-decoration-none" to={"/"}>
                <RButton
                  dark={true}
                  className=" explore-button-no-neo btn btn-ptimaryexplore-button-large mt-5"
                  style={{ backgroundColor: "#27292A" }}
                >
                  GO BACK
                </RButton>
              </Link>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Image loading="lazy" src={LoginPose} alt="Login through desktop" />
          </div>
        </Container>
      </Breakpoint>
      <Outlet />
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
    paddingTop: 50,
  },
  progressNavBar: {
    minHeight: "85px",
    backgroundColor: "#1f1f1f",
    position: "sticky",
    top: 0,
  },
  connector: {
    height: 2,
    flex: 1,
  },
  currentPage: {
    width: 15,
    height: 15,
    borderRadius: 15 / 2,
  },
};

export default Seller;
